import {
	Box,
	Button,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Flex,
	useToast,
} from "@chakra-ui/react";
import React, {useState, useEffect} from "react";
import {t} from "i18next";
import {useTable} from "react-table";
import {convertToUsd} from "utils/convertToUsd";
import {getLanguage} from "utils";
import {HSeparator} from "components/Separator/Separator";
import {useDispatch} from "react-redux";
import {renewalCloudManyServices} from "services/api";
import {RESPONSE_STATUS} from "utils";
import {getUserCredit} from "redux/slices/userSlice";
import {getAllCloudServices} from "redux/slices/serviceSlice";
import {getAllInvoicesCloudServiceByClient} from "redux/slices/serviceSlice";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {createOrderRenewalApi} from "services/api";
import {approveOrderRenewalApi} from "services/api";
import {checkServicesRenewal} from "services/api";
import {renewalCloudManyServicesPaypal} from "services/api";
import {MdPayment, MdPayments} from "react-icons/md";
import {BiLogoPaypal} from "react-icons/bi";
// const initialOptions = {
//   clientId:
//     "AYZiZOqfbM7IJ8kSvCIwIx1zYZkzQRM6Z4bZ5WJpv00IBTpkHtOsoouE4qLjuzHs3AYAMBnoRzuv2O3Y",
//   currency: "USD",
//   intent: "capture",
// };
const initialOptions = {
	clientId:
		"Af3dliCQE1RHjT9sfOP4eqqatWFlJd_-Qtpd6kln6uixb4P6t569FEKHBQhbTdlhMr4XJlXwFAxXt-u9",
	currency: "USD",
	intent: "capture",
};

function ModalRenewalServices({show, onClose, services, columns, onPayByBank}) {
	const [paypalChecked, setPaypalChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const [modalConfirmBalance, setModalConfirmBalance] = useState(false);
	const toast = useToast({
		duration: 5000,
		position: "top-end",
		isClosable: true,
	});

	const tableInstance = useTable({
		columns,
		data: services,
	});

	const {getTableProps, getTableBodyProps, headerGroups} = tableInstance;

	const genTotal = () => {
		let total = 0;
		services?.forEach((item) => {
			total += Number(item?.total);
		});
		return total;
	};
	const genListVps = () => {
		let listVps = [];
		services?.forEach((item) => {
			listVps.push(item?.config?.ip);
		});
		return listVps;
	};

	const handlePaymentByBalance = async () => {
		try {
			setModalConfirmBalance(false);
			setIsLoading(true);

			const result = await renewalCloudManyServices(
				services?.map((item) => item?.id)
			);
			const {status} = result.data;
			if (status === RESPONSE_STATUS.FAILED) {
				toast({
					title: "Renewal services are failed",
					status: "error",
				});
				return;
			}

			if (status === RESPONSE_STATUS.REDIRECT) {
				onPayByBank();
				return;
			}

			toast({
				title: t("common.service.successfullyRenewal"),
				status: "success",
			});
			dispatch(getUserCredit({}));
			dispatch(getAllCloudServices());
			dispatch(getAllInvoicesCloudServiceByClient());
			onClose();
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};
	const handlePaymentByPaypal = async () => {
		try {
			const result = await checkServicesRenewal(
				services?.map((item) => item?.id)
			);
			const {status} = result.data;
			if (status === RESPONSE_STATUS.FAILED) {
				toast({
					title: "Renewal services are failed",
					status: "error",
				});
				return;
			}

			setPaypalChecked(true);
			toast({
				title: "Please make payment to renewal services",
				status: "success",
			});
		} catch (error) {
			console.log(error);
		}
	};

	//

	const createOrder = async (data, actions) => {
		try {
			const result = await createOrderRenewalApi({
				cart: {
					total: genTotal(),
				},
			});

			const orderData = result?.data;
			if (orderData?.id) {
				return orderData?.id;
			} else {
				const errorDetail = orderData?.details?.[0];
				const errorMessage = errorDetail
					? `${errorDetail?.issue} ${errorDetail?.description} (${orderData?.debug_id})`
					: JSON.stringify(orderData);

				throw new Error(errorMessage);
			}
		} catch (error) {
			return;
		}
	};
	const onApprove = async (data, actions) => {
		// Order is captured on the server and the response is returned to the browser
		try {
			const result = await approveOrderRenewalApi({
				orderID: data?.orderID,
				cart: {
					total: genTotal(),
					listVps: genListVps(),
				},
			});
			const orderData = result?.data;

			const transaction =
				orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
				orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
			const errorDetail = orderData?.details?.[0];

			// this actions.restart() behavior only applies to the Buttons component
			if (
				errorDetail?.issue === "INSTRUMENT_DECLINED" &&
				!data.card &&
				actions
			) {
				// (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
				// recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
				return actions.restart();
			} else if (
				errorDetail ||
				!transaction ||
				transaction.status === "DECLINED"
			) {
				// (2) Other non-recoverable errors -> Show a failure message
				let errorMessage;
				if (transaction) {
					errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
				} else if (errorDetail) {
					errorMessage = `${errorDetail.description} (${orderData.debug_id})`;
				} else {
					errorMessage = JSON.stringify(orderData);
				}

				throw new Error(errorMessage);
			} else {
				const result = await renewalCloudManyServicesPaypal(
					services?.map((item) => item?.id)
				);
				const {status} = result?.data;
				if (status === RESPONSE_STATUS.FAILED) {
					toast({
						title: "Renewal services are failed",
						status: "error",
					});
					return;
				}

				toast({
					title: "Order completed",
					description:
						"We’ve sent you an email with all of the details of your reOrder.",
					status: "success",
				});
			}
		} catch (error) {
			console.error("errorrr");
			// resultMessage(
			//   `Sorry, your transaction could not be processed...<br><br>${error}`
			// );
		}
	};

	return (
		<Modal size="2xl" isOpen={show} onClose={onClose}>
			<ModalOverlay />
			<ModalContent bgColor={"#5f68c9"}>
				<ModalHeader color={"white"}>
					{t("common.service.renewalService")}
				</ModalHeader>
				<ModalCloseButton color={"white"} />

				<ModalBody>
					<Table
						{...getTableProps()}
						variant="simple"
						color="gray.500"
						mb="24px"
					>
						<Thead>
							{headerGroups.map((headerGroup, index) => (
								<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
									{headerGroup.headers.map((column, index) => (
										<Th borderColor="#56577A" pe="0px" key={index}>
											<Flex
												justify="space-between"
												align="center"
												fontSize={{sm: "10px", lg: "12px"}}
												color="white"
											>
												{column.render("label")}
											</Flex>
										</Th>
									))}
								</Tr>
							))}
						</Thead>
						<Tbody {...getTableBodyProps()}>
							{services?.map((row, index) => {
								return (
									<Tr key={index}>
										<Td>
											<Flex alignItems={"center"} gap={3} mb="2">
												<Text
													as="span"
													color={"white"}
													fontWeight={"500"}
													fontSize={"14px"}
												>
													{row?.config?.ip}
												</Text>
											</Flex>
										</Td>
										<Td>
											<Text color={"white"} fontWeight={"600"}>
												{convertToUsd(Number(row?.total), getLanguage())}
											</Text>
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
					<Box>
						<Text fontWeight={"bold"} fontSize={"16px"} color={"yellow.100"}>
							{t("common.service.quantity")}: {services?.length}
						</Text>
						<Text fontWeight={"bold"} fontSize={"16px"} color={"green.100"}>
							{t("common.service.total")}:{" "}
							{convertToUsd(genTotal(), getLanguage())}
						</Text>
					</Box>
					<Flex justifyContent={"space-between"} alignItems={"center"} mt={4}>
						<Button
							bg={"green.400"}
							_hover={{
								bg: "green.500",
							}}
							color={"white"}
							leftIcon={<MdPayments size={18} />}
							h="40px"
							mt={"12px"}
							mb={"18px"}
							fontSize="12px"
							fontWeight="bold"
							alignSelf={{sm: "flex-start", lg: null}}
							isLoading={isLoading}
							isDisabled={isLoading}
							onClick={() => setModalConfirmBalance(true)}
						>
							{t("common.service.payBalance")}
						</Button>
						<Button
							leftIcon={<MdPayment size={18} />}
							bg={"green.400"}
							_hover={{
								bg: "green.500",
							}}
							color={"white"}
							h="40px"
							mt={"12px"}
							mb={"18px"}
							fontSize="12px"
							fontWeight="bold"
							alignSelf={{sm: "flex-start", lg: null}}
							onClick={() => onPayByBank()}
							isLoading={isLoading}
							isDisabled={isLoading}
						>
							{t("common.service.payBank")}
						</Button>
						{paypalChecked ? (
							<PayPalScriptProvider options={initialOptions}>
								<PayPalButtons
									createOrder={(data, actions) => {
										return createOrder(data, actions);
									}}
									onApprove={(data, actions) => {
										onApprove(data, actions);
									}}
									style={{
										color: "gold", // Customize the color of the button
										shape: "pill", // Set the button shape to rectangular
										layout: "horizontal", // Set the button layout to horizontal
										label: "pay", // Change the button label
										height: 40,
									}}
								/>
							</PayPalScriptProvider>
						) : (
							<Button
								leftIcon={<BiLogoPaypal size={18} />}
								bg={"green.400"}
								_hover={{
									bg: "green.500",
								}}
								color={"white"}
								h="40px"
								mt={"12px"}
								mb={"18px"}
								fontSize="12px"
								fontWeight="bold"
								onClick={handlePaymentByPaypal}
								alignSelf={{sm: "flex-start", lg: null}}
								isLoading={isLoading}
								isDisabled={isLoading}
							>
								{t("common.service.payPaypal")}
							</Button>
						)}
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose} size="sm">
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>

			<Modal
				isOpen={modalConfirmBalance}
				onClose={() => setModalConfirmBalance(false)}
			>
				<ModalOverlay />
				<ModalContent mt={32} bgColor={"#5f68c9"}>
					<ModalHeader color={"white"}></ModalHeader>
					<ModalCloseButton color={"white"} />

					<ModalBody>
						<Text textAlign={"center"} mt="8" color={"white"}>
							{t("common.service.confirmRenewalService")}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={handlePaymentByBalance}
							colorScheme="purple"
							mr={3}
							size="sm"
						>
							Confirm
						</Button>
						<Button onClick={() => setModalConfirmBalance(false)} size="sm">
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Modal>
	);
}

export default ModalRenewalServices;
