import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Select,
  Stack,
  Text,
  toast,
  useDisclosure,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import LoadingOVH from "components/Loading/Loading";
import TableCloudServices from "components/Tables/TableCloudServices";
import { getLanguage } from "utils";
import CloudServiceDetail from "containers/CloudServiceList/CloudServiceDetail";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { AiFillCodeSandboxCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import ServicesList from "views/Services/ServicesList";
import { AiOutlineReload } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { minusNowAndExpireServiceActive } from "utils";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { refreshAllCloudServices } from "redux/slices/serviceSlice";

import {
  b1,
  b2,
  b3,
  b4,
  b5_1,
  b5,
  b6,
  b6_1,
  b7,
  b8,
  b9,
  b10,
  b11,
  b11_1,
  b11_2,
} from "../../assets/img/modalTele";
import { updateUserById } from "redux/slices/userSlice";
import ExportExcel from "components/Excel/Excel";
function CloudServiceList() {
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  const btnRef = React.useRef(null);
  const caculateBackupPrice = (selectedBackup) => {
    let price = 0;
    if (!backup.find((item) => item.name == service?.backup?.name)) {
      const backupStr = service?.backup?.split("GB")?.[0];
      price += 19.6 + Number(backupStr) * 0.18;
      return price;
    } else {
      return (
        backup.find((item) => item.name == service?.backup?.name)?.price || 0
      );
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  const columns = [
    {
      Header: "checkbox",
    },
    {
      Header: "SERVICE",
      label: t("common.table.service"),
    },
    {
      Header: "SERVICE LABEL",
      label: t("common.table.serviceLabel"),
    },
    {
      Header: "IPV4",
      label: "IPV4",
    },
    {
      Header: "OS",
      label: t("common.table.os"),
    },
    {
      Header: "INVOICE",
      label: t("common.table.invoice"),
    },
    {
      Header: "EXPIRATION",
      label: t("common.table.expiration"),
    },
    {
      Header: "STATUS",
      label: t("common.table.status"),
    },
    {
      Header: "ACTION",
      label: t("common.table.action"),
    },
  ];

  const ListMenuCloud = [
    {
      optionId: 1,
      name: t("common.service.active"),
      isCloud: false,
      cloudId: "Active",
    },
    {
      optionId: 2,
      name: t("common.service.expiresSoon"),
      isCloud: false,
      cloudId: "ExpiresSoon",
    },
    {
      optionId: 3,
      name: t("common.service.expires"),
      isCloud: false,
      cloudId: "Expires",
    },
    {
      optionId: 4,
      name: t("common.service.terminated"),
      isCloud: false,
      cloudId: "Terminated",
    },
  ];
  const [isLoading, setIsLoading] = useState(false);

  const [listMenuCloud, setListMenuCloud] = useState(ListMenuCloud);
  const [tab, setTab] = useState("Active");

  const [isCloudVN, setIsCloud] = useState(true);

  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDoc, setTotalDoc] = useState(0);
  const searchValue = useDebounce(search, 500);
  const [refreshData, setRefreshData] = useState(false);
  // service support for pagination
  const [allServicesExtra, setAllServicesExtra] = useState([]);

  const history = useHistory();
  // service to show
  const [services, setServices] = useState([]);
  const dispatch = useDispatch();
  //api telegram bot

  const { user } = useSelector((state) => state.user);
  const [apiTokenTelegram, setApiTokenTelegram] = useState(
    user?.apiTokenTelegram
  );
  const [isLoadingBtnLink, setIsLoadingBtnLink] = useState(false);

  //totalInvoice
  const [totalInvoice, setTotalInvoice] = useState(0);

  const handleLinkApiTokenTelegram = async () => {
    setIsLoadingBtnLink(true);
    try {
      const result = await dispatch(
        updateUserById({ apiTokenTelegram })
      ).unwrap();
      dispatch(updateUser(result));
    } catch (error) {
      // Dispatch failed, handle error
      console.error("Error updating user:", error);
    }
    setIsLoadingBtnLink(false);
    onClose();
  };

  function sum(accumulator, currentValue) {
    return accumulator + currentValue.total;
  }

  // all services of user
  const { allService, isLoading: isLoadingRedux } = useSelector(
    (state) => state.service
  );

  const handleReloadData = () => {
    window.location.reload(true);
  };

  const [sortExpire, setSortExpire] = useState(true);

  useEffect(() => {
    let temp = [];
    if (tab === "Active") {
      temp = allService
        ?.filter((item) => item?.status === "Active")
        ?.sort((a, b) => {
          let aValue =
            a?.expires === "0000-00-00"
              ? 9999999999999
              : minusNowAndExpireServiceActive(a?.expires) < -86400000
              ? 9999999999998
              : minusNowAndExpireServiceActive(a?.expires);
          let bValue =
            b?.expires === "0000-00-00"
              ? 9999999999999
              : minusNowAndExpireServiceActive(b?.expires) < -86400000
              ? 9999999999998
              : minusNowAndExpireServiceActive(b?.expires);

          return sortExpire ? aValue - bValue : bValue - aValue;
        });

      setAllServicesExtra(temp || []);
      setServices(temp?.slice(0, pageSize) || []);
      setTotalInvoice(temp.reduce(sum, 0));
    } else if (tab === "ExpiresSoon") {
      let temp = allService?.filter((item) => {
        var targetDate = moment(item?.expires);

        // Get the current date and time
        var currentDate = moment();
        // Calculate the difference in days and minutes
        var daysLeft = targetDate.diff(currentDate, "days");
        if (item?.status === "Active" && daysLeft <= 10 && daysLeft >= 0) {
          return item;
        }
      });
      setAllServicesExtra(temp || []);
      setServices(temp?.slice(0, pageSize) || []);
      setTotalInvoice(temp.reduce(sum, 0));
    } else if (tab === "Terminated") {
      temp = allService?.filter((item) => {
        var targetDate = moment(item?.expires);

        // Get the current date and time
        var currentDate = moment();
        // Calculate the difference in days and minutes
        var daysLeft = currentDate.diff(targetDate, "days");
        if (
          (item?.status === "Terminated" || item?.status === "Cancelled") &&
          item?.config?.memory !== "0" &&
          item?.config?.ip &&
          daysLeft > 15
        )
          return item;
      });
      setAllServicesExtra(temp || []);
      setServices(temp?.slice(0, pageSize) || []);
      setTotalInvoice(temp.reduce(sum, 0));
    } else if (tab === "Expires") {
      temp = allService.filter((item) => {
        var targetDate = moment(item?.expires);

        // Get the current date and time
        var currentDate = moment();
        // Calculate the difference in days and minutes
        var daysLeft = currentDate.diff(targetDate, "days");
        if (item?.status === "Active" && daysLeft <= 15 && daysLeft >= 0) {
          return item;
        }
      });
      setAllServicesExtra(temp || []);
      setServices(temp?.slice(0, pageSize) || []);
      setTotalInvoice(temp.reduce(sum, 0));
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    setSearch("");
  }, [allService, tab, isLoading, sortExpire]);

  useEffect(() => {
    // get services by tab
    let temp = [];
    if (tab === "Active") {
      temp = allService
        ?.filter((item) => item?.status === "Active")
        ?.sort((a, b) => {
          let aValue =
            a?.expires === "0000-00-00"
              ? 9999999999999
              : minusNowAndExpireServiceActive(a?.expires) < -86400000
              ? 9999999999998
              : minusNowAndExpireServiceActive(a?.expires);
          let bValue =
            b?.expires === "0000-00-00"
              ? 9999999999999
              : minusNowAndExpireServiceActive(b?.expires) < -86400000
              ? 9999999999998
              : minusNowAndExpireServiceActive(b?.expires);

          return aValue - bValue;
        });
    } else if (tab === "Terminated") {
      temp = allService?.filter(
        (item) => item?.status === "Terminated" || item?.status === "Cancelled"
      );
    } else {
      temp = allService.filter((item) => {
        const temp =
          new Date(item?.next_due).getTime() - Date.now() <
          7 * 24 * 60 * 60 * 1000;

        return temp && item?.status === "Active";
      });
    }
    if (searchValue) {
      const result = temp.filter((item) => {
        return (
          item?.domain?.includes(searchValue) || item?.ip?.includes(searchValue)
        );
      });

      setAllServicesExtra(result);
      setServices(result?.slice(0, pageSize));
    } else {
      setAllServicesExtra(temp || []);
      setServices(temp?.slice(0, pageSize) || []);
    }

    setPageIndex(1);
  }, [searchValue]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(refreshAllCloudServices());
    }, 3 * 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleAddCloud = (item) => {
    let menuCloudItem = listMenuCloud.find((x) => x.cloudId === item.id);
    if (!menuCloudItem) {
      let menuCloudItem = {
        optionId: listMenuCloud.length + 1,
        name: "Service detail #" + item.service_id,
        isCloud: true,
        cloudId: item.id || "",
      };
      setListMenuCloud([...listMenuCloud, menuCloudItem]);
      setTab(item?.service_id);
    } else {
      setTab(item?.service_id);
    }
  };

  const handleCloseCloudTab = (cloudId) => {
    if (tab === cloudId) {
      setTab(listMenuCloud[0].cloudId);
    }

    setListMenuCloud((prevState) =>
      prevState.filter((item) => item?.cloudId !== cloudId)
    );
  };
  const handleRefreshData = () => {
    setIsLoading(true);
  };

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      {isLoadingRedux ? (
        <LoadingOVH />
      ) : (
        <>
          <Flex align="center" justify="start" gap={5} mb={5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bg={isCloudVN ? "#582CFF" : "#0F1535"}
              textColor={"white"}
              rounded={"md"}
              p={2}
              cursor={"pointer"}
              onClick={() => setIsCloud(true)}
            >
              {t("common.button.cloudvn")}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bg={!isCloudVN ? "#582CFF" : "#0F1535"}
              textColor={"white"}
              rounded={"md"}
              p={2}
              cursor={"pointer"}
              onClick={() => setIsCloud(false)}
            >
              {t("common.button.cloudInternational")}
            </Box>
          </Flex>
          {isCloudVN ? (
            <Box>
              <Card className="!p-[1rem] md:!p-[22px]">
                <Flex alignItems={"center"} gap={3} flexWrap={"wrap"}>
                  {listMenuCloud?.map((item) => (
                    <Flex
                      alignItems={"center"}
                      key={item?.cloudId}
                      gap={2}
                      pb="2"
                      borderBottom={`3px solid`}
                      borderColor={
                        item?.cloudId === tab ? "brand.200" : "white"
                      }
                      cursor={"pointer"}
                      onClick={() => setTab(item?.cloudId)}
                    >
                      <Icon
                        as={AiFillCodeSandboxCircle}
                        color={"white"}
                        fontSize={"20px"}
                      />
                      <Text
                        color={"white"}
                        fontSize={"13px"}
                        fontWeight={"600"}
                      >
                        {item?.name}
                      </Text>

                      {item.isCloud && (
                        <Icon
                          ml={"1rem"}
                          cursor={"pointer"}
                          bg="red.400"
                          color={"white"}
                          p="4px"
                          borderRadius={"100%"}
                          as={CloseIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCloseCloudTab(item.cloudId);
                          }}
                        />
                      )}
                    </Flex>
                  ))}
                  <div className="grid gap-5">
                    <div className="col-span-12 md:col-span-6 cursor-pointer  gap-2 justify-center items-center">
                      <div className=" justify-center items-center">
                        <Button
                          onClick={onOpen}
                          className="text-[13px] font-bold uppercase tracking-wide"
                        >
                          {t("common.service.modalTele")}
                        </Button>
                      </div>

                      <Modal
                        onClose={onClose}
                        isOpen={isOpen}
                        isCentered
                        className="fixed"
                        scrollBehavior={scrollBehavior}
                        finalFocusRef={btnRef}
                      >
                        <ModalOverlay />
                        <ModalContent maxW="60rem">
                          <ModalHeader className="flex flex-col justify-center items-center uppercase">
                            {t("common.service.modalTele")}
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <div className="rows">
                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle1")}
                                </p>
                                <span className="p ">
                                  {t("common.botStepTele.stepSpan1")}
                                </span>
                                <Link
                                  to="https://desktop.telegram.org/"
                                  className="text-blue-500 italic"
                                >
                                  {t("common.botStepTele.stepLink1")}
                                </Link>
                              </div>
                              <div className="row flex justify-center my-3 ">
                                <Image
                                  src={b1}
                                  alt=""
                                  className="md:w-[300px]"
                                />
                              </div>
                              <div className="row">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle2")}
                                </p>
                              </div>
                              <div className="row my-3 justify-center flex">
                                <Image
                                  src={b2}
                                  alt=""
                                  className="md:w-[300px]"
                                />
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle3")}
                                </p>
                                <div className="row my-3 justify-center flex">
                                  <Image
                                    src={b3}
                                    alt=""
                                    className="md:w-[300px]"
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle4")}
                                </p>
                                <div className="row my-3 justify-center flex">
                                  <Image
                                    src={b4}
                                    alt=""
                                    className="md:w-[300px]"
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle5")}
                                </p>
                                <div className="row my-3 justify-center md:flex ">
                                  <div className="md:w-1/2 flex justify-center my-3">
                                    <Image
                                      src={b5}
                                      alt=""
                                      className="md:w-[300px]"
                                    />
                                  </div>
                                  <div className="md:w-1/2 flex justify-center my-3">
                                    <Image
                                      src={b5_1}
                                      alt=""
                                      className="md:w-[300px]"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle6")}
                                </p>
                                <span className="italic">
                                  {t("common.botStepTele.stepSpan6")}
                                </span>
                                <div className="row my-3 justify-center md:flex ">
                                  <div className="md:w-1/2 flex justify-center my-3">
                                    <Image
                                      src={b6}
                                      alt=""
                                      className="md:w-[300px]"
                                    />
                                  </div>
                                  <div className="md:w-1/2 flex justify-center my-3">
                                    <Image
                                      src={b6_1}
                                      alt=""
                                      className="md:w-[300px]"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle7")}
                                </p>
                                <span className="italic">
                                  {t("common.botStepTele.stepSpan7")}
                                </span>
                                <div className="row my-3 justify-center flex">
                                  <Image src={b7} alt="" className="bg-cover" />
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle8")}
                                </p>
                                <div className="row my-3 justify-center flex">
                                  <Image src={b8} alt="" className="" />
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle9")}
                                </p>

                                <div className="row my-3 justify-center flex">
                                  <Image src={b9} alt="" className="" />
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle10")}
                                </p>

                                <div className="row my-3 justify-center flex">
                                  <Image src={b10} alt="" className="" />
                                </div>
                              </div>

                              <div className="row my-3">
                                <p className="p title">
                                  {t("common.botStepTele.stepTitle11")}
                                </p>

                                <div className="row my-3 justify-center md:grid ">
                                  <div className="  justify-center my-3">
                                    <Image
                                      src={b11}
                                      alt=""
                                      className="md:w-[800px]"
                                    />
                                  </div>
                                  <div className="  flex justify-center my-3">
                                    <Image
                                      src={b11_1}
                                      alt=""
                                      className="md:w-[800px]"
                                    />
                                  </div>
                                  <div className="flex justify-center my-3">
                                    <Image
                                      src={b11_2}
                                      alt=""
                                      className="md:w-[800px]"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <div className="container flex flex-col gap-1 justify-center items-center pt-2">
                            <Input
                              className="mb-[20px]"
                              value={apiTokenTelegram}
                              onChange={(e) =>
                                setApiTokenTelegram(e.target.value)
                              }
                            ></Input>
                            <Button
                              isLoading={isLoadingBtnLink}
                              size="sm"
                              color="primary"
                              variant="flat"
                              radius="sm"
                              className="text-xs font-bold uppercase tracking-wide text-blue-500 bg-blue-100 hover:bg-blue-50"
                              onClick={handleLinkApiTokenTelegram}
                            >
                              {t("common.service.modalTele")}
                            </Button>
                          </div>
                          <ModalFooter>
                            {/* <Button onClick={onClose}>Close</Button> */}
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </div>
                    {/* <div className=" col-span-12 md:col-span-6  w-fit text-[18px] uppercase">
                    <p className="text-white font-bold rounded-md p-2 ">
                      {t("common.table.invoice") + ": "}
                      <span className="text-[#90cdf4]">
                        {getLanguage() === "en"
                          ? `${(totalTong / 25).toLocaleString(3)}`
                          : `${totalTong.toLocaleString(3)}`}
                        <span>{getLanguage() === "en" ? " $" : " VND"}</span>
                      </span>
                      <div className="h-[1px] total my-2 w-full"></div>
                    </p>
                  </div> */}
                  </div>
                  <ExportExcel allService={allService} />
                </Flex>
              </Card>

              {["Active", "Terminated", "Expires", "ExpiresSoon"].includes(
                tab
              ) ? (
                <Card mt="4">
                  <Flex
                    w={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Flex align="center" gap={3} flex={"1"} mb="4">
                      <Select
                        value={pageSize}
                        onChange={(e) => {
                          const size = Number(e?.target?.value);

                          setServices((prevState) => {
                            const newState = allServicesExtra.slice(0, size);

                            return newState;
                          });

                          setPageSize(size);
                          setPageIndex(1);
                        }}
                        color="gray.400"
                        bg="#0F1535"
                        border="0.5px solid"
                        borderColor="#E2E8F04D"
                        borderRadius="15px"
                        size="sm"
                        maxW="75px"
                        cursor="pointer"
                      >
                        <option>5</option>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                      </Select>
                      <Text fontSize="xs" color="gray.400" fontWeight="500">
                        entries per page
                      </Text>
                      {tab === "Expires" && (
                        <Text
                          fontSize="xs"
                          fontStyle={"bold"}
                          color="#d3f259"
                          fontWeight="500"
                        >
                          {t("common.service.attention")}
                        </Text>
                      )}
                    </Flex>

                    <Flex alignItems={"center"} gap={"20px"}>
                      <Icon
                        ml={"1rem"}
                        cursor={"pointer"}
                        bg="red.400"
                        color={"white"}
                        p="4px"
                        w={5}
                        h={5}
                        borderRadius={"100%"}
                        as={AiOutlineReload}
                        onClick={() => handleRefreshData()}
                      />
                      <Input
                        color="gray.400"
                        bg="#0F1535"
                        border="0.5px solid"
                        borderColor="#E2E8F04D"
                        borderRadius="15px"
                        type="text"
                        placeholder="Search..."
                        minW="175px"
                        maxW="175px"
                        fontSize="sm"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Flex>
                  </Flex>

                  {isLoading ? (
                    <LoadingOVH />
                  ) : (
                    <TableCloudServices
                      columns={columns}
                      totalDoc={allServicesExtra?.length}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      totalInvoice={totalInvoice}
                      tableData={services}
                      onChangePage={(value) => {
                        setServices((prevState) => {
                          const newState = allServicesExtra.slice(
                            (value - 1) * pageSize,
                            value * pageSize
                          );

                          return newState;
                        });
                        setPageIndex(value);
                      }}
                      handleAddCloud={handleAddCloud}
                      allServices={allService}
                      handleSortExpire={() => setSortExpire(!sortExpire)}
                    />
                  )}
                </Card>
              ) : (
                <CloudServiceDetail id={tab} />
              )}
            </Box>
          ) : (
            <ServicesList />
          )}
        </>
      )}
    </Box>
  );
}

export default CloudServiceList;
