import { toast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { vnpayPaymentResult } from "services/api";

function PaymentResult() {
  const location = useLocation();
  console.log("location?.search", location?.search);
  const searchParams = new URLSearchParams(location.search);
  const { invoiceId, paymentId } = useParams();

  const state = searchParams.get("vnp_TransactionStatus") === "00";

  useEffect(async () => {
    try {
      await vnpayPaymentResult({
        invoiceId,
        paymentId,
        queryStr: location?.search,
      });
      localStorage.removeItem("cloudService");
    } catch (error) {
      toast.notify(error?.response?.data?.message || "Update data fail");
    }
  }, []);

  return (
    <div>
      {state ? (
        <>
          <h1>Thanh toán hóa đơn thành công</h1>
          <p>Vui lòng đợi trong giây lát</p>
        </>
      ) : (
        <div>
          <h1> toán thất bại</h1>
          <p>
            Vui lòng kiểm tra lại tài khoản hoặc liên hệ tới số điện thoại 0985
            07 85 07 để giải đáp thắc mắc
          </p>
          <button type="primary" onClick={() => navigate("/")}>
            Về lại trang chủ
          </button>
          ,
        </div>
      )}
    </div>
  );
}

export default PaymentResult;
