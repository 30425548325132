// Chakra imports
import { Box, ChakraProvider, Portal } from "@chakra-ui/react";
import "assets/css/vud-dashboard-styles.css";
// core components
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import theme from "theme/themeAuth.js";
import Authentication2FA from "views/Authentication/2FA";
import ForgotPassword from "views/Authentication/ForgotPassword";
import SignInCover from "views/Authentication/SignIn/SignInCover";
import SignUpCover from "views/Authentication/SignUp/SignUpCover";

const routes = [
  {
    component: SignInCover,
    path: "/auth/sign-in",
  },
  {
    component: SignUpCover,
    path: "/auth/sign-up",
  },
  {
    component: Authentication2FA,
    path: "/auth/2fa",
  },
  {
    component: ForgotPassword,
    path: "/auth/forgot-password",
  },
];

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();

  const { user } = useSelector((state) => state.user);
  const history = useHistory();

  // React.useEffect(() => {
  //   if (user) {
  //     console.log("user", user);
  //     history.push("/services/list");
  //   }
  // }, [user]);

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };
  const navRef = React.useRef();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "auth";
  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box ref={navRef} w="100%">
        <Portal containerRef={navRef}>
          {/* <AuthNavbar
            secondary={getActiveNavbar(routes)}
            logoText='VISION UI PRO'
          /> */}
        </Portal>
        <Box w="100%">
          <Box ref={wrapper} w="100%">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/auth" to="/sign-in" />
            </Switch>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}
