import { useTranslation } from "react-i18next";
import packages from "../../mocks";
import { useEffect, useState } from "react";
import { formatPriceUSD, formatPriceVND } from "../../utils/format.price";
import { Button, useToast } from "@chakra-ui/react";
import { createVNGServer } from "services/api";
import { useHistory } from "react-router-dom";
import LoadingOVH from "components/Loading/Loading";

const CreateVNGServer = () => {
  const ram = [
    {
      id: 1,
      name: "8G DDR",
      price: 12,
    },
  ];

  const backup = [
    {
      id: 1,
      name: "Không",
      price: 0,
    },
    {
      id: 2,
      name: "10GB",
      price: 3.6,
    },
    {
      id: 3,
      name: "25GB",
      price: 7.2,
    },
    {
      id: 4,
      name: "50GB",
      price: 11.6,
    },
    {
      id: 5,
      name: "100GB",
      price: 19.6,
    },
    {
      id: 6,
      name: "custom 100GB+",
      price: 0,
    },
  ];

  const ssd = [
    {
      id: 1,
      name: "SSD 240GB",
      price: 8,
    },
    {
      id: 2,
      name: "SSD 480GB",
      price: 15.2,
    },
    {
      id: 3,
      name: "SSD 960GB",
      price: 26,
    },
    {
      id: 4,
      name: "SSD 1920GB",
      price: 48,
    },
    {
      id: 5,
      name: "SAS 600GB",
      price: 8,
    },
    {
      id: 6,
      name: "SAS 1.2TB",
      price: 16,
    },
    {
      id: 7,
      name: "SAS 1.8TB",
      price: 28,
    },
    {
      id: 8,
      name: "NVME 512GB",
      price: 24,
    },
    {
      id: 9,
      name: "NVME 960GB",
      price: 40,
    },
    {
      id: 10,
      name: "NVME 1.88TB",
      price: 60,
    },
    {
      id: 11,
      name: "NVME 1.92TB",
      price: 80,
    },
  ];
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState(packages?.[0]?.id); //
  const [isCustomBackup, setIsCustomBackup] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("permonth");
  const [tax, setTax] = useState(0);
  const [selectedBackup, setSelectedBackup] = useState(backup?.[0].id);
  const [premiumSupport, setPremiumSupport] = useState(false); //
  const [ipv4, setIpv4] = useState(0); //
  const [ipv6, setIpV6] = useState(0);
  const [selectedDataCenter, setSelectedDataCenter] = useState("idc");
  const [customBackup, setCustomBackup] = useState(1); //
  const [additionalSsd, setAdditionalSsd] = useState({
    item: ssd[0].id,
    number: 0,
  });
  const [loading, setLoading] = useState(false);
  const [nationalBandwith, setNationalBandwith] = useState(0); //
  const [internationalBandwith, setInternationalBandwith] = useState(0); //

  const [additionalRam, setAdditionalRam] = useState({
    item: ram[0].id,
    number: 0,
  });

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const navigate = useHistory();

  const caculateTotalPrice = () => {
    let price = 0;
    price += Number(caculatePricePeriod());
    price += 32; // set up fee
    price += caculateBackupPrice();
    if (premiumSupport) {
      price += 80;
    }

    if (ipv4 > 0) {
      price += Number(ipv4) * 4;
    }

    if (ipv6 > 0) {
      price += Number(ipv6) * 1.2;
    }

    if (nationalBandwith > 0) {
      price += nationalBandwith * 48;
    }

    if (internationalBandwith > 0) {
      price += internationalBandwith * 160;
    }
    // console.log(ram.find((item) => item.id == additionalRam.item)?.price);
    if (additionalRam.number > 0) {
      price +=
        Number(ram.find((item) => item.id == additionalRam.item)?.price || 0) *
        Number(additionalRam.number);
    }

    if (additionalSsd.number > 0) {
      price +=
        Number(ssd.find((item) => item.id == additionalSsd.item)?.price || 0) *
        Number(additionalSsd.number);
    }

    setTotalPrice(price);
    setTax(price / 10);
    setTotalPriceWithTax(price + price / 10);
  };

  const handleChangeCustomBackUp = (e) => {
    setCustomBackup(e.target.value);
  };

  const handleChangeBackup = (e) => {
    const item = backup.find((x) => x.id == e.target.value)?.name;
    setSelectedBackup(e.target.value);
    if (item == "custom 100GB+") {
      setIsCustomBackup(true);
    } else {
      setIsCustomBackup(false);
    }
  };

  const caculatePricePeriod = () => {
    let price = 0;
    if (selectedPeriod == "permonth") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.permonth
      );
      return price;
    }

    if (selectedPeriod == "price3") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price3
      );
      return price;
    }

    if (selectedPeriod == "price6") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price6
      );
      return price;
    }

    if (selectedPeriod == "price12") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price12
      );
      return price;
    }

    if (selectedPeriod == "price24") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price24
      );
      return price;
    }
  };

  const generatePricePeriod = () => {
    let price = "";
    if (selectedPeriod == "permonth") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == selectedPackage)?.permonth
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == selectedPackage)?.permonth
            );
      return price;
    }

    if (selectedPeriod == "price3") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == selectedPackage)?.price3
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == selectedPackage)?.price3
            );
      return price;
    }

    if (selectedPeriod == "price6") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == selectedPackage)?.price6
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == selectedPackage)?.price6
            );
      return price;
    }

    if (selectedPeriod == "price12") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == selectedPackage)?.price12
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == selectedPackage)?.price12
            );
      return price;
    }

    if (selectedPeriod == "price24") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == selectedPackage)?.price24
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == selectedPackage)?.price24
            );
      return price;
    }
  };
  const caculateBackupPrice = () => {
    let price = 0;
    if (selectedBackup == 6) {
      price += 19.6 + customBackup * 0.18;
      return price;
    } else {
      return backup.find((item) => item.id == selectedBackup)?.price || 0;
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const result = await createVNGServer({
        config: packages.find((item) => item.id == selectedPackage).id,
        billingCycle: selectedPeriod,
        additionalRam: {
          ramType: ram.find((item) => item.id == additionalRam.item).name,
          quantity: additionalRam.number,
        },
        additionalDisk: {
          diskType: ssd.find((item) => item.id == additionalSsd.item).name,
          quantity: additionalSsd.number,
        },
        ipv4,
        ipv6,
        additionalVNBandwith: nationalBandwith,
        additionalInternationalBandwidth: internationalBandwith,
        premiumSupport,
        backup: isCustomBackup
          ? `${100 + Number(customBackup)} GB`
          : backup.find((item) => item.id == selectedBackup).name,
        dataCenter: selectedDataCenter,
        totalPrice: totalPriceWithTax,
      });

      if (result.data?.status == 1) {
        toast({
          status: "success",
          title: "Create Success",
        });
        navigate.push("/vng-server/list");
      }
      toast({
        status: "error",
        title: result.data?.message,
      });
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Create Failed",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    caculateTotalPrice();
  }, [
    selectedBackup,
    premiumSupport,
    ipv4,
    ipv6,
    selectedDataCenter,
    selectedPackage,
    selectedPeriod,
    customBackup,
    additionalRam,
    additionalSsd,
    nationalBandwith,
    internationalBandwith,
  ]);

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[rgba(0,0,0,0.4)] z-10">
          <LoadingOVH />
        </div>
      )}
      <div className="my-6 px-4 mt-12" id="form-option">
        <div className="flex justify-center flex-wrap gap-3 mt-24">
          <div className="min-w-[285px]  rounded-sm">
            <h2 className="text-[20px] text-white uppercase p-2 font-bold">
              {t("config.chooseConfig")}
            </h2>
            <ul className="mt-2">
              {packages.map((item) => {
                return (
                  <li
                    onClick={() => setSelectedPackage(item.id)}
                    className={`p-1 px-3 shadow-lg border cursor-pointer text-[15px] transition-all ${
                      selectedPackage == item.id
                        ? "bg-[#007aff] text-white"
                        : "bg-[white]"
                    }`}
                  >
                    {item.main}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex-1">
            <h2 className="text-[20px] text-white uppercase p-2 font-bold">
              {t("config.chooseBillingCycle")}
            </h2>
            <div className="mt-2 shadow bg-white p-4 text-[12px]  rounded-sm">
              <label className="font-bold">
                {t("config.chooseBillingCycle")}
              </label>
              <select
                onChange={(e) => setSelectedPeriod(e.target.value)}
                className="w-full border-none outline-none"
                style={{ borderBottom: "1px solid black" }}
              >
                <option value={"permonth"}>
                  {t("config.1monthPrice")}{" "}
                  {lang == "vi"
                    ? formatPriceVND(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.permonth
                        ) * 25000
                      )
                    : formatPriceUSD(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.permonth
                        )
                      )}
                </option>
                <option value={"price3"}>
                  {t("config.3monthPrice")}{" "}
                  {lang == "vi"
                    ? formatPriceVND(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price3
                        ) * 25000
                      )
                    : formatPriceUSD(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price3
                        )
                      )}
                </option>
                <option value={"price6"}>
                  {t("config.6monthPrice")}{" "}
                  {lang == "vi"
                    ? formatPriceVND(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price6
                        ) * 25000
                      )
                    : formatPriceUSD(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price6
                        )
                      )}
                </option>
                <option value={"price12"}>
                  {t("config.12monthPrice")}{" "}
                  {lang == "vi"
                    ? formatPriceVND(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price12
                        ) * 25000
                      )
                    : formatPriceUSD(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price12
                        )
                      )}
                </option>
                <option value={"price24"}>
                  {t("config.24monthPrice")}{" "}
                  {lang == "vi"
                    ? formatPriceVND(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price24
                        ) * 25000
                      )
                    : formatPriceUSD(
                        Number(
                          packages.find((item) => item.id == selectedPackage)
                            ?.price24
                        )
                      )}{" "}
                  ({t("config.notePrice")})
                </option>
              </select>
            </div>
            <h2 className="text-[20px] text-white uppercase p-2 mt-4 font-bold">
              {t("config.configurableOption")}
            </h2>
            <div className="mt-2 shadow p-4 text-[12px] bg-white rounded-sm">
              <div className="flex items-center gap-4">
                <p className="font-bold text-[16px]">CPU: </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  {packages.find((item) => item.id == selectedPackage)?.main}
                </div>
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-bold text-[16px]">RAM - Default: </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  {packages.find((item) => item.id == selectedPackage)?.ram}
                </div>
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-bold text-[16px]">IPV4: </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  1 IPV4
                </div>
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-bold text-[16px]">IPV6: </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  16 IPV6
                </div>
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-bold text-[16px]">Bandwidth VN: </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  100Mbps
                </div>
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-bold text-[16px]">
                  Bandwidth International:{" "}
                </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  10Mbps
                </div>
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-bold text-[16px]">
                  {t("config.hardDisk")}:{" "}
                </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  SSD 600GB SAS
                </div>
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-bold text-[16px]">RAM: </p>
                <div className="text-[16px] flex items-center gap-1">
                  <div className="w-[15px] h-[15px] rounded-full bg-[#007aff]"></div>
                  16GB
                </div>
              </div>
              <div className="mt-4">
                <labe className="font-bold" l>
                  Data center
                </labe>
                <select
                  value={selectedDataCenter}
                  onChange={(e) => setSelectedDataCenter(e.target.value)}
                  className="w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <option value={"idc"}>
                    TPHCM - Viettel IDC Hoàng Hoa Thám
                  </option>
                  <option value={"cmc"}>TPHCM - CMC</option>
                </select>
              </div>
              <div className="mt-4">
                <label className="font-bold">{t("config.additionalRam")}</label>
                <select
                  onChange={(e) =>
                    setAdditionalRam({
                      ...additionalRam,
                      item: Number(e.target.value),
                    })
                  }
                  className="w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                >
                  {ram.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>

              <div className="mt-4">
                <label className="font-bold">
                  {t("config.additionalRamQuantity")}
                </label>
                <input
                  onChange={(e) =>
                    setAdditionalRam({
                      ...additionalRam,
                      number: Number(e.target.value),
                    })
                  }
                  className="px-2 py-1 w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                  type="number"
                  min={0}
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">
                  {t("config.additionalDiskSpace")}
                </label>
                <select
                  onChange={(e) =>
                    setAdditionalSsd({
                      ...additionalSsd,
                      item: Number(e.target.value),
                    })
                  }
                  className="w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                >
                  {ssd.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="mt-4">
                <label className="font-bold">
                  {t("config.additionalSSDQuantity")}
                </label>
                <input
                  onChange={(e) => {
                    setAdditionalSsd({
                      ...additionalSsd,
                      number: Number(e.target.value),
                    });
                  }}
                  className="px-2 py-1 w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                  type="number"
                  min={0}
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">IPV4</label>
                <input
                  defaultValue={ipv4}
                  onChange={(e) => setIpv4(Number(e.target.value))}
                  className="px-2 py-1 w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                  type="number"
                  min={0}
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">IPV6</label>
                <input
                  defaultValue={ipv6}
                  onChange={(e) => setIpV6(Number(e.target.value))}
                  className="px-2 py-1 w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                  type="number"
                  min={0}
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">
                  Bandwith VN (
                  {lang == "vi" ? "1.200.000đ/100Mbps" : "48$/100Mbps"}){" "}
                </label>
                <input
                  defaultValue={nationalBandwith}
                  onChange={(e) => setNationalBandwith(Number(e.target.value))}
                  className="px-2 py-1 w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                  type="number"
                  min={0}
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">
                  Bandwith International (
                  {lang == "vi" ? "4.000.000đ/10Mbps" : "160$/10Mbps"})
                </label>
                <input
                  defaultValue={internationalBandwith}
                  onChange={(e) =>
                    setInternationalBandwith(Number(e.target.value))
                  }
                  className="px-2 py-1 w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                  type="number"
                  min={0}
                />
              </div>

              <div className="mt-4">
                <label className="font-bold">Back Up</label>
                <select
                  onChange={handleChangeBackup}
                  defaultValue={"false"}
                  className="w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                >
                  {backup.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
              {isCustomBackup && (
                <div className="mt-4">
                  <label className="font-bold">Custom Backup</label>
                  <input
                    defaultValue={customBackup}
                    onChange={handleChangeCustomBackUp}
                    placeholder="Input backup (GB)..."
                    className="px-2 py-1 w-full border-none outline-none"
                    style={{ borderBottom: "1px solid black" }}
                    type="number"
                    min={0}
                  />
                </div>
              )}
              <div className="mt-4">
                <label className="font-bold">Premium Support</label>
                <select
                  value={String(premiumSupport)}
                  defaultValue={"false"}
                  onChange={(e) =>
                    e.target.value == "true"
                      ? setPremiumSupport(true)
                      : setPremiumSupport(false)
                  }
                  className="w-full border-none outline-none"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <option value={"true"}>
                    Có - {lang == "vi" ? "2.000.000VND" : "80$"}
                  </option>
                  <option value={"false"}>Không</option>
                </select>
              </div>
            </div>
            <h2 className="text-[20px] mt-4 text-white uppercase p-2 font-bold">
              {t("config.chooseOperatingSystem")}
            </h2>
            <div className="mt-2 shadow p-4 text-[12px] bg-white  rounded-sm">
              <label className="font-bold">
                {t("config.chooseOperatingSystem")}
              </label>
              <select
                className="w-full border-none outline-none"
                style={{ borderBottom: "1px solid black" }}
              >
                <option value={"win-server-19"}>Window Server 2019</option>
                <option value={"win-server-22"}>Window Server 2022</option>
                <option value={"win-10"}>Window 10</option>
                <option value={"ubuntu-20"}>Ubuntu 20</option>
                <option value={"ubuntu-18"}>Ubuntu 18</option>
                <option value={"centos-7"}>Centos 7</option>
                <option value={"debian-11"}>Debian 11</option>
                <option value={"almalinux8"}>Almalinux8</option>
              </select>
            </div>
          </div>
          <div className="min-w-[285px]">
            <h2 className="text-[20px] text-white uppercase p-2 font-bold">
              {t("config.orderSummary")}
            </h2>
            <div className="sticky top-16">
              <div className="p-2 shadow rounded-sm bg-white">
                <h2
                  style={{ borderBottom: "1px solid gray" }}
                  className="text-[17px] py-1"
                >
                  {packages.find((item) => item.id == selectedPackage)?.main}
                </h2>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>
                    {packages.find((item) => item.id == selectedPackage)?.main}
                  </p>
                  <p>{generatePricePeriod()}</p>
                </div>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>Set Up Fee</p>
                  <p>{lang == "vi" ? "800.000đ" : "32$"}</p>
                </div>
                {premiumSupport && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>Premium Support</p>
                    <p>{lang == "vi" ? "2.000.000đ" : "80$"}</p>
                  </div>
                )}
                {additionalRam.number > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{additionalRam.number} - 8G DDR</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(additionalRam.number * 12 * 25000)
                        : formatPriceUSD(additionalRam.number * 12)}
                    </p>
                  </div>
                )}
                {additionalSsd.number > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>
                      {additionalSsd.number} -{" "}
                      {ssd.find((item) => item.id == additionalSsd.item)?.name}
                    </p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(
                            additionalSsd.number *
                              Number(
                                ssd.find(
                                  (item) => item.id == additionalSsd.item
                                )?.price
                              ) *
                              25000
                          )
                        : formatPriceUSD(
                            additionalSsd.number *
                              Number(
                                ssd.find(
                                  (item) => item.id == additionalSsd.item
                                )?.price
                              )
                          )}
                    </p>
                  </div>
                )}
                {nationalBandwith > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{nationalBandwith} - Bandwith VN</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(48 * nationalBandwith * 25000)
                        : formatPriceUSD(48 * nationalBandwith)}
                    </p>
                  </div>
                )}
                {internationalBandwith > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{internationalBandwith} - Bandwith International</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(160 * internationalBandwith * 25000)
                        : formatPriceUSD(160 * internationalBandwith)}
                    </p>
                  </div>
                )}
                {ipv4 > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{ipv4} - IPV4</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(4 * ipv4 * 25000)
                        : formatPriceUSD(4 * ipv4)}
                    </p>
                  </div>
                )}
                {ipv6 > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{ipv6} - IPV6</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(1.2 * ipv6 * 25000)
                        : formatPriceUSD(1.2 * ipv6)}
                    </p>
                  </div>
                )}
                {selectedBackup != 1 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>
                      Backup{" "}
                      {selectedBackup != 6
                        ? backup.find((item) => item.id == selectedBackup)?.name
                        : `${Number(customBackup)}GB`}
                    </p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(caculateBackupPrice() * 25000)
                        : formatPriceUSD(caculateBackupPrice())}
                    </p>
                  </div>
                )}
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>Summary</p>
                  <p>
                    {lang == "vi"
                      ? formatPriceVND(totalPrice * 25000)
                      : formatPriceUSD(totalPrice)}
                  </p>
                </div>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>10% VAT</p>
                  <p>
                    {lang == "vi"
                      ? formatPriceVND(tax * 25000)
                      : formatPriceUSD(tax)}
                  </p>
                </div>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>Total</p>
                  <p>
                    {lang == "vi"
                      ? formatPriceVND(totalPriceWithTax * 25000)
                      : formatPriceUSD(totalPriceWithTax)}
                  </p>
                </div>
              </div>
              <Button
                type="primary"
                bg={"white"}
                mt={"20px"}
                w={"100%"}
                onClick={() => onFinish()}
              >
                {t("config.createVNGServer")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateVNGServer;
