import { Box, Button, Icon, Select, Text, useToast } from "@chakra-ui/react";
import LoadingOVH from "components/Loading/Loading";
import { HSeparator } from "components/Separator/Separator";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { updateServiceInfoRenewalByVps } from "services/api";
import { getServiceInfoRenewalByVps } from "services/api";
import Swal from "sweetalert2";
import { RESPONSE_STATUS } from "utils";

function RenewalTab({ setKey, serviceDetail }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const [info, setInfo] = useState();

  const [type, setType] = useState("automatic");
  const [frequencyOption, setFrequencyOption] = useState([]);
  const [frequency, setFrequency] = useState();

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const getServiceInfo = async (serviceName) => {
    try {
      setIsLoading(true);

      const result = await getServiceInfoRenewalByVps(serviceName);

      const { data } = result.data;

      setFrequencyOption(
        data?.possibleRenewPeriod?.map((item) => ({
          value: item,
          label: `${item} months`,
        })) || []
      );

      setFrequency(data?.possibleRenewPeriod?.[0]);
      setInfo(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps) getServiceInfo(serviceDetail?.nameVps);
  }, [serviceDetail?.nameVps]);

  const onNext = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update renewal configuration!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoadingCreate(true);

          const data = {
            renew: {
              automatic: Boolean(type === "automatic"),
              manualPayment: Boolean(type === "manual"),
              deleteAtExpiration:
                type === "automatic" ? false : info?.renew?.deleteAtExpiration,
              forced: info?.renew?.forced,
              period: frequency,
            },
          };

          const result = await updateServiceInfoRenewalByVps(id, data);

          const { status, message } = result.data;
          if (status === RESPONSE_STATUS.FAILED) {
            toast({
              status: "error",
              title: message,
            });
            return;
          }

          toast({
            status: "success",
            title: "Cập nhật chính sách làm mới vps thành công",
          });

          setKey(1);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingCreate(false);
        }
      }
    });
  };

  return (
    <Box>
      {isLoading ? (
        <LoadingOVH size="3rem" />
      ) : (
        <Box textAlign={"left"}>
          <Button
            mt="1rem"
            borderRadius="12px"
            transition="background .3s ease"
            bg={"brand.200"}
            _hover={{
              bg: "brand.200",
            }}
            _active={{
              bg: "brand.200",
            }}
            leftIcon={<Icon color="white" as={BsArrowLeft} me="6px" />}
            onClick={() => setKey(1)}
            minW="135px"
          >
            <Text fontSize="xs" color="#fff" fontWeight="bold">
              Back to previous page
            </Text>
          </Button>

          <Text fontSize="xl" color="#fff" fontWeight="bold" mt="1rem">
            Renewal configuration
          </Text>
          <Text fontSize="sm" color="gray.200" fontWeight="500" mt="0.5rem">
            Choose the type of renewal you would like (automatic or manual). For
            automatic renewal, you can also choose how often you pay.
          </Text>

          <HSeparator my="1rem" />

          <Text fontSize={"md"} mt="1rem" color={"white"}>
            Renewal type
          </Text>
          <Select
            mt="8px"
            border=".5px solid"
            borderColor="rgba(226, 232, 240, 0.3)"
            _hover="none"
            bg="#0F1535"
            borderRadius="15px"
            color="gray.400"
            fontSize="md"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            {[
              {
                value: "automatic",
                label: "Automatic",
              },
              {
                value: "manual",
                label: "Manual",
              },
            ]?.map((item) => (
              <option value={item?.value} key={item?.value}>
                {item?.label}
              </option>
            ))}
          </Select>

          {type === "automatic" && (
            <>
              <Text fontSize={"md"} mt="1rem" color={"white"}>
                Frequency
              </Text>
              <Select
                mt="8px"
                border=".5px solid"
                borderColor="rgba(226, 232, 240, 0.3)"
                _hover="none"
                bg="#0F1535"
                borderRadius="15px"
                color="gray.400"
                fontSize="md"
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
              >
                {frequencyOption?.map((item) => (
                  <option value={item?.value} key={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </>
          )}

          <Button
            isLoading={isLoadingCreate}
            variant="brand"
            w="85px"
            h="35px"
            fontSize="xs"
            fontWeight="bold"
            alignSelf={{ sm: "flex-start", lg: null }}
            mt="1rem"
            onClick={onNext}
          >
            CONFIRM
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default RenewalTab;
