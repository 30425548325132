import React, { Fragment, useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Image,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import Globe from "components/Globe/Globe";
import IconBox from "components/Icons/IconBox";
import {
  barChartDataDashboard,
  barChartOptionsDashboard,
  lineChartDataDashboard,
  lineChartOptionsDashboard,
} from "variables/charts";

// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import { getDashboardOvh } from "services/api";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import fedora from "assets/img/ovh/fedora.svg";

import almalinux from "assets/img/ovh/almalinux.svg";
import cpanel from "assets/img/ovh/cpanel.svg";
import docker from "assets/img/ovh/docker.svg";
import rocky_linux from "assets/img/ovh/rocky_linux.svg";
import plesk_debian from "assets/img/ovh/plesk_debian.svg";
import { SERVICE_OVH_STATUS } from "utils";
import LoadingOVH from "components/Loading/Loading";
import { useTranslation } from "react-i18next";

const imageTemplate = [
  {
    id: 1,
    value: "windows",
    icon: window,
  },
  {
    id: 2,
    value: "ubuntu",
    icon: ubuntu,
  },
  {
    id: 3,
    value: "centos",
    icon: centos,
  },
  {
    id: 4,
    value: "debian",
    icon: debian,
  },
  {
    id: 5,
    value: "almalinux",
    icon: almalinux,
  },
  {
    id: 6,
    value: "fedora",
    icon: fedora,
  },
  {
    id: 7,
    value: "rocky",
    icon: rocky_linux,
  },
  {
    id: 8,
    value: "docker",
    icon: docker,
  },
  {
    id: 9,
    value: "cpanel",
    icon: cpanel,
  },
  {
    id: 10,
    value: "plesk",
    icon: plesk_debian,
  },
];

export default function Default() {
  const { t } = useTranslation();
  // Chakra Color Mode
  const iconBoxInside = useColorModeValue("white", "white");
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});

  const initData = async () => {
    try {
      setIsLoading(true);

      const result = await getDashboardOvh();
      setDashboard(result.data?.data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Box mt="10rem">
          <LoadingOVH />
        </Box>
      ) : (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
          <Text
            color="#fff"
            fontWeight="bold"
            fontSize="3xl"
            mb="30px"
            ps="20px"
          >
            {t("common.dashboard.generalStatistic")}
          </Text>
          <Grid
            templateColumns={{ sm: "4fr 1fr", xl: "1.2fr 1fr" }}
            gap="32px"
            maxW="100%"
            w="100%"
          >
            {/* ThreeJS Globe */}
            <Box
              minW="700px"
              h="700px"
              position="absolute"
              right="30px"
              top="14%"
              display={{ sm: "none", md: "block" }}
            >
              <Globe />
            </Box>
            <Stack
              direction="column"
              spacing="24px"
              w="100%"
              mb="24px"
              maxW={{ sm: "315px", md: "100%" }}
              zIndex="0"
            >
              {/* Mini Statistics */}
              <SimpleGrid columns={{ sm: "1", md: "2" }} spacing="24px">
                <Card minH="83px">
                  <CardBody>
                    <Flex
                      flexDirection="row"
                      align="center"
                      justify="center"
                      w="100%"
                    >
                      <Stat me="auto">
                        <StatLabel fontSize="xs" color="gray.400" pb="2px">
                          {t("common.dashboard.serviceDuringMonth")}
                        </StatLabel>
                        <Flex>
                          <StatNumber fontSize="lg" color="#fff">
                            {dashboard?.monthVps}
                          </StatNumber>
                        </Flex>
                      </Stat>
                      <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                        <GlobeIcon
                          h={"24px"}
                          w={"24px"}
                          color={iconBoxInside}
                        />
                      </IconBox>
                    </Flex>
                  </CardBody>
                </Card>
                <Card minH="83px">
                  <CardBody>
                    <Flex
                      flexDirection="row"
                      align="center"
                      justify="center"
                      w="100%"
                    >
                      <Stat me="auto">
                        <StatLabel fontSize="xs" color="gray.400" pb="2px">
                          {t("common.dashboard.totalService")}
                        </StatLabel>
                        <Flex>
                          <StatNumber
                            fontSize="lg"
                            color="#fff"
                            fontWeight="bold"
                          >
                            {dashboard?.allVps}
                          </StatNumber>
                        </Flex>
                      </Stat>
                      <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                        <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                      </IconBox>
                    </Flex>
                  </CardBody>
                </Card>
              </SimpleGrid>
              {/* Sales table */}
              <Card px="0px">
                <CardHeader px="22px" mb="32px">
                  <Text color="#fff" fontSize="lg" fontWeight="bold">
                    {t("common.dashboard.lastestService")}
                  </Text>
                </CardHeader>
                <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th
                          color="gray.400"
                          fontSize="10px"
                          fontFamily="Plus Jakarta Display"
                          borderColor="#56577A"
                        >
                          {t("common.table.service")}
                        </Th>
                        <Th
                          color="gray.400"
                          fontSize="10px"
                          fontFamily="Plus Jakarta Display"
                          borderColor="#56577A"
                        >
                          IPV4
                        </Th>
                        <Th
                          color="gray.400"
                          fontSize="10px"
                          fontFamily="Plus Jakarta Display"
                          borderColor="#56577A"
                        >
                          {t("common.table.os")}
                        </Th>
                        <Th
                          color="gray.400"
                          fontSize="10px"
                          fontFamily="Plus Jakarta Display"
                          borderColor="#56577A"
                        >
                          {t("common.table.status")}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboard?.listVps?.slice(0, 5)?.map((item, index) => (
                        <Fragment key={index}>
                          {item?.isCloudService ? (
                            <Tr>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                <Text
                                  color={"white"}
                                  fontWeight={"400"}
                                  fontSize={"sm"}
                                >
                                  {item?.domain}
                                </Text>
                              </Td>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                <Text
                                  color={"white"}
                                  fontWeight={"400"}
                                  fontSize={"sm"}
                                >
                                  {item?.ip && Object.values(item?.ip)}
                                </Text>
                              </Td>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                <Flex alignItems="center">
                                  <Image
                                    src={
                                      imageTemplate?.find((e) =>
                                        item?.os
                                          ?.toLowerCase()
                                          .includes(e?.value)
                                      )?.icon
                                    }
                                  />
                                  <Text
                                    ml="8px"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="400"
                                  >
                                    {item?.os}
                                  </Text>
                                </Flex>
                              </Td>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                {item?.status && (
                                  <>
                                    {item?.status === "Active" ? (
                                      <Tag
                                        color={"white"}
                                        bgColor={"green.400"}
                                        size="sm"
                                      >
                                        Active
                                      </Tag>
                                    ) : item?.status === "Terminated" ? (
                                      <Tag
                                        color={"white"}
                                        bgColor={"red.400"}
                                        size="sm"
                                      >
                                        Stop
                                      </Tag>
                                    ) : (
                                      <Tag
                                        bgColor={"yellow.500"}
                                        color={"white"}
                                        size="sm"
                                      >
                                        Pending
                                      </Tag>
                                    )}
                                  </>
                                )}
                              </Td>
                            </Tr>
                          ) : (
                            <Tr>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                <Text
                                  color={"white"}
                                  fontWeight={"400"}
                                  fontSize={"sm"}
                                >
                                  {item?.nameVps}
                                </Text>
                              </Td>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                <Text
                                  color={"white"}
                                  fontWeight={"400"}
                                  fontSize={"sm"}
                                >
                                  {item?.ips?.[0]}
                                </Text>
                              </Td>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                <Flex alignItems="center">
                                  <Image
                                    src={
                                      imageTemplate?.find((e) =>
                                        item?.images?.name
                                          ?.toLowerCase()
                                          .includes(e?.value)
                                      )?.icon
                                    }
                                  />
                                  <Text
                                    ml="8px"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="400"
                                  >
                                    {item?.images?.name}
                                  </Text>
                                </Flex>
                              </Td>
                              <Td
                                borderColor={
                                  index !== dashboard?.listVps?.length - 1
                                    ? "#56577A"
                                    : "transparent"
                                }
                              >
                                {item?.info?.expiration && (
                                  <>
                                    {item?.status ===
                                    SERVICE_OVH_STATUS.PENDING ? (
                                      <Tag
                                        bgColor={"yellow.500"}
                                        color={"white"}
                                        size="sm"
                                      >
                                        Pending
                                      </Tag>
                                    ) : item?.vps?.state === "running" ? (
                                      <Tag
                                        color={"white"}
                                        bgColor={"green.400"}
                                        size="sm"
                                      >
                                        Active
                                      </Tag>
                                    ) : (
                                      <Tag
                                        color={"white"}
                                        bgColor={"red.400"}
                                        size="sm"
                                      >
                                        Stop
                                      </Tag>
                                    )}
                                  </>
                                )}
                              </Td>
                            </Tr>
                          )}
                        </Fragment>
                      ))}
                    </Tbody>
                  </Table>
                </CardBody>
              </Card>
            </Stack>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", lg: "1.4fr 1fr" }}
            maxW={{ sm: "350px", md: "100%" }}
            gap="24px"
            mb="24px"
          >
            {/* Sales overview */}
            <Card p="28px 0px 0px 0px">
              <CardHeader mb="20px" ps="22px">
                <Flex direction="column" alignSelf="flex-start">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
                    {t("common.dashboard.serviceForYear")}{" "}
                    {new Date().getFullYear()}
                  </Text>
                </Flex>
              </CardHeader>
              <Box w="100%" minH={{ sm: "300px" }}>
                <LineChart
                  chartData={[
                    {
                      name: "SERVICE OVH",
                      data: dashboard?.chartYearVps,
                    },
                    {
                      name: "CLOUD SERVICE",
                      data: dashboard?.chartYearCloudService,
                    },
                  ]}
                  chartOptions={lineChartOptionsDashboard}
                />
              </Box>
            </Card>
            {/* Active users */}
          </Grid>
        </Flex>
      )}
    </>
  );
}
