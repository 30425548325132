import { Box, Button, Flex, Icon, Tag, Text, useToast } from "@chakra-ui/react";
import Card from "components/Card/Card";
import LoadingOVH from "components/Loading/Loading";
import { HSeparator } from "components/Separator/Separator";
import React, { useState, useEffect, useMemo } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { getCartServiceOptionByVps } from "services/api";
import { getSummaryAdditionalDisk } from "services/api";
import { getSnapshotByVps } from "services/api";
import { RESPONSE_STATUS } from "utils";
import generalBg from "assets/img/generalBg.png";
import CardBody from "components/Card/CardBody";
import Swal from "sweetalert2";
import { addAdditionalDisk } from "services/api";
import { HiReceiptTax } from "react-icons/hi";
import { MdOutlineAttachMoney } from "react-icons/md";
import IconBox from "components/Icons/IconBox";

const SubscribeSnapshot = ({ id, setKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [option, setOption] = useState();
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [summary, setSummary] = useState();
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const getCartServiceOption = async () => {
    try {
      setIsLoading(true);
      const result = await getCartServiceOptionByVps(id);

      const { data } = result.data;
      console.log(data?.find((item) => item?.family === "snapshot"));

      setOption(data?.find((item) => item?.family === "snapshot"));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getCartServiceOption();
  }, [id]);

  const price = useMemo(() => {
    return (
      option?.prices?.find((item) => item?.capacities?.[0] === "renew")
        ?.price || {}
    );
  }, [option]);

  const handleSummaryBackup = async () => {
    try {
      setIsPaymentStep(true);
      setIsLoading(true);

      const result = await getSummaryAdditionalDisk(id, option?.planCode);

      const { message, status } = result.data;

      if (status === RESPONSE_STATUS.FAILED) {
        toast({
          status: "error",
          title: message,
        });
        setIsPaymentStep(false);
        return;
      }

      setSummary(result?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const genSummary = () => {
    return [
      {
        title: "Total ex. GST",
        value: `US ${
          summary?.summary?.prices?.withoutTax?.text?.split(" ")?.[0]
        }`,
      },
      {
        title: "7% GST",
        value: `US ${summary?.summary?.prices?.tax?.text?.split(" ")?.[0]}`,
        icon: HiReceiptTax,
      },
      {
        title: "Total incl. GST",
        value: `US ${summary?.summary?.prices?.withTax?.text?.split(" ")?.[0]}`,
      },
    ];
  };

  const onNext = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add snapshot for vps!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoadingCreate(true);
          const result = await addAdditionalDisk(summary?.cartId);

          const { status, message } = result.data;

          if (status === RESPONSE_STATUS.FAILED) {
            toast({
              status: "error",
              title: message,
            });

            return;
          }

          toast({
            status: "success",
            title: "Thanh toán thành công hệ thống đang xử lý",
          });
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingCreate(false);
        }
      }
    });
  };

  return (
    <Box textAlign={"left"}>
      <Button
        mt="1rem"
        borderRadius="12px"
        transition="background .3s ease"
        bg={"brand.200"}
        _hover={{
          bg: "brand.200",
        }}
        _active={{
          bg: "brand.200",
        }}
        leftIcon={<Icon color="white" as={BsArrowLeft} me="6px" />}
        onClick={() => setKey(1)}
        minW="135px"
      >
        <Text fontSize="xs" color="#fff" fontWeight="bold">
          Back to previous page
        </Text>
      </Button>

      <Text fontSize="xl" color="#fff" fontWeight="bold" mt="1rem">
        Enable the snapshot option
      </Text>

      <HSeparator my="1rem" />

      {isLoading ? (
        <LoadingOVH size="3rem" />
      ) : (
        <div>
          {!isPaymentStep ? (
            <>
              <Text color="white" fontSize={"sm"}>
                The snapshot option enables you to create a restoration point.
                It records the configuration at that point, which can be
                restored if necessary.
              </Text>

              <Text color="white" mt="1rem" fontSize={"sm"}>
                This option will be billed at{" "}
                <Text as={"span"} fontWeight={"bold"} color="gray.400">
                  {price?.text} ex. VAT
                </Text>
                /month.
              </Text>

              <Text color="white" mt="1rem" fontSize={"sm"}>
                For the first payment, you will be invoiced pro rata for the
                number of days remaining from your VPS renewal date.
              </Text>

              <Text color="white" mt="1rem" fontSize={"sm"}>
                When you click the ‘Order’ button, a new tab will open and you
                will be automatically redirected to the payment interface.
              </Text>

              <Button
                variant="brand"
                w="85px"
                h="35px"
                fontSize="xs"
                fontWeight="bold"
                alignSelf={{ sm: "flex-start", lg: null }}
                mt="2rem"
                onClick={handleSummaryBackup}
              >
                ORDER
              </Button>
            </>
          ) : (
            <>
              <Box textAlign={"end"}>
                <Text color="white" fontWeight={"500"} fontSize={"lg"}>
                  This page is the detailed summary of your order, it is not
                  your invoice.
                </Text>
                <Text color="gray" fontWeight={"500"} fontSize={"md"}>
                  Price ex. GST (USD)
                </Text>
              </Box>

              <Card mt="1rem">
                <Card bgImage={generalBg} bgSize="cover">
                  {summary?.summary?.details?.map((item, index) => (
                    <Flex
                      h="100%"
                      _notFirst={{
                        mt: "1rem",
                      }}
                    >
                      <Flex
                        bgColor="green.200"
                        borderRadius="12px"
                        minWidth="3px"
                        minH="100%"
                      />
                      <Box ml="1rem" w={"100%"}>
                        <Text
                          color={"white"}
                          fontWeight={"600"}
                          fontSize={"lg"}
                        >
                          {item?.detailType === "DURATION"
                            ? "Plan"
                            : "Installation"}
                        </Text>
                        <Flex
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Text
                            color={"white"}
                            fontWeight={"500"}
                            fontSize={"sm"}
                          >
                            {item?.description}
                          </Text>

                          <Flex alignItems={"center"}>
                            <Tag
                              bgColor={"white"}
                              color={"brand.100"}
                              size="sm"
                            >
                              {item?.quantity}X
                            </Tag>

                            <Text
                              color={"white"}
                              fontWeight={"400"}
                              fontSize={"sm"}
                              ml="1rem"
                            >
                              {item?.unitPrice?.text}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                    </Flex>
                  ))}
                </Card>

                <Flex
                  mt="1rem"
                  alignItems={"center"}
                  direction={{
                    md: "row",
                    sm: "column",
                  }}
                  gap={4}
                  gridAutoRows={"1fr"}
                >
                  {genSummary()?.map((item) => (
                    <Card
                      bgColor="#5f68c9"
                      p="16px"
                      display="flex"
                      align="center"
                      justify="center"
                      w="300px"
                      key={item.title}
                    >
                      <CardBody>
                        <Flex
                          direction="column"
                          align="center"
                          w="100%"
                          py="14px"
                        >
                          <IconBox
                            as="box"
                            h={"60px"}
                            w={"60px"}
                            bg="brand.200"
                          >
                            <Icon
                              h={"24px"}
                              w={"24px"}
                              color="white"
                              as={item?.icon || MdOutlineAttachMoney}
                            />
                          </IconBox>
                          <Flex
                            direction="column"
                            m="14px"
                            justify="center"
                            textAlign="center"
                            align="center"
                            w="100%"
                          >
                            <Text fontSize="lg" color="#fff" fontWeight="bold">
                              {item.title}
                            </Text>
                            <HSeparator my="1rem" />

                            <Text fontSize="lg" color="#fff" fontWeight="bold">
                              {item.value}
                            </Text>
                          </Flex>
                        </Flex>
                      </CardBody>
                    </Card>
                  ))}
                </Flex>

                <Button
                  isLoading={isLoadingCreate}
                  variant="brand"
                  w="85px"
                  h="35px"
                  fontSize="xs"
                  fontWeight="bold"
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mt="2rem"
                  onClick={onNext}
                >
                  CONFIRM
                </Button>
              </Card>
            </>
          )}
        </div>
      )}
    </Box>
  );
};

function SnapshotTab({ serviceDetail, setKey }) {
  const [isLoading, setIsLoading] = useState(true);
  const [snapshot, setSnapshot] = useState();

  const getSnapshot = async (serviceName) => {
    try {
      setIsLoading(true);
      const result = await getSnapshotByVps(serviceName);

      setSnapshot(result?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps) getSnapshot(serviceDetail?.nameVps);
  }, [serviceDetail?.nameVps]);

  return (
    <Box>
      {isLoading ? (
        <LoadingOVH size={"3rem"} />
      ) : !snapshot ? (
        <SubscribeSnapshot setKey={setKey} id={serviceDetail?.nameVps} />
      ) : (
        <div>Pending</div>
      )}
    </Box>
  );
}

export default SnapshotTab;
