import moment from "moment";

export const formatMoney = (money) => {
  return money?.toLocaleString("it-IT", { style: "currency", currency: "VND" });
};

export function convertMBtoGB(mb) {
  return parseFloat((mb / 1024).toFixed(2));
}

export function getMonthDateGap(date) {
  const start = moment();
  const end = moment(date, "YYYY-MM-DD");

  const months = end.diff(start, "months");
  start.add(months, "months");
  const days = end.diff(start, "days");

  return { months, days };
}

export const genGbps = (value) => {
  if (!value) return `0 Mbps`;

  return value < 1000 ? `${value} Mbit/s` : `${value / 1000} Gbit/s`;
};

export const genPriceOvh = (value, isAlways) => {
  if (isAlways) return (value / 100000000).toFixed(2);

  return value % 100000000 === 0
    ? value / 100000000
    : (value / 100000000).toFixed(2);
};

export const BILLING_CYCLE_OVH = {
  MONTH: "month",
  ALL: "all",
};

export const DISTRIBUTION_OVH = {
  ONLY: "order-funnel:distribution-only",
  WITH_APPLICATION: "order-funnel:distribution-with-application",
};

export const SERVICE_OVH_STATUS = {
  PENDING: 0,
  DONE: 1,
};

export const PERIOD_MONITORING_OVH = {
  LAST_DAY: "lastday",
  LAST_MONTH: "lastmonth",
  LAST_WEEK: "lastweek",
  LAST_YEAR: "lastyear",
  TODAY: "today",
};

export const RESPONSE_STATUS = {
  SUCCESS: 1,
  FAILED: 0,
  REDIRECT: 2,
};

export const genIdNameVps = (nameVps) => {
  return nameVps?.split("vps-")?.[1]?.split(".vps")?.[0] || "";
};

export const convertMoney = (money) => {
  return money ? String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";
};

export const is_phonenumber = (phonenumber) => {
  const phoneno = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  return phoneno.test(phonenumber);
};

export const CYCLE_TIME = {
  MONTHLY: "m",
  QUARTERLY: "q",
  SEMI_ANNUALLY: "s",
  ANNUALLY: "a",
};

export const convertDescriptionProductToObject = (str) => {
  const obj = {};

  str?.split("<br>").forEach((item, index) => {
    if (index < str.split("<br>").length - 1) {
      const [key, value] = item.split(":");
      obj[key] = value;
    }
  });
  return obj;
};

export const genMarkSlider = (data, custom, step) => {
  const marks = {};
  const qty = data?.maxValue / data?.step;
  if (qty >= 10 && custom) {
    const extra = step || Math.ceil(data?.maxValue / 10);
    for (let i = data?.minValue; i <= data?.maxValue; i += extra) {
      marks[i] = i.toString();

      if (i + extra > data?.maxValue) {
        marks[data?.maxValue] = data?.maxValue?.toString();
      }
    }
  } else {
    for (let i = data?.minValue; i <= data?.maxValue; i += data?.step) {
      marks[i] = i.toString();
    }
  }
  return marks;
};

export const convertObjectToQuery = (obj) => {
  const keys = Object.keys(obj);
  let query = "?";
  keys.forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== "") {
      query +=
        typeof obj[key] === "string"
          ? `${key}=${obj[key]}&`
          : `${key}=${JSON.stringify(obj[key])}&`;
    }
  });
  return query;
};

export const convertByteToMB = (value) => {
  const mbValue = (value / 1000000).toFixed(2);

  return mbValue + " MB";
};

export const formatMoneyVND = (money) => {
  return money?.toLocaleString("it-IT", { style: "currency", currency: "VND" });
};

export const setLanguage = (lang = "en") => {
  localStorage.setItem("app_lang", lang);
};

export const getLanguage = () => {
  return localStorage.getItem("app_lang") || "en";
};

export const minusNowAndExpireServiceActive = (expires) => {
  return (new Date(expires).getTime() - new Date().getTime()) || 0;
}
