import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

// Icons
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { getServicesOvhByUser } from "services/api";

// Icons
import { IoIosArrowDown } from "react-icons/io";

// Data
import { columnsData2 } from "variables/columnsData";
import tableData2 from "variables/tableData2.json";
import TableServices from "components/Tables/TableServices";
import useDebounce from "hooks/useDebounce";

import { useHistory } from "react-router-dom";

function ServicesList() {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDoc, setTotalDoc] = useState(0);
  const history = useHistory();

  const searchValue = useDebounce(search, 500);

  const getServices = async (pageIndex, pageSize, search) => {
    try {
      setIsLoading(true);
      const result = await getServicesOvhByUser(pageIndex, pageSize, search);

      const { data } = result.data;
      setServices(data?.doc || []);
      setTotalDoc(data?.totalDoc || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getServices(pageIndex, pageSize, searchValue);
  }, [pageIndex, pageSize, searchValue]);

  const handleChangePageSize = (value) => {
    setPageSize(value);
  };

  const handleChangePageIndex = (value) => {
    setPageIndex(value);
  };

  return (
    <Flex direction="column">
      <Card px="0px">
        <CardBody>
          <TableServices
            isLoading={isLoading}
            tableData={services || []}
            totalDoc={totalDoc || 0}
            pageIndex={pageIndex || 1}
            pageSize={pageSize || 10}
            // search={search}
            // setSearch={setSearch}
            onChangeSize={handleChangePageSize}
            onChangePage={handleChangePageIndex}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ServicesList;
