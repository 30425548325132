import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode,
} from "@chakra-ui/react";

// Assets
import signInImage from "assets/img/bg-signin1.png";

import { NavLink, useHistory } from "react-router-dom";

// Custom Components
import AuthFooter from "components/Footer/AuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { login } from "services/api";
import { useDispatch } from "react-redux";
import { updateUser } from "redux/slices/userSlice";
import { getUserCredit } from "redux/slices/userSlice";

const schema = yup.object({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup.string().required("Password is required"),
});

function SignInCover() {
  // Chakra color mode
  const titleColor = "white";
  const textColor = "gray.400";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserCredit()).unwrap();
  }, []);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const result = await login(data?.email, data?.password);

      const { user, accessToken, goto2FA, userId } = result?.data?.data;
      if (goto2FA) {
        history.push({
          pathname: "/auth/2fa",
          state: {
            userId,
          },
        });

        return;
      }
      dispatch(updateUser(user));
      localStorage.setItem("accessToken", JSON.stringify(accessToken));

      history.push("/admin/services/list");
    } catch (error) {
      console.log(error);
      setError("Email hoặc mật khẩu không hợp lệ");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex position="relative">
      <Flex
        minH="100vh"
        h={{ base: "120vh", lg: "fit-content" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: "100px", md: "0px" }}
        flexDirection="column"
        me={{ base: "auto", lg: "50px", xl: "auto" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          w={{ base: "100%", md: "50%", lg: "450px" }}
          px="50px"
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{ base: "50px", md: "150px", lg: "160px", xl: "245px" }}
            mb={{ base: "60px", lg: "95px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              SIGN IN
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and password to sign in
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color="white"
                >
                  Email
                </FormLabel>
                <GradientBorder
                  mb="8px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color="white"
                    bg={{ base: "rgb(19,21,54)", lg: "rgb(19,21,54)" }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="46px"
                    placeholder="Your email address"
                    {...register("email")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px">
                  {errors.email?.message}
                </Text>
              </FormControl>
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color="white"
                >
                  Password
                </FormLabel>
                <GradientBorder
                  mb="8px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color="white"
                    bg={{ base: "rgb(19,21,54)", lg: "rgb(19,21,54)" }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    type="password"
                    placeholder="Your password"
                    {...register("password")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px">
                  {errors.password?.message}
                </Text>
              </FormControl>
              {/* <FormControl display="flex" alignItems="center">
                <DarkMode>
                  <Switch id="remember-login" colorScheme="brand" me="10px" />
                </DarkMode>
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  ms="1"
                  fontWeight="500"
                  color="white"
                >
                  Remember me
                </FormLabel>
              </FormControl> */}

              {error && (
                <Text color="red.500" mt="12px">
                  {error}
                </Text>
              )}

              <Button
                variant="brand"
                fontSize="10px"
                type="submit"
                w="100%"
                h="45"
                mb="20px"
                mt="20px"
                isLoading={isLoading}
              >
                SIGN IN
              </Button>
            </form>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Do not have an account?
                <NavLink to="/auth/sign-up">
                  <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                    sign up
                  </Link>
                </NavLink>
              </Text>
              <Text color={textColor} fontWeight="medium">
                <NavLink to="/auth/forgot-password">
                  <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                    Forgot password?
                  </Link>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb="80px"
        >
          {/* <AuthFooter /> */}
        </Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: "50vw", lg: "50vw" }}
          minH="100vh"
          w="960px"
          position="absolute"
          left="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="absolute"
          >
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500"
            ></Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)"
            ></Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignInCover;
