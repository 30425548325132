import {
  Avatar,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  LightMode,
  DarkMode,
  Image,
  Select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Box,
  Spinner,
  Tag,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import Pagination from "rc-pagination";
import React, { useEffect, useMemo, useState } from "react";
import { AiFillEye, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {
  FaCheckCircle,
  FaFileInvoice,
  FaFileInvoiceDollar,
  FaTimesCircle,
  FaUndoAlt,
} from "react-icons/fa";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { convertMBtoGB } from "utils";
import { useHistory } from "react-router-dom";
import { getMonthDateGap } from "utils";
import { FiMoreHorizontal } from "react-icons/fi";
import { BsEye } from "react-icons/bs";
import { genIdNameVps } from "utils";
import { SERVICE_OVH_STATUS } from "utils";
import vietnam from "assets/img/vietnam.png";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import almalinux from "assets/img/ovh/almalinux.svg";
import { convertMoney } from "utils";
import { HSeparator } from "components/Separator/Separator";
import { useSelector } from "react-redux";
import { convertToUsd } from "utils/convertToUsd";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { getLanguage } from "utils";
import { minusNowAndExpireServiceActive } from "utils";
import { CheckIcon, CloseIcon, EditIcon, UpDownIcon } from "@chakra-ui/icons";
import { renewalCloudService } from "services/api";
import Swal from "sweetalert2";
import { RESPONSE_STATUS } from "utils";
import { getAllCloudServices } from "redux/slices/serviceSlice";
import { getAllInvoicesCloudServiceByClient } from "redux/slices/serviceSlice";
import { getUserCredit } from "redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { renewalCloudManyServices } from "services/api";
import ModalRenewalServices from "containers/CloudServiceList/ModalRenewalServices";
import { updateHostnameService } from "services/api";
import { updateAllService } from "redux/slices/serviceSlice";
import packages from "../../../mocks";

const imageTemplate = [
  {
    id: 1,
    value: "windows",
    value2: "template:302",
    image: window,
  },
  {
    id: 2,
    value: "ubuntu",
    value2: "template:256",
    image: ubuntu,
  },
  {
    id: 3,
    value: "centos",
    value2: "template:308",
    image: centos,
  },
  {
    id: 4,
    value: "debian",
    value2: "template:306",
    image: debian,
  },
  {
    id: 5,
    value: "almalinux",
    value2: "template:309",
    image: almalinux,
  },
  {
    id: 6,
    value: "windows",
    value2: "template:301",
    image: window,
  },
  {
    id: 7,
    value: "",
    value2: "template:301",
    image: window,
  },
];

// Danh sách service connect bằng tay

function TableVNGServer(props) {
  const {
    tableData,
    totalDoc,
    pageIndex,
    pageSize,
    onChangePage,
    isLoading,
    handleAddCloud,
    allServices,
    columns,
    handleSortExpire,
    type,
  } = props;

  const [inputValue, setInputValue] = useState("");

  const [multiSelect, setMultiSelect] = useState([]);
  const [itemSelected, setItemSelected] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEditItem = (id) => {
    const item = tableData?.find((item) => item?.id === id);
    setMultiSelect((prev) => [...prev, ...item?.domain.split(",")]);
    setItemSelected(item?.service_id);
    setIsModalOpen(true);
  };
  const handleAddSelect = () => {
    setMultiSelect((prev) => [...prev, inputValue]);
    setInputValue("");
  };
  const handleRemoveSelect = (index) => {
    setMultiSelect((prev) => prev.filter((item, i) => i !== index));
  };

  const handleOk = async () => {
    try {
      const data = {
        id: itemSelected,
        hostname: multiSelect.join(","),
      };

      const result = await updateHostnameService(data);

      const { status } = result?.data;

      if (status === RESPONSE_STATUS.SUCCESS) {
        toast({
          title: "Cập nhật tên nhãn thành công",
          status: "success",
        });

        dispatch(
          updateAllService({
            id: itemSelected,
            data: {
              domain: multiSelect.join(","),
            },
          })
        );

        setIsModalOpen(false);
        setMultiSelect([]);

        return;
      }

      // toast({
      //   title: "Cập nhật tên nhãn thất bại",
      //   status: "error",
      // });
    } catch (error) {
      toast({
        title: "Cập nhật tên nhãn thất bại",
        status: "error",
      });
    }
  };
  const { allInvoicesCloudService: allInvoice } = useSelector(
    (state) => state.service
  );

  const dispatch = useDispatch();

  const history = useHistory();
  const [modal, setModal] = useState({
    visible: false,
    data: [],
  });

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    usePagination
  );

  const [chosenIds, setChosenIds] = useState([]);
  const [ids, setIds] = useState([]);
  const [isRenewing, setIsRenewing] = useState(false);

  const [modalRenewalServices, setModalRenewalServices] = useState({
    show: false,
    services: [],
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setGlobalFilter,
  } = tableInstance;

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const render = {
    ubuntu: ubuntu,
    debian: debian,
    centos: centos,
    almalinux: almalinux,
    windows: window,
  };

  const genCycle = (value, lang) => {
    if (lang === "vi") {
      return value === "Annually"
        ? "Năm"
        : value === "Quarterly"
        ? "3 tháng"
        : value === "Semi-Annually"
        ? "6 tháng"
        : "Tháng";
    }
    return value === "Annually"
      ? "Year"
      : value === "Quarterly"
      ? "3 Month"
      : value === "Semi-Annually"
      ? "6 Month"
      : "Month";
  };

  const genInvoiceId = (serviceId) => {
    const temp = allInvoice.find((invoice) => {
      return invoice?.items?.find((e) => e?.item_id === serviceId);
    });

    return temp?.id || "";
  };

  useEffect(() => {
    // const listInvoiceId = chosenIds?.map((item) => genInvoiceId(item));
    setIds(chosenIds);
  }, [chosenIds?.length]);
  const handleChooseId = (value) => {
    setChosenIds((prevState) => {
      const temp = prevState.find((id) => id === value);

      if (temp) {
        return prevState?.filter((id) => id !== value);
      }

      return [...prevState, value];
    });
  };

  const handleSelectAllServicesId = (e) => {
    const idsInCurrentPage = tableData?.map((item) => item?.id);

    if (e) {
      setChosenIds(Array.from(new Set([...chosenIds, ...idsInCurrentPage])));
    } else {
      setChosenIds((prevState) => {
        const temp = [];
        prevState?.forEach((id) => {
          if (!idsInCurrentPage?.includes(id)) temp.push(id);
        });
        return temp;
      });
    }
  };

  const isCheckedAll = () => {
    const idsInCurrentPage = tableData?.map((item) => item?.id);
    if (idsInCurrentPage?.length === 0) return false;
    let result = true;

    idsInCurrentPage?.forEach((id) => {
      if (!chosenIds?.includes(id)) {
        result = false;
      }
    });

    return result;
  };

  const handlePayment = (data) => {
    let result = [];

    result = allServices.filter((service) => {
      return chosenIds.includes(service?.id);
    });

    setModal({
      data: data || result,
      visible: true,
    });
  };

  const genDataModal = (data) => {
    let amount = 0;
    let invoices = "Services: ";

    // data?.forEach((item: any, index: number) => {
    //     amount += Number(item?.linetotal) || 0
    //     invoices += `GOF${item?.invoice_id}${
    //         index < data?.length - 1 ? ',' : ''
    //     } `
    // })

    data?.forEach((item, index) => {
      amount += Number(item?.total) || 0;
      invoices += `${item?.id}${index < data?.length - 1 ? "," : ""} `;
    });

    return {
      amount,
      invoices,
    };
  };

  const handleRenewalCloudService = (row) => {
    Swal.fire({
      title: row?.domain,
      text: t("common.service.confirmRenewal"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsRenewing(true);
          toast({
            title: t("common.service.reviewing"),
            status: "info",
          });

          const result = await renewalCloudService(row?.service_id);

          if (result?.data?.status === RESPONSE_STATUS.FAILED) {
            toast({
              title: result?.data?.message,
              status: "error",
            });
            return;
          }

          if (result?.data?.status === RESPONSE_STATUS.REDIRECT) {
            handlePayment([row]);
            return;
          }

          toast({
            title: t("common.service.successfullyRenewal"),
            status: "success",
          });
          dispatch(getUserCredit({}));
          dispatch(getAllCloudServices());
          dispatch(getAllInvoicesCloudServiceByClient());
        } catch (error) {
          console.log(error);
        } finally {
          setIsRenewing(false);
        }
      }
    });
  };

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{
          sm: "scroll",
          lg: "scroll",
          xl: "scroll",
          "2xl": "scroll",
        }}
      >
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    borderColor="#56577A"
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="0px"
                    key={index}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header") === "checkbox" ? (
                        <Checkbox
                          isChecked={Boolean(isCheckedAll())}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleSelectAllServicesId(e.target.checked);
                          }}
                          colorScheme="brand"
                        />
                      ) : (
                        column.render("Header")
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td colSpan={9}>
                  <Flex py="2rem" justifyContent="center" w="100%">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#582cff"
                      size="xl"
                    />
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          ) : tableData?.length === 0 ? (
            <Tbody>
              <Tr>
                <Td colSpan={9}>
                  <Flex py="2rem" justifyContent="center" w="100%">
                    <Text color={"white"}>
                      {t("common.service.emptyService")}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {tableData?.map((row, index) => {
                return (
                  <Tr key={index}>
                    <Td minW="50px">
                      <Checkbox
                        value={row?._id}
                        isChecked={Boolean(
                          chosenIds?.find((id) => id === row?._id)
                        )}
                        onChange={(e) => handleChooseId(e?.target?.value)}
                        colorScheme="brand"
                      />
                    </Td>
                    <Td minW="250px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {packages.find((item) => item.id == row?.config)?.id}
                      </Text>
                    </Td>
                    <Td minW="250px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {packages.find((item) => item.id == row?.config)?.main}
                      </Text>
                    </Td>

                    <Td minW="50px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {row?.additionalIpv4 || 0}
                      </Text>
                    </Td>
                    <Td minW="50px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {row?.additionalIpv6 || 0}
                      </Text>
                    </Td>
                    <Td minW={"200px"}>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"500"}
                        color={"white"}
                      >
                        {row?.dataCenter == "idc"
                          ? "TPHCM - Viettel IDC Hoàng Hoa Thám"
                          : "TPHCM - CMC"}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {row?.ip}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {row?.username}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {row?.password}
                      </Text>
                    </Td>
                    <Td minW="150px">
                      <>
                        <Text
                          mb="2"
                          color="white"
                          fontSize="sm"
                          fontWeight="400"
                        >
                          {row?.backup}
                        </Text>
                      </>
                    </Td>
                    <Td minW="100px">
                      {row?.premiumSupport ? (
                        <CheckIcon color="green.400" />
                      ) : (
                        <CloseIcon color={"red.400"} />
                      )}
                    </Td>
                    <Td minW="150px">
                      <>
                        <Text
                          mb="2"
                          color="white"
                          fontSize="sm"
                          fontWeight="400"
                        >
                          {convertToUsd(
                            row?.price,
                            localStorage.getItem("app_lang")
                          )}
                        </Text>
                      </>
                    </Td>
                    <Td minW="150px">
                      <FaFileInvoice
                        cursor={"pointer"}
                        onClick={() =>
                          history.push(`/vngserver/invoice/${row._id}`)
                        }
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="500"
            mb={{ sm: "12px", md: "0px" }}
          >
            Showing {totalDoc > 0 ? pageSize * (pageIndex - 1) + 1 : 0} to{" "}
            {pageSize * pageIndex <= totalDoc ? pageSize * pageIndex : totalDoc}{" "}
            of {totalDoc} entries
          </Text>
          <Box
            mb={{
              sm: "12px",
              lg: "0px",
            }}
          >
            <Pagination
              current={pageIndex}
              total={totalDoc}
              showTitle={false}
              jumpNextIcon={<span>...</span>}
              jumpPrevIcon={<span>...</span>}
              pageSize={pageSize}
              prevIcon={<AiOutlineLeft />}
              nextIcon={<AiOutlineRight />}
              onChange={(current) => {
                onChangePage(current);
              }}
            />
          </Box>
        </Flex>

        <ModalRenewalServices
          show={modalRenewalServices?.show}
          services={modalRenewalServices?.services}
          onClose={() => {
            setModalRenewalServices({
              show: false,
              services: [],
            });
          }}
          columns={[
            {
              Header: "IPV4",
              label: "IPV4",
            },
            {
              Header: "INVOICE",
              label: t("common.table.invoice"),
            },
          ]}
          onPayByBank={() => handlePayment(null)}
        />

        <Modal
          isOpen={modal?.visible}
          onClose={() =>
            setModal({
              data: [],
              visible: false,
            })
          }
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent bgColor={"#5f68c9"}>
            <ModalHeader color={"white"}>Service payment</ModalHeader>
            <ModalCloseButton color={"white"} />

            <ModalBody>
              <Flex
                justifyContent={"space-between"}
                gap={8}
                alignItems={"center"}
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                <Box>
                  <img
                    src={`https://manager.idcviettel.com/vietqr.php?account=6863868686&bankcode=970416&amount=${
                      genDataModal(modal?.data)?.amount
                    }&noidung=${genDataModal(modal?.data)?.invoices}`}
                    style={{
                      height: "200px",
                      maxWidth: "200px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Box>
                <Box color={"white"}>
                  <Text>Account number: 6863868686</Text>
                  <Text>Account owner: CTY TNHH CONG NGHE VNG CLOUD</Text>
                  <Text>Account bank: ACB</Text>
                  <Text>
                    Content billing: {genDataModal(modal?.data).invoices}
                  </Text>
                  <Text>
                    Amount:{" "}
                    {convertMoney(
                      Number(genDataModal(modal?.data).amount) || 0
                    )}
                    đ
                  </Text>
                </Box>
              </Flex>

              <Text textAlign={"center"} mt="8" color={"white"}>
                Please fill in the correct invoice number and amount or scan the
                code QR for payment.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() =>
                  setModal({
                    data: [],
                    visible: false,
                  })
                }
                size="sm"
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
}

export default TableVNGServer;
