import { formatMoney } from "utils";

export const convertToUsd = (amount, locale = "en") => {
  if (locale == "en") return `${Number(amount / 25000).toFixed(2)}$`;
  return `${formatMoney(amount)}`;
};

export const convertToVnd = (amount, locale = "vi") => {
  if (locale == "vi") return `${formatMoney(Number(amount * 25000))}`;
  return `${amount}$`;
};
