import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useTable } from "react-table";

const columns = [
  {
    Header: "ID",
  },
  {
    Header: "MODEL",
  },
  {
    Header: "MAC",
  },
  {
    Header: "IP ADDRESS",
  },
  {
    Header: "FIREWALL",
  },
  {
    Header: "BRIDGE",
  },
];

function Interfaces({ data }) {
  const tableInstance = useTable({
    columns,
    data: data,
  });

  const { getTableProps, getTableBodyProps, headerGroups } = tableInstance;

  return (
    <Box px="4" py="8">
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th borderColor="#56577A" pe="0px" key={index}>
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {data?.map((row, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Text color="white" fontWeight="600" fontSize="md">
                    {row?.name}
                  </Text>
                </Td>
                <Td>
                  <Text color="white" fontWeight="600" fontSize="md">
                    {row?.model}
                  </Text>
                </Td>
                <Td>
                  <Text color="white" fontWeight="600" fontSize="md">
                    {row?.mac}
                  </Text>
                </Td>
                <Td>
                  <Text color="white" fontWeight="600" fontSize="md">
                    {row?.ip?.[0]?.ip}
                  </Text>
                </Td>
                <Td>
                  <Text color="white" fontWeight="600" fontSize="md">
                    {row?.firewall === "1" ? "Yes" : "No"}
                  </Text>
                </Td>
                <Td>
                  <Text color="white" fontWeight="600" fontSize="md">
                    {row?.bridge}
                  </Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Interfaces;
