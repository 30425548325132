import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import LoadingOVH from "components/Loading/Loading";
import { HSeparator } from "components/Separator/Separator";
import React, { useState, useMemo, useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { getImagesAvailableByVps } from "services/api";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import fedora from "assets/img/ovh/fedora.svg";

import almalinux from "assets/img/ovh/almalinux.svg";
import cpanel from "assets/img/ovh/cpanel.svg";
import docker from "assets/img/ovh/docker.svg";
import rocky_linux from "assets/img/ovh/rocky_linux.svg";
import plesk_debian from "assets/img/ovh/plesk_debian.svg";
import Swal from "sweetalert2";
import { updateImagesByVps } from "services/api";
import { RESPONSE_STATUS } from "utils";

const cannotChange = ["Plesk", "Docker", "cPanel"];

const render = {
  ubuntu: ubuntu,
  debian: debian,
  fedora: fedora,
  centos: centos,
  almalinux: almalinux,
  rocky_linux: rocky_linux,
  windows_server: window,
  plesk_debian: plesk_debian,
  cpanel: cpanel,
  docker: docker,
};

function ChangeFamilyOsItem({
  chosenFamily,
  chosenOs,
  item,
  setChosenFamily,
  setChosenOs,
}) {
  const handleChooseFamily = () => {
    setChosenFamily(item?.family);
    setChosenOs(item?.listOs?.[0]?.id);
  };

  return (
    <GridItem>
      <Flex
        direction={"column"}
        h="100%"
        bg="rgb(31, 35, 89)"
        borderColor={
          chosenFamily === item?.family ? "brand.100" : "transparent"
        }
        borderWidth={"4px"}
        borderRadius="20px"
        cursor={"pointer"}
        w="100%"
        _hover={{
          borderColor: "brand.100",
        }}
        onClick={(e) => {
          handleChooseFamily();
        }}
      >
        <Box p={"32px"}>
          <Flex alignItems={"center"} justifyContent={"flex-start"}>
            <Image
              w={"32px"}
              h={"32px"}
              mr={"1rem"}
              src={render?.[item?.family?.replace(" ", "_")?.toLowerCase()]}
            />

            <Text color={"white"} fontSize={"lg"} fontWeight={"500"}>
              {item?.family}
            </Text>
          </Flex>

          {chosenFamily === item?.family && (
            <>
              <Select
                mt="2rem"
                border=".5px solid"
                borderColor="rgba(226, 232, 240, 0.3)"
                _hover="none"
                bg="#0F1535"
                borderRadius="15px"
                color="gray.400"
                fontSize="md"
                value={chosenOs}
                onChange={(e) => setChosenOs(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              >
                {item?.listOs?.map((item) => (
                  <option value={item?.id} key={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </Select>
            </>
          )}
        </Box>
      </Flex>
    </GridItem>
  );
}

function ImagesTab({ serviceDetail, setServiceDetail, setTab }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowing, setIsShowing] = useState(false);
  const [images, setImages] = useState([]);

  const [chosenFamily, setChosenFamily] = useState("");
  const [chosenOs, setChosenOs] = useState("");
  const [isLoadingInstall, setIsLoadingInstall] = useState(false);

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const getImages = async (id) => {
    try {
      setIsLoading(true);

      const result = await getImagesAvailableByVps(id);

      const { data } = result.data;

      setImages(
        data?.filter(
          (item) =>
            !cannotChange?.find((e) => item?.name?.includes(e)) &&
            serviceDetail?.images?.name !== item?.name
        ) || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps && isShowing) getImages(serviceDetail?.nameVps);
  }, [serviceDetail?.nameVps, isShowing]);

  const genData = useMemo(() => {
    const result = [];
    const families = new Set(
      images?.map((item) => {
        return item.name?.substring(0, item.name.lastIndexOf(" "));
      })
    );
    families.forEach((item) => {
      result.push({
        family: item,
        listOs: images
          ?.filter((e) => e?.name?.includes(item))
          ?.sort((a, b) => a?.name?.localeCompare(b?.name)),
      });
    });

    return result?.sort((a, b) => a?.family?.localeCompare(b?.family));
  }, [images]);

  const onNext = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change operating system for vps!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoadingInstall(true);

          const data = {
            os: chosenOs,
          };

          const result = await updateImagesByVps(serviceDetail?.nameVps, data);

          const { status, message } = result.data;

          if (status === RESPONSE_STATUS.FAILED) {
            toast({
              status: "error",
              title: message,
            });

            return;
          }

          toast({
            status: "success",
            title: "Cập nhật hệ điều hành thành công. Hệ thống đang xử lý.",
          });

          setServiceDetail(result?.data?.data || serviceDetail);
          setTab(1);
        } catch (error) {
          toast({
            status: "error",
            title: "Cập nhật hệ điều hành thất bại.",
          });
          console.log(error);
        } finally {
          setIsLoadingInstall(false);
        }
      }
    });
  };

  return (
    <Box textAlign={"left"}>
      <Button
        mt="1rem"
        borderRadius="12px"
        transition="background .3s ease"
        bg={"brand.200"}
        _hover={{
          bg: "brand.200",
        }}
        _active={{
          bg: "brand.200",
        }}
        leftIcon={<Icon color="white" as={BsArrowLeft} me="6px" />}
        onClick={() => setTab(1)}
        minW="135px"
      >
        <Text fontSize="xs" color="#fff" fontWeight="bold">
          Change operating system for vps
        </Text>
      </Button>

      <Text fontSize="xl" color="#fff" fontWeight="bold" mt="1rem">
        Change operating system for vps
      </Text>

      <Text color="white" mt="1rem" fontSize={"sm"}>
        When changing the operating system all your data will be restored to the
        original.
      </Text>

      {!isShowing ? (
        <Button
          variant="brand"
          w="85px"
          h="35px"
          fontSize="xs"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          mt="2rem"
          onClick={() => setIsShowing(true)}
        >
          SHOW
        </Button>
      ) : (
        <Box>
          {isLoading ? (
            <LoadingOVH size="3rem" />
          ) : (
            <>
              <HSeparator my="2rem" />
              <Text
                color={"white"}
                fontSize={"md"}
                fontWeight={"500"}
                mb="1rem"
              >
                Current operating system
              </Text>
              <Grid
                templateColumns={{
                  sm: "repeat(2, 1fr)",
                  lg: "repeat(4, 1fr)",
                }}
              >
                <GridItem>
                  <Flex
                    direction={"row"}
                    h="100%"
                    p="32px"
                    bg="rgb(31, 35, 89)"
                    borderColor={"brand.100"}
                    borderWidth={"4px"}
                    borderRadius="20px"
                    cursor={"pointer"}
                    w="100%"
                    _hover={{
                      borderColor: "brand.100",
                    }}
                  >
                    <Image
                      w={"32px"}
                      h={"32px"}
                      mr={"1rem"}
                      src={
                        render?.[
                          serviceDetail?.images?.name
                            ?.substring(
                              0,
                              serviceDetail?.images?.name?.lastIndexOf(" ")
                            )
                            ?.replace(" ", "_")
                            ?.toLowerCase()
                        ]
                      }
                    />

                    <Text
                      color={"white"}
                      fontSize={"lg"}
                      fontWeight={"500"}
                      mb="1rem"
                    >
                      {serviceDetail?.images?.name}
                    </Text>
                  </Flex>
                </GridItem>
              </Grid>
              <HSeparator my="1rem" />

              <Text
                color={"white"}
                fontSize={"md"}
                fontWeight={"500"}
                mb="1rem"
              >
                List operating system you can change
              </Text>
              <Grid
                gap={3}
                templateColumns={{
                  sm: "repeat(2, 1fr)",
                  lg: "repeat(4, 1fr)",
                }}
              >
                {genData?.map((item) => (
                  <ChangeFamilyOsItem
                    key={item?.family}
                    item={item}
                    chosenOs={chosenOs}
                    setChosenOs={setChosenOs}
                    chosenFamily={chosenFamily}
                    setChosenFamily={setChosenFamily}
                  />
                ))}
              </Grid>

              <Button
                isLoading={isLoadingInstall}
                variant="brand"
                w="85px"
                h="35px"
                fontSize="xs"
                fontWeight="bold"
                alignSelf={{ sm: "flex-start", lg: null }}
                mt="2rem"
                onClick={onNext}
              >
                CONFIRM
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
export default ImagesTab;
