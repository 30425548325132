import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAllInvoicesCloudServiceByClientId } from "services/api";
import { getPagingCloudServices } from "services/api";

export const getAllCloudServices = createAsyncThunk(
  "service/getAllCloudServices",
  async (data) => {
    try {
      // get all
      const result = await getPagingCloudServices("", 0, 0);

      return result?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const refreshAllCloudServices = createAsyncThunk(
  "service/refreshAllCloudServices",
  async (data) => {
    try {
      // get all
      const result = await getPagingCloudServices("", 0, 0);

      return result?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllInvoicesCloudServiceByClient = createAsyncThunk(
  "invoice/getAllInvoicesCloudServiceByClient",
  async (data) => {
    try {
      const result = await getAllInvoicesCloudServiceByClientId();
      return result?.data;
    } catch (error) {
      return [];
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    allService: [],
    isLoading: true,
    allInvoicesCloudService: [],
  },
  reducers: {
    updateAllService: (state, { payload }) => {
      const all = [...current(state.allService)];
      let temp = all?.find((item) => item?.id === payload?.id);

      if (temp) {
        temp = {
          ...temp,
          ...payload?.data,
        };
      }

      const index = all?.findIndex((item) => item?.id === payload?.id);

      all.splice(index, 1, temp);
      state.allService = all;
    },
  },
  extraReducers: (builders) =>
    builders
      .addCase(getAllCloudServices.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.allService = data?.data || [];
        state.isLoading = false;
      })
      .addCase(getAllCloudServices.rejected, (state, { payload }) => {
        state.allService = [];
        state.isLoading = false;
      })
      .addCase(refreshAllCloudServices.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.allService = data?.data || [];
      })
      .addCase(getAllInvoicesCloudServiceByClient.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.allInvoicesCloudService = data || [];
      })
      .addCase(getAllInvoicesCloudServiceByClient.rejected, (state, { payload }) => {
        state.allInvoicesCloudService = [];
      }),
});

const { reducer: serviceReducer, actions } = serviceSlice;

export const { updateAllService } = actions;

export default serviceReducer;
