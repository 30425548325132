import React from "react";
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from "@chakra-ui/react";

function ModalShowFeedback({ onClose, show, ticket }) {
  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor={"#5f68c9"} maxW={"800px"}>
        <ModalHeader color={"white"}>Feedback</ModalHeader>
        <ModalCloseButton color={"white"} />

        <ModalBody>
          <Box>
            <Text color={"white"} fontWeight={"400"} fontSize={"md"}>
              Title:
            </Text>
            <Text color={"white"} fontWeight={"500"} fontSize={"lg"}>
              {ticket?.subject}
            </Text>

            <Text color={"white"} fontWeight={"400"} fontSize={"md"} mt="1rem">
              Status:
            </Text>

            {ticket?.status === "1" ? (
              <Tag bgColor={"green.500"} color={"white"}>
                Solved
              </Tag>
            ) : ticket?.status === "2" ? (
              <Tag bgColor={"yellow.500"} color={"white"}>
                Confirmed
              </Tag>
            ) : (
              <Tag bgColor={"red.400"} color={"white"}>
                Unconfirmed
              </Tag>
            )}

            <Text color={"white"} fontWeight={"400"} fontSize={"md"} mt="1rem">
              
              Content feedback:
            </Text>

            <Box>
              {ticket?.feedBack ? (
                <Box
                  w={"100%"}
                  dangerouslySetInnerHTML={{ __html: ticket?.feedBack }}
                  color={"white"}
                  fontSize={"sm"}
                  border={"1px solid"}
                  borderColor={"white"}
                  padding={"0.5rem"}
                  mt="0.5rem"
                  borderRadius={"12px"}
                ></Box>
              ) : (
                <Text fontWeight={"500"} fontSize={"lg"}>
                  No response yet
                </Text>
              )}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="purple" size="sm">
            CLOSE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalShowFeedback;
