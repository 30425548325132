import { Box, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import LoadingOVH from "components/Loading/Loading";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getConsoleUrlByVps } from "services/api";

function KVM() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState("");
  const history = useHistory();

  const getUrl = async () => {
    try {
      setIsLoading(true);

      const result = await getConsoleUrlByVps(`${id}.vps.ovh.ca`);

      if (result?.data?.data) {
        setUrl(result?.data?.data);
      } else {
        history.push("/services-international");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getUrl();
  }, [id]);

  return (
    <Box mt="80px">
      {isLoading ? (
        <LoadingOVH />
      ) : (
        <Card className="position-relative">
          <CardHeader mb="1rem">
            <Text color={"white"} fontWeight={"bold"} fontSize={"xl"}>
              KVM: {`${id}.vps.ovh.ca`}
            </Text>
          </CardHeader>
          <iframe style={{ width: "100%", height: "600px" }} src={url} />
        </Card>
      )}
    </Box>
  );
}

export default KVM;
