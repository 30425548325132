import { Box, Button, Flex, Icon, Tag, Text, useToast } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import LoadingOVH from "components/Loading/Loading";
import { HSeparator } from "components/Separator/Separator";
import React, { useEffect, useState, useMemo } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { HiReceiptTax } from "react-icons/hi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { getSummaryAdditionalDisk } from "services/api";
import { getCartServiceOptionByVps } from "services/api";
import { getBackupByVps } from "services/api";
import Swal from "sweetalert2";
import { RESPONSE_STATUS } from "utils";
import generalBg from "assets/img/generalBg.png";
import { addAdditionalDisk } from "services/api";

const SubscribeAutomatedBackup = ({ id, setStep }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [option, setOption] = useState();
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [summary, setSummary] = useState();
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const getCartServiceOption = async () => {
    try {
      setIsLoading(true);
      const result = await getCartServiceOptionByVps(id);

      const { data } = result.data;
      console.log(data?.find((item) => item?.family === "automatedBackup"));

      setOption(data?.find((item) => item?.family === "automatedBackup"));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getCartServiceOption();
  }, [id]);

  const price = useMemo(() => {
    return (
      option?.prices?.find((item) => item?.capacities?.[0] === "renew")
        ?.price || {}
    );
  }, [option]);

  const handleSummaryBackup = async () => {
    try {
      setIsPaymentStep(true);
      setIsLoading(true);

      const result = await getSummaryAdditionalDisk(id, option?.planCode);

      const { message, status } = result.data;

      if (status === RESPONSE_STATUS.FAILED) {
        toast({
          status: "error",
          title: message,
        });
        setIsPaymentStep(false);
        return;
      }

      setSummary(result?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const genSummary = () => {
    return [
      {
        title: "Total ex. GST",
        value: `US ${
          summary?.summary?.prices?.withoutTax?.text?.split(" ")?.[0]
        }`,
      },
      {
        title: "7% GST",
        value: `US ${summary?.summary?.prices?.tax?.text?.split(" ")?.[0]}`,
        icon: HiReceiptTax,
      },
      {
        title: "Total incl. GST",
        value: `US ${summary?.summary?.prices?.withTax?.text?.split(" ")?.[0]}`,
      },
    ];
  };

  const onNext = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add automated backup for vps!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoadingCreate(true);
          const result = await addAdditionalDisk(summary?.cartId);

          const { status, message } = result.data;

          if (status === RESPONSE_STATUS.FAILED) {
            toast({
              status: "error",
              title: message,
            });
            return;
          }

          toast({
            status: "success",
            title: "Thanh toán thành công hệ thống đang xử lý",
          });
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingCreate(false);
        }
      }
    });
  };

  return (
    <Box textAlign={"left"}>
      <Button
        borderRadius="12px"
        transition="background .3s ease"
        bg={"brand.200"}
        _hover={{
          bg: "brand.200",
        }}
        _active={{
          bg: "brand.200",
        }}
        me={{ base: "none", lg: "20px" }}
        leftIcon={<Icon color="white" as={BsArrowLeft} me="6px" />}
        onClick={() => setStep(1)}
        minW="135px"
      >
        <Text fontSize="xs" color="#fff" fontWeight="bold">
          Back to previous page
        </Text>
      </Button>

      <Text fontSize="xl" color="#fff" fontWeight="bold" mt="1rem">
        Subscribe to Automated Backups
      </Text>

      <HSeparator my="1rem" />

      {isLoading ? (
        <LoadingOVH size="2rem" />
      ) : (
        <div>
          {!isPaymentStep ? (
            <>
              <Text color="white" mt="1rem" fontSize={"sm"}>
                With the Automated Backup feature, you can restore your VPS at
                any time. On your dashboard, you will find{" "}
                <Text as={"span"} fontWeight={"bold"} color="gray.400">
                  7 VPS backups
                </Text>{" "}
                (excluding additional disks){" "}
                <Text as={"span"} fontWeight={"bold"} color="gray.400">
                  corresponding to the last 7 days of use.
                </Text>
              </Text>

              <Text color="white" mt="1rem" fontSize={"sm"}>
                You can schedule backups at the times that are most convenient
                for you.
              </Text>

              <HSeparator my="1rem" />

              <Text color="white" fontSize={"sm"}>
                For the first payment, you will be invoiced pro rata for the
                number of days remaining from your VPS renewal date.
              </Text>

              <Text color="white" fontSize={"sm"} mt="1rem">
                The service will then be billed monthly, at a price of{" "}
                <Text as={"span"} color="gray.400" fontWeight={"bold"}>
                  {price?.text} ex.VAT
                </Text>
                /month
              </Text>

              <Button
                variant="brand"
                w="85px"
                h="35px"
                fontSize="xs"
                fontWeight="bold"
                alignSelf={{ sm: "flex-start", lg: null }}
                mt="2rem"
                onClick={handleSummaryBackup}
              >
                ORDER
              </Button>
            </>
          ) : (
            <>
              <Box textAlign={"end"}>
                <Text color="white" fontWeight={"500"} fontSize={"lg"}>
                  This page is the detailed summary of your order, it is not
                  your invoice.
                </Text>
                <Text color="gray" fontWeight={"500"} fontSize={"md"}>
                  Price ex. GST (USD)
                </Text>
              </Box>

              <Card mt="1rem">
                <Card bgImage={generalBg} bgSize="cover">
                  {summary?.summary?.details?.map((item, index) => (
                    <Flex
                      h="100%"
                      _notFirst={{
                        mt: "1rem",
                      }}
                    >
                      <Flex
                        bgColor="green.200"
                        borderRadius="12px"
                        minWidth="3px"
                        minH="100%"
                      />
                      <Box ml="1rem" w={"100%"}>
                        <Text
                          color={"white"}
                          fontWeight={"600"}
                          fontSize={"lg"}
                        >
                          {item?.detailType === "DURATION"
                            ? "Plan"
                            : "Installation"}
                        </Text>
                        <Flex
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Text
                            color={"white"}
                            fontWeight={"500"}
                            fontSize={"sm"}
                          >
                            {item?.description}
                          </Text>

                          <Flex alignItems={"center"}>
                            <Tag
                              bgColor={"white"}
                              color={"brand.100"}
                              size="sm"
                            >
                              {item?.quantity}X
                            </Tag>

                            <Text
                              color={"white"}
                              fontWeight={"400"}
                              fontSize={"sm"}
                              ml="1rem"
                            >
                              {item?.unitPrice?.text}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                    </Flex>
                  ))}
                </Card>

                <Flex
                  mt="1rem"
                  alignItems={"center"}
                  direction={{
                    md: "row",
                    sm: "column",
                  }}
                  gap={4}
                  gridAutoRows={"1fr"}
                >
                  {genSummary()?.map((item) => (
                    <Card
                      bgColor="#5f68c9"
                      p="16px"
                      display="flex"
                      align="center"
                      justify="center"
                      w="300px"
                      key={item.title}
                    >
                      <CardBody>
                        <Flex
                          direction="column"
                          align="center"
                          w="100%"
                          py="14px"
                        >
                          <IconBox
                            as="box"
                            h={"60px"}
                            w={"60px"}
                            bg="brand.200"
                          >
                            <Icon
                              h={"24px"}
                              w={"24px"}
                              color="white"
                              as={item?.icon || MdOutlineAttachMoney}
                            />
                          </IconBox>
                          <Flex
                            direction="column"
                            m="14px"
                            justify="center"
                            textAlign="center"
                            align="center"
                            w="100%"
                          >
                            <Text fontSize="lg" color="#fff" fontWeight="bold">
                              {item.title}
                            </Text>
                            <HSeparator my="1rem" />

                            <Text fontSize="lg" color="#fff" fontWeight="bold">
                              {item.value}
                            </Text>
                          </Flex>
                        </Flex>
                      </CardBody>
                    </Card>
                  ))}
                </Flex>

                <Button
                  isLoading={isLoadingCreate}
                  variant="brand"
                  w="85px"
                  h="35px"
                  fontSize="xs"
                  fontWeight="bold"
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mt="2rem"
                  onClick={onNext}
                >
                  CONFIRM
                </Button>
              </Card>
            </>
          )}
        </div>
      )}
    </Box>
  );
};

function BackupTab({ serviceDetail }) {
  const [isLoading, setIsLoading] = useState(false);
  const [backup, setBackup] = useState();
  const [step, setStep] = useState(1);

  const getBackup = async (serviceName) => {
    try {
      setIsLoading(true);
      const result = await getBackupByVps(serviceName);

      setBackup(result?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps) getBackup(serviceDetail?.nameVps);
  }, [serviceDetail?.nameVps]);

  const render = {
    1: (
      <Box textAlign={"start"}>
        <Text color={"white"} fontSize={"md"} fontWeight={"500"} mb="1rem">
          You haven{"'"}t subscribed to the Automated backup option.
        </Text>
        <Button
          variant="brand"
          h="35px"
          fontSize="xs"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          onClick={() => setStep(2)}
        >
          Enable Automated backup
        </Button>
      </Box>
    ),
    2: (
      <SubscribeAutomatedBackup setStep={setStep} id={serviceDetail?.nameVps} />
    ),
  };

  return (
    <Box>
      {isLoading ? (
        <LoadingOVH size={"3rem"} />
      ) : !backup ? (
        <>{render[step]}</>
      ) : (
        <div>Pending</div>
      )}
    </Box>
  );
}

export default BackupTab;
