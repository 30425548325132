import {
  Button,
  Flex,
  Input,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  Box,
  Spinner,
  Tag,
  Icon,
} from "@chakra-ui/react";
import Pagination from "rc-pagination";
import React, { useState } from "react";
import { AiFillEdit, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { useHistory } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
import moment from "moment";
import ModalShowFeedback from "containers/Support/ModalShowFeedback";
import { useTranslation } from "react-i18next";

function TableTickets(props) {
  const {
    tableData,
    totalDoc,
    pageIndex,
    pageSize,
    onChangeSize,
    onChangePage,
    search,
    setSearch,
    isLoading,
    priority,
    setPriority,
    isManager,
    columns,
  } = props;

  const history = useHistory();
  const [modalFeedback, setModalFeedback] = useState({
    show: false,
    ticket: null,
  });
  const { t } = useTranslation();
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups } = tableInstance;

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          direction={{ lg: "row", sm: "column" }}
          align={{ sm: "flex-start", lg: "center" }}
          justify={{ sm: "flex-start", lg: "space-between" }}
          w="100%"
          px="22px"
          mb="12px"
        >
          <Text fontWeight="600" color="white" mb={{ lg: "0px", sm: "8px" }}>
            {t("common.ticket.listOfTicket")}
          </Text>
          <Flex
            alignItems={"center"}
            direction={{
              sm: "column",
              lg: "row",
            }}
          >
            <Select
              h={"40px"}
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              placeholder="Select Priority"
              size="sm"
              w="200px"
              maxW="200px"
              cursor="pointer"
              onChange={(e) => setPriority(e.target.value)}
              mr={{
                sm: "0px",
                lg: "1rem",
              }}
            >
              <option value={"1"}>Normal</option>
              <option value={"2"}>Priority</option>
              <option value={"3"}>Emergency</option>
            </Select>
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Flex>
        </Flex>

        <Stack
          direction={{ sm: "row", md: "row" }}
          spacing={{ sm: "4px", md: "12px" }}
          align="center"
          me="12px"
          my="12px"
          minW={{ sm: "100px", md: "200px" }}
          px="22px"
        >
          <Select
            value={pageSize}
            onChange={(e) => onChangeSize(Number(e.target.value))}
            color="gray.400"
            bg="#0F1535"
            border="0.5px solid"
            borderColor="#E2E8F04D"
            borderRadius="15px"
            size="sm"
            maxW="75px"
            cursor="pointer"
          >
            <option>5</option>
            <option>10</option>
            <option>50</option>
            <option>100</option>
          </Select>
          <Text fontSize="xs" color="gray.400" fontWeight="500">
            entries per page
          </Text>
        </Stack>

        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th borderColor="#56577A" pe="0px" key={index}>
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("label")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td colSpan={7}>
                  <Flex py="2rem" justifyContent="center" w="100%">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#582cff"
                      size="xl"
                    />
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {tableData?.map((row, index) => {
                return (
                  <Tr key={index}>
                    <Td minW="200px">
                      <Text color="white" fontWeight="600" fontSize="md">
                        {row?.subject}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      {row?.level === 3 ? (
                        <Tag bgColor={"red.400"} color={"white"}>
                          Emergency
                        </Tag>
                      ) : row?.level === 2 ? (
                        <Tag bgColor={"yellow.500"} color={"white"}>
                          Priority
                        </Tag>
                      ) : (
                        <Tag bgColor={"green.500"} color={"white"}>
                          Normal
                        </Tag>
                      )}
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="500" fontSize="md">
                        {row?.dept_id?.name}
                      </Text>
                    </Td>
                    <Td minW="150px">
                      <Text color="white" fontWeight="500" fontSize="md">
                        {row?.file}
                      </Text>
                    </Td>
                    <Td minW="150px">
                      <Text color="white" fontWeight="500" fontSize="md">
                        {row?.status === "1" ? (
                          <Tag bgColor={"green.500"} color={"white"}>
                            Solved
                          </Tag>
                        ) : row?.status === "2" ? (
                          <Tag bgColor={"yellow.500"} color={"white"}>
                            Confirmed
                          </Tag>
                        ) : (
                          <Tag bgColor={"red.400"} color={"white"}>
                            Unconfimred
                          </Tag>
                        )}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="500" fontSize="sm">
                        {moment(row?.createdAt).format("DD/MM/YYYY HH:mm")}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      {isManager ? (
                        <Button
                          variant="brand"
                          w="35px"
                          h="35px"
                          fontSize="md"
                          fontWeight="bold"
                          alignSelf={{ sm: "flex-start", lg: null }}
                          onClick={() =>
                            history.push(`/manager-ticket/${row?._id}`)
                          }
                        >
                          <Icon as={AiFillEdit} />
                        </Button>
                      ) : (
                        <Button
                          variant="brand"
                          w="35px"
                          h="35px"
                          fontSize="md"
                          fontWeight="bold"
                          alignSelf={{ sm: "flex-start", lg: null }}
                          onClick={() =>
                            setModalFeedback({
                              show: true,
                              ticket: row,
                            })
                          }
                        >
                          <Icon as={BsEyeFill} />
                        </Button>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="500"
            mb={{ sm: "12px", md: "0px" }}
          >
            Showing {pageSize * (pageIndex - 1) + 1} to{" "}
            {pageSize * pageIndex <= totalDoc ? pageSize * pageIndex : totalDoc}{" "}
            of {totalDoc} entries
          </Text>
          <Box
            mb={{
              sm: "12px",
              lg: "0px",
            }}
          >
            <Pagination
              current={pageIndex}
              total={totalDoc}
              showTitle={false}
              jumpNextIcon={<span>...</span>}
              jumpPrevIcon={<span>...</span>}
              pageSize={pageSize}
              prevIcon={<AiOutlineLeft />}
              nextIcon={<AiOutlineRight />}
              onChange={(current) => {
                onChangePage(current);
              }}
            />
          </Box>
        </Flex>
      </Flex>

      <ModalShowFeedback
        show={modalFeedback.show}
        ticket={modalFeedback.ticket}
        onClose={() =>
          setModalFeedback({
            show: false,
            ticket: null,
          })
        }
      />
    </>
  );
}

export default TableTickets;
