import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import React from "react";

function SlideConfigCloudService({
  title,
  value,
  min,
  max,
  step,
  onChange,
  marks,
}) {
  return (
    <Box
      _notLast={{
        mb: "8",
      }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Text color={"white"} fontWeight={"500"} fontSize={"18px"}>
          {title}:
        </Text>
        <Text color={"white"} fontWeight={"500"} fontSize={"18px"}>
          {value}
        </Text>
      </Flex>
      <Box px="4">
        <Slider
          mt="2"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e) => onChange(e)}
        >
          {Object.values(marks)?.map((item) => (
            <SliderMark
              color={"white"}
              mt="2"
              fontSize={"14px"}
              ml="-2"
              value={item}
              key={item}
            >
              {item}
            </SliderMark>
          ))}
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    </Box>
  );
}

export default SlideConfigCloudService;
