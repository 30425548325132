// Chakra imports
import {
  ChakraProvider,
  Portal,
  useDisclosure,
  ColorModeScript,
} from "@chakra-ui/react";
import "assets/css/vud-dashboard-styles.css";
import Configurator from "components/Configurator/Configurator";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
import Footer from "components/Footer/Footer.js";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { getAllInvoicesCloudServiceByClient } from "redux/slices/serviceSlice";
import { getAllCloudServices } from "redux/slices/serviceSlice";
import { checkRole } from "redux/slices/userSlice";
import { getUserCredit } from "redux/slices/userSlice";
// Custom Chakra theme
import theme from "theme/themeAdmin.js";
export default function Dashboard(props) {
  const { notShowRoutes, managerRoutes, routes, ...rest } = props;
  // states and functions
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(275);
  // ref for main panel div
  const mainPanel = React.createRef();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const initData = async () => {
    try {
      await Promise.all([
        dispatch(getUserCredit()),
        dispatch(checkRole()),
        dispatch(getAllCloudServices()),
        dispatch(getAllInvoicesCloudServiceByClient()),
      ]);
    } catch (error) {
      history.push("/auth/sign-in");
    }
  };

  React.useEffect(() => {
    if (!user || !JSON.parse(localStorage.getItem("accessToken") || "null")) {
      history.push("/auth/sign-in");
    } else {
      initData();
    }
  }, [user]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "admin";
  // Chakra Color Mode
  return (
    <>
      <ChakraProvider theme={theme} resetCss={false} overflowX="hidden">
        <SidebarContext.Provider
          value={{
            sidebarWidth,
            setSidebarWidth,
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            routes={
              user?.role?.roleName === "admin"
                ? [routes?.[0]]?.concat(managerRoutes)?.concat(routes?.slice(1))
                : routes
            }
            logoText={"VNGCLOUD"}
            display="flex"
            sidebarVariant={sidebarVariant}
            {...rest}
          />
          <MainPanel
            ref={mainPanel}
            w={{
              base: "100%",
              xl: `calc(100% - ${sidebarWidth}px)`,
            }}
          >
            <Portal>
              <AdminNavbar
                onOpen={onOpen}
                logoText={"VNGCLOUD"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                fixed={fixed}
                routes={routes}
                {...rest}
              />
            </Portal>

            {getRoute() ? (
              <PanelContent>
                <PanelContainer>
                  <Switch>
                    {user?.role?.roleName === "admin" &&
                      getRoutes(managerRoutes)}
                    {getRoutes(routes)}
                    {getRoutes(notShowRoutes)}
                    <Redirect from="/" to="/dashboard" />
                  </Switch>
                </PanelContainer>
              </PanelContent>
            ) : null}
            {/* <Footer /> */}
            {/* <Portal>
              <FixedPlugin fixed={fixed} onOpen={onOpen} />
            </Portal> */}
            <Configurator
              secondary={getActiveNavbar(routes)}
              isOpen={isOpen}
              onClose={onClose}
              isChecked={fixed}
              onSwitch={(value) => {
                setFixed(value);
              }}
              onOpaque={() => setSidebarVariant("opaque")}
              onTransparent={() => setSidebarVariant("transparent")}
            />
          </MainPanel>
        </SidebarContext.Provider>
      </ChakraProvider>
    </>
  );
}
