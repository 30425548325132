import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Grid,
  GridItem,
  Stack,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import React, { useEffect, useState } from "react";
import { getBackupByVps } from "services/api";
import { getSnapshotByVps } from "services/api";
import BoxOption from "./BoxOption";
import { convertMBtoGB } from "utils";
import { CopyIcon } from "@chakra-ui/icons";
import moment from "moment";
import LoadingOVH from "components/Loading/Loading";
import ModalChangeDisplayNameVps from "./ModalChangeDisplayNameVps";
import { updateDisplayNameVps } from "services/api";
import { RESPONSE_STATUS } from "utils";
import ModalReboot from "./ModalReboot";
import { rebootVps } from "services/api";

function HomeTab({
  serviceDetail,
  setServiceDetail,
  setTab,
  serviceInfoExtra,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [snapshot, setSnapshot] = useState();
  const [backup, setBackup] = useState();

  const [modalChangeName, setModalChangeName] = useState(false);
  const [modalReboot, setModalReboot] = useState(false);
  const [isRebooting, setIsRebooting] = useState(false);

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const initData = async (serviceName) => {
    try {
      setIsLoading(true);

      const [snapshot, backup] = await Promise.any([
        getSnapshotByVps(serviceName),
        getBackupByVps(serviceName),
      ]);

      setSnapshot(snapshot?.data?.data);
      setBackup(backup?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps) {
      initData(serviceDetail?.nameVps);
    }
  }, [serviceDetail]);

  const menuName = [
    {
      name: "Change the name",
      onClick: () => setModalChangeName(true),
    },
    {
      name: "KVM",
      onClick: () => {
        window.open(
          `/services/kvm/${serviceDetail?.nameVps?.split(".vps")?.[0]}`,
          "_blank"
        );
      },
    },
    {
      name: "Delete VPS",
      onClick: null,
    },
  ];

  const menuReboot = [
    {
      name: "Reboot my VPS",
      onClick: () => setModalReboot(true),
      className: "only",
    },
  ];

  const menuSnapshot = [
    {
      name: "Order",
      onClick: () => setTab(7),
      className: "only",
    },
  ];

  const menuBackup = [
    {
      name: "Order",
      onClick: () => setTab(3),
      className: "only",
    },
  ];

  const menuDisk = [
    {
      name: "Add a disk",
      onClick: () => setTab(4),
      className: "only",
    },
  ];

  const menuRenewal = [
    {
      name: "Configure Renewal",
      onClick: () => {
        setTab(8);
      },
      className: "only",
    },
  ];

  const menuImages = [
    {
      name: "Change OS",
      onClick: () => {
        setTab(9);
      },
      className: "only",
    },
  ];

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast({
      status: "success",
      title: "Copy thành công",
      description: text,
    });
  };

  const handleChangeDisplayName = async (displayName) => {
    try {
      const result = await updateDisplayNameVps(
        serviceDetail?.nameVps,
        displayName
      );

      const { status, message, data } = result.data;

      if (status === RESPONSE_STATUS.FAILED) {
        toast({
          status: "error",
          title: message,
        });
        return false;
      }

      toast({
        status: "success",
        title: "Cập nhật tên hiển thị vps thành công",
      });
      setModalChangeName(false);
      setServiceDetail(data);

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  const handleRebootVps = async () => {
    try {
      const result = await rebootVps(serviceDetail?.nameVps);

      const { status, message } = result.data;

      if (status === RESPONSE_STATUS.FAILED) {
        toast({
          status: "error",
          title: message,
        });
        return false;
      }

      toast({
        status: "success",
        title: "Reboot vps thành công",
      });
      setModalReboot(false);
      setIsRebooting(true);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <Box>
      {isLoading ? (
        <LoadingOVH size="3rem" />
      ) : (
        <>
          {isRebooting && (
            <Alert borderRadius="8px" status="info" variant="solid" mb="1rem">
              <AlertIcon color="white" />
              <AlertTitle mr="12px">
                The {serviceDetail?.nameVps} VPS will reboot (estimated time: 2
                minutes)
              </AlertTitle>
            </Alert>
          )}

          <Grid
            templateColumns={{
              sm: "repeat(1, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={3}
            gridAutoRows={"1fr"}
          >
            {/* your vps */}
            <GridItem>
              <Card h="100%">
                <CardHeader>
                  <Text color={"white"} fontSize={"lg"} fontWeight={"600"}>
                    Your VPS
                  </Text>
                </CardHeader>
                <HSeparator my="1rem" />
                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Status
                    </Text>
                    <Tag
                      bgColor={
                        serviceDetail?.vps?.state === "running"
                          ? "green.400"
                          : serviceDetail?.vps?.state === "installing"
                          ? "yellow.500"
                          : "red.200"
                      }
                      color={"white"}
                      size="sm"
                    >
                      {serviceDetail?.vps?.state === "running"
                        ? "Active"
                        : serviceDetail?.vps?.state === "installing"
                        ? "Installing"
                        : "Stop"}
                    </Tag>
                  </Stack>
                </Flex>

                <HSeparator my="1rem" />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Name
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.vps?.displayName}
                    </Text>
                  </Stack>

                  <BoxOption options={menuName} />
                </Flex>

                <HSeparator my="1rem" />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Boot
                    </Text>
                    <Text
                      color={"gray.300"}
                      size="sm"
                      fontWeight={"500"}
                      textTransform={"uppercase"}
                    >
                      {serviceDetail?.vps?.netbootMode}
                    </Text>
                  </Stack>

                  <BoxOption options={menuReboot} />
                </Flex>

                <HSeparator my="1rem" />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      OS/Distribution
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.images?.name}
                    </Text>
                  </Stack>

                  <BoxOption options={menuImages} />
                </Flex>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Zone
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.vps?.zone}
                    </Text>
                  </Stack>
                </Flex>
              </Card>
            </GridItem>

            {/* your configuration */}
            <GridItem>
              <Card h="100%">
                <CardHeader>
                  <Text color={"white"} fontSize={"lg"} fontWeight={"600"}>
                    Your configuration
                  </Text>
                </CardHeader>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Model
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.vps?.model?.offer}
                    </Text>
                  </Stack>
                </Flex>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      vCores
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.vps?.model?.vcore}
                    </Text>
                  </Stack>
                </Flex>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Memory
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {convertMBtoGB(serviceDetail?.vps?.model?.memory)} GB
                    </Text>
                  </Stack>
                </Flex>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Storage
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.vps?.model?.disk} GB
                    </Text>
                  </Stack>
                </Flex>
              </Card>
            </GridItem>

            {/* IP */}
            <GridItem>
              <Card h="100%">
                Stack
                <CardHeader>
                  <Text color={"white"} fontSize={"lg"} fontWeight={"600"}>
                    IP
                  </Text>
                </CardHeader>
                <HSeparator my="1rem" />
                <Flex>
                  <Stack textAlign={"start"} w="100%">
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      IPv4
                    </Text>

                    <Flex
                      bgColor={"brand.100"}
                      w={"100%"}
                      p="6px 8px"
                      borderRadius={"8px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text color="white" fontSize={"sm"} fontWeight={"500"}>
                        {serviceDetail?.ips?.[0]}
                      </Text>

                      <CopyIcon
                        onClick={() => handleCopy(serviceDetail?.ips?.[0])}
                        color="white"
                        cursor={"pointer"}
                        fontSize={"lg"}
                      />
                    </Flex>
                  </Stack>
                </Flex>
                <HSeparator my="1rem" />
                <Flex>
                  <Stack textAlign={"start"} w="100%">
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      IPv6
                    </Text>

                    <Flex
                      bgColor={"brand.100"}
                      w={"100%"}
                      p="6px 8px"
                      borderRadius={"8px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text color="white" fontSize={"sm"} fontWeight={"500"}>
                        {serviceDetail?.ips?.[1]}
                      </Text>

                      <CopyIcon
                        onClick={() => handleCopy(serviceDetail?.ips?.[1])}
                        color="white"
                        cursor={"pointer"}
                        fontSize={"lg"}
                      />
                    </Flex>
                  </Stack>
                </Flex>
                <HSeparator my="1rem" />
                <Flex>
                  <Stack textAlign={"start"} w="100%">
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Gateway
                    </Text>

                    <Flex
                      bgColor={"brand.100"}
                      w={"100%"}
                      p="6px 8px"
                      borderRadius={"8px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text color="white" fontSize={"sm"} fontWeight={"500"}>
                        {serviceInfoExtra?.gateway?.gateway || "..."}
                      </Text>

                      <CopyIcon
                        onClick={() =>
                          handleCopy(serviceInfoExtra?.gateway?.gateway)
                        }
                        color="white"
                        cursor={"pointer"}
                        fontSize={"lg"}
                      />
                    </Flex>
                  </Stack>
                </Flex>
                <HSeparator my="1rem" />
                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Secondary DNS
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceInfoExtra?.dns?.length
                        ? "Pending"
                        : "No domains configured"}
                    </Text>
                  </Stack>
                </Flex>
              </Card>
            </GridItem>

            {/* Summary of options */}
            <GridItem>
              <Card h="100%">
                <CardHeader>
                  <Text color={"white"} fontSize={"lg"} fontWeight={"600"}>
                    Summary of options
                  </Text>
                </CardHeader>

                <HSeparator my="1rem" />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Snapshot
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {snapshot ? "Pending" : "Disabled"}
                    </Text>
                  </Stack>

                  <BoxOption options={menuSnapshot} />
                </Flex>

                <HSeparator my="1rem" />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Automated backup
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {backup ? "Pending" : "Disabled"}
                    </Text>
                  </Stack>

                  <BoxOption options={menuBackup} />
                </Flex>

                <HSeparator my="1rem" />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Automated backup
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceInfoExtra?.disk?.length ? "Pending" : "Disabled"}
                    </Text>
                  </Stack>

                  <BoxOption options={menuDisk} />
                </Flex>
              </Card>
            </GridItem>

            {/* Subscription */}
            <GridItem>
              <Card h="100%">
                <CardHeader>
                  <Text color={"white"} fontSize={"lg"} fontWeight={"600"}>
                    Subscription
                  </Text>
                </CardHeader>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Creation date
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.info?.creation}
                    </Text>
                  </Stack>
                </Flex>

                <HSeparator my="1rem" />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Next payment date
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {moment(
                        new Date(
                          serviceInfoExtra?.service?.billing?.nextBillingDate
                        )
                      ).format("YYYY-MM-DD")}
                    </Text>

                    <Tag
                      w={"fit-content"}
                      bgColor={
                        serviceInfoExtra?.service?.billing?.renew?.current
                          ?.mode === "automatic"
                          ? "green.400"
                          : "yellow.200"
                      }
                      color={"white"}
                      size="md"
                    >
                      {serviceInfoExtra?.service?.billing?.renew?.current
                        ?.mode === "automatic"
                        ? "Automatic renewal"
                        : "Manual renewal"}
                    </Tag>
                  </Stack>

                  <BoxOption options={menuRenewal} />
                </Flex>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Commitment
                    </Text>
                    <Tag
                      w={"fit-content"}
                      bgColor={
                        serviceInfoExtra?.service?.billing?.pricing
                          ?.interval !== 1
                          ? "green.400"
                          : "red.400"
                      }
                      color={"white"}
                      size="md"
                    >
                      {serviceInfoExtra?.service?.billing?.pricing?.interval !==
                      1
                        ? "Pending"
                        : "None"}
                    </Tag>
                  </Stack>
                </Flex>
              </Card>
            </GridItem>

            {/* Username password */}
            <GridItem>
              <Card h="100%">
                <CardHeader>
                  <Text color={"white"} fontSize={"lg"} fontWeight={"600"}>
                    Information
                  </Text>
                </CardHeader>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Username
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.username}
                    </Text>
                  </Stack>
                </Flex>

                <HSeparator my="1rem" />

                <Flex>
                  <Stack textAlign={"start"}>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Password
                    </Text>
                    <Text color={"gray.300"} size="sm" fontWeight={"500"}>
                      {serviceDetail?.password}
                    </Text>
                  </Stack>
                </Flex>
              </Card>
            </GridItem>
          </Grid>

          <ModalChangeDisplayNameVps
            show={modalChangeName}
            defaultName={serviceDetail?.vps?.displayName}
            onClose={() => setModalChangeName(false)}
            onOk={(e) => handleChangeDisplayName(e)}
          />

          <ModalReboot
            show={modalReboot}
            onClose={() => setModalReboot(false)}
            onOk={handleRebootVps}
          />
        </>
      )}
    </Box>
  );
}

export default HomeTab;
