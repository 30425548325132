import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux";
import PageLoader from "components/PageLoader";
import "./locales/config-translation";
import "react-datetime/css/react-datetime.css";
import AppRouter from "./routes.js";
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PageLoader />
      <AppRouter />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
