import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";

function BoxOption({ options }) {
  return (
    <Menu isLazy placement="bottom-end">
      <MenuButton
        as={Button}
        p="0px"
        borderRadius="100%"
        lineHeight="1"
        size="sm"
        bgColor="brand.200"
        _hover={{
          bgColor: "brand.300",
        }}
        _focus={{
          outline: "2px dashed",
          outlineColor: "green.200",
        }}
        _active={{
          outline: "2px dashed",
          outlineColor: "green.200",
        }}
      >
        <Icon as={FiMoreHorizontal} fontSize={"22px"} m="0" color="white" />
      </MenuButton>
      <MenuList p="0" borderWidth="0px">
        {options?.map((item, index) => (
          <MenuItem
            _first={{
              borderRadius:
                item?.className === "only"
                  ? "0.375rem"
                  : "0.375rem 0.375rem 0px 0px",
            }}
            _last={{
              borderRadius:
                item?.className === "only"
                  ? "0.375rem"
                  : "0px 0px 0.375rem 0.375rem",
            }}
            bgColor="white"
            color="brand.200"
            fontSize="sm"
            fontWeight="600"
            key={index}
            _hover={{
              bgColor: "brand.100",
              color: "white",
            }}
            onClick={item.onClick}
          >
            {item?.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default BoxOption;
