import { Box } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import LoadingOVH from "components/Loading/Loading";
import TableActionHistory from "components/Tables/TableActionHistory";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getListActionHistory } from "services/api";

function ActionHistoryPage() {
  const [allHistories, setAllHistories] = useState([]);
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const { t } = useTranslation();

  const columns = [
    {
      Header: "CONTENT",
      label: t("common.table.content"),
    },
    {
      Header: "IP",
      label: "IP",
    },
    {
      Header: "STATUS",
      label: t("common.table.status"),
    },
    {
      Header: "TIME",
      label: t("common.table.time"),
    },
  ];

  const getHistories = async () => {
    try {
      const result = await getListActionHistory();

      const { data } = result.data;

      setAllHistories(
        data?.sort((a, b) => {
          return new Date(b?.date).getTime() - new Date(a?.date).getTime();
        }) || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistories();
  }, []);

  useEffect(() => {
    const temp = allHistories?.slice(
      (pageIndex - 1) * pageSize,
      pageIndex * pageSize
    );

    setHistories(temp || []);
  }, [allHistories, pageIndex, pageSize]);

  const handleChangePageSize = (value) => {
    setPageSize(value);
  };

  const handleChangePageIndex = (value) => {
    setPageIndex(value);
  };

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      {/* {isLoading ? (
        <LoadingOVH />
      ) : ( */}
      <Card px="0px">
        <CardBody>
          <TableActionHistory
            tableData={histories}
            totalDoc={allHistories?.length || 0}
            pageIndex={pageIndex}
            pageSize={pageSize}
            onChangeSize={handleChangePageSize}
            onChangePage={handleChangePageIndex}
            isLoading={isLoading}
            columns={columns}
          />
        </CardBody>
      </Card>
      {/* )} */}
    </Box>
  );
}

export default ActionHistoryPage;
