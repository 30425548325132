import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import LoadingOVH from "components/Loading/Loading";
import { HSeparator } from "components/Separator/Separator";
import TableAdditionalDisk from "components/Tables/TableAdditionalDisk";
import React, { useState, useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { HiReceiptTax } from "react-icons/hi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { getSummaryAdditionalDisk } from "services/api";
import { addAdditionalDisk } from "services/api";
import { getCartServiceOptionByVps } from "services/api";
import { getAdditionalDiskByVps } from "services/api";
import { RESPONSE_STATUS } from "utils";
import generalBg from "assets/img/generalBg.png";

function OrderAdditionalDiskItem({ item, onChoose, chosen }) {
  const genPrice = () => {
    return item?.prices?.find((e) => e?.interval === 1)?.price || {};
  };

  return (
    <GridItem w={"300px"}>
      <Flex
        pl="12px"
        py="22px"
        bg="rgb(31, 35, 89)"
        borderColor={
          chosen?.planCode === item?.planCode ? "brand.100" : "transparent"
        }
        borderWidth={"4px"}
        borderRadius="20px"
        align="center"
        direction={"column"}
        justifyContent={"center"}
        cursor={"pointer"}
        w="300px"
        key={item.title}
        _hover={{
          borderColor: "brand.100",
        }}
        onClick={() => onChoose(item)}
      >
        <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
          {item?.productName?.substring(22)}
        </Text>

        <Text my="8px" color={"white"} fontWeight={"600"} fontSize={"md"}>
          {genPrice()?.text}
        </Text>

        <Text color={"gray"} fontWeight={"500"} fontSize={"sm"}>
          ex. VAT/month
        </Text>
      </Flex>
    </GridItem>
  );
}

const OrderAdditionalDisk = ({ setStep, id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [options, setOptions] = useState([]);
  const [chosen, setChosen] = useState({});
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [summary, setSummary] = useState();

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const getCartServiceOption = async () => {
    try {
      setIsLoading(true);
      const result = await getCartServiceOptionByVps(id);

      const { data } = result.data;

      const temp = data?.filter((item) => item?.family === "additionalDisk");

      temp?.sort((a, b) => {
        const valueA = a?.productName?.substring(22)?.split("GB")?.[0];
        const valueB = b?.productName?.substring(22)?.split("GB")?.[0];

        return Number(valueA) - Number(valueB);
      });

      setOptions(temp);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getCartServiceOption();
  }, [id]);

  const handleSummaryAdditionalDisk = async () => {
    try {
      setIsPaymentStep(true);
      setIsLoadingSummary(true);

      const result = await getSummaryAdditionalDisk(id, chosen?.planCode);

      const { message, status } = result.data;

      if (status === RESPONSE_STATUS.FAILED) {
        toast({
          status: "error",
          title: message,
        });

        setIsPaymentStep(false);
        return;
      }

      setSummary(result?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSummary(false);
    }
  };

  const onNext = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add additional disk for vps!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoadingCreate(true);
          const result = await addAdditionalDisk(summary?.cartId);

          const { status, message } = result.data;

          if (status === RESPONSE_STATUS.FAILED) {
            toast({
              status: "error",
              title: message,
            });

            return;
          }

          toast({
            status: "success",
            title: "Thanh toán thành công hệ thống đang xử lý",
          });
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingCreate(false);
        }
      }
    });
  };

  const genSummary = () => {
    return [
      {
        title: "Total ex. GST",
        value: `US ${
          summary?.summary?.prices?.withoutTax?.text?.split(" ")?.[0]
        }`,
      },
      {
        title: "7% GST",
        value: `US ${summary?.summary?.prices?.tax?.text?.split(" ")?.[0]}`,
        icon: HiReceiptTax,
      },
      {
        title: "Total incl. GST",
        value: `US ${summary?.summary?.prices?.withTax?.text?.split(" ")?.[0]}`,
      },
    ];
  };

  return (
    <Box textAlign={"left"}>
      <Button
        borderRadius="12px"
        transition="background .3s ease"
        bg={"brand.200"}
        _hover={{
          bg: "brand.200",
        }}
        _active={{
          bg: "brand.200",
        }}
        me={{ base: "none", lg: "20px" }}
        leftIcon={<Icon color="white" as={BsArrowLeft} me="6px" />}
        onClick={() => setStep(1)}
        minW="135px"
      >
        <Text fontSize="xs" color="#fff" fontWeight="bold">
          Back to previous page
        </Text>
      </Button>

      <Text fontSize="xl" color="#fff" fontWeight="bold" mt="1rem">
        Order an additional disk
      </Text>

      <HSeparator my="1rem" />

      {isLoading ? (
        <LoadingOVH size="3rem" />
      ) : (
        <div>
          {!isPaymentStep ? (
            <Card>
              <Flex>
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  minW={"40px"}
                  h="40px"
                  borderRadius={"100%"}
                  bgColor={"brand.100"}
                  color={"white"}
                  fontSize={"lg"}
                  fontWeight={"600"}
                  mr="1rem"
                >
                  1
                </Flex>
                <Box>
                  <Text color={"white"} fontWeight={"500"} fontSize={"xl"}>
                    Choice of disk
                  </Text>
                  <Text color={"gray"} fontSize={"sm"}>
                    Please choose the size of the additional disk for your VPS.
                    It will appear as a second hard drive on your operating
                    system.
                  </Text>

                  <Grid
                    mt="1rem"
                    gap={3}
                    templateColumns={{
                      sm: "repeat(1, 1fr)",
                      lg: "repeat(3, 1fr)",
                    }}
                  >
                    {options?.map((item) => (
                      <OrderAdditionalDiskItem
                        item={item}
                        key={item?.planCode}
                        onChoose={setChosen}
                        chosen={chosen}
                      />
                    ))}
                  </Grid>
                </Box>
              </Flex>

              <Button
                variant="brand"
                w="85px"
                h="35px"
                fontSize="xs"
                fontWeight="bold"
                alignSelf={{ sm: "flex-start", lg: null }}
                mt="2rem"
                onClick={handleSummaryAdditionalDisk}
                disabled={Boolean(!chosen?.planCode)}
              >
                ORDER
              </Button>
            </Card>
          ) : (
            <div>
              {isLoadingSummary ? (
                <LoadingOVH size="3rem" />
              ) : (
                <>
                  <Box textAlign={"end"}>
                    <Text color="white" fontWeight={"500"} fontSize={"lg"}>
                      This page is the detailed summary of your order, it is not
                      your invoice.
                    </Text>
                    <Text color="gray" fontWeight={"500"} fontSize={"md"}>
                      Price ex. GST (USD)
                    </Text>
                  </Box>

                  <Card mt="1rem">
                    <Card bgImage={generalBg} bgSize="cover">
                      {summary?.summary?.details?.map((item, index) => (
                        <Flex
                          h="100%"
                          _notFirst={{
                            mt: "1rem",
                          }}
                        >
                          <Flex
                            bgColor="green.200"
                            borderRadius="12px"
                            minWidth="3px"
                            minH="100%"
                          />
                          <Box ml="1rem" w={"100%"}>
                            <Text
                              color={"white"}
                              fontWeight={"600"}
                              fontSize={"lg"}
                            >
                              {item?.detailType === "DURATION"
                                ? "Plan"
                                : "Installation"}
                            </Text>
                            <Flex
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Text
                                color={"white"}
                                fontWeight={"500"}
                                fontSize={"sm"}
                              >
                                {item?.description}
                              </Text>

                              <Flex alignItems={"center"}>
                                <Tag
                                  bgColor={"white"}
                                  color={"brand.100"}
                                  size="sm"
                                >
                                  {item?.quantity}X
                                </Tag>

                                <Text
                                  color={"white"}
                                  fontWeight={"400"}
                                  fontSize={"sm"}
                                  ml="1rem"
                                >
                                  {item?.unitPrice?.text}
                                </Text>
                              </Flex>
                            </Flex>
                          </Box>
                        </Flex>
                      ))}
                    </Card>

                    <Flex
                      mt="1rem"
                      alignItems={"center"}
                      direction={{
                        md: "row",
                        sm: "column",
                      }}
                      gap={4}
                      gridAutoRows={"1fr"}
                    >
                      {genSummary()?.map((item) => (
                        <Card
                          bgColor="#5f68c9"
                          p="16px"
                          display="flex"
                          align="center"
                          justify="center"
                          w="300px"
                          key={item.title}
                        >
                          <CardBody>
                            <Flex
                              direction="column"
                              align="center"
                              w="100%"
                              py="14px"
                            >
                              <IconBox
                                as="box"
                                h={"60px"}
                                w={"60px"}
                                bg="brand.200"
                              >
                                <Icon
                                  h={"24px"}
                                  w={"24px"}
                                  color="white"
                                  as={item?.icon || MdOutlineAttachMoney}
                                />
                              </IconBox>
                              <Flex
                                direction="column"
                                m="14px"
                                justify="center"
                                textAlign="center"
                                align="center"
                                w="100%"
                              >
                                <Text
                                  fontSize="lg"
                                  color="#fff"
                                  fontWeight="bold"
                                >
                                  {item.title}
                                </Text>

                                <HSeparator my="1rem" />

                                <Text
                                  fontSize="lg"
                                  color="#fff"
                                  fontWeight="bold"
                                >
                                  {item.value}
                                </Text>
                              </Flex>
                            </Flex>
                          </CardBody>
                        </Card>
                      ))}
                    </Flex>

                    <Button
                      isLoading={isLoadingCreate}
                      variant="brand"
                      w="85px"
                      h="35px"
                      fontSize="xs"
                      fontWeight="bold"
                      alignSelf={{ sm: "flex-start", lg: null }}
                      mt="2rem"
                      onClick={onNext}
                    >
                      CONFIRM
                    </Button>
                  </Card>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

function AdditionalDiskTab({ serviceDetail }) {
  const [isLoading, setIsLoading] = useState(true);
  const [disks, setDisks] = useState([]);
  const [step, setStep] = useState(1);

  const getDisk = async (serviceName) => {
    try {
      setIsLoading(true);
      const result = await getAdditionalDiskByVps(serviceName);

      setDisks(result?.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps) getDisk();
  }, [serviceDetail?.nameVps]);

  const render = {
    1: (
      <Box>
        <Card>
          <Box textAlign={"start"} mb="1rem">
            <Button
              variant="brand"
              w="125px"
              h="35px"
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={() => setStep(2)}
            >
              ADD A DISK
            </Button>
          </Box>

          <TableAdditionalDisk tableData={disks} />
        </Card>
      </Box>
    ),
    2: <OrderAdditionalDisk id={serviceDetail?.nameVps} setStep={setStep} />,
  };

  return (
    <Box>{isLoading ? <LoadingOVH size={"3rem"} /> : <>{render[step]}</>}</Box>
  );
}

export default AdditionalDiskTab;
