import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

function ModalReboot({ onClose, onOk, show }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = async () => {
    try {
      setIsLoading(true);

      await onOk();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor={"#5f68c9"}>
        <ModalHeader color={"white"}>Reboot my VPS</ModalHeader>
        <ModalCloseButton color={"white"} />

        <ModalBody>
          <Text color={"white"} fontWeight={"600"} fontSize={"sm"}>
            If you confirm your request, your server will reboot immediately.
          </Text>
          <Text color={"gray.200"} fontWeight={"500"} fontSize={"xs"}>
            <i>Downtime due to reboot: approximately 2 minutes.</i>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={handleOk}
            colorScheme="purple"
            mr={3}
            size="sm"
          >
            Confirm
          </Button>
          <Button onClick={onClose} size="sm">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalReboot;
