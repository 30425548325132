import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, GridItem, Icon, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { BiChip } from "react-icons/bi";
import { convertDescriptionProductToObject } from "utils";
import { convertMoney } from "utils";

function CloudServerCustomProduct({ ram, cpu, disk, unit, price }) {
  const genDescription = () => {
    return [
      {
        name: "CPU",
        value: `${cpu} vCPU`,
      },
      {
        name: "RAM",
        value: `${ram} GB`,
      },
      {
        name: "SSD",
        value: `${disk} GB`,
      },
      {
        name: "Data Transfer",
        value: `Unlimited`,
      },
      {
        name: "IPv4",
        value: `1 Địa chỉ`,
      },
    ];
  };

  return (
    <GridItem colSpan={"1"}>
      <Card p={0} borderRadius={"12px"}>
        <CardHeader
          position="relative"
          textAlign="center"
          py={"2"}
          px="4"
          bg="brand.200"
          borderRadius="12px 12px 0px 0px"
        >
          <Text
            textAlign={"center"}
            w="100%"
            color={"white"}
            fontWeight={"500"}
            fontSize={"18px"}
          >
            CUSTOM CONFIGURATION
          </Text>
        </CardHeader>
        <Box bg={"#eee"} py="4" textAlign={"center"}>
          <Text color={"red.500"} fontSize={"26px"} fontWeight={"600"}>
            {convertMoney(price)}₫
          </Text>

          <Text mt="0" color="brand.200" fontWeight={"500"} fontSize={"16px"}>
            /1 month
          </Text>
        </Box>

        <Box py={4} px="8" bg={"#f8fdff"} borderRadius={"0px 0px 12px 12px"}>
          {genDescription()?.map((value, index) => (
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              _notFirst={{
                mt: "2",
              }}
              pb="1"
              borderBottom={"1px solid #ddd"}
              key={index}
            >
              <Flex alignItems={"center"} gap={1}>
                <Icon as={BiChip} fontSize={"16px"} />
                <Text fontSize={"13px"} fontWeight={"500"}>
                  {value?.name}
                </Text>
              </Flex>
              <Text color={"brand.200"} fontWeight={"600"} fontSize={"15px"}>
                {value?.value}
              </Text>
            </Flex>
          ))}
        </Box>
      </Card>
    </GridItem>
  );
}

export default CloudServerCustomProduct;
