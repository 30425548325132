import { Box, Grid, GridItem, Tag, Text } from "@chakra-ui/react";
import React from "react";
import { convertMoney } from "utils";

function Payment({
  billingcycle,
  date_created,
  expires,
  next_invoice,
  status,
  total,
}) {
  const genCycle = (value) => {
    if (value === "Monthly") return `( Monthly )`;

    return ``;
  };

  const genStatus = (value) => {
    if (value === "Active") return <Tag bg="green.500">Active</Tag>;
    return <Tag color="red.400">Terminated</Tag>;
  };

  return (
    <Box px="4" py="8">
      <Grid
        templateColumns={{
          md: "repeat(2, 1fr)",
          sm: "repeat(1, 1fr)",
        }}
      >
        {/* col 1 */}
        <GridItem>
          <Box mt="8">
            <Text color={"gray.200"} fontSize={"16px"}>
              Registration date
            </Text>
            <Text color={"white"} fontSize={"16px"} fontWeight={"600"}>
              {date_created}
            </Text>
          </Box>

          <Box mt="8">
            <Text color={"gray.200"} fontSize={"16px"}>
              Cycle payment amount
            </Text>
            <Text color={"white"} fontSize={"16px"} fontWeight={"600"}>
              {convertMoney(Number(total))}
              {" $ "}
              {genCycle(billingcycle)}
            </Text>
          </Box>

          <Box mt="8">
            <Text color={"gray.200"} fontSize={"16px"}>
              End date
            </Text>
            <Text color={"red.400"} fontSize={"16px"} fontWeight={"600"}>
              {expires}
            </Text>
          </Box>
        </GridItem>

        {/* col 2 */}
        <GridItem>
          <Box mt="8">
            <Text color={"gray.200"} fontSize={"16px"}>
              Status
            </Text>
            <Text color={"white"} fontSize={"16px"} fontWeight={"600"}>
              {genStatus(status)}
            </Text>
          </Box>

          <Box mt="8">
            <Text color={"gray.200"} fontSize={"16px"}>
              Next bill
            </Text>
            <Text color={"white"} fontSize={"16px"} fontWeight={"600"}>
              {next_invoice}
            </Text>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Payment;
