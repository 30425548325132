import {
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  Box,
  Spinner,
  Input,
  Button,
} from "@chakra-ui/react";
import Pagination from "rc-pagination";
import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { convertToUsd } from "utils/convertToUsd";
import { useTranslation } from "react-i18next";
import { getLanguage } from "utils";
import moment from "moment";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

function TableManagerPaymentHistory(props) {
  const {
    tableData,
    totalDoc,
    pageIndex,
    pageSize,
    onChangeSize,
    onChangePage,
    isLoading,
    columns,
    search,
    setSearch,
    onDelete,
  } = props;

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups } = tableInstance;

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          direction={{ lg: "row", sm: "column" }}
          align={{ sm: "flex-start", lg: "center" }}
          justify={{ sm: "flex-start", lg: "space-between" }}
          w="100%"
          px="22px"
          mb="12px"
        >
          <Text fontWeight="600" color="white" mb={{ lg: "0px", sm: "8px" }}>
            {t("common.history.listPaymentHistory")}
          </Text>
          <Flex
            alignItems={"center"}
            direction={{
              sm: "column",
              lg: "row",
            }}
          >
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Flex>
        </Flex>

        <Stack
          direction={{ sm: "row", md: "row" }}
          spacing={{ sm: "4px", md: "12px" }}
          align="center"
          me="12px"
          my="12px"
          minW={{ sm: "100px", md: "200px" }}
          px="22px"
        >
          <Select
            value={pageSize}
            onChange={(e) => onChangeSize(Number(e.target.value))}
            color="gray.400"
            bg="#0F1535"
            border="0.5px solid"
            borderColor="#E2E8F04D"
            borderRadius="15px"
            size="sm"
            maxW="75px"
            cursor="pointer"
          >
            <option>5</option>
            <option>10</option>
            <option>50</option>
            <option>100</option>
          </Select>
          <Text fontSize="xs" color="gray.400" fontWeight="500">
            entries per page
          </Text>
        </Stack>

        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th borderColor="#56577A" pe="0px" key={index}>
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("label")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td colSpan={7}>
                  <Flex py="2rem" justifyContent="center" w="100%">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#582cff"
                      size="xl"
                    />
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {tableData?.map((row, index) => {
                return (
                  <Tr key={index}>
                    <Td minW="300px">
                      <Text color="white" fontWeight="500" fontSize="sm">
                        {row?.user?.email}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="sm">
                        {row?.services?.map((item) => item?.ip)?.toString() ||
                          "-"}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="sm">
                        {row?.services?.length || 0}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="sm">
                        {convertToUsd(Number(row?.total || 0), getLanguage())}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="sm">
                        {moment(row?.dateOfInvoice).format("DD-MM-YYYY")}
                      </Text>
                    </Td>
                    <Td minW="100px">
                      <Text color="white" fontWeight="600" fontSize="sm">
                        {moment(row?.paymentNeededDate).format("DD-MM-YYYY")}
                      </Text>
                    </Td>
                    <Td minW="150px">
                      <Flex gap={4} alignItems={"center"}>
                        <Button
                          p={0}
                          bg={"blue.400"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          onClick={() => {
                            history.push(
                              `/manager-payment-history-edit/${row?._id}`
                            );
                          }}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          p={0}
                          bg={"red.400"}
                          color={"white"}
                          _hover={{
                            bg: "red.500",
                          }}
                          onClick={() => onDelete(row?._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="500"
            mb={{ sm: "12px", md: "0px" }}
          >
            Showing {pageSize * (pageIndex - 1) + 1} to{" "}
            {pageSize * pageIndex <= totalDoc ? pageSize * pageIndex : totalDoc}{" "}
            of {totalDoc} entries
          </Text>
          <Box
            mb={{
              sm: "12px",
              lg: "0px",
            }}
          >
            <Pagination
              current={pageIndex}
              total={totalDoc}
              showTitle={false}
              jumpNextIcon={<span>...</span>}
              jumpPrevIcon={<span>...</span>}
              pageSize={pageSize}
              prevIcon={<AiOutlineLeft />}
              nextIcon={<AiOutlineRight />}
              onChange={(current) => {
                onChangePage(current);
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default TableManagerPaymentHistory;
