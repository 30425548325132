import { Button, Flex } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableTickets from "components/Tables/TableTickets";
import React, { useEffect, useState } from "react";
import { getSupportByUserId } from "services/api";
import { useHistory } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";

function SupportsList() {
  const { t } = useTranslation();
  const columns = [
    {
      Header: "TITLE",
      label: t("common.table.title"),
    },
    {
      Header: "PRIORITY",
      label: t("common.table.priority"),
    },
    {
      Header: "DEPARTMENT",
      label: t("common.table.department"),
    },
    {
      Header: "ATTACHMENT",
      label: t("common.table.attachment"),
    },
    {
      Header: "STATUS",
      label: t("common.table.status"),
    },
    {
      Header: "CREATED AT",
      label: t("common.table.createdAt"),
    },
    {
      Header: "ACTION",
      label: t("common.table.action"),
    },
  ];
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalDoc, setTotalDoc] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [search, setSearch] = useState("");

  const searchValue = useDebounce(search, 500);

  const history = useHistory();

  const getAllTickets = async () => {
    try {
      setIsLoading(true);
      const result = await getSupportByUserId(
        pageIndex,
        pageSize,
        searchValue,
        status,
        priority
      );

      setTickets(result.data?.data || []);
      setTotalDoc(result.data?.totalItem || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllTickets();
  }, [pageIndex, pageSize, priority, searchValue]);

  return (
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        mb="24px"
      >
        <Button
          variant="brand"
          w="125px"
          h="35px"
          fontSize="xs"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          mb={{ sm: "12px", md: "0px" }}
          onClick={() => history.push("/supports/create")}
        >
          CREATE TICKET
        </Button>
      </Flex>
      <Card px="0px">
        <CardBody>
          <TableTickets
            isLoading={isLoading}
            tableData={tickets}
            totalDoc={totalDoc}
            pageIndex={pageIndex}
            pageSize={pageSize}
            search={search}
            setSearch={setSearch}
            onChangeSize={(e) => setPageSize(e)}
            onChangePage={(e) => setPageIndex(e)}
            setPriority={setPriority}
            columns={columns}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default SupportsList;
