import {
  Box,
  Button,
  Checkbox,
  Flex,
  GridItem,
  Select,
  Text,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLanguage } from "utils";
import { genPriceOvh } from "utils";
import { convertToVnd } from "utils/convertToUsd";

function Step2({
  addons,
  setStep,
  step,
  listVps,
  chosen,
  optionStep2,
  setOptionStep2,
  onSummaryOrderCart,
}) {
  const [dataSnapshot, setDataSnapshot] = useState();
  const [dataAdditionalDisk, setDataAdditionalDisk] = useState();
  const [dataBackup, setDataBackup] = useState();
  const [valueDisk, setValueDisk] = useState("");
  const [optionDisk, setOptionDisk] = useState([]);
  const [disk, setDisk] = useState();

  const initOption = [
    {
      id: 1,
      name: "option-snapshot",
    },
    {
      id: 2,
      name: "option-additional-disk",
      isRange: true,
    },
    {
      id: 3,
      name: "option-auto-backup",
    },
  ];

  useEffect(() => {
    const optionDisks = [];
    const disks = [];

    addons?.forEach((item) => {
      if (
        item?.planCode?.includes(initOption[0]?.name) &&
        item?.planCode?.includes(
          "-" +
            listVps?.[chosen]?.blobs?.technical?.storage?.disks?.[0]?.capacity +
            "g"
        )
      ) {
        setDataSnapshot(item);
      } else if (item?.planCode?.includes(initOption[1]?.name)) {
        optionDisks.push({
          value: item?.planCode,
          label: item?.blobs?.commercial?.name,
        });
        disks.push(item);
      } else if (
        item?.planCode?.includes(initOption[2]?.name) &&
        item?.planCode?.includes(
          "-" +
            listVps?.[chosen]?.blobs?.technical?.storage?.disks?.[0]?.capacity +
            "g"
        )
      ) {
        setDataBackup(item);
      }
    });

    setDataAdditionalDisk(disks || []);
    setOptionDisk(optionDisks || []);
    setValueDisk(
      optionDisks?.sort((a, b) => {
        const qA = Number(a?.label?.split(" ")?.[0] || 0);
        const qB = Number(b?.label?.split(" ")?.[0] || 0);

        return qA - qB;
      })?.[0]?.value
    );
  }, [addons]);

  useEffect(() => {
    if (valueDisk) {
      setDisk(addons?.find((item) => item?.planCode === valueDisk));
    }
  }, [valueDisk]);

  const genPriceByAddon = (addon) => {
    if (!addon) return 0;

    return (
      addon?.pricings?.find(
        (item) =>
          item?.commitment === 0 && item?.interval === 1 && item?.phase === 1
      )?.price || 0
    );
  };

  const { t } = useTranslation();

  return (
    <GridItem colSpan={3}>
      <Text color={"white"} fontSize="1.5rem" mb="1rem" fontWeight={"600"}>
        Options
      </Text>

      <Card>
        <CardHeader>
          <Box w={"100%"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              mb="1rem"
              w={"100%"}
            >
              <Flex alignItems={"center"}>
                <Checkbox
                  isChecked={Boolean(
                    optionStep2?.snapshot?.planCode === dataSnapshot?.planCode
                  )}
                  size="lg"
                  mr="1rem"
                  colorScheme={"purple"}
                  onChange={() => {
                    setOptionStep2({
                      ...optionStep2,
                      snapshot: optionStep2?.snapshot ? null : dataSnapshot,
                    });
                  }}
                />
                <Text
                  fontSize="xl"
                  color="brand.100"
                  fontWeight="bold"
                  lineHeight={"1"}
                >
                  Snapshot
                </Text>
              </Flex>
              <Text fontSize={"lg"} color={"white"} fontWeight={"600"}>
                {getLanguage() == "en" ? `US$` : ""}{" "}
                {convertToVnd(
                  genPriceOvh(genPriceByAddon(dataSnapshot), true),
                  getLanguage()
                )}
                <Text as="span" fontSize={"sm"}>
                  {" "}
                  /month
                </Text>
              </Text>
            </Flex>

            <Text fontSize="sm" color="gray.400">
              {t("common.service.snapshotDescription")}
            </Text>
          </Box>
        </CardHeader>
      </Card>

      <HSeparator my="1rem" />

      <Card>
        <CardHeader>
          <Box w={"100%"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              mb="1rem"
              w={"100%"}
            >
              <Flex alignItems={"center"}>
                <Checkbox
                  isChecked={Boolean(
                    optionStep2?.additionalDisk?.planCode === disk?.planCode
                  )}
                  size="lg"
                  mr="1rem"
                  colorScheme={"purple"}
                  onChange={() => {
                    setOptionStep2({
                      ...optionStep2,
                      additionalDisk: optionStep2?.additionalDisk ? null : disk,
                    });
                  }}
                />
                <Text
                  fontSize="xl"
                  color="brand.100"
                  fontWeight="bold"
                  lineHeight={"1"}
                >
                  Additional storage
                </Text>
              </Flex>
              <Text fontSize={"lg"} color={"white"} fontWeight={"600"}>
                {getLanguage() == "en" ? `US$` : ""}{" "}
                {convertToVnd(
                  genPriceOvh(genPriceByAddon(disk), true),
                  getLanguage()
                )}
                <Text as="span" fontSize={"sm"}>
                  {" "}
                  /month
                </Text>
              </Text>
            </Flex>

            <Select
              my="1rem"
              border=".5px solid"
              borderColor="rgba(226, 232, 240, 0.3)"
              _hover="none"
              bg="#0F1535"
              borderRadius="15px"
              color="gray.400"
              fontSize="md"
              value={valueDisk}
              onChange={(e) => setValueDisk(e.target.value)}
            >
              {optionDisk?.map((item) => (
                <option value={item?.value} key={item?.value}>
                  {item?.label}
                </option>
              ))}
            </Select>

            <Text fontSize="sm" color="gray.400">
              {t("common.service.additionalStorageDescription")}
            </Text>
          </Box>
        </CardHeader>
      </Card>

      <HSeparator my="1rem" />

      <Card>
        <CardHeader>
          <Box w={"100%"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              mb="1rem"
              w={"100%"}
            >
              <Flex alignItems={"center"}>
                <Checkbox
                  isChecked={Boolean(
                    optionStep2?.backup?.planCode === dataBackup?.planCode
                  )}
                  size="lg"
                  mr="1rem"
                  colorScheme={"purple"}
                  onChange={() => {
                    setOptionStep2({
                      ...optionStep2,
                      backup: optionStep2?.backup ? null : dataBackup,
                    });
                  }}
                />
                <Text
                  fontSize="xl"
                  color="brand.100"
                  fontWeight="bold"
                  lineHeight={"1"}
                >
                  Automated backup
                </Text>
              </Flex>
              <Text fontSize={"lg"} color={"white"} fontWeight={"600"}>
                {getLanguage() == "en" ? `US$` : ""}{" "}
                {convertToVnd(
                  genPriceOvh(genPriceByAddon(dataBackup), true),
                  getLanguage()
                )}
                <Text as="span" fontSize={"sm"}>
                  {" "}
                  /month
                </Text>
              </Text>
            </Flex>

            <Text fontSize="sm" color="gray.400">
              {t("common.service.automatedBackUpDescription")}
            </Text>
          </Box>
        </CardHeader>
      </Card>

      <Flex mt="1rem" justifyContent={"space-between"}>
        <Button
          bgColor={"red.400"}
          _hover={{
            bgColor: "red.500",
          }}
          color={"white"}
          w="125px"
          h="40px"
          fontSize="md"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          onClick={() => setStep(step - 1)}
        >
          {t("common.button.back")}
        </Button>

        <Button
          variant="brand"
          w="125px"
          h="40px"
          fontSize="md"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          onClick={onSummaryOrderCart}
        >
          {t("common.button.continue")}
        </Button>
      </Flex>
    </GridItem>
  );
}

export default Step2;
