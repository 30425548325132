import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import LoadingOVH from "components/Loading/Loading";
import TableSecondaryDNS from "components/Tables/TableSecondaryDNS";
import React, { useEffect, useState } from "react";
import { addSecondaryDNSDomain } from "services/api";
import { getSecondaryDNSByVps } from "services/api";

const ModalAddDomain = ({ show, onClose, title, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  const handleClose = () => {
    onClose();
    setError("");
    setText("");
  };

  const handleAddDomain = async () => {
    try {
      setIsLoading(true);

      const result = await addSecondaryDNSDomain(id, text);

      handleClose();
    } catch (error) {
      console.log(error);
      setError(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={show} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent bgColor={"#5f68c9"}>
        <ModalHeader color={"white"}>{title}</ModalHeader>
        <ModalCloseButton color={"white"} />

        <ModalBody>
          {isLoading ? (
            <LoadingOVH size="3rem" />
          ) : (
            <Box>
              {error && (
                <Box>
                  <Alert
                    borderRadius="8px"
                    status="error"
                    variant="solid"
                    mb="1rem"
                  >
                    <AlertIcon color="white" />
                    <AlertTitle fontSize={"sm"} mr="12px">
                      {error}
                    </AlertTitle>
                  </Alert>
                </Box>
              )}
              <Text color={"white"} fontWeight={"600"} fontSize={"sm"}>
                Domain to be added:
              </Text>
              <Input
                color="gray.400"
                bg="#0F1535"
                border="0.5px solid"
                borderColor="#E2E8F04D"
                borderRadius="15px"
                w={"100%"}
                _focus={{
                  borderColor: "white",
                }}
                _active={{
                  borderColor: "white",
                }}
                value={text}
                onChange={(e) => setText(e.target?.value)}
              />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleAddDomain}
            colorScheme="purple"
            mr={3}
            size="sm"
          >
            Confirm
          </Button>
          <Button onClick={handleClose} size="sm">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const initModal = {
  visible: false,
  title: "Add a domain to the secondary DNS",
  domain: null,
};

function SecondaryDNS({ serviceDetail }) {
  const [isLoading, setIsLoading] = useState(true);
  const [dns, setDns] = useState([]);
  const [modal, setModal] = useState(initModal);

  const getDns = async (serviceName) => {
    try {
      setIsLoading(true);
      const result = await getSecondaryDNSByVps(serviceName);

      setDns(result?.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps) getDns(serviceDetail?.nameVps);
  }, [serviceDetail?.nameVps]);

  const handleOpenModalAddDomain = (title, domain) => {
    setModal({
      visible: true,
      domain,
      title: title || initModal.title,
    });
  };

  return (
    <Box>
      {isLoading ? (
        <LoadingOVH size={"3rem"} />
      ) : (
        <>
          <Box textAlign={"start"} mb="1rem">
            <Button
              variant="brand"
              w="125px"
              h="35px"
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={() => handleOpenModalAddDomain(null, "")}
            >
              ADD A DOMAIN
            </Button>
          </Box>
          <Card>
            <CardHeader>
              <Text
                color={"white"}
                fontSize={"lg"}
                fontWeight={"600"}
                mb="1rem"
              >
                SECONDARY DNS
              </Text>
            </CardHeader>
            <TableSecondaryDNS tableData={dns} />
          </Card>

          <ModalAddDomain
            id={serviceDetail?.nameVps}
            onClose={() => setModal(initModal)}
            show={modal.visible}
            title={modal.title}
          />
        </>
      )}
    </Box>
  );
}

export default SecondaryDNS;
