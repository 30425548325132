import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { useHistory } from "react-router-dom";

export const columns = [
  {
    Header: "Domain",
    accessor: "domain",
  },
  {
    Header: "IP",
    accessor: "ipMaster",
  },
  {
    Header: "Creation date",
    accessor: "creationDate",
  },
  {
    Header: "Secondary DNS",
    accessor: "dns",
  },
  {
    Header: "ACTION",
    accessor: "",
  },
];

function TableSecondaryDNS(props) {
  const { tableData } = props;

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups } = tableInstance;

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th borderColor="#56577A" pe="0px" key={index}>
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          {tableData?.length ? (
            <Tbody {...getTableBodyProps()}>
              {tableData?.map((row, index) => {
                return {
                  /* <Tr key={index}>
                  <Td minW="300px">
                    <Text color="white" fontWeight="600" fontSize="lg">
                      {row?.nameVps}
                    </Text>
                    <Text mt="8px" fontSize="xs">
                      {row?.vps?.model?.vcore || 0} CPUs -{" "}
                      {convertMBtoGB(Math.ceil(row?.vps?.model?.memory || 0))}{" "}
                      GB RAM - {row?.vps?.model?.disk || 0} GB DISK
                    </Text>
                  </Td>
                  <Td minW="250px">
                    <Text color="white" fontWeight="400" fontSize="sm">
                      {row?.ips?.[0]}
                    </Text>
                    <Text color="white" fontWeight="400" fontSize="sm">
                      {row?.vps?.zone}
                    </Text>
                  </Td>
                  <Td minW="200px">
                    <Flex alignItems="center">
                      <Image
                        src={
                          imageTemplate?.find((item) =>
                            row?.images?.name
                              ?.toLowerCase()
                              .includes(item?.value)
                          )?.icon
                        }
                      />
                      <Text
                        ml="8px"
                        color="white"
                        fontSize="sm"
                        fontWeight="400"
                      >
                        {row?.images?.name}
                      </Text>
                    </Flex>
                  </Td>
                  <Td minW="150px">
                    <Text color="white" fontSize="sm" fontWeight="400">
                      {row?.info?.creation}
                    </Text>
                  </Td>
                  <Td minW="150px">
                    {row?.info?.expiration && (
                      <>
                        <Text color="white" fontSize="sm" fontWeight="400">
                          {row?.info?.expiration}
                        </Text>
                        <Text color="red.500" fontSize="sm" fontWeight="600">
                          {getMonthDateGap(row?.info?.expiration).months === 0
                            ? null
                            : getMonthDateGap(row?.info?.expiration).months +
                              " tháng"}{" "}
                          {getMonthDateGap(row?.info?.expiration).days +
                            " ngày tới "}{" "}
                        </Text>
                      </>
                    )}
                  </Td>
                  <Td minW="150px">
                    <Menu placement="bottom-end">
                      <MenuButton
                        color="white"
                        _hover={{
                          color: "#0F1535",
                          background: "white",
                        }}
                        bg="#0F1535"
                        w="40px"
                        h="40px"
                        border="0.5px solid"
                        borderColor="#E2E8F04D"
                        borderRadius="100%"
                        size="sm"
                      >
                        <Flex justifyContent={"center"}>
                          <FiMoreHorizontal size={22} />
                        </Flex>
                      </MenuButton>
                      <MenuList p="0" borderRadius="0" minW="150px">
                        <MenuItem p="0">
                          <Button
                            color="#fff"
                            _hover={{
                              color: "#0F1535",
                              background: "#fff",
                            }}
                            bg="#0F1535"
                            borderRadius="0"
                            size="sm"
                            w="100%"
                            textAlign="left"
                            display="flex"
                            justifyContent="flex-start"
                            onClick={() => {
                              history.push(
                                `/services/detail/${genIdNameVps(row?.nameVps)}`
                              );
                            }}
                          >
                            <AiFillEye size={20} />
                            <Text ml="4px">Xem chi tiết</Text>
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr> */
                };
              })}
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              <Tr>
                <Td colSpan={6}>
                  <Text
                    textAlign={"center"}
                    color={"white"}
                    fontSize={"lg"}
                    fontWeight={"600"}
                  >
                    No data
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </Flex>
    </>
  );
}

export default TableSecondaryDNS;
