import { Box, Select, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingOVH from "components/Loading/Loading";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getMonitoringByVps } from "services/api";
import { PERIOD_MONITORING_OVH } from "utils";
import LineChart from "components/Charts/LineChart";

function MonitoringTab({ serviceDetail }) {
  const [period, setPeriod] = useState(PERIOD_MONITORING_OVH.LAST_DAY);
  const [isLoading, setIsLoading] = useState(true);
  const [processorUsage, setProcessorUsage] = useState({
    data: [],
    labels: [],
    unit: "",
  });

  const [ramUsage, setRamUsage] = useState({ data: [], labels: [], unit: "" });

  const [traffic, setTraffic] = useState({
    unit: "",
    labels: [],
    incoming: [],
    outgoing: [],
  });

  const getMonitoring = async (serviceName) => {
    try {
      setIsLoading(true);

      const result = await getMonitoringByVps(serviceName, period);
      console.log("dasasdasdasdzxc zxc zxc zxc zxc ", result.data.data);
      const labelsProcessor = [];
      const dataProcessor = [];
      const labelsRam = [];
      const dataRam = [];
      const labelTraffic = [];
      const incoming = [];
      const outgoing = [];

      result.data.data?.processorUsage?.values?.forEach((item) => {
        labelsProcessor.push(
          moment(item?.timestamp * 1000).format("DD/MM/YY - HH:mm:ss")
        );
        dataProcessor.push(item?.value?.toFixed(2));
      });

      result.data.data?.ram?.values?.forEach((item) => {
        labelsRam.push(
          moment(item?.timestamp * 1000).format("DD/MM/YY - HH:mm:ss")
        );
        dataRam.push(item?.value?.toFixed(2));
      });

      result.data.data?.incoming?.values?.forEach((item) => {
        labelTraffic.push(
          moment(item?.timestamp * 1000).format("DD/MM/YY - HH:mm:ss")
        );
        incoming.push(item?.value?.toFixed(2));
      });

      result.data.data?.outgoing?.values?.forEach((item) => {
        outgoing.push(item?.value?.toFixed(2));
      });

      setProcessorUsage({
        unit: result.data.data?.processorUsage?.unit || "",
        data: dataProcessor,
        labels: labelsProcessor,
      });

      setRamUsage({
        unit: result.data.data?.ram?.unit || "",
        data: dataRam,
        labels: labelsRam,
      });

      setTraffic({
        unit: "RPS",
        labels: labelTraffic,
        incoming,
        outgoing,
      });
      console.log(result.data.data?.processorUsage);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceDetail?.nameVps) getMonitoring(serviceDetail?.nameVps);
  }, [serviceDetail?.nameVps, period]);

  const genOptionChart = (xaxis = [], colors = ["#582CFF"]) => {
    const option = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      xaxis: {
        type: "datetime",
        categories: xaxis,
        labels: {
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 0.8,
          opacityTo: 0,
          stops: [],
        },
        colors: colors,
      },
      colors: colors,
    };

    return option;
  };

  return (
    <Box>
      {isLoading ? (
        <LoadingOVH size="3rem" />
      ) : (
        <>
          <Box textAlign={"start"}>
            <Select
              w={"300px"}
              my="1rem"
              border=".5px solid"
              borderColor="rgba(226, 232, 240, 0.3)"
              _hover="none"
              bg="#0F1535"
              borderRadius="15px"
              color="gray.400"
              fontSize="md"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
            >
              {[
                {
                  value: PERIOD_MONITORING_OVH.TODAY,
                  label: "Today",
                },
                {
                  value: PERIOD_MONITORING_OVH.LAST_DAY,
                  label: "The last 24 hours",
                },
                {
                  value: PERIOD_MONITORING_OVH.LAST_WEEK,
                  label: "The last 7 days",
                },
                {
                  value: PERIOD_MONITORING_OVH.LAST_MONTH,
                  label: "The last month",
                },
                {
                  value: PERIOD_MONITORING_OVH.LAST_YEAR,
                  label: "The last 12 months",
                },
              ]?.map((item) => (
                <option value={item?.value} key={item?.value}>
                  {item?.label}
                </option>
              ))}
            </Select>
          </Box>

          {processorUsage?.unit && (
            <Card>
              <CardHeader mb="34px" px="22px">
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Processor Usage ({processorUsage?.unit})
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box
                  w="100%"
                  h={{
                    lg: "700px",
                    sm: "400px",
                  }}
                >
                  <LineChart
                    chartData={[
                      {
                        name: "Processor usage",
                        data: processorUsage.data,
                      },
                    ]}
                    chartOptions={genOptionChart(processorUsage?.labels, [
                      "#fff",
                    ])}
                  />
                </Box>
              </CardBody>
            </Card>
          )}

          {ramUsage?.unit && (
            <Card mt="2rem">
              <CardHeader mb="34px" px="22px">
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Ram Usage ({ramUsage?.unit})
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box
                  w="100%"
                  h={{
                    lg: "700px",
                    sm: "400px",
                  }}
                >
                  <LineChart
                    chartData={[
                      {
                        name: "Ram usage",
                        data: ramUsage.data,
                      },
                    ]}
                    chartOptions={genOptionChart(ramUsage?.labels, ["#fff"])}
                  />
                </Box>
              </CardBody>
            </Card>
          )}

          {traffic?.unit && (
            <Card mt="2rem">
              <CardHeader mb="34px" px="22px">
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Traffic Usage ({traffic?.unit})
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box
                  w="100%"
                  h={{
                    lg: "700px",
                    sm: "400px",
                  }}
                >
                  <LineChart
                    chartData={[
                      {
                        name: "Incoming",
                        data: traffic.incoming,
                      },
                      {
                        name: "Outgoing",
                        data: traffic.outgoing,
                      },
                    ]}
                    chartOptions={genOptionChart(traffic?.labels, [
                      "#fff",
                      "#582CFF",
                    ])}
                  />
                </Box>
              </CardBody>
            </Card>
          )}
        </>
      )}
    </Box>
  );
}

export default MonitoringTab;
