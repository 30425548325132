import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import React from "react";
import ConfigVPSInstanceItem from "./ConfigVPSInstanceItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import FamilyOsItem from "./FamilyOsItem";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const images = [
  {
    title: "Distribution only",
    value: "order-funnel:distribution-only",
  },
  {
    title: "Distribution with application",
    value: "order-funnel:distribution-with-application",
  },
];

function Step1({
  listPlans,
  listVps,
  genDataCenter,
  vpsOs,
  addons,
  chosenFamily,
  setChosenFamily,
  distribution,
  setDistribution,
  os,
  setOs,
  chosen,
  setChosen,
  listDataCenters,
  setListDataCenters,
  setStep,
  step,
  license,
  setLicense,
}) {
  const history = useHistory();

  const handleChooseVps = (index) => {
    setChosen(index);
  };

  const handleChooseDistribution = (value) => {
    setDistribution(value);
  };

  const handleChangeDataCenter = (position, quantity) => {
    if (quantity < 0 || quantity > 100) return;
    setListDataCenters((prevState) => {
      const newState = [...prevState];

      newState?.splice(position, 1, {
        ...newState?.[position],
        quantity,
      });

      return newState;
    });
  };

  const disableContinue = () => {
    return !os || !genDataCenter?.count;
  };

  const { t } = useTranslation();

  return (
    <GridItem colSpan={3}>
      <Text color={"white"} fontSize="1.5rem" mb="1rem" fontWeight={"600"}>
        {t("common.service.configureYourServer")}
      </Text>

      <Card>
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
              {t("common.service.configureYourServerInstance")}
            </Text>
            <Text fontSize="sm" color="gray.400">
              {t("common.service.introduceService")}
            </Text>
          </Flex>
        </CardHeader>

        <Grid
          templateColumns={{
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
          }}
          gap={4}
          mt={"8px"}
        >
          {listVps?.map((item, index) => (
            <ConfigVPSInstanceItem
              key={item?.name}
              item={item}
              plan={listPlans[index]}
              chosen={chosen}
              index={index}
              handleChooseVps={handleChooseVps}
            />
          ))}
        </Grid>
      </Card>

      <Card mt="2rem">
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
              {t("common.service.chooseImage")}
            </Text>
            <Text fontSize="sm" color="gray.400">
              {t("common.service.chooseImageDescription")}
            </Text>
          </Flex>
        </CardHeader>

        <Box>
          <RadioGroup value={distribution}>
            <Flex gap={[3]}>
              {images?.map((item) => (
                <Flex
                  pl="12px"
                  py="32px"
                  bg="rgb(31, 35, 89)"
                  borderColor={
                    distribution === item?.value ? "brand.100" : "transparent"
                  }
                  borderWidth={"4px"}
                  borderRadius="20px"
                  align="center"
                  cursor={"pointer"}
                  w="300px"
                  key={item.title}
                  _hover={{
                    borderColor: "brand.100",
                  }}
                  onClick={() => handleChooseDistribution(item.value)}
                >
                  <Radio
                    value={item?.value}
                    size="lg"
                    mr="1rem"
                    colorScheme={"purple"}
                  />
                  <Text color="#fff" fontSize="lg">
                    {item?.title}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </RadioGroup>

          {distribution && (
            <>
              <HSeparator my="1rem" />
              <Grid
                gap={[3, 4]}
                templateColumns={{
                  sm: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  xl: "repeat(3, 1fr)",
                }}
                gridAutoRows={"1fr"}
              >
                {console.log(vpsOs)}
                {vpsOs?.map((item) => (
                  <FamilyOsItem
                    item={item}
                    key={item?.family}
                    addons={addons || []}
                    chosenProduct={listVps?.[chosen]}
                    chosenFamily={chosenFamily}
                    onChooseFamily={(family) => {
                      setChosenFamily(family);
                    }}
                    os={os}
                    setOs={setOs}
                    distribution={distribution}
                    license={license}
                    setLicense={setLicense}
                  />
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Card>

      {os && (
        <Card mt="2rem">
          <CardHeader p="12px 5px" mb="12px">
            <Flex direction="column">
              <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
                {t("common.service.keepOrModifyDataCenter")}
              </Text>
              <Text fontSize="sm" color="gray.400">
                {t("common.service.keepOrModifyDataCenterDescription")}
              </Text>
            </Flex>
          </CardHeader>

          <Box>
            <Flex justify="space-between" w="100%">
              <Text color="#fff" fontSize="lg" fontWeight="bold">
                Slot
              </Text>
              <Text color="white" fontSize="lg" fontWeight="bold">
                Quantity
              </Text>
            </Flex>

            {listDataCenters?.map((item, index) => (
              <React.Fragment key={item?.value}>
                <HSeparator my="8px" />
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  h={"100%"}
                >
                  <Flex h="100%" minH={"100%"} position={"relative"}>
                    <Box
                      position={"absolute"}
                      top={"0px"}
                      left={"0px"}
                      bgColor="brand.200"
                      borderRadius="12px"
                      minWidth="4px"
                      maxH="100%"
                      h="100%"
                    />

                    <Text
                      py="8px"
                      color={"white"}
                      fontWeight={"500"}
                      fontSize={"md"}
                      ml="12px"
                    >
                      {item?.blobs?.technical?.datacenter?.country},{" "}
                      {item?.blobs?.technical?.datacenter?.city} ({item?.value})
                    </Text>
                  </Flex>

                  <Flex alignItems={"center"}>
                    <Button
                      disabled={item?.quantity === 0}
                      size="sm"
                      variant="brand"
                      onClick={() =>
                        handleChangeDataCenter(index, item?.quantity - 1)
                      }
                    >
                      <Icon as={FaMinus} w="14px" h="14px" />
                    </Button>

                    <Input
                      mx="0.5rem"
                      bg="#0F1535"
                      border=".5px solid"
                      borderColor="rgba(226, 232, 240, 0.3)"
                      _hover="none"
                      color="#fff"
                      borderRadius="15px"
                      placeholder=""
                      fontSize="sm"
                      w={"60px"}
                      type="number"
                      textAlign={"center"}
                      onChange={(e) => {
                        let value = Number(e?.target?.value);

                        handleChangeDataCenter(
                          index,
                          value > 100 ? 100 : value
                        );
                      }}
                      value={item?.quantity}
                    />

                    <Button
                      disabled={item?.quantity === 100}
                      size="sm"
                      variant="brand"
                      onClick={() =>
                        handleChangeDataCenter(index, item?.quantity + 1)
                      }
                    >
                      <Icon as={FaPlus} w="14px" h="14px" />
                    </Button>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Box>
        </Card>
      )}

      <Flex mt="1rem" justifyContent={"space-between"}>
        <Button
          bgColor={"red.400"}
          _hover={{
            bgColor: "red.500",
          }}
          color={"white"}
          w="125px"
          h="40px"
          fontSize="md"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          onClick={() => history.push("/services/list")}
        >
          {t("common.button.back")}
        </Button>

        <Button
          variant="brand"
          w="125px"
          h="40px"
          fontSize="md"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          onClick={() => setStep(step + 1)}
          disabled={disableContinue()}
        >
          {t("common.button.continue")}
        </Button>
      </Flex>
    </GridItem>
  );
}

export default Step1;
