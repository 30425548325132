import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, GridItem, Text } from "@chakra-ui/react";
import React from "react";

function OsTemplate({
  item,
  onShow,
  onChosen,
  isShow,
  isChosen,
  chosenOsTemplate,
}) {
  return (
    <GridItem gridAutoRows={"1fr"} w={"100%"}>
      <Flex
        p={"2rem 1rem"}
        bg={"white"}
        gap={5}
        borderRadius={"6px"}
        position={"relative"}
        onClick={onShow}
        cursor={"pointer"}
      >
        <Box w="48px" h={"48px"}>
          {item?.image}
        </Box>

        <Box>
          <Text fontWeight={"600"} color={"brand.200"} fontSize={"18px"}>
            {item?.name}
          </Text>
          {isChosen ? (
            <Text cursor={"pointer"} color={"green.400"} fontSize={"14px"}>
              {chosenOsTemplate?.name}
            </Text>
          ) : (
            <Text cursor={"pointer"} color={"gray"} fontSize={"14px"}>
              Choose version
            </Text>
          )}

          {isShow && (
            <Box>
              {item?.template?.map((item) => (
                <Text
                  fontWeight={"500"}
                  fontSize={"14px"}
                  cursor={"pointer"}
                  _hover={{
                    color: "brand.200",
                  }}
                  color={
                    chosenOsTemplate?.id === item?.id ? "brand.200" : "#000"
                  }
                  key={item?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChosen(item);
                  }}
                >
                  {item?.name}
                </Text>
              ))}
            </Box>
          )}
        </Box>
        {isChosen && (
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            bg={"brand.200"}
            position={"absolute"}
            w={"30px"}
            h={"25px"}
            borderRadius={"0 0 0 100%"}
            top={"0px"}
            right={"0px"}
          >
            <CheckIcon ml={"5px"} mb={"5px"} color={"white"} />
          </Flex>
        )}
      </Flex>
    </GridItem>
  );
}

export default OsTemplate;
