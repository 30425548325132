import {
  Box,
  Button,
  Flex,
  Icon,
  Radio,
  RadioGroup,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import LoadingOVH from "components/Loading/Loading";
import { HSeparator } from "components/Separator/Separator";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { getCatalogCloudDB } from "services/api";
import { createCloudDBForVPS } from "services/api";
import Swal from "sweetalert2";
import { genPriceOvh } from "utils";
import { RESPONSE_STATUS } from "utils";

const OrderCloudDatabase = ({ id, setStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [catalog, setCatalog] = useState({});
  const [optionServer, setOptionServer] = useState([]);
  const [optionRam, setOptionRam] = useState([]);
  const [optionPrice, setOptionPrice] = useState([]);
  const [chosenServer, setChosenServer] = useState();
  const [chosenRam, setChosenRam] = useState();
  const [chosenPrice, setChosenPrice] = useState({});

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const getCatalog = async () => {
    try {
      setIsLoading(true);

      const result = await getCatalogCloudDB();

      const { data } = result.data;

      const serverVersion = data?.plans?.[0]?.configurations?.[1]?.values?.map(
        (db) => {
          return {
            value: db,
            label: db
              ?.replace(db?.[0], db?.[0]?.toUpperCase())
              ?.replace("_", " "),
          };
        }
      );

      const ram = data?.plans?.sort((a, b) => {
        return (
          a?.blobs?.technical?.memory?.size - b?.blobs?.technical?.memory?.size
        );
      });

      setOptionRam(
        ram?.map((item) => {
          return {
            value: item?.planCode,
            label: item?.invoiceName?.substring(20),
          };
        })
      );

      setOptionServer(serverVersion || []);
      setCatalog(data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCatalog();
  }, []);

  useEffect(() => {
    if (chosenRam && chosenServer) {
      setChosenPrice(null);
      const temp = catalog?.plans?.find((item) => item?.planCode === chosenRam);

      const data = temp?.pricings?.filter((item) => item?.phase) || [];
      data?.sort((a, b) => {
        return a?.interval - b?.interval;
      });

      setOptionPrice(data);
    }
  }, [chosenRam, chosenServer]);

  const onNext = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to use this cloud database for vps!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoadingCreate(true);

          const plan = catalog?.plans?.find(
            (item) => item?.planCode === chosenRam
          );

          const data = {
            duration:
              chosenPrice?.interval < 12
                ? `P${chosenPrice?.interval}M`
                : `P${chosenPrice?.interval / 12}Y`,
            planCode: chosenRam,
            dc: plan?.configurations?.[0]?.values?.[0] || "",
            engine: chosenServer,
            total: Number(genPriceOvh(chosenPrice?.price + chosenPrice?.tax)),
          };

          console.log("zxczxczxc", data);

          const result = await createCloudDBForVPS(id, data);

          const { status, message } = result.data;

          if (status === RESPONSE_STATUS.FAILED) {
            toast({
              status: "error",
              title: message,
            });

            return;
          }

          toast({
            status: "success",
            title: "Thanh toán thành công hệ thống đang xử lý",
          });
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingCreate(false);
        }
      }
    });
  };

  return (
    <div className="order-cloud-db">
      {isLoading ? (
        <LoadingOVH size="3rem" />
      ) : (
        <>
          <Box textAlign={"left"}>
            <Button
              borderRadius="12px"
              transition="background .3s ease"
              bg={"brand.200"}
              _hover={{
                bg: "brand.200",
              }}
              _active={{
                bg: "brand.200",
              }}
              me={{ base: "none", lg: "20px" }}
              leftIcon={<Icon color="white" as={BsArrowLeft} me="6px" />}
              onClick={() => setStep(1)}
              minW="135px"
            >
              <Text fontSize="xs" color="#fff" fontWeight="bold">
                Back to previous page
              </Text>
            </Button>

            <Text fontSize="xl" color="#fff" fontWeight="bold" mt="1rem">
              Order a Web Cloud Database
            </Text>
            <Text fontSize="sm" color="gray.200" fontWeight="500" mt="0.5rem">
              Web Cloud Databases require a service that has access to the
              public network/internet.
            </Text>
          </Box>

          <HSeparator my="1rem" />

          <Box>
            <Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                minW={"40px"}
                h="40px"
                borderRadius={"100%"}
                bgColor={"brand.100"}
                color={"white"}
                fontSize={"lg"}
                fontWeight={"600"}
                mr="1rem"
              >
                1
              </Flex>
              <Box textAlign={"left"}>
                <Text color={"white"} fontWeight={"500"} fontSize={"xl"}>
                  Customisation
                </Text>

                <Text fontSize={"md"} mt="1rem" color={"white"}>
                  Server version
                </Text>
                <Select
                  mt="8px"
                  border=".5px solid"
                  borderColor="rgba(226, 232, 240, 0.3)"
                  _hover="none"
                  bg="#0F1535"
                  borderRadius="15px"
                  color="gray.400"
                  fontSize="md"
                  placeholder="Select..."
                  value={chosenServer}
                  onChange={(e) => setChosenServer(e.target.value)}
                >
                  {optionServer?.map((item) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Select>

                <Text fontSize={"md"} mt="1rem" color={"white"}>
                  RAM
                </Text>
                <Select
                  mt="8px"
                  border=".5px solid"
                  borderColor="rgba(226, 232, 240, 0.3)"
                  _hover="none"
                  bg="#0F1535"
                  placeholder="Select..."
                  borderRadius="15px"
                  color="gray.400"
                  fontSize="md"
                  value={chosenRam}
                  onChange={(e) => setChosenRam(e.target.value)}
                >
                  {optionRam?.map((item) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
          </Box>

          <HSeparator my="1rem" />

          <Box>
            <Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                minW={"40px"}
                h="40px"
                borderRadius={"100%"}
                bgColor={"brand.100"}
                color={"white"}
                fontSize={"lg"}
                fontWeight={"600"}
                mr="1rem"
              >
                2
              </Flex>
              <Box>
                <Text
                  align={"start"}
                  color={"white"}
                  fontWeight={"500"}
                  fontSize={"xl"}
                >
                  Choose the duration
                </Text>

                <RadioGroup value={chosenPrice?.interval}>
                  <Flex
                    direction={"column"}
                    alignItems={"flex-start"}
                    mt="1rem"
                  >
                    {optionPrice?.map((item) => (
                      <div key={item?.interval}>
                        <Radio
                          colorScheme="purple"
                          checked={chosenPrice?.interval === item?.interval}
                          onChange={() => setChosenPrice(item)}
                        >
                          <Text as={"span"} color={"white"} fontSize={"sm"}>
                            {item?.interval} month(s):{" "}
                          </Text>
                          <Text
                            as={"span"}
                            fontSize={"sm"}
                            fontWeight={"600"}
                            color="green.200"
                          >
                            US$ {genPriceOvh(item?.price)} ex.VAT{" "}
                          </Text>
                          <Text as={"span"} color={"white"} fontSize={"sm"}>
                            (US$ {genPriceOvh(item?.price + item?.tax)} incl.
                            VAT)
                          </Text>
                        </Radio>
                      </div>
                    ))}
                  </Flex>
                </RadioGroup>
              </Box>
            </Flex>
          </Box>

          <HSeparator my="1rem" />

          <Box>
            <Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                minW={"40px"}
                h="40px"
                borderRadius={"100%"}
                bgColor={"brand.100"}
                color={"white"}
                fontSize={"lg"}
                fontWeight={"600"}
                mr="1rem"
              >
                3
              </Flex>
              <Box textAlign={"start"}>
                <Text color={"white"} fontWeight={"500"} fontSize={"xl"}>
                  Payment
                </Text>

                {chosenPrice?.interval && (
                  <>
                    <Flex align={"center"}>
                      <Text
                        color={"green.200"}
                        fontWeight={"600"}
                        fontSize={"md"}
                        w="200px"
                      >
                        Price ex. VAT:
                      </Text>
                      <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                        ${genPriceOvh(chosenPrice?.price)} USD
                      </Text>
                    </Flex>

                    <Flex align={"center"}>
                      <Text
                        color={"green.200"}
                        fontWeight={"600"}
                        fontSize={"md"}
                        w="200px"
                      >
                        Price incl. VAT:
                      </Text>
                      <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                        ${genPriceOvh(chosenPrice?.price + chosenPrice?.tax)}{" "}
                        USD
                      </Text>
                    </Flex>

                    <Flex align={"center"}>
                      <Text
                        color={"green.200"}
                        fontWeight={"600"}
                        fontSize={"md"}
                        w="200px"
                      >
                        Duration:
                      </Text>
                      <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                        {chosenPrice?.interval} month(s)
                      </Text>
                    </Flex>

                    <Button
                      isLoading={isLoadingCreate}
                      variant="brand"
                      w="85px"
                      h="35px"
                      fontSize="xs"
                      fontWeight="bold"
                      alignSelf={{ sm: "flex-start", lg: null }}
                      mt="1rem"
                      onClick={onNext}
                    >
                      CONFIRM
                    </Button>
                  </>
                )}
              </Box>
            </Flex>
          </Box>
        </>
      )}
    </div>
  );
};

function DatabaseTab({ serviceDetail }) {
  const [step, setStep] = useState(1);

  const render = {
    1: (
      <Box textAlign={"start"}>
        <Text color={"white"} fontWeight={"bold"} fontSize={"2rem"}>
          Databases
        </Text>
        <Text color={"white"} fontWeight={"400"} fontSize={"sm"} mt="1rem">
          Need reliable, high-performance databases that are fully-managed by
          OVHcloud?
        </Text>
        <Text color={"white"} fontWeight={"400"} fontSize={"sm"} mt="8px">
          Order our Cloud Databases service.
        </Text>
        <Text color={"white"} fontWeight={"400"} fontSize={"sm"} mt="8px">
          You will have a standalone database that is compatible with all of
          your OVHcloud products.
        </Text>
        <Text color={"white"} fontWeight={"400"} fontSize={"sm"} mt="8px">
          MariaDB, MongoDB, MySQL, PostgreSQL and Redis available
        </Text>

        <Button
          variant="brand"
          w="125px"
          h="35px"
          fontSize="xs"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          mt={{ sm: "12px", md: "22px" }}
          onClick={() => setStep(2)}
        >
          ORDER
        </Button>
      </Box>
    ),
    2: <OrderCloudDatabase id={serviceDetail?.nameVps} setStep={setStep} />,
  };
  return <Box>{render[step]}</Box>;
}

export default DatabaseTab;
