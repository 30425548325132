import { Box, Input, Text } from "@chakra-ui/react";
import React from "react";

const InputText = ({ value, name, label, onChangeInput, disable = false }) => {
  return (
    <Box>
      <Text color={"white"}>{label}</Text>
      <Input
        value={value}
        name={name}
        onChange={onChangeInput}
        color={"white"}
        disabled={disable}
      />
    </Box>
  );
};

export default InputText;
