import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import LoadingOVH from "components/Loading/Loading";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { BsBootstrapReboot, BsFillPauseFill } from "react-icons/bs";
import {
  FaPause,
  FaPlay,
  FaPowerOff,
  FaSolarPanel,
  FaTerminal,
} from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import { GrConfigure } from "react-icons/gr";
import { getServiceDetailForPayment } from "services/api";
import GeneralInformation from "./GeneralInformation";
import { convertMBtoGB } from "utils";
import { convertByteToMB } from "utils";
import Interfaces from "./Interfaces";
import Storage from "./Storage";
import Payment from "./Payment";
import { getVMDetail } from "services/api";
import { stopCloud } from "services/api";
import { startCloud } from "services/api";
import { rebootCloud } from "services/api";
import { shutDownCloud } from "services/api";
import ModalRebuildVM from "./ModalRebuildVM";
import { rebuildCloud } from "services/api";
import { useTranslation } from "react-i18next";

const ButtonOption = ({
  tooltip,
  icon,
  onClick,
  bg = "gray.200",
  color = "black",
}) => {
  return (
    <Tooltip label={tooltip} placement="top" bg={"brand.200"}>
      <Button onClick={onClick} bg={bg} p={0} _hover={{ bg: bg }}>
        <Icon color={color} fontSize={"15px"} as={icon} />
      </Button>
    </Tooltip>
  );
};

const listOsNameWithVariable = [
  {
    id: "template:302/base-302-disk-0.raw",
    name: "Windows Server 2019",
    id2: "302",
  },
  {
    id: "template:301/base-301-disk-0.raw",
    name: "Windows Server 2022",
    id2: "301",
  },
  {
    id: "template:256/base-256-disk-0.raw",
    name: "Ubuntu 20",
    id2: "256",
  },
  {
    id: "template:256/base-256-disk-0.raw",
    name: "Ubuntu 22",
    id2: "256",
  },
  {
    id: "template:254/base-254-disk-0.raw",
    name: "Ubuntu 18",
    id2: "254",
  },
  {
    id: "template:306/base-306-disk-0.raw",
    name: "Debian 11",
    id2: "306",
  },
  {
    id: "template:308/base-308-disk-0.raw",
    id2: "308",
    name: "Centos 7",
  },
  {
    id: "template:309/base-309-disk-0.raw",
    name: "Almalinux8",
    id2: "308",
  },
];

function CloudServiceDetail({ id }) {
  const { t } = useTranslation();
  const menu = [
    {
      id: 1,
      name: "Overview",
      value: t("common.serviceDetail.overview"),
    },
    {
      id: 3,
      name: "Interfaces",
      value: t("common.serviceDetail.interface"),
    },
    // {
    //     id: 4,
    //     name: 'Firewall',
    //     value: 'firewall',
    // },
    {
      id: 5,
      name: "Storage",
      value: t("common.serviceDetail.storage"),
    },
    // {
    //     id: 6,
    //     name: 'Snapshots',
    //     value: 'snapshots',
    // },
    // {
    //     id: 7,
    //     name: 'Nhật ký hệ thống',
    //     value: 'daily',
    // },
    // {
    //     id: 9,
    //     name: 'Resources',
    //     value: 'resources',
    // },
    // {
    //     id: 10,
    //     name: 'Network traffic',
    //     value: 'traffic',
    // },
    // {
    //     id: 11,
    //     name: 'ISO List',
    //     value: 'iso',
    // },
    {
      id: 12,
      name: "Payment",
      value: t("common.serviceDetail.payment"),
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [service, setService] = useState({});
  const [vm, setVm] = useState({});
  const [isActive, setIsActive] = useState(false);

  const [labelName, setLabelName] = useState("");
  const [password, setPassword] = useState("");

  const [isStopping, setIsStopping] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isRebooting, setIsRebooting] = useState(false);
  const [isShutdown, setIsShutdown] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [isRebuild, setIsRebuild] = useState(false);

  const [optionId, setOptionId] = useState(menu[0].id);
  const [modalPanelLogin, setModalPanelLogin] = useState({
    visible: false,
    username: "",
    password: "",
  });
  const [modalRebuild, setModalRebuild] = useState({
    visible: false,
    service: null,
  });

  const toast = useToast({
    isClosable: true,
    duration: 5000,
    position: "top-right",
  });

  const getServiceDetail = async (id) => {
    try {
      setIsLoading(true);
      const result = await getServiceDetailForPayment(id);

      const { data } = result?.data;
      setService(data?.service || {});
      setLabelName(data?.service?.domain || "");
      setPassword(data?.service?.password || "");
      setVm(data?.vm || {});
      setIsActive(data?.service?.status === "Active" ? true : false);
    } catch (error) {
      console.log("getServiceDetailForPayment", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getServiceDetail(id);
  }, [id]);

  useEffect(() => {
    if (vm?.status === "rebooting") {
      setIsRebooting(true);
    } else if (vm?.status === "starting") {
      setIsStarting(true);
    } else if (vm?.status === "stopping") {
      setIsStopping(true);
    } else if (vm?.status === "shutdown") {
      setIsShutdown(true);
    } else if (vm?.status === "shutdown") {
      setIsShutdown(true);
    } else if (vm?.status === "rebuild") {
      setIsRebuild(true);
    } else {
      setIsRebooting(false);
      setIsShutdown(false);
      setIsResetting(false);
      setIsStopping(false);
      setIsStarting(false);
      setIsRebuild(false);
    }
  }, [vm]);

  const genStatus = (value) => {
    if (value === "running") return true;
    return false;
  };

  const genOsNameWithVariable = (variable) => {
    return listOsNameWithVariable.find(
      (item) => item?.id === variable || variable?.includes(item.id2)
    )?.name;
  };

  const genUsername = (templateName) => {
    const temp = templateName?.toLowerCase().includes("window");

    return temp ? "administrator" : "root";
  };

  const handleConsole = () => {
    const newwindow = window.open(
      `https://manager.idcviettel.com/clientarea/services/cloud-vn/${service?.id}/&vpsdo=console&vpsid=${vm?.id}&headless=1`,
      "_blank",
      "height=550, width=800"
    );
    window.focus();
    newwindow.focus();
  };

  const handleShowModalPanelLogin = (username, password) => {
    setModalPanelLogin({
      visible: true,
      password,
      username,
    });
  };
  const handleCloseModalPanelLogin = () => {
    setModalPanelLogin({
      visible: false,
      password: "",
      username: "",
    });
  };

  const handleShowModalRebuild = () => {
    setModalRebuild({
      visible: true,
      service: service,
    });
  };

  const handleCloseModalRebuild = () => {
    setModalRebuild({
      visible: false,
      service: null,
    });
  };

  const handleStartVM = async () => {
    try {
      setIsStarting(true);
      toast({
        status: "success",
        title: "VM is starting",
      });
      const result = await startCloud(vm?.id, service?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStopVM = async () => {
    try {
      setIsStopping(true);
      toast({
        status: "success",
        title: "VM is stopping",
      });

      const result = await stopCloud(vm?.id, service?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefreshVM = async () => {
    try {
      setIsRefreshing(true);
      const result = await getVMDetail(vm?.id, service?.id);

      const { data } = result?.data;
      setVm(data || vm);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
      // setIsStarting(false)
      // setIsStopping(false)
      // setIsRebooting(false)
      // setIsResetting(false)
      // setIsShutdown(false)
    }
  };

  const handleRebootVM = async () => {
    try {
      setIsRebooting(true);
      toast({
        status: "success",
        title: "VM is rebooting",
      });

      const result = await rebootCloud(vm?.id, service?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShutdownVM = async () => {
    // if (Number(auth.user?.client_id) == 22) {
    //     return
    // }
    try {
      setIsShutdown(true);
      toast({
        status: "success",
        title: "The VM is being shutdown",
      });

      const result = await shutDownCloud(vm?.id, service?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRebuildVM = async (templateId) => {
    try {
      setIsRebuild(true);
      toast({
        status: "success",
        title: "The VM is having its operating system reinstalled",
      });

      const result = await rebuildCloud(vm?.id, service?.id, templateId);
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseModalRebuild();
    }
  };

  return (
    <Card mt="4">
      {isLoading ? (
        <LoadingOVH />
      ) : (
        <Box>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Box>
              <Text color={"white"} fontWeight={"600"} fontSize={"26px"}>
                {labelName || "Linda"}
              </Text>
              <Text color={"gray.200"} mt="4">
                Created at{" "}
                {service?.date_created ||
                  moment(service?.bill?.createdAt).format("DD-MM-YYYY")}
              </Text>
            </Box>

            {/* {isActive && ( */}
            <Flex alignItems={"center"} gap={2}>
              {/* Text */}
              {isStopping ||
              isStarting ||
              isRebooting ||
              isShutdown ||
              isRebuild ? (
                <Text color={"red.400"} fontWeight={"500"} fontSize={"1.1rem"}>
                  {isStopping
                    ? "Shutting down"
                    : isStarting
                    ? "Is Starting"
                    : isRebooting
                    ? "Is Rebooting"
                    : isShutdown
                    ? "Is Shutdown"
                    : isRebuild
                    ? "Is Rebuild"
                    : ""}
                  ...
                </Text>
              ) : (
                <>
                  {genStatus(vm?.status) ? (
                    <Flex alignItems={"center"} gap={2}>
                      <Icon as={FaPlay} color={"green.200"} fontSize={"1rem"} />
                      <Text
                        color={"green.200"}
                        fontWeight={"500"}
                        fontSize={"1.1rem"}
                      >
                        Running
                      </Text>
                    </Flex>
                  ) : (
                    <Flex alignItems={"center"} gap={2}>
                      <Icon as={FaPause} fontSize={"1rem"} color={"red.400"} />
                      <Text
                        color={"red.400"}
                        fontWeight={"500"}
                        fontSize={"1.1rem"}
                      >
                        Pause
                      </Text>
                    </Flex>
                  )}
                </>
              )}

              {/* button option */}
              {isStopping ||
              isStarting ||
              isRebooting ||
              isShutdown ||
              isRebuild ? (
                <>
                  <ButtonOption
                    tooltip={"Refresh"}
                    icon={FiRefreshCcw}
                    bg="green.400"
                    color="white"
                    onClick={handleRefreshVM}
                  />
                  {isShutdown && (
                    <ButtonOption
                      tooltip={"Start"}
                      icon={FaPause}
                      bg="red.400"
                      color="white"
                      onClick={handleStartVM}
                    />
                  )}
                </>
              ) : (
                <>
                  {genStatus(vm?.status) ? (
                    <ButtonOption
                      tooltip={"Stop"}
                      icon={FaPlay}
                      bg="green.400"
                      color="white"
                      onClick={handleStopVM}
                    />
                  ) : (
                    <ButtonOption
                      tooltip={"Start"}
                      icon={FaPause}
                      bg="red.400"
                      color="white"
                      onClick={handleStartVM}
                    />
                  )}

                  {/* <ButtonOption
                    tooltip={"Console"}
                    icon={FaTerminal}
                    onClick={handleConsole}
                    bg="green.400"
                    color="white"
                  /> */}

                  <ButtonOption
                    tooltip={"Panel Login"}
                    icon={FaSolarPanel}
                    bg="blue.400"
                    color="white"
                    onClick={() => {
                      handleShowModalPanelLogin(
                        service?.username,
                        service?.rootpassword
                      );
                    }}
                  />

                  <ButtonOption
                    tooltip={"Reboot"}
                    icon={BsBootstrapReboot}
                    bg="yellow.400"
                    color="white"
                    onClick={handleRebootVM}
                  />

                  <ButtonOption
                    tooltip={"Reinstall"}
                    icon={AiOutlineSetting}
                    bg="brand.200"
                    color="white"
                    onClick={handleShowModalRebuild}
                  />

                  <ButtonOption
                    tooltip={"Shutdown"}
                    icon={FaPowerOff}
                    bg="red.400"
                    color="white"
                    onClick={handleShutdownVM}
                  />
                </>
              )}
            </Flex>
            {/* )} */}
          </Flex>

          {/* menu */}
          <Flex
            mt="8"
            alignItems={"center"}
            gap={4}
            borderTop={"1px solid"}
            borderBottom={"1px solid"}
            borderColor={"gray"}
          >
            {menu?.map((item) => (
              <Box
                py="3"
                key={item?.id}
                cursor={"pointer"}
                fontSize={"16px"}
                borderBottom={`3px solid`}
                borderColor={
                  optionId === item?.id ? "brand.200" : "transparent"
                }
                color={"white"}
                transition={"all .2s"}
                onClick={() => setOptionId(item?.id)}
              >
                {item?.name}
              </Box>
            ))}
          </Flex>

          {/* content tab */}
          {optionId === 1 ? (
            <GeneralInformation
              id={id}
              optionId={optionId}
              username={
                isActive ? genUsername(vm?.template_name) : service?.username
              }
              password={password}
              setPassword={setPassword}
              ip={service?.ip || "Not yet"}
              os={vm?.template_name || genOsNameWithVariable(service?.os)}
              cpu={isActive ? vm?.cpus : service?.options?.cpulimit}
              disk={isActive ? vm?.disk : service?.disk_limit}
              ram={convertMBtoGB(
                isActive ? vm?.memory : service?.guaranteed_ram
              )}
              label={labelName}
              setLabel={setLabelName}
              vmid={isActive ? vm.id : service?.veid}
              bandwidth={{
                in: convertByteToMB(vm?.bandwidth?.data_received || 0),
                out: convertByteToMB(vm?.bandwidth?.data_sent || 0),
              }}
            />
          ) : optionId === 3 && isActive ? (
            <Interfaces data={Object.values(vm?.interfaces || {})} />
          ) : optionId === 5 && isActive ? (
            <Storage data={Object.values(vm?.storage)} />
          ) : optionId === 12 ? (
            <Payment
              billingcycle={service?.billingcycle}
              total={service?.total}
              date_created={service?.date_created}
              expires={service?.expires}
              next_invoice={service?.next_invoice}
              status={service?.status}
            />
          ) : null}

          {/* model controlpanel */}
          <Modal
            isOpen={modalPanelLogin.visible}
            onClose={handleCloseModalPanelLogin}
          >
            <ModalOverlay />
            <ModalContent bgColor={"#5f68c9"}>
              <ModalHeader color={"white"}>Control panel</ModalHeader>
              <ModalCloseButton color={"white"} />

              <ModalBody>
                <Box>
                  <Text color={"white"} fontWeight={"500"} fontSize={"15px"}>
                    Username
                  </Text>
                  <Input
                    color="gray.400"
                    bg="#0F1535"
                    border="0.5px solid"
                    borderColor="#E2E8F04D"
                    borderRadius="15px"
                    w={"100%"}
                    mt="2"
                    _focus={{
                      borderColor: "white",
                    }}
                    _active={{
                      borderColor: "white",
                    }}
                    readOnly
                    value={modalPanelLogin?.username}
                  />

                  <Text
                    color={"white"}
                    fontWeight={"500"}
                    fontSize={"15px"}
                    mt="4"
                  >
                    Password
                  </Text>
                  <Input
                    color="gray.400"
                    bg="#0F1535"
                    border="0.5px solid"
                    borderColor="#E2E8F04D"
                    borderRadius="15px"
                    w={"100%"}
                    mt="2"
                    _focus={{
                      borderColor: "white",
                    }}
                    _active={{
                      borderColor: "white",
                    }}
                    readOnly
                    value={modalPanelLogin?.password}
                  />
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    window.open(
                      `https://116.103.108.245:8006/?#v1:0:18:4:::::::`,
                      "_blank"
                    );
                  }}
                  colorScheme="purple"
                  mr={3}
                  size="sm"
                >
                  To Control Panel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* model rebuild */}
          <ModalRebuildVM
            service={modalRebuild.service}
            visible={modalRebuild.visible}
            onClose={handleCloseModalRebuild}
            handleRebuild={handleRebuildVM}
            vm={vm}
          />
        </Box>
      )}
    </Card>
  );
}

export default CloudServiceDetail;
