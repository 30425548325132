import { Box, Flex, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import React from "react";
import { convertMoney } from "utils";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";

function PaymentForCloudService() {
  const state = useLocation().state;

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      <Text color={"white"} fontSize="1.5rem" mb="1rem" fontWeight={"600"}>
        PAYMENT FOR SERVICES
      </Text>
      <Card py="8">
        <Flex justifyContent={"space-around"}>
          <Box>
            <img
              src={`https://manager.idcviettel.com/vietqr.php?account=6863868686&bankcode=970416&amount=${
                state?.amount
              }&noidung=${state?.noidung || ""}`}
              style={{
                height: "200px",
                maxWidth: "200px",
                border: "1px solid #ccc",
              }}
            />
          </Box>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Text color={"white"} fontWeight={"400"} fontSize={"16px"}>
              Bank:{" "}
              <Text fontWeight={"600"} as="span">
                ACB
              </Text>
            </Text>
            <Text color={"white"} fontWeight={"400"} fontSize={"16px"}>
              Account number:
              <Text fontWeight={"600"} as="span">
                {" "}
                {"6863868686"}
              </Text>
            </Text>
            <Text color={"white"} fontWeight={"400"} fontSize={"16px"}>
              Account owner:
              <Text fontWeight={"600"} as="span">
                {" "}
                CTY TNHH CONG NGHE VNG CLOUD
              </Text>
            </Text>
            <Text color={"white"} fontWeight={"400"} fontSize={"16px"}>
              Content billing:{" "}
              <Text fontWeight={"600"} as="span">
                {state?.noidung}
              </Text>
            </Text>
            <Text color={"white"} fontWeight={"400"} fontSize={"16px"}>
              Amount:
              <Text fontWeight={"600"} as="span">
                {state?.amount}
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Text
          color={"white"}
          fontSize={"16px"}
          fontWeight={"500"}
          mt="8"
          textAlign={"center"}
        >
          Please fill in the correct invoice number and amount or scan the QR
          code Payment is confirmed automatically.
        </Text>
      </Card>
    </Box>
  );
}

export default PaymentForCloudService;
