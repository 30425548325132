import { CheckIcon, SpinnerIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  GridItem,
  Icon,
  Spacer,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { convertToRaw } from "draft-js";
import React from "react";
import { useMemo } from "react";
import { BiChip } from "react-icons/bi";
import { BsGrid3X2, BsHdd } from "react-icons/bs";
import { GiGolfFlag, GiHandWing } from "react-icons/gi";
import { convertDescriptionProductToObject } from "utils";
import { getLanguage } from "utils";
import { convertMoney } from "utils";
import { convertToUsd } from "utils/convertToUsd";

const icons = [BiChip, BsGrid3X2, BsHdd, GiHandWing, GiGolfFlag];
const discount = [
  {
    name: "Cloud VN #1",
    m: {
      price: 129000,
      discount: 0,
    },
    q: {
      price: 387000,
      discount: 15,
    },
    s: {
      price: 774000,
      discount: 20,
    },
    a: {
      price: 1548000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #2",
    m: {
      price: 199000,
      discount: 0,
    },
    q: {
      price: 597000,
      discount: 15,
    },
    s: {
      price: 1194000,
      discount: 20,
    },
    a: {
      price: 2388000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #3",
    m: {
      price: 299000,
      discount: 0,
    },
    q: {
      price: 897000,
      discount: 15,
    },
    s: {
      price: 1794000,
      discount: 20,
    },
    a: {
      price: 3588000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #4",
    m: {
      price: 590000,
      discount: 0,
    },
    q: {
      price: 1770000,
      discount: 15,
    },
    s: {
      price: 3540000,
      discount: 20,
    },
    a: {
      price: 7080000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #5",
    m: {
      price: 1299000,
      discount: 0,
    },
    q: {
      price: 3897000,
      discount: 15,
    },
    s: {
      price: 7794000,
      discount: 20,
    },
    a: {
      price: 15588000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #6",
    m: {
      price: 1599000,
      discount: 0,
    },
    q: {
      price: 4797000,
      discount: 15,
    },
    s: {
      price: 9594000,
      discount: 20,
    },
    a: {
      price: 19188000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #7",
    m: {
      price: 1999000,
      discount: 0,
    },
    q: {
      price: 5997000,
      discount: 15,
    },
    s: {
      price: 11994000,
      discount: 20,
    },
    a: {
      price: 23988000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #8",
    m: {
      price: 2299000,
      discount: 0,
    },
    q: {
      price: 6897000,
      discount: 15,
    },
    s: {
      price: 13794000,
      discount: 20,
    },
    a: {
      price: 27588000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #9",
    m: {
      price: 3800000,
      discount: 0,
    },
    q: {
      price: 11400000,
      discount: 15,
    },
    s: {
      price: 22800000,
      discount: 20,
    },
    a: {
      price: 45600000,
      discount: 25,
    },
  },
  {
    name: "Cloud VN #10",
    m: {
      price: 5300000,
      discount: 0,
    },
    q: {
      price: 15900000,
      discount: 15,
    },
    s: {
      price: 31800000,
      discount: 20,
    },
    a: {
      price: 63600000,
      discount: 25,
    },
  },
];

function CloudServerProduct({
  data,
  unit,
  isChosen,
  onClick,
  isGettingProductForConfig,
}) {
  const getPriceByUnit = (unit) => {
    const priceUsd = convertToUsd(Number(data[unit]), getLanguage());
    return priceUsd || 0;
  };

  const getBeforeDiscountPrice = () => {
    const dis = discount.find((x) => x.name == data?.name);
    if (unit == "m") {
      return dis?.m;
    } else if (unit == "a") {
      return dis?.a;
    } else if (unit == "q") {
      return dis?.q;
    } else {
      return dis?.s;
    }
  };

  const discountPrice = useMemo(() => getBeforeDiscountPrice(), [unit]);

  return (
    <GridItem gridAutoColumns={"1fr"}>
      <Card
        cursor="pointer"
        onClick={() => !isGettingProductForConfig && onClick(data)}
        p={0}
        borderRadius={"12px"}
      >
        <CardHeader
          position="relative"
          textAlign="center"
          alignItems="center"
          py={"2"}
          px="4"
          bg="brand.200"
          borderRadius="12px 12px 0px 0px"
        >
          {discountPrice?.discount ? (
            <Text
              bg="red"
              fontSize={"14px"}
              lineHeight={"1"}
              px="2"
              py="1"
              borderRadius={"4px"}
              color={"white"}
              fontWeight={"600"}
            >
              {discountPrice?.discount}%
            </Text>
          ) : null}
          <Text
            textAlign={"center"}
            w="100%"
            color={"white"}
            fontWeight={"500"}
            fontSize={"18px"}
          >
            {data?.name}
          </Text>

          {isGettingProductForConfig ? (
            <Spinner color="white" />
          ) : isChosen ? (
            <Icon color={"white"} fontSize={"20px"} as={CheckIcon} />
          ) : (
            <Text></Text>
          )}
        </CardHeader>
        <Box bg={"#eee"} py="4" textAlign={"center"}>
          {discountPrice?.discount ? (
            <Text
              mb="2"
              textDecoration={"line-through"}
              fontWeight={"500"}
              fontSize={"16px"}
            >
              {convertToUsd(discountPrice?.price, getLanguage())}
            </Text>
          ) : null}
          <Text color={"red.500"} fontSize={"22px"} fontWeight={"600"}>
            {convertMoney(getPriceByUnit(unit))}
          </Text>
        </Box>
        <Box py={4} px="8" bg={"#f8fdff"} borderRadius={"0px 0px 12px 12px"}>
          {Object.entries(
            convertDescriptionProductToObject(data?.description)
          )?.map((value, index) => (
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              _notFirst={{
                mt: "2",
              }}
              pb="1"
              borderBottom={"1px solid #ddd"}
              key={index}
            >
              <Flex alignItems={"center"} gap={1}>
                <Icon as={icons[index]} fontSize={"16px"} />
                <Text fontSize={"13px"} fontWeight={"500"}>
                  {value[0]}
                </Text>
              </Flex>
              <Text color={"brand.200"} fontWeight={"600"} fontSize={"15px"}>
                {value[1]}
              </Text>
            </Flex>
          ))}
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            _notFirst={{
              mt: "2",
            }}
            pb="1"
            borderBottom={"1px solid #ddd"}
          >
            <Flex alignItems={"center"} gap={1}>
              <Icon as={GiGolfFlag} fontSize={"16px"} />
              <Text fontSize={"13px"} fontWeight={"500"}>
                IPV4
              </Text>
            </Flex>
            <Text color={"brand.200"} fontWeight={"600"} fontSize={"15px"}>
              Private
            </Text>
          </Flex>
        </Box>
      </Card>
    </GridItem>
  );
}

export default CloudServerProduct;
