import { Box } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TablePaymentHistory from "components/Tables/TablePaymentHistory";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllInvoicesCloudServiceByClientId } from "services/api";

function PaymentHistory() {
  const { t } = useTranslation();

  const columns = [
    {
      Header: "STT",
      label: "No",
    },

    {
      Header: "TOTAL",
      label: t("common.managerPaymentHistory.total"),
    },
    {
      Header: "DATE OF INVOICE",
      label: t("common.managerPaymentHistory.dateOfInvoice"),
    },
    {
      Header: "STATUS",
      label: "Status",
    },
  ];
  const [allCreditLogs, setAllCreditLogs] = useState([]);
  const [creditLogs, setCreditLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalDoc, setTotalDoc] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);

  const getCreditLogs = async () => {
    try {
      const result = await getAllInvoicesCloudServiceByClientId();

      const { data } = result.data;
      setAllCreditLogs(
        data.filter((item) => {
          return item && item?.status == "Paid";
        }) || []
      );
      setTotalDoc(
        data.filter((item) => {
          return item && item?.status == "Paid";
        }).length || 0
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCreditLogs();
  }, []);

  const handleChangePageSize = (value) => {
    setPageSize(value);
  };

  const handleChangePageIndex = (value) => {
    setPageIndex(value);
  };
  useEffect(() => {
    const temp = allCreditLogs?.slice(
      (pageIndex - 1) * pageSize,
      pageIndex * pageSize
    );

    setCreditLogs(temp || []);
  }, [allCreditLogs, pageIndex, pageSize]);

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      <Card px="0px">
        <CardBody>
          <TablePaymentHistory
            tableData={creditLogs}
            totalDoc={totalDoc || 0}
            pageIndex={pageIndex}
            pageSize={pageSize}
            onChangeSize={handleChangePageSize}
            onChangePage={handleChangePageIndex}
            isLoading={isLoading}
            columns={columns}
          />
        </CardBody>
      </Card>
      {/* )} */}
    </Box>
  );
}

export default PaymentHistory;
