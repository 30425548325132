import {
  Box,
  Button,
  Flex,
  Image,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useReactToPrint } from "react-to-print";

import { useParams, useHistory } from "react-router-dom";
import bgInvoice from "assets/img/background.png";
import moment from "moment";
import { formatMoneyVND } from "utils";
import LoadingOVH from "components/Loading/Loading";
import logo from "../../assets/img/logo.png";
import { getProductsBySubOrderPage } from "services/api";

import { convertToUsd } from "utils/convertToUsd";

import { getServiceDetailForPayment } from "services/api";
import { getLanguage } from "utils";
const ORDER_PAGE_ID = {
  CLOUDVN: "1",
  CUSTOM: "4",
};

function CloudServiceInvoiceDetail() {
  const componentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const [products, setProducts] = useState([]);

  const [groupItems, setGroupItems] = useState([]);

  const { id } = useParams();

  const ids = id.split("-");

  const [invoice, setInvoice] = useState([]);

  const history = useHistory();

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });

  const getServiceDetail = async (ids) => {
    try {
      setIsLoading(true);
      const results = await Promise.all(
        ids?.map(async (item) => {
          try {
            const result = await getServiceDetailForPayment(item);

            return result?.data?.data;
          } catch (error) {
            console.error(`Error fetching data for ID ${id}: ${error.message}`);
            return null; // Return a placeholder value or an empty value when there's an error
          }
        })
      );

      const successfulResults =
        results?.length > 0 && results?.filter((result) => result !== null);

      setProducts(successfulResults);

      const groupedData = {};

      // Group the data
      successfulResults.forEach((entry) => {
        const key = `${entry?.vm?.cpus}-${Number(entry?.vm?.memory / 1024)}-${
          entry?.vm?.disk
        }`;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(entry);
      });

      setGroupItems(groupedData);
    } catch (error) {
      console.log("getServiceDetailForPayment", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ids?.length > 0) getServiceDetail(ids);
  }, [ids?.length]);

  useEffect(() => {
    const getProductByOs = async (id) => {
      try {
        const result = await getProductsBySubOrderPage(id);

        const { data } = result?.data;
      } catch (error) {
        console.log("getProductsBySubOrderPage", error);
      }
    };

    getProductByOs(ORDER_PAGE_ID.CLOUDVN);
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = () => {
    toast({
      status: "success",
      title: "In hóa đơn thành công",
    });
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: (invoice?.id == 396
      ? "GOF413"
      : invoice?.id == 398
      ? "GOF415"
      : invoice?.number
    )?.replace("GOF", "VNG"),
    handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <Box direction="column" pt={{ sm: "125px", md: "75px" }}>
      {isLoading ? (
        <LoadingOVH />
      ) : (
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          bg="white"
          py="20px"
          height={"auto"}
        >
          <Box
            ref={componentRef}
            border={"1px solid"}
            borderColor={"gray.200"}
            width={"800px"}
            minWidth={"800px"}
            minHeight={"900px"}
            px="60px"
            py="70px"
            bgImage={bgInvoice}
            bgSize={"100% 100%"}
            bgRepeat={"no-repeat"}
            bgBlendMode={""}
          >
            <Image
              boxSize="30px 30px"
              objectFit="cover"
              src={logo}
              alt="Dan Abramov"
            />
            {/* <Text
              color={"white"}
              fontWeight={"500"}
              fontSize={"40px"}
              lineHeight={"1"}
            >
              INVOICE
            </Text>
            <Text
              color={"green.200"}
              fontWeight={"600"}
              fontSize={"50px"}
              lineHeight={"1"}
            >
              VNGCLOUD
            </Text> */}

            <Flex justifyContent={"space-between"}>
              <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
                <Box>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    BILL
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    DATE OF INVOICE
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    PAYMENT NEEDED DATE
                  </Text>
                </Box>

                <Box textAlign={"right"}>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    #{invoice[0]?.number?.replace("GOF", "VNG")}
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    {
                      groupItems[Object.keys(groupItems)?.[0]]?.[0]?.service
                        ?.date_created
                    }
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    {
                      groupItems[Object.keys(groupItems)?.[0]]?.[0]?.service
                        ?.date_created
                    }
                  </Text>
                </Box>
              </Flex>

              <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
                <Box>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    PAYMENT METHOD
                  </Text>

                  <Tag
                    color={"white"}
                    bg={"green.500"}
                    mt="1"
                    size="md"
                    pb={2}
                    pt={2.5}
                    lineHeight={"1"}
                  >
                    PAID
                  </Tag>
                </Box>

                <Box textAlign={"right"}>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    TRANSFER
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  ></Text>
                </Box>
              </Flex>
            </Flex>

            {/* table item */}

            <>
              <Flex mt="16" py={4} px={6} bg={"white"}>
                <Text fontWeight={"600"} fontSize={"16px"}>
                  INVOICE CONTENT
                </Text>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                bg="rgba(135, 245, 219, 0.4)"
                py={4}
                px={6}
              >
                <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                  INVOICE DETAIL
                </Text>
                <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                  #{invoice[0]?.number?.replace("GOF", "VNG")}
                </Text>
              </Flex>
              {products?.length > 0 &&
                products?.map((item, index) => (
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    bg="rgba(255, 255, 255, 0.4)"
                    py={3}
                    px={6}
                  >
                    <Box
                      display={"flex"}
                      color={"black"}
                      fontSize={"13px"}
                      fontWeight={"600"}
                    >
                      {`IP ${
                        item?.vm?.cpus
                          ? `(CPU: ${item?.vm?.cpus || ""}Core - RAM: ${
                              Number(item?.vm?.memory / 1024) || ""
                            }GB - SSD: ${item?.vm?.disk || ""}GB)`
                          : ""
                      }`}
                    </Box>
                    <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                      {item?.vm?.ipv4 || ""}
                    </Text>
                  </Flex>
                ))}
              {Object.keys(groupItems).map((key, index) => (
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  bg="rgba(135, 245, 219, 0.4)"
                  py={3}
                  px={6}
                >
                  <Text te color={"black"} fontSize={"13px"} fontWeight={"600"}>
                    CLOUD VPS {""} {groupItems[key]?.[0]?.vm?.cpus || ""}-
                    {Number(groupItems[key]?.[0]?.vm?.memory / 1024)}
                  </Text>
                  <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                    {convertToUsd(
                      groupItems[key]?.[0]?.service?.total * 1,
                      getLanguage()
                    )}{" "}
                    x {groupItems[key]?.length} ={" "}
                    {convertToUsd(
                      groupItems[key]?.[0]?.service?.total *
                        groupItems[key]?.length,
                      getLanguage()
                    )}
                  </Text>
                </Flex>
              ))}
            </>

            <Box bg="rgba(255, 255, 255, 0.4)" py="8" px={6}>
              <Flex
                alignItems={"center"}
                justifyContent={"flex-end"}
                textColor={"black"}
              >
                <Text fontSize={"13px"} fontWeight={"600"}>
                  QUANTITY
                </Text>

                <Text
                  width={"150px"}
                  textAlign={"right"}
                  fontSize={"13px"}
                  fontWeight={"600"}
                >
                  {Object.keys(groupItems).reduce((total, key) => {
                    return total + groupItems[key]?.length;
                  }, 0)}
                </Text>
              </Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"flex-end"}
                textColor={"black"}
                mt={1}
              >
                <Text fontSize={"13px"} fontWeight={"600"}>
                  INTO MONEY
                </Text>

                <Text
                  width={"150px"}
                  textAlign={"right"}
                  fontSize={"13px"}
                  fontWeight={"600"}
                >
                  {convertToUsd(
                    Object.keys(groupItems).reduce((total, key) => {
                      return (
                        total +
                        Number(groupItems[key]?.[0]?.service?.total) *
                          groupItems[key]?.length
                      );
                    }, 0),

                    getLanguage()
                  )}
                </Text>
              </Flex>

              <Flex
                alignItems={"center"}
                justifyContent={"flex-end"}
                mt="1"
                textColor={"black"}
              >
                <Text fontSize={"13px"} fontWeight={"600"}>
                  TAX
                </Text>

                <Text
                  width={"150px"}
                  textAlign={"right"}
                  fontSize={"13px"}
                  fontWeight={"600"}
                >
                  {convertToUsd(
                    Object.keys(groupItems).reduce((total, key) => {
                      return (
                        total +
                        Number(groupItems[key]?.[0]?.service?.total) *
                          groupItems[key]?.length
                      );
                    }, 0) * 0.1,

                    getLanguage()
                  )}
                </Text>
              </Flex>

              <Flex
                alignItems={"center"}
                justifyContent={"flex-end"}
                mt="1"
                textColor={"black"}
              >
                <Text fontSize={"13px"} fontWeight={"600"}>
                  DISCOUNT
                </Text>

                <Text
                  width={"150px"}
                  textAlign={"right"}
                  fontSize={"13px"}
                  fontWeight={"600"}
                >
                  {getLanguage() == "vi" ? formatMoneyVND(0) : "$0"}
                </Text>
              </Flex>
            </Box>

            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              bg="linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6)"
              py={4}
              px={6}
              textColor={"black"}
            >
              <Text fontSize={"15px"} fontWeight={"600"}>
                TOTAL
              </Text>

              <Text
                width={"150px"}
                textAlign={"right"}
                fontSize={"15px"}
                fontWeight={"600"}
              >
                {convertToUsd(
                  Object.keys(groupItems).reduce((total, key) => {
                    return (
                      total +
                      Number(groupItems[key]?.[0]?.service?.total) *
                        groupItems[key]?.length
                    );
                  }, 0) * 1.1,

                  getLanguage()
                )}
              </Text>
            </Flex>

            <Box mt="8" textAlign={"right"}>
              <Text fontWeight={"600"} fontSize={"18px"}>
                VNG CLOUD TECHNOLOGY COMPANY LIMITED
              </Text>
              <Text fontWeight={"500"} fontSize={"13px"}>
                698/1/12 Truong Chinh Street, Ward 15, Tan Binh District, Ho Chi
                Minh City, Vietnam.
              </Text>
            </Box>
          </Box>

          <Flex
            mt="1rem"
            width={"800px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button
              w="125px"
              h="35px"
              bg={"red.400"}
              color={"white"}
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={() => {
                history.push("/cloud-services/list");
              }}
            >
              Back
            </Button>

            <Button
              variant="brand"
              w="125px"
              h="35px"
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={handlePrint}
            >
              PRINT INVOICE
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

export default CloudServiceInvoiceDetail;
