import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect, useMemo } from "react";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import fedora from "assets/img/ovh/fedora.svg";

import almalinux from "assets/img/ovh/almalinux.svg";
import cpanel from "assets/img/ovh/cpanel.svg";
import docker from "assets/img/ovh/docker.svg";
import rocky_linux from "assets/img/ovh/rocky_linux.svg";
import plesk_debian from "assets/img/ovh/plesk_debian.svg";
import { DISTRIBUTION_OVH } from "utils";
import { genPriceOvh } from "utils";

function FamilyOsItem({
  item,
  addons = [],
  chosenProduct,
  chosenFamily,
  onChooseFamily,
  os,
  setOs,
  distribution,
  license,
  setLicense,
}) {
  const [options, setOptions] = useState([]);
  const [optionLicense, setOptionLicense] = useState([]);
  const [chosenLicense, setChosenLicense] = useState("");

  useEffect(() => {
    setOptions(
      item?.items?.map((item) => {
        return {
          value: item?.value,
          label: item?.blobs?.technical?.os?.version,
        };
      })
    );

    if (distribution === DISTRIBUTION_OVH.WITH_APPLICATION) {
      // word for search license
      const key = "option-" + item?.family?.split(" ")?.[0]?.toLowerCase();
      // get license -> plesk and cPanel
      const listAddons = addons?.filter((e) => {
        return e?.planCode?.includes(key);
      });

      setOptionLicense(
        listAddons?.map((item) => {
          return {
            value: item?.planCode,
            label: item?.blobs?.technical?.license?.edition,
          };
        }) || []
      );
      setChosenLicense(listAddons?.[0]?.planCode);
    }
  }, []);

  useEffect(() => {
    if (chosenFamily && chosenFamily?.family === item?.family) {
      setOs(chosenFamily?.items?.[0]?.value);
      setLicense(optionLicense?.[0]?.value || "");
      setChosenLicense(optionLicense?.[0]?.value || []);
    }
  }, [chosenFamily, optionLicense]);

  const render = {
    ubuntu: ubuntu,
    debian: debian,
    fedora: fedora,
    centos: centos,
    almalinux: almalinux,
    rocky_linux: rocky_linux,
    windows_server: window,
    plesk_debian: plesk_debian,
    cpanel: cpanel,
    docker: docker,
  };

  // gen price following family
  const genPrice = useMemo(() => {
    const addon = addons?.find(
      (e) =>
        e?.planCode?.includes(
          chosenProduct?.name?.substring(chosenProduct?.name?.indexOf("-") + 1)
        ) && e?.planCode?.includes(item?.family?.split(" ")?.[0]?.toLowerCase())
    );
    const price = addon?.pricings?.find(
      (item) =>
        item?.commitment === 0 && item?.interval === 1 && item?.phase === 1
    );

    return price?.price || 0;
  }, [addons, chosenProduct]);

  // gen price license
  const genPriceLicense = useMemo(() => {
    let code = optionLicense?.[0]?.value;
    if (chosenLicense) {
      code = chosenLicense;
    }

    const addon = addons?.find((e) => e?.planCode === code);

    const price = addon?.pricings?.find(
      (item) =>
        item?.commitment === 0 && item?.interval === 1 && item?.phase === 1
    );

    return price?.price || 0;
  }, [addons, chosenLicense, optionLicense]);

  return (
    <GridItem>
      <Flex
        direction={"column"}
        justifyContent={"space-between"}
        h="100%"
        bg="rgb(31, 35, 89)"
        borderColor={
          chosenFamily?.family === item?.family ? "brand.100" : "transparent"
        }
        borderWidth={"4px"}
        borderRadius="20px"
        cursor={"pointer"}
        w="100%"
        key={item.title}
        _hover={{
          borderColor: "brand.100",
        }}
        onClick={() => {
          onChooseFamily(item);
        }}
      >
        <Box p={"32px"}>
          <Flex alignItems={"center"} justifyContent={"flex-start"}>
            <Image
              w={"32px"}
              h={"32px"}
              mr={"1rem"}
              src={render?.[item?.family?.replace(" ", "_")?.toLowerCase()]}
            />

            <Text color={"white"} fontSize={"lg"} fontWeight={"500"}>
              {item?.family}
            </Text>
          </Flex>

          {chosenFamily?.family === item?.family && (
            <>
              {!optionLicense?.length ? (
                <Select
                  mt="2rem"
                  border=".5px solid"
                  borderColor="rgba(226, 232, 240, 0.3)"
                  _hover="none"
                  bg="#0F1535"
                  borderRadius="15px"
                  color="gray.400"
                  fontSize="md"
                  value={os}
                  onChange={(e) => setOs(e.target.value)}
                >
                  {options?.map((item) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Select>
              ) : (
                <Select
                  mt="2rem"
                  border=".5px solid"
                  borderColor="rgba(226, 232, 240, 0.3)"
                  _hover="none"
                  bg="#0F1535"
                  borderRadius="15px"
                  color="gray.400"
                  fontSize="md"
                  value={chosenLicense}
                  onChange={(e) => {
                    setLicense(e.target.value);
                    setChosenLicense(e.target.value);
                  }}
                  options={optionLicense}
                >
                  {optionLicense?.map((item) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Select>
              )}

              {item?.distribution && (
                <Text color={"white"} mt="1rem" fontWeight={"600"}>
                  Pre-installed on {item?.distribution}
                </Text>
              )}
            </>
          )}
        </Box>

        <Box bgColor={"brand.300"} borderRadius={"0px 0px 18px 18px"} py="12px">
          {optionLicense?.length ? (
            <Text
              textAlign={"center"}
              color={"white"}
              fontWeight={"600"}
              fontSize={"lg"}
            >
              {genPriceLicense
                ? `US$ ${genPriceOvh(genPriceLicense, true)}`
                : "Free"}
            </Text>
          ) : (
            <Text
              textAlign={"center"}
              color={"white"}
              fontWeight={"600"}
              fontSize={"lg"}
            >
              {genPrice ? `US$ ${genPriceOvh(genPrice, true)}` : "Free"}
            </Text>
          )}
        </Box>
      </Flex>
    </GridItem>
  );
}

export default FamilyOsItem;
