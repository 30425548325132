import { EditIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateAllService } from "redux/slices/serviceSlice";
import { updateHostnameService } from "services/api";
import { updatePasswordService } from "services/api";
import { RESPONSE_STATUS } from "utils";

const InfoItem = ({ title, value, extra }) => {
  return (
    <Flex
      _notLast={{
        mb: "2rem",
      }}
      alignItems={"center"}
    >
      <Box>
        <Text w={"150px"} color={"gray.200"} fontSize={"16px"}>
          {title}:
        </Text>

        <Text w={"150px"} color={"gray.200"} fontSize={"16px"}>
          {extra}
        </Text>
      </Box>
      <Text flex={1} color={"white"} fontSize={"16px"} fontWeight={"600"}>
        {value}
      </Text>
    </Flex>
  );
};

function GeneralInformation({
  ip,
  username,
  password,
  cpu,
  disk,
  os,
  ram,
  label,
  vmid,
  bandwidth,
  optionId,
  id,
  setLabel,
  setPassword,
}) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [labelNameToChange, setLabelNameToChange] = useState("");
  const [passwordToChange, setPasswordNameToChange] = useState("");
  // label
  const [inputValue, setInputValue] = useState("");

  const [multiSelect, setMultiSelect] = useState([]);
  console.log("multiSelect", multiSelect);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEditItem = () => {
    console.log("label", label);
    setMultiSelect((prev) => [...prev, ...label?.split(",")]);
    setIsModalOpen(true);
  };
  const handleAddSelect = () => {
    setMultiSelect((prev) => [...prev, inputValue]);
    setInputValue("");
  };
  const handleRemoveSelect = (index) => {
    setMultiSelect((prev) => prev.filter((item, i) => i !== index));
  };

  // password
  const [isModalOpenPassword, setIsModalOpenPassword] = useState(false);

  const toast = useToast({
    isClosable: true,
    duration: 5000,
    position: "top-right",
  });
  //

  //
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalPassword = () => {
    setIsModalOpenPassword(true);
  };

  useEffect(() => {
    setLabelNameToChange(label);
  }, [label]);

  useEffect(() => {
    setPasswordNameToChange(password);
  }, [password]);

  const handleOk = async () => {
    try {
      const data = {
        id,
        hostname: multiSelect.join(","),
      };

      const result = await updateHostnameService(data);

      const { status } = result?.data;

      if (status === RESPONSE_STATUS.SUCCESS) {
        toast({
          title: "Cập nhật tên nhãn thành công",
          status: "success",
        });

        setLabel(multiSelect.join(", "));
        dispatch(
          updateAllService({
            id,
            data: {
              domain: multiSelect.join(","),
            },
          })
        );

        setIsModalOpen(false);
        setMultiSelect([]);

        return;
      }

      toast({
        title: "Cập nhật tên nhãn thất bại",
        status: "error",
      });
    } catch (error) {
      toast({
        title: "Cập nhật tên nhãn thất bại",
        status: "error",
      });
    }
  };

  const handleOkPassword = async () => {
    try {
      const data = {
        id,
        password: passwordToChange,
      };

      const result = await updatePasswordService(data);

      const { status } = result?.data;

      if (status === RESPONSE_STATUS.SUCCESS) {
        toast({
          title: "Cập nhật mật khẩu thành công",
          status: "success",
        });

        setPassword(passwordToChange);
        setIsModalOpenPassword(false);

        return;
      }

      toast({
        title: "Cập nhật mật khẩu thất bại",
        status: "error",
      });
    } catch (error) {
      toast({
        title: "Cập nhật mật khẩu thất bại",
        status: "error",
      });
    }
  };

  const { t } = useTranslation();

  return (
    <Box px="4" py="8">
      <Grid
        templateColumns={{
          lg: "repeat(3, 1fr)",
          sm: "repeat(1, 1fr)",
        }}
      >
        {/* col 1 */}
        <GridItem>
          <InfoItem
            title={t("common.serviceDetail.ipAddress")}
            value={vmid == 3006 ? "116.103.108.229" : ip}
          />
          <InfoItem
            title={t("common.serviceDetail.account")}
            value={os?.includes("Windows") ? "Administrator" : "root"}
          />

          <Flex mb={"2rem"} alignItems={"center"}>
            <Text w={"150px"} color={"gray.200"} fontSize={"16px"}>
              {t("common.serviceDetail.password")}:
            </Text>

            <Flex alignItems={"center"} gap={3}>
              {showPassword ? (
                <>
                  <Text
                    flex={1}
                    color={"green.200"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    cursor={"pointer"}
                  >
                    {password}
                  </Text>
                  <Text
                    flex={1}
                    color={"red.400"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    cursor={"pointer"}
                    onClick={() => setShowPassword(false)}
                  >
                    Hidden
                  </Text>
                </>
              ) : (
                <Text
                  flex={1}
                  color={"red.400"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  cursor={"pointer"}
                  onClick={() => setShowPassword(true)}
                >
                  Show
                </Text>
              )}
              <Icon
                cursor={"pointer"}
                color={"white"}
                as={EditIcon}
                fontSize={"20px"}
                onClick={() => {
                  showModalPassword();
                }}
              />
            </Flex>
          </Flex>

          <InfoItem title={"OS"} value={os} />
        </GridItem>

        {/* col 2 */}
        <GridItem>
          <InfoItem
            title={t("common.serviceDetail.cpuNumber")}
            value={vmid == 3006 ? "88" : cpu}
          />
          <InfoItem title={"Ram"} value={vmid == 3006 ? "256" : ram} />
          <InfoItem title={"SSD"} value={vmid == 3006 ? "1TB" : disk} />
          <InfoItem
            title={t("common.serviceDetail.bandwidth")}
            value={`${bandwidth?.in} / ${bandwidth?.out}`}
            extra={"(Receive/Send)"}
          />
        </GridItem>

        {/* col 3 */}
        <GridItem>
          <InfoItem title={"Server code"} value={vmid} />

          <Flex mb={"2rem"} alignItems={"center"}>
            <Text w={"150px"} color={"gray.200"} fontSize={"16px"}>
              {t("common.serviceDetail.labelName")}:
            </Text>

            <Flex alignItems={"center"} gap={3}>
              <Text
                flex={1}
                color={"white"}
                fontSize={"16px"}
                fontWeight={"600"}
                cursor={"pointer"}
                onClick={() => setShowPassword(true)}
              >
                {label}
              </Text>
              <Icon
                cursor={"pointer"}
                color={"white"}
                as={EditIcon}
                fontSize={"20px"}
                onClick={handleEditItem}
              />
            </Flex>
          </Flex>
        </GridItem>
      </Grid>

      <Modal
        isOpen={isModalOpenPassword}
        onClose={() => setIsModalOpenPassword(false)}
      >
        <ModalOverlay />
        <ModalContent bgColor={"#5f68c9"}>
          <ModalHeader color={"white"}>Mật khẩu</ModalHeader>
          <ModalCloseButton color={"white"} />

          <ModalBody>
            <Box>
              <Input
                color="gray.400"
                bg="#0F1535"
                border="0.5px solid"
                borderColor="#E2E8F04D"
                borderRadius="15px"
                w={"100%"}
                mt="1rem"
                _focus={{
                  borderColor: "white",
                }}
                _active={{
                  borderColor: "white",
                }}
                onChange={(e) => setPasswordNameToChange(e.target.value)}
                value={passwordToChange}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleOkPassword}
              colorScheme="purple"
              mr={3}
              size="sm"
            >
              Confirm
            </Button>
            <Button onClick={() => setIsModalOpenPassword(false)} size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent bgColor={"#5f68c9"}>
          <ModalHeader color={"white"}>Tên nhãn</ModalHeader>
          <ModalCloseButton color={"white"} />

          <ModalBody>
            <Box>
              <Input
                color="gray.400"
                bg="#0F1535"
                border="0.5px solid"
                borderColor="#E2E8F04D"
                borderRadius="15px"
                w={"100%"}
                mt="1rem"
                _focus={{
                  borderColor: "white",
                }}
                _active={{
                  borderColor: "white",
                }}
                onChange={(e) => setLabelNameToChange(e.target.value)}
                value={labelNameToChange}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleOk} colorScheme="purple" mr={3} size="sm">
              Confirm
            </Button>
            <Button onClick={() => setIsModalOpen(false)} size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setMultiSelect([]);
        }}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent bgColor={"#5f68c9"}>
          <ModalHeader color={"white"}>Tên nhãn</ModalHeader>
          <ModalCloseButton color={"white"} />

          <ModalBody>
            <Box w="full" position={"relative"}>
              <Flex
                color="gray.400"
                bg="#0F1535"
                border="0.5px solid"
                borderColor="#E2E8F04D"
                borderRadius="15px"
                w={"100%"}
                mt="1rem"
                _focus={{
                  borderColor: "white",
                }}
                _active={{
                  borderColor: "white",
                }}
                rounded="lg"
                p={2}
              >
                <Flex align="center" justify="space-between" gap={2}>
                  {multiSelect?.map((item, index) => (
                    <Flex
                      key={index}
                      rounded="md"
                      bg="gray.300"
                      align="center"
                      justify="center"
                      borderWidth={1}
                      borderColor="gray.300"
                    >
                      <Text color={"gray.700"} p={2} py={1} fontSize="sm">
                        {item}
                      </Text>
                      <Badge
                        as="span"
                        p={1.5}
                        px={2}
                        fontWeight="semibold"
                        fontSize="xs"
                        rounded={"md"}
                        size={"sm"}
                        cursor="pointer"
                        onClick={() => handleRemoveSelect(index)}
                        bg="gray.300"
                        _hover={{
                          bg: "purple.500",
                          color: "white",
                        }}
                      >
                        x
                      </Badge>
                    </Flex>
                  ))}
                </Flex>
                <Input
                  type="text"
                  flex="1"
                  size="sm"
                  outline={"none"}
                  textColor={"white"}
                  border={"none"}
                  borderColor={"white"}
                  focusBorderColor="none"
                  value={inputValue || ""}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <Button
                  size="sm"
                  px={4}
                  colorScheme="purple"
                  rounded="md"
                  fontSize="xs"
                  onClick={handleAddSelect}
                >
                  Add
                </Button>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleOk} colorScheme="purple" mr={3} size="sm">
              Confirm
            </Button>
            <Button
              onClick={() => {
                setIsModalOpen(false);
                setMultiSelect([]);
              }}
              size="sm"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default GeneralInformation;
