import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Img,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import vps from "assets/img/hosting-cao-cap.svg";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { getProductById } from "services/api";
import { vnpayRequest } from "services/api";
import { convertDescriptionProductToObject } from "utils";
import { convertToUsd } from "utils/convertToUsd";
import { Link as ChakraLink } from "@chakra-ui/react";
import { convertMoney } from "utils";
import { useTranslation } from "react-i18next";
function VNPay() {
  const state = useLocation().state;
  const invoice = JSON.parse(localStorage.getItem("cloudService"));
  const [selectPayment, setSelectPayment] = useState("vnpay");
  const user = useSelector((state) => state.user.user);
  const toast = useToast({
    isClosable: true,
    duration: 5000,
    position: "top-right",
  });

  const { t } = useTranslation();

  const tinRef = useRef(null);
  const [product, setProduct] = useState({});

  const handleVNPayment = async () => {
    try {
      //
      const response = await vnpayRequest({
        invoice_id: invoice?.invoice_id,
        proforma_id: invoice?.noidung,
        total: invoice?.amount,
      });
      window.open(response?.data?.data?.redirect, "_blank", "noreferrer");
    } catch (error) {
      toast("Could not initiate Vnpay Checkout...");
    }
  };

  useEffect(async () => {
    try {
      const result = await getProductById(invoice?.items[0]?.product_id);

      const { description, name } = result?.data?.data;
      const decoded = convertDescriptionProductToObject(description);

      const newData = {
        ...decoded,
        name,
        price: convertToUsd(Number(invoice.total)),
      };
      tinRef.current = newData;

      setProduct(newData);
    } catch (error) {
      console.log(error);
    }
  }, [invoice?.items[0]?.productId]);

  return (
    <Box>
      <Box pt={{ sm: "125px", md: "75px" }}>
        <Card>
          <Box mb={"4"}>
            <RadioGroup onChange={setSelectPayment} value={selectPayment}>
              <Stack direction="row" gap={5}>
                <Radio value="vnpay">
                  <Text fontSize="lg" color="#fff" fontWeight="semibold">
                    VNPAY
                  </Text>
                </Radio>
                <Radio value="acb">
                  <Text fontSize="lg" color="#fff" fontWeight="semibold">
                    ACB
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
          <CardHeader mb="12px">
            <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px" cap>
              {t("common.payment.selectProduct")}
            </Text>
          </CardHeader>
          <Grid templateColumns="repeat(5, 1fr)" gap={10}>
            <GridItem colSpan={3} w="100%">
              <Flex
                border={"1px"}
                borderColor={"#4656E7"}
                borderRadius={"8px"}
                p={4}
                justifyContent={"space-between"}
                alignContent={"center"}
                gap={2}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src={vps}
                    alt="Dan Abramov"
                  />
                  <Text fontSize="sm" color="#fff" fontWeight="bold" mb="6px">
                    {product?.name}
                  </Text>
                </Box>
                <ChakraLink as={ReactRouterLink} to="/cloud-services/create">
                  <Button colorScheme="blue" variant="outline">
                    CHANGE
                  </Button>
                </ChakraLink>
              </Flex>

              <Box textColor={"white"} py={4}>
                {t("common.payment.unitAndBillingCycle")}: 1 month
              </Box>

              <Grid templateColumns="repeat(4, 1fr)" gap={8}>
                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>CPU</Box>
                    <Box>{product?.CPU}</Box>
                  </Box>
                </GridItem>

                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>RAM</Box>
                    <Box>{product?.Ram}</Box>
                  </Box>
                </GridItem>

                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>SSD</Box>
                    <Box>{product?.Storage}</Box>
                  </Box>
                </GridItem>

                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>IPV4</Box>
                    <Box>Private</Box>
                  </Box>
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem w="100%" colSpan={2}>
              {selectPayment === "vnpay" ? (
                <Box boxShadow={"md"} rounded={"lg"} bg={"white"} p={4}>
                  <Heading as="h3" fontSize="lg" fontWeight="bold" pb={4}>
                    Order statistics
                  </Heading>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">{product?.name}</Text>
                    <Text>{product?.price} $</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">quantity:</Text>
                    <Text>{invoice?.items?.length || 0} </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">INSTALLATION FEES:</Text>
                    <Text>0.00 $</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">Monthly:</Text>
                    <Text>{convertToUsd(invoice?.total || 0)} $</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">TAX:</Text>
                    <Text>0.00 $</Text>
                  </Flex>
                  <Flex justifyContent="space-between" py={2}>
                    <Text textTransform="uppercase">Total</Text>
                    <Text>{convertToUsd(invoice?.total || 0)} $</Text>
                  </Flex>
                  <Button
                    color={"white"}
                    background={"orange.600"}
                    padding={"12px 24px"}
                    borderRadius={"4px"}
                    width={"100%"}
                    display={"block"}
                    textAlign={"center"}
                    onClick={handleVNPayment}
                  >
                    Pay with VNPay
                  </Button>
                </Box>
              ) : (
                <Card p={"7"}>
                  <Flex justifyContent={"space-between"}>
                    <Box>
                      <img
                        src={`https://manager.idcviettel.com/vietqr.php?account=6863868686&bankcode=970416&amount=${
                          invoice?.amount
                        }&noidung=${invoice?.noidung || ""}`}
                        style={{
                          height: "200px",
                          maxWidth: "200px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </Box>
                    <Flex
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"column"}
                    >
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Bank:{" "}
                        <Text fontWeight={"600"} as="span">
                          ACB
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Account number:
                        <Text fontWeight={"600"} as="span">
                          {" "}
                          {"6863868686"}
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Account owner:
                        <Text fontWeight={"600"} as="span">
                          {" "}
                          CTY TNHH CONG NGHE VNG CLOUD
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Content billing:{" "}
                        <Text fontWeight={"600"} as="span">
                          {invoice?.noidung}
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Amount:{" "}
                        <Text fontWeight={"600"} as="span">
                          {convertMoney(invoice?.amount)} đ
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Số lượng:{" "}
                        <Text fontWeight={"600"} as="span">
                          {invoice?.items?.length || 0}
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                  <Text
                    color={"white"}
                    fontSize={"16px"}
                    fontWeight={"500"}
                    mt="8"
                    textAlign={"center"}
                  >
                    Please fill in the correct invoice number and amount or scan
                    the QR code Payment is confirmed automatically.
                  </Text>
                </Card>
              )}
            </GridItem>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
}

export default VNPay;
