const packages = [
  {
    id: "MCWEB02",
    name: "MCWEB02",
    permonth: 128,
    price3: 364.8,
    price6: 691.2,
    price12: 1305.6,
    price24: 3072,
    main: "Intel Xeon E5 v4-5",
    cpu: "H3C R490 G3",
    ram: "16 GB RAM",
    ssd: "SSD 600GB SAS",
    hdd: "2*600GB SAS",
    bandwidth: "100Mbs/10Mbps",
  },
  {
    id: "MCWEB05",
    name: "MCWEB05",
    permonth: 160,
    price3: 456,
    price6: 864,
    price12: 1632,
    price24: 4320,
    main: "Intel Xeon Platinum 8171M",
    cpu: "H3C R490 G3",
    ram: "16 GB RAM",
    ssd: "SSD 600GB SAS",
    hdd: "2*600GB SAS",
    bandwidth: "100Mbs/10Mbps",
  },
  {
    id: "MCWEB03",
    name: "MCWEB03",
    permonth: 260,
    price3: 741,
    price6: 1404,
    price12: 2652,
    price24: 6240,
    main: "AMD EPYC 7642",
    cpu: "H3C R490 G3",
    ram: "16 GB RAM",
    ssd: "SSD 600GB SAS",
    hdd: "2*600GB SAS",
    bandwidth: "100Mbs/10Mbps",
  },
  {
    id: "MCWEB04",
    name: "MCWEB04",
    permonth: 148,
    price3: 421.8,
    price6: 799.2,
    price12: 1736.04,
    price24: 3552,
    main: "Intel Xeon Gold 6133",
    cpu: "H3C R490 G3",
    ram: "16 GB RAM",
    ssd: "SSD 600GB SAS",
    hdd: "2*600GB SAS",
    bandwidth: "100Mbs/10Mbps",
  },
];

export default packages;
