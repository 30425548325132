import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { getListOsByService } from "services/api";

function ModalRebuildVM({ service, visible, onClose, handleRebuild, vm }) {
  const [listOs, setListOs] = useState([]);
  const [os, setOs] = useState("");

  const getListOs = async () => {
    try {
      const result = await getListOsByService(service?.id);

      const { data } = result?.data;
      setListOs(data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (visible) {
      getListOs();
      setOs(vm?.template_name);
    }
  }, [visible]);

  const onOk = () => {
    const template = listOs?.find((item) => item?.name === os);
    console.log(template?.id);
    handleRebuild(template?.id || "");
  };

  return (
    <Modal isOpen={visible} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={"600px"} bgColor={"#5f68c9"}>
        <ModalHeader color={"white"}>Cài đặt lại</ModalHeader>
        <ModalCloseButton color={"white"} />

        <ModalBody>
          <Box>
            <Text mb="2" color={"white"}>
              Cài đặt lại hệ điều hành cho Máy ảo,{" "}
              <Text as={"span"} color={"red.400"}>
                xóa tất cả dữ liệu trên các ổ đĩa
              </Text>
            </Text>

            <Text color={"white"} fontWeight={"500"} fontSize={"15px"}>
              Hệ điều hành
            </Text>
            <Select
              value={os}
              onChange={(e) => setOs(e.target.value)}
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              size="sm"
              mt="1"
              maxW="200px"
              cursor="pointer"
            >
              {listOs?.map((item) => (
                <option key={item?.id}>{item?.name}</option>
              ))}
            </Select>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onOk} colorScheme="purple" mr={3} size="sm">
            Confirm
          </Button>
          <Button onClick={onClose} size="sm">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalRebuildVM;
