import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  Grid,
  GridItem,
  Icon,
  Radio,
  RadioGroup,
  Tag,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import React, { useState, useEffect } from "react";
import { genPriceOvh } from "utils";
import { BILLING_CYCLE_OVH } from "utils";
import generalBg from "assets/img/generalBg.png";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import { MdOutlineAttachMoney } from "react-icons/md";
import { HiReceiptTax } from "react-icons/hi";
import condition from "variables/condition.json";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { createVpsOvh } from "services/api";
import { RESPONSE_STATUS } from "utils";
import { getUserCredit } from "redux/slices/userSlice";
import { deleteOrderCart } from "services/api";
import { useTranslation } from "react-i18next";
import { convertToVnd } from "utils/convertToUsd";
import { getLanguage } from "utils";

function Step3({
  cartId,
  optionDuration,
  billCycle,
  duration,
  setBillCycle,
  setDuration,
  getDiscountBillingCycle,
  genDataCenter,
  chosen,
  listVps,
  pricePackage,
  genPriceOs,
  os,
  step,
  setStep,
  optionStep2,
  genPriceLicense,
  family,
  priceSummary,
  genInformation,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [accept, setAccept] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const deleteSummaryCart = async () => {
    try {
      const result = await deleteOrderCart(cartId);
    } catch (error) {
      console.log(error);
    }
  };

  const onReloadPage = () => {
    deleteSummaryCart();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onReloadPage);
    return () => {
      window.removeEventListener("beforeunload", onReloadPage);
      deleteSummaryCart();
    };
  }, []);

  const genPriceByAddon = (addon) => {
    if (!addon) return 0;

    return (
      addon?.pricings?.find(
        (item) =>
          item?.commitment === 0 && item?.interval === 1 && item?.phase === 1
      )?.price || 0
    );
  };

  const genDueDate = () => {
    if (duration === 0 || billCycle === "month")
      return {
        including: "month",
        withThisConfig: `Next month`,
      };

    if (duration === 12 && billCycle === "all")
      return {
        including: "one year",
        withThisConfig: `Next year`,
      };

    return {
      including: "two years",
      withThisConfig: `the two following years`,
    };
  };

  const genSummary = () => {
    let additional = 0;

    return [
      {
        title: t("common.service.totalGST"),
        extra: `${t("common.service.includeTheFirst")} ${
          genDueDate().including
        } ${t("common.service.ofUse")}`,
        value: `${convertToVnd(
          priceSummary?.withoutTax?.value,
          getLanguage()
        )}`,
      },
      {
        title: "10% GST",
        extra: "",
        value: `${convertToVnd(priceSummary?.tax?.value, getLanguage())}`,
        icon: HiReceiptTax,
      },
      {
        title: "Total incl. GST",
        extra: ``,
        value: `${convertToVnd(
          priceSummary?.withoutTax?.value + priceSummary?.tax?.value,
          getLanguage()
        )}`,
      },
    ];
  };

  const onNext = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to use this vps!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);

          const result = await createVpsOvh(cartId, priceSummary);

          if (result?.data?.status === RESPONSE_STATUS.FAILED) {
            toast({
              title: result?.data?.message,
              status: "error",
            });
            return;
          }

          toast({
            title: `Tạo service thành công`,
            status: "success",
          });
          dispatch(getUserCredit({}));
          history.push("/services-international");
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const { t } = useTranslation();

  return (
    <GridItem colSpan={4}>
      <Text color={"white"} fontSize="1.5rem" mb="1rem" fontWeight={"600"}>
        {t("common.service.orderSummary")}
      </Text>

      {isLoading && (
        <Alert borderRadius="8px" status="info" variant="solid" mb="1rem">
          <AlertIcon color="white" />
          <AlertTitle mr="12px">Hệ thống đang xử lý</AlertTitle>
        </Alert>
      )}

      <Card>
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column" w={"100%"}>
            <Text fontSize="lg" color="#fff" fontWeight="bold" mb="1rem">
              Contract duration
            </Text>
            <RadioGroup value={duration} w={"100%"}>
              <Grid
                gap={3}
                templateColumns={"repeat(3, 1fr)"}
                gridAutoRows={"1fr"}
              >
                {optionDuration?.map((item) => (
                  <GridItem key={item?.label?.title}>
                    <Box
                      pl="12px"
                      py="32px"
                      bg="rgb(31, 35, 89)"
                      borderColor={
                        item?.value?.commitment === duration
                          ? "brand.100"
                          : "transparent"
                      }
                      borderWidth={"4px"}
                      borderRadius="20px"
                      cursor={"pointer"}
                      w="100%"
                      h={"100%"}
                      _hover={{
                        borderColor: "brand.100",
                      }}
                      onClick={() => setDuration(item?.value?.commitment)}
                    >
                      <Flex alignItems={"center"} mb={"12px"}>
                        <Radio
                          value={item?.value?.commitment}
                          size="lg"
                          mr="1rem"
                          colorScheme={"purple"}
                        />
                        <Text
                          color="#fff"
                          fontSize="lg"
                          fontWeight={"bold"}
                          lineHeight={"1"}
                        >
                          {item?.label?.title}
                        </Text>
                      </Flex>

                      {!item?.value?.commitment ? null : (
                        <>
                          <Text color={"green.200"} fontSize={"md"}>
                            Save {item?.label?.extra} per month
                          </Text>

                          <Text color={"gray.400"}>
                            throughout your commitment
                          </Text>
                        </>
                      )}
                    </Box>
                  </GridItem>
                ))}
              </Grid>
            </RadioGroup>

            {duration !== 0 && (
              <>
                <HSeparator my="2rem" />
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="1rem">
                  Billing cycle
                </Text>
                <RadioGroup value={billCycle} w={"100%"}>
                  <Grid
                    gap={3}
                    templateColumns={"repeat(3, 1fr)"}
                    gridAutoRows={"1fr"}
                  >
                    <GridItem>
                      <Box
                        pl="12px"
                        py="32px"
                        bg="rgb(31, 35, 89)"
                        borderColor={
                          billCycle === BILLING_CYCLE_OVH.MONTH
                            ? "brand.100"
                            : "transparent"
                        }
                        borderWidth={"4px"}
                        borderRadius="20px"
                        cursor={"pointer"}
                        w="100%"
                        h={"100%"}
                        _hover={{
                          borderColor: "brand.100",
                        }}
                        onClick={() => setBillCycle(BILLING_CYCLE_OVH.MONTH)}
                      >
                        <Flex alignItems={"center"} mb={"12px"}>
                          <Radio
                            value={BILLING_CYCLE_OVH.MONTH}
                            size="lg"
                            mr="1rem"
                            colorScheme={"purple"}
                          />
                          <Text
                            color="#fff"
                            fontSize="lg"
                            fontWeight={"bold"}
                            lineHeight={"1"}
                          >
                            Monthly payment
                          </Text>
                        </Flex>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box
                        pl="12px"
                        py="32px"
                        bg="rgb(31, 35, 89)"
                        borderColor={
                          billCycle === BILLING_CYCLE_OVH.ALL
                            ? "brand.100"
                            : "transparent"
                        }
                        borderWidth={"4px"}
                        borderRadius="20px"
                        cursor={"pointer"}
                        w="100%"
                        h={"100%"}
                        _hover={{
                          borderColor: "brand.100",
                        }}
                        onClick={() => setBillCycle(BILLING_CYCLE_OVH.ALL)}
                      >
                        <Flex alignItems={"center"} mb={"12px"}>
                          <Radio
                            value={BILLING_CYCLE_OVH.ALL}
                            size="lg"
                            mr="1rem"
                            colorScheme={"purple"}
                          />
                          <Text
                            color="#fff"
                            fontSize="lg"
                            fontWeight={"bold"}
                            lineHeight={"1"}
                          >
                            Payment Up-Front
                          </Text>
                        </Flex>
                        <Text
                          fontWeight={"400"}
                          fontSize={"md"}
                          color={"green.200"}
                        >
                          Get an additional US${" "}
                          {genPriceOvh(getDiscountBillingCycle)} off on your
                          total bill
                        </Text>
                      </Box>
                    </GridItem>
                  </Grid>
                </RadioGroup>
              </>
            )}
          </Flex>
        </CardHeader>
      </Card>

      <Card mt="2rem">
        <Text
          textAlign={"right"}
          color={"white"}
          fontSize={"md"}
          fontWeight={"500"}
        >
          This page is the detailed summary of your order, it is not your
          invoice.
        </Text>
        <Text
          textAlign={"right"}
          color={"gray.300"}
          fontSize={"sm"}
          fontWeight={"400"}
        >
          Price ex. GST (USD)
        </Text>

        <Card bgImage={generalBg} bgSize="fill" mt="1rem">
          <CardHeader justifyContent="space-between">
            <Text color="white" fontWeight={"600"} fontSize={"lg"}>
              Datacentre location
            </Text>
          </CardHeader>

          <HSeparator my="2rem" />

          {genDataCenter?.data?.map((item) => (
            <Flex
              mt={"4px"}
              justifyContent={"space-between"}
              alignItems={"center"}
              key={item?.value}
            >
              <Text color={"white"} fontSize={"MD"} fontWeight={"500"}>
                {item?.blobs?.technical?.datacenter?.city},{" "}
                {item?.blobs?.technical?.datacenter?.country}
              </Text>
              <Tag
                bg={"white"}
                fontSize="12px"
                size="sm"
                color="brand.600"
                borderRadius="15px"
                alignSelf="flex-start"
              >
                {item?.quantity}X
              </Tag>
            </Flex>
          ))}
        </Card>

        <Card bgImage={generalBg} bgSize="cover" mt="1rem">
          <CardHeader justifyContent="space-between">
            <Text color="white" fontWeight={"600"} fontSize={"xl"}>
              {listVps?.[chosen]?.description?.split(" ")?.[1]}
            </Text>

            <Box className="summary-cart-info-type-price">
              {pricePackage?.priceDefault !== pricePackage?.pricePerMonth && (
                <Text color={"red.400"} textDecoration={"line-through"}>
                  {getLanguage() == "en" ? `US$` : ""}{" "}
                  {convertToVnd(
                    genPriceOvh(pricePackage?.priceDefault, true),
                    getLanguage()
                  )}
                </Text>
              )}

              <Text color="white" fontWeight={"500"} fontSize={"md"}>
                {getLanguage() == "en" ? `US$` : ""}{" "}
                {convertToVnd(
                  genPriceOvh(pricePackage?.pricePerMonth, true),
                  getLanguage()
                )}
              </Text>
              <Text color="white" fontWeight={"500"} fontSize={"md"}>
                /month
              </Text>
            </Box>
          </CardHeader>
          {genInformation?.map((item) => (
            <React.Fragment key={item.title}>
              <HSeparator my={"8px"} />
              <Flex h="100%">
                <Flex
                  bgColor="green.200"
                  borderRadius="12px"
                  minWidth="3px"
                  minH="100%"
                />
                <Box ml="1rem">
                  <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                    {item.title}
                  </Text>
                  <Text color={"white"} fontWeight={"500"} fontSize={"sm"}>
                    {item.value}
                  </Text>
                </Box>
              </Flex>
            </React.Fragment>
          ))}

          {os && (
            <>
              <HSeparator my={"8px"} />
              <Flex h="100%">
                <Flex
                  bgColor="green.200"
                  borderRadius="12px"
                  minWidth="3px"
                  minH="100%"
                />
                <Box ml="1rem">
                  <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                    Image
                  </Text>
                  <Text color={"white"} fontWeight={"500"} fontSize={"sm"}>
                    {os}
                  </Text>
                </Box>
              </Flex>

              {(genPriceOs > 0 || genPriceLicense?.price > 0) && (
                <>
                  <HSeparator my={"8px"} />
                  <Flex h="100%">
                    <Flex
                      bgColor="green.200"
                      borderRadius="12px"
                      minWidth="3px"
                      minH="100%"
                    />
                    <Flex justifyContent={"space-between"} ml="1rem" w={"100%"}>
                      <Box>
                        <Text
                          color={"white"}
                          fontWeight={"600"}
                          fontSize={"sm"}
                        >
                          License
                        </Text>

                        <Text
                          color={"white"}
                          fontWeight={"500"}
                          fontSize={"xs"}
                        >
                          {genPriceLicense?.price > 0
                            ? genPriceLicense?.name
                            : chosenFamily?.family}
                        </Text>
                      </Box>

                      <Box>
                        <Text
                          color={"white"}
                          fontWeight={"500"}
                          fontSize={"md"}
                        >
                          {getLanguage() == "en" ? `US$` : ""}{" "}
                          {convertToVnd(
                            genPriceOvh(
                              genPriceOs || genPriceLicense?.price,
                              true
                            ),
                            getLanguage()
                          )}
                        </Text>

                        <Text
                          color={"white"}
                          fontWeight={"400"}
                          fontSize={"md"}
                        >
                          /month
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </>
              )}
            </>
          )}

          {optionStep2?.snapshot && (
            <>
              <HSeparator my={"8px"} />
              <Flex h="100%">
                <Flex
                  bgColor="green.200"
                  borderRadius="12px"
                  minWidth="3px"
                  minH="100%"
                />
                <Flex justifyContent={"space-between"} ml="1rem" w={"100%"}>
                  <Box>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Snapshot
                    </Text>
                    <Text color={"white"} fontWeight={"500"} fontSize={"sm"}>
                      {optionStep2?.snapshot?.blobs?.commercial?.name}
                    </Text>
                  </Box>

                  <Box>
                    <Text color={"white"} fontWeight={"500"} fontSize={"md"}>
                      {getLanguage() == "en" ? `US$` : ""}{" "}
                      {convertToVnd(
                        genPriceOvh(
                          genPriceByAddon(optionStep2?.snapshot),
                          true
                        ),
                        getLanguage()
                      )}
                    </Text>

                    <Text color={"white"} fontWeight={"400"} fontSize={"md"}>
                      /month
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </>
          )}

          {optionStep2.additionalDisk && (
            <>
              <HSeparator my={"8px"} />
              <Flex h="100%">
                <Flex
                  bgColor="green.200"
                  borderRadius="12px"
                  minWidth="3px"
                  minH="100%"
                />
                <Flex justifyContent={"space-between"} ml="1rem" w={"100%"}>
                  <Box>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Addition Storage
                    </Text>
                    <Text color={"white"} fontWeight={"500"} fontSize={"sm"}>
                      {optionStep2?.additionalDisk?.blobs?.commercial?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"} fontWeight={"500"} fontSize={"md"}>
                      {getLanguage() == "en" ? `US$` : ""}{" "}
                      {convertToVnd(
                        genPriceOvh(
                          genPriceByAddon(optionStep2?.additionalDisk),
                          true
                        ),
                        getLanguage()
                      )}
                    </Text>

                    <Text color={"white"} fontWeight={"400"} fontSize={"md"}>
                      /month
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </>
          )}

          {optionStep2.backup && (
            <>
              <HSeparator my={"8px"} />
              <Flex h="100%">
                <Flex
                  bgColor="green.200"
                  borderRadius="12px"
                  minWidth="3px"
                  minH="100%"
                />
                <Flex justifyContent={"space-between"} ml="1rem" w={"100%"}>
                  <Box>
                    <Text color={"white"} fontWeight={"600"} fontSize={"md"}>
                      Automated backup
                    </Text>

                    <Text color={"white"} fontWeight={"500"} fontSize={"sm"}>
                      {optionStep2?.backup?.blobs?.commercial?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"} fontWeight={"500"} fontSize={"md"}>
                      {getLanguage() == "en" ? `US$` : ""}{" "}
                      {convertToVnd(
                        genPriceOvh(genPriceByAddon(optionStep2?.backup), true),
                        getLanguage()
                      )}
                    </Text>

                    <Text color={"white"} fontWeight={"400"} fontSize={"md"}>
                      /month
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </>
          )}
        </Card>

        <Flex
          mt="1rem"
          alignItems={"center"}
          direction={{
            md: "row",
            sm: "column",
          }}
          gap={4}
          gridAutoRows={"1fr"}
        >
          {genSummary()?.map((item) => (
            <Card
              bgColor="#5f68c9"
              p="16px"
              display="flex"
              align="center"
              justify="center"
              w="300px"
              key={item.title}
            >
              <CardBody>
                <Flex direction="column" align="center" w="100%" py="14px">
                  <IconBox as="box" h={"60px"} w={"60px"} bg="brand.200">
                    <Icon
                      h={"24px"}
                      w={"24px"}
                      color="white"
                      as={item?.icon || MdOutlineAttachMoney}
                    />
                  </IconBox>
                  <Flex
                    direction="column"
                    m="14px"
                    justify="center"
                    textAlign="center"
                    align="center"
                    w="100%"
                  >
                    <Text fontSize="lg" color="#fff" fontWeight="bold">
                      {item.title}
                    </Text>
                    <Text
                      mb="12px"
                      fontSize="xs"
                      color="gray.400"
                      lineHeight={"1"}
                      minHeight={"14px"}
                    >
                      {item.extra || " "}
                    </Text>
                    <HSeparator />
                  </Flex>
                  <Text fontSize="lg" color="#fff" fontWeight="bold">
                    {item.value}
                  </Text>
                </Flex>
              </CardBody>
            </Card>
          ))}
        </Flex>
      </Card>

      <Card mt="1rem">
        <CardHeader p="12px 5px" mb="12px">
          <Text fontSize="lg" color="#fff" fontWeight="bold" mb="1rem">
            {t("common.service.termAndCondition")}
          </Text>
        </CardHeader>

        <Accordion allowToggle>
          {condition?.map((item) => (
            <AccordionItem border={"none"} mb="8px">
              <AccordionButton
                _focus={{ boxShadow: "none" }}
                bgColor={"brand.200"}
                color={"white"}
                borderRadius={"8px"}
                _hover={{ bgColor: "brand.400" }}
              >
                <Box as="span" flex="1" textAlign="left">
                  {item?.name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Textarea
                  value={item?.content}
                  minH="400px"
                  border={"none"}
                  color={"white"}
                  _focus={{
                    boxShadow: "none",
                    borderWidth: "0px",
                  }}
                  readOnly
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Checkbox
          color={"white"}
          mt="8px"
          colorScheme="purple"
          onChange={() => setAccept(!accept)}
          isChecked={accept}
        >
          {t("common.service.haveReadTermAndCondition")}
        </Checkbox>
      </Card>

      <Flex mt="1rem" justifyContent={"space-between"}>
        <Button
          bgColor={"red.400"}
          _hover={{
            bgColor: "red.500",
          }}
          color={"white"}
          w="125px"
          h="40px"
          fontSize="md"
          fontWeight="bold"
          disabled={isLoading}
          alignSelf={{ sm: "flex-start", lg: null }}
          onClick={() => setStep(step - 1)}
        >
          {t("common.button.back")}
        </Button>

        <Button
          variant="brand"
          w="125px"
          h="40px"
          fontSize="md"
          fontWeight="bold"
          disabled={!accept}
          isLoading={isLoading}
          alignSelf={{ sm: "flex-start", lg: null }}
          onClick={onNext}
        >
          {t("common.button.create")}
        </Button>
      </Flex>
    </GridItem>
  );
}

export default Step3;
