import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { CiLogout } from "react-icons/ci";
import newProductBg from "assets/img/new-product-background.png";
import { useHistory, useParams } from "react-router-dom";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import { BiLogOut } from "react-icons/bi";
import { getServicesInfoOvhByUser } from "services/api";
import LoadingOVH from "components/Loading/Loading";
import HomeTab from "containers/ServiceDetail/HomeTab";
import SecondaryDNS from "containers/ServiceDetail/SecondaryDNS";
import BackupTab from "containers/ServiceDetail/BackupTab";
import AdditionalDiskTab from "containers/ServiceDetail/AdditionalDiskTab";
import DatabaseTab from "containers/ServiceDetail/DatabaseTab";
import MonitoringTab from "containers/ServiceDetail/MonitoringTab";
import SnapshotTab from "containers/ServiceDetail/SnapshotTab";
import RenewalTab from "containers/ServiceDetail/RenewalTab";
import ImagesTab from "containers/ServiceDetail/ImagesTab";
import { useTranslation } from "react-i18next";

// Chakra color mode
const textColor = "white";
const bgPrevButton = "gray.100";
const bgProfile =
  "linear-gradient(112.83deg, rgba(6, 11, 40, 0.94) 0%, rgba(10, 14, 35, 0.62) 110.84%)";
const borderProfileColor = "transparent";
const emailColor = "gray.400";

function ServiceDetail() {
  const { t } = useTranslation();
  const tabs = [
    {
      id: 1,
      name: t("common.serviceDetail.home"),
    },
    {
      id: 2,
      name: t("common.serviceDetail.secondaryDns"),
    },
    {
      id: 3,
      name: t("common.serviceDetail.automatedBackup"),
    },
    {
      id: 4,
      name: t("common.serviceDetail.additionalDisk"),
    },
    {
      id: 5,
      name: t("common.serviceDetail.databases"),
    },
    {
      id: 6,
      name: t("common.serviceDetail.monitoring"),
    },
  ];

  const history = useHistory();
  const { id } = useParams();
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceDetail, setServiceDetail] = useState();
  const [serviceInfoExtra, setServiceInfoExtra] = useState();

  const getServiceDetail = async (id) => {
    try {
      setIsLoading(true);

      const data = {
        serviceName: `vps-${id}.vps.ovh.ca`,
        isNew: true,
      };

      const result = await getServicesInfoOvhByUser(data);

      setServiceDetail(result.data?.data?.serviceDetail);
      delete result.data?.data?.serviceDetail;
      setServiceInfoExtra(result.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getServiceDetail(id);
  }, []);

  const render = {
    1: (
      <HomeTab
        serviceDetail={serviceDetail}
        setServiceDetail={setServiceDetail}
        setTab={setTab}
        serviceInfoExtra={serviceInfoExtra}
      />
    ),
    2: <SecondaryDNS serviceDetail={serviceDetail} />,
    3: <BackupTab serviceDetail={serviceDetail} />,
    4: <AdditionalDiskTab serviceDetail={serviceDetail} />,
    5: <DatabaseTab serviceDetail={serviceDetail} />,
    6: <MonitoringTab serviceDetail={serviceDetail} />,
    7: <SnapshotTab serviceDetail={serviceDetail} setKey={setTab} />,
    8: <RenewalTab serviceDetail={serviceDetail} setKey={setTab} />,
    9: (
      <ImagesTab
        serviceDetail={serviceDetail}
        setServiceDetail={setServiceDetail}
        setTab={setTab}
      />
    ),
  };

  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        {isLoading ? (
          <LoadingOVH />
        ) : (
          <>
            <Box
              bgImage={newProductBg}
              w="100%"
              h="150px"
              borderRadius="20px"
              bgPosition="50%"
              bgRepeat="no-repeat"
              position="relative"
              display="flex"
              justifyContent="center"
              mt="80px"
            >
              <Text
                fontWeight={"600"}
                fontSize={{
                  sm: "1.3rem",
                  lg: "1.5rem",
                }}
                mt="2rem"
                color={"white"}
              >
                {t("common.serviceDetail.label")}
              </Text>
              <Flex
                direction={{ sm: "column", md: "row" }}
                mx="1.5rem"
                maxH="400px"
                w={{ sm: "90%", xl: "95%" }}
                justifyContent={{ sm: "center", md: "space-between" }}
                align="center"
                backdropFilter="saturate(200%) blur(50px)"
                position="absolute"
                boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
                border="2px solid"
                borderColor={borderProfileColor}
                bg={bgProfile}
                p="24px"
                borderRadius="20px"
                top={"100px"}
              >
                <Flex
                  align="center"
                  mb={{ sm: "10px", md: "0px" }}
                  direction={{ sm: "column", md: "row" }}
                  w={{ sm: "100%" }}
                  textAlign={{ sm: "center", md: "start" }}
                >
                  <Button
                    bgColor={"red.500"}
                    w={{
                      sm: "32px",
                      lg: "45px",
                    }}
                    h={{
                      sm: "32px",
                      lg: "45px",
                    }}
                    fontSize={{
                      sm: "sm",
                      lg: "md",
                    }}
                    fontWeight="bold"
                    _hover={{
                      bgColor: "red.600",
                    }}
                    onClick={() => history.push("/services/list")}
                  >
                    <Icon as={BiLogOut} color={"white"} />
                  </Button>
                  <Flex
                    direction="column"
                    maxWidth="100%"
                    my={{ sm: "14px" }}
                    ml={"1rem"}
                  >
                    <Text
                      fontSize={{ sm: "md", lg: "md" }}
                      color={textColor}
                      fontWeight="bold"
                      ms={{ sm: "8px", md: "0px" }}
                    >
                      {serviceDetail?.nameVps}
                    </Text>
                    <Text
                      fontSize={{ sm: "xs", md: "sm" }}
                      color={emailColor}
                      fontWeight="500"
                    >
                      {serviceDetail?.vps?.displayName}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  direction={{ sm: "column", lg: "row" }}
                  w={{ sm: "100%", md: "50%", lg: "auto" }}
                  gap={{
                    sm: [2, 0],
                    lg: [0, 3],
                  }}
                  flexWrap={{
                    lg: "wrap",
                  }}
                >
                  {tabs.map((item) => (
                    <Button
                      py="0px"
                      bg={tab === item.id ? "brand.200" : "transparent"}
                      boxShadow={tab === item.id ? "brand.200" : null}
                      _hover={{
                        bgColor: "brand.200",
                      }}
                      _focus={{
                        bgColor: "brand.200",
                      }}
                      _active={{
                        bgColor: "brand.200",
                      }}
                      key={item.id}
                      borderRadius="15px"
                      transition="all .5s ease"
                      onClick={() => setTab(item?.id)}
                    >
                      <Flex
                        align="center"
                        w={{ sm: "100%", lg: "100%" }}
                        justifyContent="center"
                        cursor="pointer"
                      >
                        <Text
                          textTransform={"uppercase"}
                          fontSize="xs"
                          color={textColor}
                          fontWeight="bold"
                        >
                          {item.name}
                        </Text>
                      </Flex>
                    </Button>
                  ))}
                </Flex>
              </Flex>
            </Box>
            <Box
              mt={{
                sm: "380px",
                lg: "100px",
              }}
            >
              {render[tab]}
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
}

export default ServiceDetail;
