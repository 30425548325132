import { Box, Button, Flex } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableManagerPaymentHistory from "components/Tables/TableManagerPaymentHistory";
import useDebounce from "hooks/useDebounce";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { deletePaymentHistory } from "services/api";
import { getPagingPaymentHistory } from "services/api";

function ManagerPaymentHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalDoc, setTotalDoc] = useState(0);

  const [search, setSearch] = useState("");
  const searchValue = useDebounce(search, 500);
  const [payments, setPayments] = useState([]);

  const history = useHistory();
  const { t } = useTranslation();
  const columns = [
    {
      Header: "USER",
      label: t("common.managerPaymentHistory.user"),
    },
    {
      Header: "IP",
      label: "IP",
    },
    {
      Header: "TOTAL SERVICE",
      label: t("common.managerPaymentHistory.totalService"),
    },
    {
      Header: "TOTAL",
      label: t("common.managerPaymentHistory.total"),
    },
    {
      Header: "DATE OF INVOICE",
      label: t("common.managerPaymentHistory.dateOfInvoice"),
    },
    {
      Header: "PAYMENT NEEDED DATE",
      label: t("common.managerPaymentHistory.paymentNeededDate"),
    },
    {
      Header: "ACTION",
      label: t("common.table.action"),
    },
  ];

  const getAllPaymentHistory = async () => {
    try {
      setIsLoading(true);
      const result = await getPagingPaymentHistory(
        pageIndex,
        pageSize,
        searchValue
      );

      setPayments(result.data?.data || []);
      setTotalDoc(result.data?.totalItem || 0);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllPaymentHistory();
  }, [pageIndex, pageSize, searchValue]);

  const handleDeletePaymentHistory = async (id) => {
    try {
      setIsDeleting(true)

      const result = await deletePaymentHistory(id);

      console.log('result', result)

    } catch (error) {
      console.log(error)
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        mb="24px"
      >
        <Button
          variant="brand"
          w="200px"
          h="35px"
          fontSize="xs"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          mb={{ sm: "12px", md: "0px" }}
          onClick={() => history.push("/manager-payment-history")}
        >
          {t("common.managerPaymentHistory.create")}
        </Button>
      </Flex>

      <Card px="0px">
        <CardBody>
          <TableManagerPaymentHistory
            columns={columns}
            isLoading={isLoading}
            tableData={payments}
            totalDoc={totalDoc}
            pageIndex={pageIndex}
            pageSize={pageSize}
            search={search}
            setSearch={setSearch}
            onChangeSize={(e) => setPageSize(e)}
            onChangePage={(e) => setPageIndex(e)}
            onDelete={handleDeletePaymentHistory}
          />
        </CardBody>
      </Card>
      {/* )} */}
    </Box>
  );
}

export default ManagerPaymentHistory;
