import { Box, Button, Flex, Image, Tag, Text } from "@chakra-ui/react";
import LoadingOVH from "components/Loading/Loading";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getPaymentHistoryById } from "services/api";
import bgInvoice from "assets/img/background.png";
import moment from "moment";
import { formatMoneyVND } from "utils";
import logo from "../../assets/img/logo.png";
import { convertToUsd } from "utils/convertToUsd";
import { getLanguage } from "utils";

function PaymentHistoryDetail() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentHistory, setPaymentHistory] = useState({});
  const history = useHistory();

  const getPaymentHistory = async (id) => {
    try {
      setIsLoading(true);
      const result = await getPaymentHistoryById(id);

      const { data } = result.data;

      setPaymentHistory(data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPaymentHistory(id);
    }
  }, [id]);

  return (
    <Box direction="column" pt={{ sm: "125px", md: "75px" }}>
      {isLoading ? (
        <LoadingOVH />
      ) : (
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          bg="white"
          py="20px"
          height={"auto"}
        >
          <Box
            border={"1px solid"}
            borderColor={"gray.200"}
            width={"800px"}
            minWidth={"800px"}
            minHeight={"900px"}
            px="60px"
            py="70px"
            bgImage={bgInvoice}
            bgSize={"100% 100%"}
            bgRepeat={"no-repeat"}
            bgBlendMode={""}
          >
            <Image
              boxSize="30px 30px"
              objectFit="cover"
              src={logo}
              alt="Dan Abramov"
            />
            {/* <Text
            color={"white"}
            fontWeight={"500"}
            fontSize={"40px"}
            lineHeight={"1"}
          >
            INVOICE
          </Text>
          <Text
            color={"green.200"}
            fontWeight={"600"}
            fontSize={"50px"}
            lineHeight={"1"}
          >
            VNGCLOUD
          </Text> */}

            <Flex justifyContent={"space-between"}>
              <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
                <Box>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    BILL
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    DATE OF INVOICE
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    PAYMENT NEEDED DATE
                  </Text>
                </Box>

                <Box textAlign={"right"}>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    -
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    {moment(new Date(paymentHistory?.dateOfInvoice)).format(
                      "DD-MM-YYYY"
                    )}
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    {moment(new Date(paymentHistory?.paymentNeededDate)).format(
                      "DD-MM-YYYY"
                    )}
                  </Text>
                </Box>
              </Flex>

              <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
                <Box>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    PAYMENT METHOD
                  </Text>

                  <Tag
                    color={"white"}
                    bg={"green.500"}
                    mt="1"
                    size="md"
                    pb={2}
                    pt={2.5}
                    lineHeight={"1"}
                  >
                    PAID
                  </Tag>
                </Box>

                <Box textAlign={"right"}>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    TRANSFER
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  ></Text>
                </Box>
              </Flex>
            </Flex>

            {/* table item */}

            <>
              <Flex mt="16" py={4} px={6} bg={"white"}>
                <Text fontWeight={"600"} fontSize={"16px"}>
                  INVOICE CONTENT
                </Text>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                bg="rgba(135, 245, 219, 0.4)"
                py={4}
                px={6}
              >
                <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                  INVOICE DETAIL
                </Text>
                <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                  {/* #{invoice[0]?.number?.replace("GOF", "VNG")} */}
                </Text>
              </Flex>
              {paymentHistory?.services?.map((item, index) => (
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  bg="rgba(255, 255, 255, 0.4)"
                  py={3}
                  px={6}
                >
                  <Box
                    display={"flex"}
                    color={"black"}
                    fontSize={"13px"}
                    fontWeight={"600"}
                  >
                    {item?.name}
                  </Box>
                  <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                    {convertToUsd(item?.price, getLanguage()) || ""}
                  </Text>
                  <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                    {item?.ip || ""}
                  </Text>
                </Flex>
              ))}
              {/* {Object.keys(groupItems).map((key, index) => (
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  bg="rgba(135, 245, 219, 0.4)"
                  py={3}
                  px={6}
                >
                  <Text te color={"black"} fontSize={"13px"} fontWeight={"600"}>
                    CLOUD VPS {""}{" "}
                    {products[key]?.[0]?.CPU?.split(" ")[0] || ""}-
                    {parseInt(products[key]?.[0]?.Ram?.match(/\d+/)[0])}
                  </Text>
                  <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                    {convertToUsd(
                      groupItems[key]?.[0]?.service?.total * 1,
                      getLanguage()
                    )}{" "}
                    x {groupItems[key]?.length} ={" "}
                    {convertToUsd(
                      groupItems[key]?.[0]?.service?.total *
                        groupItems[key]?.length,
                      getLanguage()
                    )}
                  </Text>
                </Flex>
              ))} */}
            </>

            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              bg="linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6)"
              py={4}
              px={6}
              textColor={"black"}
            >
              <Text fontSize={"15px"} fontWeight={"600"}>
                TOTAL
              </Text>

              <Text
                width={"150px"}
                textAlign={"right"}
                fontSize={"15px"}
                fontWeight={"600"}
              >
                {convertToUsd(paymentHistory?.total, getLanguage())}
              </Text>
            </Flex>

            <Box mt="8" textAlign={"right"}>
              <Text fontWeight={"600"} fontSize={"18px"}>
                VNG CLOUD TECHNOLOGY COMPANY LIMITED
              </Text>
              <Text fontWeight={"500"} fontSize={"13px"}>
                698/1/12 Truong Chinh Street, Ward 15, Tan Binh District, Ho Chi
                Minh City, Vietnam.
              </Text>
            </Box>
          </Box>

          <Flex
            mt="1rem"
            width={"800px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button
              w="125px"
              h="35px"
              bg={"red.400"}
              color={"white"}
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={() => {
                history.push("/payment-history");
              }}
            >
              Back
            </Button>

            {/* <Button
              variant="brand"
              w="125px"
              h="35px"
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={handlePrint}
            >
              PRINT INVOICE
            </Button> */}
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

export default PaymentHistoryDetail;
