import * as API from "services/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: JSON.parse(localStorage.getItem("user") || "null"),
    surplus: 0,
    balance: 0,
    role: "",
    lang: localStorage.getItem("app_lang") || "vi"
  },
  reducers: {
    updateUser: (state, { payload }) => {
      if (payload) {
        const {
          firstname,
          id,
          client_id,
          lastname,
          email,
          status,
          phonenumber,
          codeId,
          role,
          _2fa,
          isHasSecret,
          apiTokenTelegram
        } = payload;
        console.log(payload)
        state.user = {
          firstname,
          id,
          client_id,
          lastname,
          email,
          status,
          phonenumber,
          codeId,
          role,
          _2fa,
          isHasSecret,
          apiTokenTelegram
        };
        localStorage.setItem(
          "user",
          JSON.stringify({
            firstname,
            id,
            client_id,
            lastname,
            email,
            status,
            phonenumber,
            codeId,
            role,
            _2fa,
            isHasSecret,
            apiTokenTelegram
          })
        );
      } else {
        state.user = null;
        localStorage.removeItem("user");
      }
    },
    updateLang: (state, { payload }) => {
      state.lang = payload;
    }
  },
  extraReducers: (builders) =>
    builders
      .addCase(getUserCredit.fulfilled, (state, { payload }) => {
        state.surplus = Number(payload?.data?.details?.acc_credit || 0);
        state.balance = Number(payload?.data?.details?.acc_balance || 0);
      })
      .addCase(getUserCredit.rejected, (state, { payload }) => {
        state.surplus = 0;
        state.balance = 0;
      })
      .addCase(updateUserById.fulfilled, (state, { payload }) => {
        const {
          firstname,
          id,
          client_id,
          lastname,
          email,
          status,
          countryname,
          phonenumber,
          address1,
          role,
          codeId,
          _2fa,
          isHasSecret,
          apiTokenTelegram
        } = payload?.data;
        state.user = {
          firstname,
          id,
          client_id,
          lastname,
          email,
          status,
          countryname,
          phonenumber,
          address1,
          codeId,
          role,
          _2fa,
          isHasSecret,
          apiTokenTelegram
        };
        localStorage.setItem(
          "user",
          JSON.stringify({
            firstname,
            id,
            client_id,
            lastname,
            email,
            status,
            countryname,
            phonenumber,
            address1,
            codeId,
            role,
            _2fa,
            isHasSecret,
            apiTokenTelegram
          })
        );
      })
      .addCase(checkRole.fulfilled, (state, { payload }) => {
        state.role = payload?.data;
      }),
});

export const updateUserById = createAsyncThunk(
  "user/updateUserById",
  async (data) => {
    try {
      const result = await API.apiUpdateUserById(data);
      return result?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getUserCredit = createAsyncThunk(
  "user/getUserCredit",
  async (data) => {
    try {
      const result = await API.getUserSurplus();

      return result?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const checkRole = createAsyncThunk("user/checkRole", async (data) => {
  try {
    const result = await API.checkRole();

    return result?.data;
  } catch (error) {
    console.log(error);
  }
});

const { reducer: userReducer, actions } = userSlice;

export const { updateUser, updateLang } = actions;

export default userReducer;
