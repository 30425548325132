import {
  Box,
  Button,
  Flex,
  Image,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import { getInvoiceForDetail } from "services/api";
import { useParams, useHistory } from "react-router-dom";
import bgInvoice from "assets/img/background.png";
import moment from "moment";
import { formatMoneyVND } from "utils";
import LoadingOVH from "components/Loading/Loading";
import logo from "../../assets/img/logo.png";
import { getProductsBySubOrderPage } from "services/api";
import { convertDescriptionProductToObject } from "utils";
import { convertToUsd } from "utils/convertToUsd";

import { getProductById } from "services/api";
import { getServiceDetailForPayment } from "services/api";
import { getLanguage } from "utils";
import { getVngServer } from "services/api";
import { formatPriceVND } from "utils/format.price";
import packages from "mocks";
import { formatPriceUSD } from "utils/format.price";

const ram = [
  {
    id: 1,
    name: "8G DDR",
    price: 12,
  },
];

const backup = [
  {
    id: 1,
    name: "Không",
    price: 0,
  },
  {
    id: 2,
    name: "10GB",
    price: 3.6,
  },
  {
    id: 3,
    name: "25GB",
    price: 7.2,
  },
  {
    id: 4,
    name: "50GB",
    price: 11.6,
  },
  {
    id: 5,
    name: "100GB",
    price: 19.6,
  },
  {
    id: 6,
    name: "custom 100GB+",
    price: 0,
  },
];

const ssd = [
  {
    id: 1,
    name: "SSD 240GB",
    price: 8,
  },
  {
    id: 2,
    name: "SSD 480GB",
    price: 15.2,
  },
  {
    id: 3,
    name: "SSD 960GB",
    price: 26,
  },
  {
    id: 4,
    name: "SSD 1920GB",
    price: 48,
  },
  {
    id: 5,
    name: "SAS 600GB",
    price: 8,
  },
  {
    id: 6,
    name: "SAS 1.2TB",
    price: 16,
  },
  {
    id: 7,
    name: "SAS 1.8TB",
    price: 28,
  },
  {
    id: 8,
    name: "NVME 512GB",
    price: 24,
  },
  {
    id: 9,
    name: "NVME 960GB",
    price: 40,
  },
  {
    id: 10,
    name: "NVME 1.88TB",
    price: 60,
  },
  {
    id: 11,
    name: "NVME 1.92TB",
    price: 80,
  },
];

function VngserverInvoice() {
  const componentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lang, setLang] = useState("en");

  const [service, setService] = useState();

  const { id } = useParams();

  const history = useHistory();

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });

  const caculateBackupPrice = (selectedBackup) => {
    let price = 0;
    if (!backup.find((item) => item.name == service?.backup?.name)) {
      const backupStr = service?.backup?.split("GB")?.[0];
      price += 19.6 + Number(backupStr) * 0.18;
      return price;
    } else {
      return (
        backup.find((item) => item.name == service?.backup?.name)?.price || 0
      );
    }
  };

  const generatePricePeriod = (selectedPeriod) => {
    let price = "";
    if (selectedPeriod == "permonth") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == service?.config)?.permonth
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == service?.config)?.permonth
            );
      return price;
    }

    if (selectedPeriod == "price3") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == service?.config)?.price3
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == service?.config)?.price3
            );
      return price;
    }

    if (selectedPeriod == "price6") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == service?.config)?.price6
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == service?.config)?.price6
            );
      return price;
    }

    if (selectedPeriod == "price12") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == service?.config)?.price12
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == service?.config)?.price12
            );
      return price;
    }

    if (selectedPeriod == "price24") {
      price =
        lang == "vi"
          ? formatPriceVND(
              Number(
                packages.find((item) => item.id == service?.config)?.price24
              ) * 25000
            )
          : formatPriceUSD(
              packages.find((item) => item.id == service?.config)?.price24
            );
      return price;
    }
  };

  const caculatePricePeriod = (selectedPackage) => {
    let price = 0;
    if (selectedPeriod == "permonth") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.permonth
      );
      return price;
    }

    if (selectedPeriod == "price3") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price3
      );
      return price;
    }

    if (selectedPeriod == "price6") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price6
      );
      return price;
    }

    if (selectedPeriod == "price12") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price12
      );
      return price;
    }

    if (selectedPeriod == "price24") {
      price = Number(
        packages.find((item) => item.id == selectedPackage)?.price24
      );
      return price;
    }
  };

  const getServiceDetail = async (id) => {
    try {
      setIsLoading(true);
      const result = await getVngServer(id);

      setService(result.data);
    } catch (error) {
      console.log("getServiceDetailForPayment", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getServiceDetail(id);
  }, [id]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = () => {
    toast({
      status: "success",
      title: "In hóa đơn thành công",
    });
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: service?.config,
    handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    setLang(localStorage.getItem("app_lang") || "vi");
  }, []);

  return (
    <Box direction="column" pt={{ sm: "125px", md: "75px" }}>
      {isLoading ? (
        <LoadingOVH />
      ) : (
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          bg="white"
          py="20px"
          height={"auto"}
        >
          <Box
            ref={componentRef}
            border={"1px solid"}
            borderColor={"gray.200"}
            width={"800px"}
            minWidth={"800px"}
            minHeight={"900px"}
            px="60px"
            py="70px"
            bgImage={bgInvoice}
            bgSize={"100% 100%"}
            bgRepeat={"no-repeat"}
            bgBlendMode={""}
          >
            <Image
              boxSize="30px 30px"
              objectFit="cover"
              src={logo}
              alt="Dan Abramov"
            />
            {/* <Text
                color={"white"}
                fontWeight={"500"}
                fontSize={"40px"}
                lineHeight={"1"}
              >
                INVOICE
              </Text>
              <Text
                color={"green.200"}
                fontWeight={"600"}
                fontSize={"50px"}
                lineHeight={"1"}
              >
                VNGCLOUD
              </Text> */}

            <Flex justifyContent={"space-between"}>
              <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
                <Box>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    BILL
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    DATE OF INVOICE
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    PAYMENT NEEDED DATE
                  </Text>
                </Box>

                <Box textAlign={"right"}>
                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    {moment(service?.createdAt).format("DD/MM/YYYY")}
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  >
                    {moment(service?.createdAt).format("DD/MM/YYYY")}
                  </Text>
                </Box>
              </Flex>

              <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
                <Box>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    PAYMENT METHOD
                  </Text>

                  <Tag
                    color={"white"}
                    bg={"green.500"}
                    mt="1"
                    size="md"
                    pb={2}
                    pt={2.5}
                    lineHeight={"1"}
                  >
                    PAID
                  </Tag>
                </Box>

                <Box textAlign={"right"}>
                  <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                    TRANSFER
                  </Text>

                  <Text
                    color={"white"}
                    mt="1"
                    fontSize={"13px"}
                    fontWeight={"500"}
                  ></Text>
                </Box>
              </Flex>
            </Flex>

            {/* table item */}

            <>
              <Flex mt="16" py={4} px={6} bg={"white"}>
                <Text fontWeight={"600"} fontSize={"16px"}>
                  INVOICE CONTENT
                </Text>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                bg="rgba(135, 245, 219, 0.4)"
                py={4}
                px={6}
              >
                <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
                  INVOICE DETAIL
                </Text>
              </Flex>
            </>
            <div>
              <div className="p-2 shadow rounded-sm bg-white">
                <h2
                  style={{ borderBottom: "1px solid gray" }}
                  className="text-[17px] py-1"
                >
                  {packages.find((item) => item.name == service?.config)?.main}
                </h2>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>{"IP"}</p>
                  <p>{service?.ip}</p>
                </div>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>
                    {packages.find((item) => item.id == service?.config)?.main}
                  </p>
                  <p>{generatePricePeriod(service?.billingCycle)}</p>
                </div>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>Set Up Fee</p>
                  {console.log(lang)}
                  <p>{lang == "vi" ? "800.000đ" : "32$"}</p>
                </div>
                {service?.premiumSupport && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>Premium Support</p>
                    <p>{lang == "vi" ? "2.000.000đ" : "80$"}</p>
                  </div>
                )}
                {service?.additionalRam?.quantity > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{service?.additionalRam?.quantity} - 8G DDR</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(
                            service?.additionalRam?.quantity * 12 * 25000
                          )
                        : formatPriceUSD(service?.additionalRam?.quantity * 12)}
                    </p>
                  </div>
                )}
                {service?.additionalDisk?.quantity > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>
                      {service?.additionalDisk?.quantity} -{" "}
                      {
                        ssd.find(
                          (item) =>
                            item.name == service?.additionalDisk?.diskType
                        )?.name
                      }
                    </p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(
                            service?.additionalDisk?.quantity *
                              Number(
                                ssd.find(
                                  (item) =>
                                    item.name ==
                                    service?.additionalDisk?.diskType
                                )?.price
                              ) *
                              25000
                          )
                        : formatPriceUSD(
                            service?.additionalDisk?.quantity *
                              Number(
                                ssd.find(
                                  (item) =>
                                    item.name ==
                                    service?.additionalDisk?.diskType
                                )?.price
                              )
                          )}
                    </p>
                  </div>
                )}
                {service?.nationalBandwith > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{service?.nationalBandwith} - Bandwith VN</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(48 * service?.nationalBandwith * 25000)
                        : formatPriceUSD(48 * service?.nationalBandwith)}
                    </p>
                  </div>
                )}
                {service?.internationalBandwith > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>
                      {service?.internationalBandwith} - Bandwith International
                    </p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(
                            160 * service?.internationalBandwith * 25000
                          )
                        : formatPriceUSD(160 * service?.internationalBandwith)}
                    </p>
                  </div>
                )}
                {service?.additionalIpv4 > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{service?.additionalIpv4} - IPV4</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(4 * service?.additionalIpv4 * 25000)
                        : formatPriceUSD(4 * service?.additionalIpv4)}
                    </p>
                  </div>
                )}
                {service?.additionalIpv6 > 0 && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>{service?.ipv6} - IPV6</p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(1.2 * service?.additionalIpv6 * 25000)
                        : formatPriceUSD(1.2 * service?.additionalIpv6)}
                    </p>
                  </div>
                )}
                {service?.backup && (
                  <div className="text-[12px] mt-2 flex justify-between">
                    <p>
                      Backup{" "}
                      {backup.find((item) => item.id == service?.backup)
                        ? backup.find((item) => item.id == service?.backup)
                            ?.name
                        : `${service?.backup}`}
                    </p>
                    <p>
                      {lang == "vi"
                        ? formatPriceVND(caculateBackupPrice() * 25000)
                        : formatPriceUSD(caculateBackupPrice())}
                    </p>
                  </div>
                )}

                <div className="text-[12px] mt-2 flex justify-between">
                  <p>Summary</p>
                  <p>{convertToUsd((service?.price / 110) * 100, lang)}</p>
                </div>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>10% VAT</p>
                  <p>{convertToUsd((service?.price / 110) * 10, lang)}</p>
                </div>
                <div className="text-[12px] mt-2 flex justify-between">
                  <p>Total</p>
                  <p>{convertToUsd(service?.price, lang)}</p>
                </div>
              </div>
            </div>
            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              bg="linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6)"
              py={4}
              px={6}
              textColor={"black"}
            >
              <Text fontSize={"15px"} fontWeight={"600"}>
                TOTAL
              </Text>

              <Text
                width={"150px"}
                textAlign={"right"}
                fontSize={"15px"}
                fontWeight={"600"}
              >
                {convertToUsd(service?.price, getLanguage())}
              </Text>
            </Flex>

            <Box mt="8" textAlign={"right"}>
              <Text fontWeight={"600"} fontSize={"18px"}>
                VNG CLOUD TECHNOLOGY COMPANY LIMITED
              </Text>
              <Text fontWeight={"500"} fontSize={"13px"}>
                698/1/12 Truong Chinh Street, Ward 15, Tan Binh District, Ho Chi
                Minh City, Vietnam.
              </Text>
            </Box>
          </Box>

          <Flex
            mt="1rem"
            width={"800px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button
              w="125px"
              h="35px"
              bg={"red.400"}
              color={"white"}
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={() => {
                history.push("/vng-server/list");
              }}
            >
              Back
            </Button>

            <Button
              variant="brand"
              w="125px"
              h="35px"
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "12px", md: "0px" }}
              onClick={handlePrint}
            >
              PRINT INVOICE
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

export default VngserverInvoice;
