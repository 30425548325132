import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Link,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import LoadingOVH from "components/Loading/Loading";
import React from "react";
import { useState } from "react";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import almalinux from "assets/img/ovh/almalinux.svg";
import OsTemplate from "containers/CloudServiceCreate/OsTemplate";
import { CYCLE_TIME } from "utils";
import CloudServerProduct from "containers/CloudServiceCreate/CloudServerProduct";
import { InfoIcon } from "@chakra-ui/icons";
import { convertMoney } from "utils";
import { getProductDetailForConfig } from "services/api";
import { getProductsBySubOrderPage } from "services/api";
import { useEffect } from "react";
import CloudServerCustomProduct from "containers/CloudServiceCreate/CloudServerCustomProduct";
import { useMemo } from "react";
import SlideConfigCloudService from "containers/CloudServiceCreate/SlideConfigCloudService";
import { genMarkSlider } from "utils";
import { getProductDiscount } from "services/api";
import { RESPONSE_STATUS } from "utils";
import { convertObjectToQuery } from "utils";
import { createNewCloudService } from "services/api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import ServiceCreate from "views/Services/ServiceCreate";
import { convertToUsd } from "utils/convertToUsd";
import { useTranslation } from "react-i18next";
import { getLanguage } from "utils";

const osTemplate = [
  {
    id: 1,
    name: "Windows",
    image: <img src={window} alt="" width={48} height={48} />,
    template: [
      {
        id: "36",
        idCustom: "302 win2019",
        name: "Windows Server 2019",
        subOrderPage: 1,
      },
      {
        id: "293",
        idCustom: "302 win2022",
        name: "Windows Server 2022",
        subOrderPage: 1,
      },
      {
        id: "634",
        idCustom: "302 win2022",
        name: "Windows 10",
        subOrderPage: 1,
      },
    ],
  },
  {
    id: 2,
    name: "Ubuntu",
    image: <img src={ubuntu} alt="" width={48} height={48} />,
    template: [
      {
        id: "120",
        idCustom: "256 ubuntu22.tino.org",
        name: "Ubuntu 22",
        subOrderPage: 2,
      },
      {
        id: "118",
        idCustom: "256 ubuntu20.tino.org",
        name: "Ubuntu 20",
        subOrderPage: 2,
      },
      {
        id: "283",
        idCustom: "256 ubuntu18.tino.org",
        name: "Ubuntu 18",
        subOrderPage: 2,
      },
    ],
  },
  {
    id: 3,
    name: "Centos",
    image: <img src={centos} alt="" width={48} height={48} />,
    template: [
      {
        id: "122",
        idCustom: "308 centos7",
        name: "Centos 7",
        subOrderPage: 2,
      },
    ],
  },
  {
    id: 4,
    name: "Debian",
    image: (
      <img
        src={debian}
        alt=""
        width={48}
        height={"48px"}
        style={{ height: "48px" }}
      />
    ),
    template: [
      {
        id: "121",
        idCustom: "306 debian11.tino.org",
        name: "Debian 11",
        subOrderPage: 2,
      },
    ],
  },
  {
    id: 5,
    name: "Almalinux",
    image: <img src={almalinux} alt="" width={48} height={"48px"} />,
    template: [
      {
        id: "123",
        idCustom: "309 almalinux8",
        name: "Almalinux8",
        subOrderPage: 2,
      },
    ],
  },
];

const PRODUCT_ID = "30";

const ORDER_PAGE_ID = {
  CLOUDVN: "31",
  CUSTOM: "32",
};

const paymentMethods = [
  {
    object_id: 13,
    name: "ACB - Ngân hàng TMCP Á Châu",
    image: "https://cdn.tgdd.vn/2020/04/GameApp/unnamed-200x200-18.png",
  },
  // {
  //     object_id: 14,
  //     name: 'Cổng thanh toán VNPAY',
  //     image: 'https://vnpay.vn/_nuxt/img/logo-primary.55e9c8c.svg',
  // },
  // {
  //     object_id: 15,
  //     name: 'Ví điện tử MoMo',
  //     image: 'https://cdn.tgdd.vn/2020/03/GameApp/Untitled-2-200x200.jpg',
  // },
];

function CloudServiceCreate() {
  const { t } = useTranslation();

  const cycleTime = [
    {
      id: CYCLE_TIME.MONTHLY,
      name: t("common.service.pricePerMonth"),
      extra: "month",
    },
    {
      id: CYCLE_TIME.QUARTERLY,
      name: t("common.service.pricePer3Month"),
      extra: "3 month",
    },
    {
      id: CYCLE_TIME.SEMI_ANNUALLY,
      name: t("common.service.pricePer6Month"),
      extra: "6 month",
    },
    {
      id: CYCLE_TIME.ANNUALLY,
      name: t("common.service.pricePerYear"),
      extra: "1 year",
    },
  ];
  const [value, setValue] = useState(1);
  const handleChange = (event) => setValue(Number(event.target.value));
  const handlePrev = () => setValue((prev) => (prev <= 1 ? 1 : prev - 1));
  const handleNext = () => setValue((prev) => prev + 1);
  const [isCloudVN, setIsCloud] = useState(true);
  const { user } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [chosenOsTemplate, setChosenOsTemplate] = useState(
    osTemplate[0].template[0]
  );
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);

  const [toggle, setToggle] = useState(0);
  const [isCustomServer, setIsCustomServer] = useState(false);
  const [unit, setUnit] = useState(cycleTime[0]);
  const [isConfirm, setIsConfirm] = useState(false);

  const [products, setProducts] = useState([]);

  const [chosenProduct, setChosenProduct] = useState({});

  const [CPU, setCPU] = useState(1);
  const [RAM, setRAM] = useState(1);
  const [DISK, setDISK] = useState(1);
  const [config, setConfig] = useState({
    ram: {},
    cpu: {},
    disk: {},
    cycle: {
      id: "cycle",
    },
    os: [],
  });

  // For check setup fee
  const [items, setItems] = useState([]);

  //promotion
  const [promotion, setPromotion] = useState({
    //code temp
    temp: "",
    code: "",
    discount: 0,
  });

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });
  const history = useHistory();
  const [isGettingProductForConfig, setIsGettingProductForConfig] = useState(
    true
  );

  const getProductForConfig = async (id, e) => {
    try {
      setIsGettingProductForConfig(true);
      const result = await getProductDetailForConfig((id ||= PRODUCT_ID));

      const { data } = result?.data;

      const { forms, product } = data?.config;

      const cycle = product.find((item) => item?.id === "cycle");
      const ram = forms.find((item) => item?.title.includes("RAM"));
      const cpu = forms.find((item) => item?.title.includes("CPU"));
      const disk = forms.find((item) => item?.title.includes("Disk"));

      const os = forms.find((item) => item?.title.includes("OS"));

      setRAM(Number(ram?.config?.minvalue || 0));
      setCPU(Number(cpu?.config?.minvalue || 0));
      setDISK(Number(disk?.config?.minvalue || 0));

      setConfig({
        cycle: {
          id: "cycle",
          ...cycle?.items[0],
        },
        ram: {
          name: `custom[${ram?.id}][${ram?.items[0].id}]`,
          title: ram?.title,
          price: Number(ram?.items[0]?.price || 0),
          minValue: Number(ram?.config?.minvalue),
          maxValue: Number(ram?.config?.maxvalue),
          step: Number(ram?.config?.step),
        },
        cpu: {
          name: `custom[${cpu?.id}][${cpu?.items[0].id}]`,
          title: cpu?.title,
          price: Number(cpu?.items[0]?.price || 0),
          minValue: Number(cpu?.config?.minvalue),
          maxValue: Number(cpu?.config?.maxvalue),
          step: Number(cpu?.config?.step),
        },
        disk: {
          name: `custom[${disk?.id}][${disk?.items[0].id}]`,
          title: disk?.title,
          price: Number(disk?.items[0]?.price || 0),
          minValue: Number(disk?.config?.minvalue),
          maxValue: Number(disk?.config?.maxvalue),
          step: Number(disk?.config?.step),
        },
        os: os?.items,
      });
      setItems(cycle?.items);
      e && setChosenProduct(e);
    } catch (error) {
      console.log("ProductForConfig", error);
    } finally {
      setIsGettingProductForConfig(false);
    }
  };

  useEffect(() => {
    const getProductByOs = async (id) => {
      try {
        const result = await getProductsBySubOrderPage(id);

        const { data } = result?.data;

        setProducts(data || []);
        if (data?.length > 0) {
          getProductForConfig(data?.[0]?.object_id, data?.[2]);
          // setChosenProduct( || {})
        }
      } catch (error) {
        console.log("getProductsBySubOrderPage", error);
      }
    };

    if (isCustomServer) getProductByOs(ORDER_PAGE_ID.CUSTOM);
    else getProductByOs(ORDER_PAGE_ID.CLOUDVN);
  }, [isCustomServer]);

  const handleChooseProduct = (item) => {
    getProductForConfig(item?.object_id, item);
    // setChosenProduct(item)

    setPromotion({
      code: "",
      discount: 0,
      temp: "",
    });
  };

  const onChangeConfig = (value, variable) => {
    switch (variable) {
      case "cpu":
        setCPU(value);
        break;

      case "ram":
        setRAM(value);
        break;

      case "disk":
        setDISK(value);
        break;
    }
  };

  const cPrice = useMemo(() => {
    let total = 0;
    let fee = 0;

    if (isCustomServer) {
      Object.entries(config).forEach((item) => {
        const [attr, value] = item;
        if (attr === "cycle") {
          total += value?.price;
        } else if (attr === "ram") {
          total += value?.price * (RAM || 0);
        } else if (attr === "cpu") {
          total += value?.price * (CPU || 0);
        } else if (attr === "disk") {
          total += value?.price * (DISK || 0);
        }
      });

      return total;
    }

    fee = items?.find((item) => item?.value === unit?.id)?.setup || 0;
    // return chosenProduct[unit.id] + fee;
    return chosenProduct[unit.id] * value + fee;
  }, [unit, chosenProduct, CPU, RAM, DISK, value]);

  const isShowPromotionCode = () => {
    const objectIds = [32, 33, 34, 35];
    const cycles = [CYCLE_TIME.MONTHLY];

    if (
      [
        CYCLE_TIME.QUARTERLY,
        CYCLE_TIME.ANNUALLY,
        CYCLE_TIME.SEMI_ANNUALLY,
      ].includes(unit.id)
    )
      return true;

    return (
      objectIds.includes(chosenProduct?.object_id) && cycles.includes(unit.id)
    );
  };

  const getDiscount = async () => {
    try {
      const data = {
        id: chosenProduct?.object_id,
        query: convertObjectToQuery({
          promocode: promotion?.temp,
          cycle: unit?.id,
        }),
      };

      const result = await getProductDiscount(data);

      const { status, data: res, message } = result?.data;

      if (status === RESPONSE_STATUS.FAILED) {
        toast({
          title: message,
          status: "error",
        });
        return;
      }

      setPromotion((prevState) => ({
        ...prevState,
        code: prevState?.temp,
        discount: res?.discount || 0,
      }));
    } catch (error) {}
  };

  const onFinish = async () => {
    try {
      setIsCreating(true);
      const domain =
        user?.firstname + user?.lastname + moment().format("mmDMYY");
      // const listData = chosenProduct.map((item) => ({
      //   cycle: unit.id,
      //   domain: domain.toLowerCase(),
      //   // domain: 'testdungxoanhaacuong8102023',
      //   pay_method: paymentMethod?.object_id,
      //   product_id: item?.object_id,
      //   os: chosenOsTemplate?.name,
      //   type: "instance",
      //   promocode: promotion?.code || "",
      // }));
      let data = {
        cycle: unit.id,
        domain: domain.toLowerCase(),
        // domain: 'testdungxoanhaacuong8102023',
        quanlity: value,
        pay_method: paymentMethod?.object_id,
        product_id: chosenProduct?.object_id,
        os: chosenOsTemplate?.name,
        type: "instance",
        promocode: promotion?.code || "",
      };

      if (isCustomServer) {
        const dataCustom = [];

        Object.entries(config).forEach((item) => {
          const [attr, value] = item;
          if (attr === "ram" && value?.title) {
            dataCustom.push(`${value?.name}: ${RAM}`);
          } else if (attr === "cpu" && value?.title) {
            dataCustom.push(`${value?.name}: ${CPU}`);
          } else if (attr === "disk" && value?.title) {
            dataCustom.push(`${value?.name}: ${DISK}`);
          }
        });

        data = {
          ...data,
          product_id: chosenProduct?.object_id,
          os: chosenOsTemplate?.name,
          cycle: cPrice === 0 ? "Free" : "m",
          type: "custom",
          dataCustom,
        };
      }

      const result = await createNewCloudService(data);

      localStorage.setItem("cloudService", JSON.stringify(result?.data?.data));

      history.push({
        pathname: "/deposit/paypal",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreating(false);
    }
  };

  const renderCustomServer = {
    custom: (
      <Grid templateColumns={"repeat(3, 1fr)"} mt="4" gap="5">
        <GridItem colSpan={"2"}>
          <SlideConfigCloudService
            title={config?.cpu?.title}
            value={CPU}
            min={config?.cpu?.minValue}
            max={config?.cpu?.maxValue}
            step={config?.cpu?.step}
            onChange={(e) => onChangeConfig(e, "cpu")}
            marks={genMarkSlider(config?.cpu)}
          />

          <SlideConfigCloudService
            title={config?.ram?.title}
            value={RAM}
            min={config?.ram?.minValue}
            max={config?.ram?.maxValue}
            step={config?.ram?.step}
            onChange={(e) => onChangeConfig(e, "ram")}
            marks={{
              1: 1,
              2: 4,
              3: 8,
              4: 12,
              5: 16,
              6: 20,
              7: 24,
              8: 28,
              9: 32,
            }}
          />

          <SlideConfigCloudService
            title={config?.disk?.title}
            value={DISK}
            min={config?.disk?.minValue}
            max={config?.disk?.maxValue}
            step={config?.disk?.step}
            onChange={(e) => onChangeConfig(e, "disk")}
            marks={{
              1: 20,
              2: 50,
              3: 100,
              4: 150,
              5: 200,
              6: 250,
              7: 300,
              8: 350,
              9: 400,
              10: 450,
              11: 500,
            }}
          />
        </GridItem>

        <CloudServerCustomProduct
          ram={RAM}
          cpu={CPU}
          disk={DISK}
          unit={unit?.id}
          price={cPrice}
        />
      </Grid>
    ),
    notCustom: (
      <Box mt="4">
        <Flex gap={3}>
          {cycleTime.map((item) => (
            <Button
              bg={item.id === unit?.id ? "brand.200" : "white"}
              color={item.id === unit?.id ? "white" : "brand.200"}
              fontSize={"sm"}
              lineHeight={1}
              _hover={{
                bg: item.id === unit?.id ? "brand.400" : "white",
              }}
              onClick={() => setUnit(item)}
            >
              {item.name}
            </Button>
          ))}
        </Flex>

        <Grid
          my={4}
          templateColumns={{
            lg: "repeat(4, 1fr)",
            md: "repeat(4, 1fr)",
            sm: "repeat(3, 1fr)",
          }}
          gap={[1, 4]}
        >
          {products
            ?.filter((item) => ![1, 7].includes(item.object_id))
            ?.map((item) => (
              <CloudServerProduct
                isChosen={item.object_id === chosenProduct?.object_id}
                unit={unit?.id}
                data={item}
                key={item}
                onClick={handleChooseProduct}
                isGettingProductForConfig={isGettingProductForConfig}
              />
            ))}
        </Grid>
      </Box>
    ),
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
      {isLoading ? (
        <LoadingOVH />
      ) : (
        <>
          <Flex align="center" justify="start" gap={5} mb={5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bg={isCloudVN ? "#582CFF" : "#0F1535"}
              textColor={"white"}
              rounded={"md"}
              p={2}
              cursor={"pointer"}
              onClick={() => setIsCloud(true)}
            >
              {t("common.button.cloudvn")}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bg={!isCloudVN ? "#582CFF" : "#0F1535"}
              textColor={"white"}
              rounded={"md"}
              p={2}
              cursor={"pointer"}
              onClick={() => setIsCloud(false)}
            >
              {t("common.button.cloudInternational")}
            </Box>
          </Flex>
          {isCloudVN ? (
            <Box>
              {/* <Text color={"white"} fontSize="1.5rem" mb="1rem" fontWeight={"600"}>
              Tạo cloud service
            </Text> */}

              <Card mt="8">
                {/* Chon he dieu hanh */}
                <Text color={"white"} fontWeight={"600"} fontSize={"22px"}>
                  {t("common.service.chooseOperatingSystem")}
                </Text>
                <Grid templateColumns={"repeat(4, 1fr)"} gap={[3, 5]} mt={4}>
                  {osTemplate?.map((item) => (
                    <OsTemplate
                      onShow={() => setToggle(item?.id)}
                      onChosen={(e) => {
                        setChosenOsTemplate(e);
                        setToggle(0);
                      }}
                      isShow={Boolean(item.id === toggle)}
                      isChosen={Boolean(
                        item?.template.find(
                          (e) => e.id === chosenOsTemplate?.id
                        )
                      )}
                      chosenOsTemplate={chosenOsTemplate}
                      item={item}
                      key={item.id}
                    />
                  ))}
                </Grid>

                {/* cau hinh  */}
                <Flex mt={12} gap={3} alignItems={"center"}>
                  <Text color={"white"} fontWeight={"600"} fontSize={"22px"}>
                    {isCustomServer
                      ? t("common.service.customConfiguration")
                      : t("common.service.availableConfiguration")}
                  </Text>

                  <Button
                    variant="brand"
                    h="40px"
                    fontSize="sm"
                    fontWeight="bold"
                    alignSelf={{ sm: "flex-start", lg: null }}
                    onClick={() => setIsCustomServer(!isCustomServer)}
                  >
                    {t("common.service.customConfiguration")}
                  </Button>
                </Flex>
                {renderCustomServer[isCustomServer ? "custom" : "notCustom"]}

                {/* su dung ma khuyen mai */}
                {isShowPromotionCode() && (
                  <Text
                    color={"white"}
                    mt={12}
                    fontWeight={"600"}
                    fontSize={"22px"}
                  >
                    USE PROMO CODE
                  </Text>
                )}

                {isShowPromotionCode() && promotion.discount ? (
                  <>
                    <Text fontSize="16px" color={"white"}>
                      Discount code: {promotion.code}
                    </Text>
                    <Text color={"white"} fontSize="16px" fontWeight={"600"}>
                      - {convertMoney(promotion?.discount || 0)} ₫
                    </Text>
                    <Button
                      onClick={() =>
                        setPromotion({
                          code: "",
                          discount: 0,
                          temp: "",
                        })
                      }
                      bg={"red.400"}
                      color={"white"}
                      h={"40px"}
                      fontSize={"sm"}
                      w="fit-content"
                      mt="2"
                      _hover={{
                        bg: "red.500",
                      }}
                    >
                      Remove promotional code
                    </Button>
                  </>
                ) : isShowPromotionCode() ? (
                  <>
                    <Input
                      color="gray.400"
                      bg="#0F1535"
                      border="0.5px solid"
                      borderColor="#E2E8F04D"
                      borderRadius="15px"
                      type="text"
                      mt="1"
                      placeholder="Enter discount code"
                      fontSize="md"
                      value={promotion.temp}
                      onChange={(e) =>
                        setPromotion({
                          ...promotion,
                          temp: e?.target?.value,
                        })
                      }
                    />
                    <Button
                      bg={"brand.200"}
                      color={"white"}
                      h={"40px"}
                      fontSize={"sm"}
                      w="fit-content"
                      mt="2"
                      _hover={{
                        bg: "brand.400",
                      }}
                      onClick={getDiscount}
                    >
                      Apply
                    </Button>
                  </>
                ) : null}

                {/* Chon he dieu hanh */}
                {/* <Text
                  color={"white"}
                  mt={12}
                  fontWeight={"600"}
                  fontSize={"22px"}
                >
                  QUY ĐỊNH SỬ DỤNG DỊCH VỤ
                </Text> */}
                <Box display={"flex"} alignItems={"center"} gap={"1"}>
                  <Button
                    bgColor={"#582CFF"}
                    _hover={"#582CFF"}
                    color={"white"}
                    isDisabled={value <= 1}
                    size="sm"
                    onClick={handlePrev}
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    value={value}
                    onChange={handleChange}
                    size="sm"
                    w="50px"
                    min={1}
                    color={"#fff"}
                    borderRadius={"5px"}
                  />
                  <Button
                    bg={"#582CFF"}
                    color={"white"}
                    _hover={"#582CFF"}
                    size="sm"
                    onClick={handleNext}
                  >
                    +
                  </Button>
                </Box>
                <Flex my="4" gap="1">
                  <Checkbox
                    color={"white"}
                    colorScheme="purple"
                    onChange={() => setIsConfirm(!isConfirm)}
                    isChecked={isConfirm}
                  >
                    {t("common.service.agree")}
                  </Checkbox>

                  <Link
                    color={"brand.100"}
                    fontWeight={"500"}
                    href={"https://vngcloud.com/policy/payment-policy"}
                    target="_blank"
                  >
                    {t("common.service.termOfService")}
                  </Link>
                </Flex>

                <Flex
                  bg="white"
                  p="4"
                  left={0}
                  position={"sticky"}
                  bottom={0}
                  borderRadius={"12px"}
                  boxShadow={"0 8px 16px #0a0e1d0a, 0 8px 64px #0a0e1d14"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Flex alignItems={"center"} gap={2}>
                      <Text fontWeight={"500"}>Cycle payment :</Text>
                      <Icon as={InfoIcon} color={"blue.400"} />
                    </Flex>

                    <Text
                      mt="1"
                      fontWeight={"600"}
                      color={"brand.200"}
                      fontSize={"30px"}
                    >
                      {convertToUsd(Number(cPrice ? cPrice : 0), getLanguage())}
                      <Text fontWeight={"500"} fontSize={"24px"} as={"span"}>
                        /{isCustomServer ? "m" : unit?.extra}
                      </Text>
                    </Text>
                  </Box>

                  <Button
                    _hover={{
                      bg: "brand.400",
                    }}
                    px="8"
                    py="4"
                    bg="brand.200"
                    color="white"
                    isDisabled={!isConfirm}
                    isLoading={isCreating}
                    onClick={onFinish}
                  >
                    {t("common.service.initialize")}
                  </Button>
                </Flex>
              </Card>
            </Box>
          ) : (
            <ServiceCreate />
          )}
        </>
      )}
    </Flex>
  );
}

export default CloudServiceCreate;
