import React, { Fragment, useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Switch,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import Globe from "components/Globe/Globe";
import IconBox from "components/Icons/IconBox";
import {
  barChartDataDashboard,
  barChartOptionsDashboard,
  lineChartDataDashboard,
  lineChartOptionsDashboard,
} from "variables/charts";

// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import { getDashboardOvh } from "services/api";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import fedora from "assets/img/ovh/fedora.svg";

import almalinux from "assets/img/ovh/almalinux.svg";
import cpanel from "assets/img/ovh/cpanel.svg";
import docker from "assets/img/ovh/docker.svg";
import rocky_linux from "assets/img/ovh/rocky_linux.svg";
import plesk_debian from "assets/img/ovh/plesk_debian.svg";
import { SERVICE_OVH_STATUS } from "utils";
import LoadingOVH from "components/Loading/Loading";
import InputText from "components/Input/Input";
import { BiUserCircle } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateUser } from "redux/slices/userSlice";
import { updateUserById } from "redux/slices/userSlice";
import { getUserQrCode } from "services/api";
import { IoMdCopy } from "react-icons/io";
import googleauthenticator from "assets/img/googleauthenticator.png";
import microsoftauthenticator from "assets/img/microsoftauthenticator.png";
import twilio from "assets/img/twilio.png";
import { verify2FaToken } from "services/api";
import { useTranslation } from "react-i18next";

const imageTemplate = [
  {
    id: 1,
    value: "windows",
    icon: window,
  },
  {
    id: 2,
    value: "ubuntu",
    icon: ubuntu,
  },
  {
    id: 3,
    value: "centos",
    icon: centos,
  },
  {
    id: 4,
    value: "debian",
    icon: debian,
  },
  {
    id: 5,
    value: "almalinux",
    icon: almalinux,
  },
  {
    id: 6,
    value: "fedora",
    icon: fedora,
  },
  {
    id: 7,
    value: "rocky",
    icon: rocky_linux,
  },
  {
    id: 8,
    value: "docker",
    icon: docker,
  },
  {
    id: 9,
    value: "cpanel",
    icon: cpanel,
  },
  {
    id: 10,
    value: "plesk",
    icon: plesk_debian,
  },
];

export default function Profile() {
  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });
  // Chakra Color Mode
  const iconBoxInside = useColorModeValue("white", "white");
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});
  const dispatch = useDispatch();
  const { user, surplus } = useSelector((state) => state.user);
  const [showEnable2FA, setShowEnable2FA] = useState(false);
  const [isLoadingQRCode, setIsLoadingQRCode] = useState(true);
  const [qrcode, setQrcode] = useState("");
  const [secret, setSecret] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [_2fa, set_2fa] = useState(false);

  const [inputObject, setInputObject] = useState({
    codeId: "",
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    address1: "",
    countryname: "",
  });
  const handleChangeInput = (e) => {
    setInputObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitForm = async (e) => {
    // e.preventDefault();
    try {
      const result = await dispatch(
        updateUserById({ ...inputObject, _2fa })
      ).unwrap();
      console.log("result", result);
      // Dispatch was successful, show a success message here
      toast({
        status: "success",
        title: "Cập nhật thành công",
      });
    } catch (error) {
      // Dispatch failed, handle error
      console.error("Error updating user:", error);
      toast({
        status: "error",
        title: "Cập nhật thất bại",
      });
    }
  };

  const initData = async () => {
    try {
      setIsLoading(true);

      const result = await getDashboardOvh();
      setDashboard(result.data?.data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setInputObject({
        codeId: user?.codeId || "",
        firstname: user?.firstname || "",
        lastname: user?.lastname || "",
        email: user?.email || "",
        phonenumber: user?.phonenumber || "",
        address1: user?.address1 || "",
        countryname: user?.countryname || "",
      });
      set_2fa(user?._2fa);
    }
  }, [user]);

  useEffect(() => {
    initData();
  }, []);

  const getQrCode = async () => {
    try {
      setIsLoadingQRCode(true);
      const result = await getUserQrCode(user?.id);
      console.log(result?.data?.data);
      setSecret(result?.data?.data?.secret);
      setQrcode(result?.data?.data?.qrcode);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingQRCode(false);
    }
  };

  useEffect(() => {
    if (showEnable2FA) {
      getQrCode();
    }
  }, [showEnable2FA]);

  const copyCode = () => {
    navigator.clipboard
      .writeText(secret || "")
      .then(() =>
        toast({
          title: "Đã copy QRCode",
          status: "success",
        })
      )
      .catch((err) =>
        toast({
          title: err,
          status: "error",
        })
      );
  };

  const verifyToken = async () => {
    try {
      if (!token) {
        return setError("The confirmation code is empty");
      }
      setError("");

      const result = await verify2FaToken(token);
      if (result?.data?.status == 1) {
        toast({
          title: "Enable 2FA Success",
          status: "success",
        });
        dispatch(updateUser({ ...user, _2fa: true, isHasSecret: true }));
        setShowEnable2FA(false);
      } else {
        toast({
          title: "Enable 2FA Error",
          status: "error",
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Enable 2FA Success",
        status: "error",
      });
    } finally {
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <Box mt="10rem">
          <LoadingOVH />
        </Box>
      ) : (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
          <Text
            color="#fff"
            fontWeight="bold"
            fontSize="3xl"
            mb="30px"
            ps="20px"
          >
            {t("common.account.title")}
          </Text>
          <Grid
            templateColumns={{ sm: "4fr 1fr", xl: "1.2fr 1fr" }}
            gap="32px"
            maxW="100%"
            w="100%"
          >
            {/* ThreeJS Globe */}
            <Box
              minW="700px"
              h="700px"
              position="absolute"
              right="30px"
              top="14%"
              display={{ sm: "none", md: "block" }}
            >
              <Globe />
            </Box>
            {showEnable2FA ? (
              <Card>
                <CardHeader px="22px" mb="20px">
                  <Flex alignItems={"center"} gap={2}>
                    <BiUserCircle size={24} color="white" />
                    <Text color="#fff" fontSize="lg" fontWeight="bold">
                      Enable 2FA
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Box>
                    <Text color={"white"}>
                      {t("common.2fa.step")} 1: {t("common.2fa.step1")}
                    </Text>
                    <Text color={"white"}>{t("common.2fa.step1Note")}</Text>
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Flex
                        direction={"column"}
                        alignItems={"center"}
                        mt="2"
                        ml="4"
                      >
                        <Image
                          src={googleauthenticator}
                          w={"40px"}
                          h={"40px"}
                        />
                        <Text color={"white"} mt="2">
                          Google Authenticator
                        </Text>
                      </Flex>
                      <Flex direction={"column"} alignItems={"center"} mt="2">
                        <Image
                          src={microsoftauthenticator}
                          w={"40px"}
                          h={"40px"}
                        />
                        <Text color={"white"} mt={"2"}>
                          Microsoft Authenticator
                        </Text>
                      </Flex>
                      <Flex direction={"column"} alignItems={"center"} mt="2">
                        <Image src={twilio} w={"40px"} h={"40px"} />
                        <Text color={"white"} mt={"2"}>
                          Twilio Authy
                        </Text>
                      </Flex>
                    </Flex>
                    <Text color={"white"} mt="8">
                      {t("common.2fa.step")} 2: {t("common.2fa.step2")}
                    </Text>
                    <Text color={"white"}>{t("common.2fa.step2Note")}</Text>
                    <Flex
                      direction={"column"}
                      alignItems={"center"}
                      mt="4"
                      textAlign={"center"}
                    >
                      <Image src={qrcode} w={"150px"} h={"150px"} />
                      <Flex mt="2" align={"center"} color={"white"}>
                        <Text>{secret} </Text>
                        <IoMdCopy
                          onClick={() => copyCode()}
                          size={20}
                          style={{
                            verticalAlign: "-4px",
                            marginLeft: "10px",
                            border: "1px solid gray",
                            cursor: "pointer",
                            borderRadius: "4px",
                          }}
                        />
                      </Flex>
                    </Flex>
                    <Text color={"white"} mt="8">
                      {t("common.2fa.step")} 3: {t("common.2fa.step3")}
                    </Text>
                    <Text color={"white"}>{t("common.2fa.step3Note")}</Text>

                    <Input
                      color="gray.400"
                      bg="#0F1535"
                      border="0.5px solid"
                      borderColor="#E2E8F04D"
                      borderRadius="15px"
                      type="text"
                      placeholder="Mã xác nhận"
                      fontSize="sm"
                      mt="1rem"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                    />
                    {error && (
                      <Text color={"red.400"} mt="2">
                        {error}
                      </Text>
                    )}
                    <Button
                      // isLoading={isLoadingCreate}
                      variant="brand"
                      w="85px"
                      h="35px"
                      fontSize="xs"
                      fontWeight="bold"
                      alignSelf={{ sm: "flex-start", lg: null }}
                      mt="1rem"
                      onClick={verifyToken}
                    >
                      {t("common.button.confirm")}
                    </Button>
                  </Box>
                </CardBody>
              </Card>
            ) : (
              <Stack
                direction="column"
                spacing="24px"
                w="100%"
                mb="24px"
                maxW={{ sm: "315px", md: "100%" }}
                zIndex="0"
              >
                <Card px="0px">
                  <CardHeader px="22px" mb="20px">
                    <Flex alignItems={"center"} gap={2}>
                      <BiUserCircle size={24} color="white" />
                      <Text color="#fff" fontSize="lg" fontWeight="bold">
                        Profile
                      </Text>
                    </Flex>
                  </CardHeader>

                  <Box style={{ padding: "0px 22px" }}>
                    <Grid
                      templateColumns={{ sm: "4fr 1fr", xl: "1fr 1fr" }}
                      gap="20px"
                      maxW="100%"
                      w="100%"
                    >
                      <InputText
                        value={inputObject?.codeId}
                        name="code"
                        disable
                        label={t("common.account.customerCode")}
                      />
                      <InputText
                        value={inputObject?.lastname}
                        name="lastname"
                        onChangeInput={handleChangeInput}
                        label={t("common.account.lastname")}
                      />
                      <InputText
                        value={inputObject?.firstname}
                        name="firstname"
                        onChangeInput={handleChangeInput}
                        label={t("common.account.firstname")}
                      />
                      <InputText
                        value={inputObject?.email}
                        name="email"
                        onChangeInput={handleChangeInput}
                        label={t("common.account.email")}
                      />
                      <InputText
                        value={inputObject?.phonenumber}
                        name="phonenumber"
                        onChangeInput={handleChangeInput}
                        label={t("common.account.phone")}
                      />
                      <InputText
                        value={inputObject?.address1}
                        name="address1"
                        onChangeInput={handleChangeInput}
                        label={t("common.account.address")}
                      />
                      <InputText
                        value={inputObject?.countryname}
                        name="countryname"
                        onChangeInput={handleChangeInput}
                        label={t("common.account.country")}
                      />
                      {user?.isHasSecret ? (
                        <Box>
                          <Text color={"white"}>2FA</Text>
                          <Switch
                            mt="2"
                            isChecked={_2fa}
                            onChange={() => set_2fa(!_2fa)}
                          />
                        </Box>
                      ) : (
                        <Button
                          bg={"brand.400"}
                          _hover={{ bg: "brand.200" }}
                          color={"white"}
                          mt="1.5rem"
                          onClick={() => setShowEnable2FA(true)}
                        >
                          Enable 2FA
                        </Button>
                      )}
                    </Grid>
                    <Button
                      width={"100%"}
                      type="submit"
                      mt={"20px"}
                      onClick={onSubmitForm}
                      variant="brand"
                    >
                      {t("common.button.save")}
                    </Button>
                  </Box>
                </Card>
              </Stack>
            )}
          </Grid>
        </Flex>
      )}
    </>
  );
}
