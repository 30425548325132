import {
  CartIcon,
  DocumentIcon,
  HomeIcon,
  PersonIcon,
  StatsIcon,
} from "components/Icons/Icons";
import { AiFillCreditCard, AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsCloudPlusFill, BsHeadphones } from "react-icons/bs";
import { IoMdListBox } from "react-icons/io";
import { RiComputerFill } from "react-icons/ri";
import { PiMathOperationsFill } from "react-icons/pi";
import Calendar from "views/Applications/Calendar";
import DataTables from "views/Applications/DataTables";
import Kanban from "views/Applications/Kanban.js";
import Wizard from "views/Applications/Wizard.js";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import CRM from "views/Dashboard/CRM.js";
import Default from "views/Dashboard/Default.js";
import Deposit from "views/Deposit";
import OrderDetails from "views/Ecommerce/Orders/OrderDetails";
import OrderList from "views/Ecommerce/Orders/OrderList";
import EditProduct from "views/Ecommerce/Products/EditProduct";
import NewProduct from "views/Ecommerce/Products/NewProduct";
import ProductPage from "views/Ecommerce/Products/ProductPage";
import Billing from "views/Pages/Account/Billing.js";
import Invoice from "views/Pages/Account/Invoice.js";
import Settings from "views/Pages/Account/Settings.js";
import Alerts from "views/Pages/Alerts";
import Charts from "views/Pages/Charts.js";
import Pricing from "views/Pages/Pricing.js";
import Overview from "views/Pages/Profile/Overview.js";
import Projects from "views/Pages/Profile/Projects.js";
import Teams from "views/Pages/Profile/Teams.js";
import General from "views/Pages/Projects/General.js";
import Timeline from "views/Pages/Projects/Timeline.js";
import RTLPage from "views/Pages/RTLPage.js";
import SupportCreate from "views/Support/SupportCreate";
import SupportsList from "views/Support/SupportsList";
import NewUser from "views/Pages/Users/NewUser.js";
import Reports from "views/Pages/Users/Reports.js";
import Widgets from "views/Pages/Widgets.js";
import KVM from "views/Services/KVM";
import ServiceCreate from "views/Services/ServiceCreate";
import ServiceDetail from "views/Services/ServiceDetail";
import ServicesList from "views/Services/ServicesList";
import CloudServiceCreate from "views/CloudService/CloudServiceCreate";
import PaymentForCloudService from "views/CloudService/PaymentForCloudService";
import CloudServiceList from "views/CloudService/CloudServiceList";
import CloudServiceInvoiceDetail from "views/CloudService/CloudServiceInvoiceDetail";
import Profile from "views/Profile/Profile";
import Paypal from "views/Paypal";
import VNPay from "views/VNPay";
import PaymentResult from "views/VNPay/PaymentResult";
import { MdWorkHistory } from "react-icons/md";
import ActionHistory from "views/History/ActionHistory";
import PaymentHistory from "views/History/PaymentHistory";
import ManagerTicket from "views/Manager/ManagerTicket";
import ManagerTicketDetail from "views/Manager/ManagerTicketDetail";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
import Pages from "layouts/Auth.js";
import Dashboard from "layouts/Admin";
import { useEffect } from "react";
import axios from "axios";
import { setLanguage } from "utils";
import ManagerPaymentHistory from "views/Manager/ManagerPaymentHistory";
import ManagerPaymentHistoryCreate from "views/Manager/ManagerPaymentHistoryCreate";
import PaymentHistoryDetail from "views/History/PaymentHistoryDetail";
import ChangePassword from "views/Profile/ChangePassword";
import CreateVNGServer from "views/VNGServer/CreateVNGServer";
import VNGServer from "views/VNGServer";
import VngserverInvoice from "views/CloudService/VngServerInvoice";

const AppRouter = () => {
  const { t, i18n } = useTranslation();
  const managerRoutes = [
    {
      name: "Manager",
      path: "/manager",
      icon: <RiComputerFill color="inherit" />,
      authIcon: <RiComputerFill color="inherit" />,
      collapse: true,
      items: [
        {
          name: "Manager Ticket",
          path: "/manager-ticket/list",
          authIcon: <IoMdListBox />,
          component: ManagerTicket,
          layout: "/admin",
        },
        {
          name: "Payment History",
          path: "/manager-payment-history/list",
          authIcon: <IoMdListBox />,
          component: ManagerPaymentHistory,
          layout: "/admin",
        },
      ],
    },
  ];

  const dashRoutes = [
    {
      name: t("common.route.dashboard"),
      path: "/dashboard",
      icon: <HomeIcon color="inherit" />,
      authIcon: <HomeIcon color="inherit" />,
      component: Default,
      layout: "/admin",
      parentOnly: true,
    },
    {
      name: "Profile",
      path: "/profile",
      icon: <HomeIcon color="inherit" />,
      authIcon: <HomeIcon color="inherit" />,
      component: Profile,
      layout: "/admin",
      parentOnly: true,
      isHidden: true,
    },
    {
      name: "ChangePassword",
      path: "/change-password",
      icon: <HomeIcon color="inherit" />,
      authIcon: <HomeIcon color="inherit" />,
      component: ChangePassword,
      layout: "/admin",
      parentOnly: true,
      isHidden: true,
    },
    {
      name: t("common.route.cloudvps"),
      path: "/cloud-vps",
      icon: <RiComputerFill color="inherit" />,
      authIcon: <RiComputerFill color="inherit" />,
      collapse: true,
      items: [
        {
          name: t("common.route.listCloudVps"),
          path: "/cloud-services/list",
          authIcon: <IoMdListBox />,
          component: CloudServiceList,
          layout: "/admin",
        },
        {
          name: t("common.route.createCloudVps"),
          path: "/cloud-services/create",
          authIcon: <BsCloudPlusFill />,
          component: CloudServiceCreate,
          layout: "/admin",
        },
      ],
    },
    {
      name: "VNG Server",
      path: "/vng-server",
      icon: <RiComputerFill color="inherit" />,
      authIcon: <RiComputerFill color="inherit" />,
      collapse: true,
      items: [
        {
          name: t("common.route.createVNGServer"),
          path: "/vng-server/create",
          authIcon: <IoMdListBox />,
          component: CreateVNGServer,
          layout: "/admin",
        },
        {
          name: t("common.route.listVngServer"),
          path: "/vng-server/list",
          authIcon: <IoMdListBox />,
          component: VNGServer,
          layout: "/admin",
        },
      ],
    },
    {
      name: t("common.route.support"),
      path: "/supports",
      icon: <BsHeadphones color="inherit" />,
      authIcon: <BsHeadphones color="inherit" />,
      collapse: true,
      items: [
        {
          name: t("common.route.listTicket"),
          path: "/supports/list",
          authIcon: <IoMdListBox />,
          component: SupportsList,
          layout: "/admin",
        },
        {
          name: t("common.route.createTicket"),
          path: "/supports/create",
          authIcon: <AiOutlineAppstoreAdd />,
          component: SupportCreate,
          layout: "/admin",
        },
      ],
    },
    {
      name: t("common.route.payment"),
      path: "/deposit",
      icon: <AiFillCreditCard color="inherit" />,
      authIcon: <AiFillCreditCard color="inherit" />,
      collapse: true,
      items: [
        {
          name: t("common.route.banking"),
          path: "/deposit/banking",
          authIcon: <IoMdListBox />,
          component: Deposit,
          layout: "/admin",
        },
        {
          name: "Paypal",
          path: "/deposit/paypal",
          authIcon: <IoMdListBox />,
          component: Paypal,
          layout: "/admin",
        },
      ],
    },
    {
      name: t("common.route.history"),
      path: "/history",
      icon: <MdWorkHistory color="inherit" />,
      authIcon: <MdWorkHistory color="inherit" />,
      collapse: true,
      items: [
        {
          name: t("common.route.actionHistory"),
          path: "/action-history",
          authIcon: <PiMathOperationsFill />,
          component: ActionHistory,
          layout: "/admin",
        },
        {
          name: t("common.route.paymentHistory"),
          path: "/payment-history",
          authIcon: <AiFillCreditCard />,
          component: PaymentHistory,
          layout: "/admin",
        },
      ],
    },
  ];

  const notShowRoutes = [
    {
      path: "/services/detail/:id",
      component: ServiceDetail,
      layout: "/admin",
    },
    {
      path: "/vngserver/invoice/:id",
      component: VngserverInvoice,
      layout: "/admin",
    },
    {
      path: "/services/kvm/:id",
      component: KVM,
      layout: "/admin",
    },
    {
      path: "/cloud-services/payment-service",
      component: PaymentForCloudService,
      layout: "/admin",
    },
    {
      path: "/cloud-services/invoice-detail/:id",
      component: CloudServiceInvoiceDetail,
      layout: "/admin",
    },
    {
      path: "/manager-ticket/:id",
      component: ManagerTicketDetail,
      layout: "/admin",
    },
    {
      path: "/manager-payment-history",
      component: ManagerPaymentHistoryCreate,
      layout: "/admin",
    },
    {
      path: "/manager-payment-history-edit/:id",
      component: ManagerPaymentHistoryCreate,
      layout: "/admin",
    },
    {
      path: "/payment-history-detail/:id",
      component: PaymentHistoryDetail,
      layout: "/admin",
    },
    {
      path: "/deposit/vnpay-payment-result/:invoiceId/:paymentId",
      component: PaymentResult,
      layout: "/admin",
    },
  ];
  const checkIpAndSetLanguage = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      if (res.data?.country_code == "VN") {
        i18n.changeLanguage("vi");
        setLanguage("vi");
      } else {
        i18n.changeLanguage("en");
        setLanguage("en");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIpAndSetLanguage();
  }, []);

  return (
    <Switch>
      <Route path={`/auth`} component={() => <Pages routes={dashRoutes} />} />

      <Route
        path={`/`}
        component={() => (
          <Dashboard
            routes={dashRoutes}
            managerRoutes={managerRoutes}
            notShowRoutes={notShowRoutes}
          />
        )}
      />
      <Redirect from={`/`} to="/auth/sign-in" />
    </Switch>
  );
};

export default AppRouter;
