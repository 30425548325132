import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import vps from "assets/img/hosting-cao-cap.svg";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getProductById } from "services/api";
import { convertDescriptionProductToObject } from "utils";
import { convertToUsd } from "utils/convertToUsd";
import { createOrderApi } from "services/api";
import { approveOrderApi } from "services/api";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { convertMoney } from "utils";
import { paymentPaypalApi } from "services/api";
import { useTranslation } from "react-i18next";
import { activePayment } from "services/api";
import { RESPONSE_STATUS } from "utils";
// const initialOptions = {
//   clientId:
//     "AYZiZOqfbM7IJ8kSvCIwIx1zYZkzQRM6Z4bZ5WJpv00IBTpkHtOsoouE4qLjuzHs3AYAMBnoRzuv2O3Y",
//   currency: "USD",
//   intent: "capture",
// };
const initialOptions = {
  clientId:
    "Af3dliCQE1RHjT9sfOP4eqqatWFlJd_-Qtpd6kln6uixb4P6t569FEKHBQhbTdlhMr4XJlXwFAxXt-u9",
  currency: "USD",
  intent: "capture",
};
function Paypal() {
  const invoice = JSON.parse(localStorage.getItem("cloudService"));
  const history = useHistory();
  const [selectPayment, setSelectPayment] = useState("paypal");
  const user = useSelector((state) => state.user.user);
  const toast = useToast({
    isClosable: true,
    duration: 5000,
    position: "top-right",
  });
  const [loading, setLoading] = useState(false);

  const tinRef = useRef(null);
  const [product, setProduct] = useState({});

  const { t } = useTranslation();

  const walletPayment = async () => {
    try {
      setLoading(true);
      const result = await activePayment(invoice?.invoice_id);
      if (result.data?.status == RESPONSE_STATUS.REDIRECT) {
        toast({ status: "error", title: "Số dư không đủ" });
        return;
      } else {
        toast({
          status: "success",
          title: "Thanh toán thành công vui lòng đợi khởi tạo",
        });
        history.push("/cloud-services/list");
      }
    } catch (error) {
      console.log(error);
      toast({ status: "error", title: "Có lỗi xảy ra" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    try {
      const result = await getProductById(invoice?.items[0]?.product_id);

      const { description, name } = result?.data?.data;
      const decoded = convertDescriptionProductToObject(description);

      const newData = {
        ...decoded,
        name,
        price: convertToUsd(Number(invoice.total / invoice.items.length)),
      };
      tinRef.current = newData;

      setProduct(newData);
    } catch (error) {
      console.log(error);
    }
  }, [invoice?.items[0]?.productId]);
  const createOrder = async (data, actions) => {
    try {
      const result = await createOrderApi({
        cart: {
          product: data?.product,
          invoice: invoice,
        },
      });
      const orderData = result?.data;
      if (orderData?.id) {
        return orderData?.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail?.issue} ${errorDetail?.description} (${orderData?.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      return;

      // resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
    }
  };
  const onApprove = async (data, actions) => {
    // Order is captured on the server and the response is returned to the browser
    try {
      const result = await approveOrderApi({
        orderID: data?.orderID,
        cart: {
          product: data?.product,
          userInfo: data?.userInfo,
          invoice: invoice,
        },
      });
      const orderData = result?.data;

      const transaction =
        orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
        orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
      const errorDetail = orderData?.details?.[0];

      // this actions.restart() behavior only applies to the Buttons component
      if (
        errorDetail?.issue === "INSTRUMENT_DECLINED" &&
        !data.card &&
        actions
      ) {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart();
      } else if (
        errorDetail ||
        !transaction ||
        transaction.status === "DECLINED"
      ) {
        // (2) Other non-recoverable errors -> Show a failure message
        let errorMessage;
        if (transaction) {
          errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
        } else if (errorDetail) {
          errorMessage = `${errorDetail.description} (${orderData.debug_id})`;
        } else {
          errorMessage = JSON.stringify(orderData);
        }

        throw new Error(errorMessage);
      } else {
        await paymentPaypalApi({
          invoice_id: invoice?.invoice_id,
          total: invoice?.total,
          content: invoice?.noidung,
          amount: invoice?.amount,
          bankcode: invoice?.bankcode,
        });
        toast({
          title: "Order completed",
          description:
            "We’ve sent you an email with all of the details of your order.",
          status: "success",
        });
      }
    } catch (error) {
      console.error("errorrr");
      // resultMessage(
      //   `Sorry, your transaction could not be processed...<br><br>${error}`
      // );
    }
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <Box pt={{ sm: "125px", md: "75px" }}>
        <Card>
          <Box mb={"4"}>
            <RadioGroup onChange={setSelectPayment} value={selectPayment}>
              <Stack direction="row" gap={5}>
                <Radio value="paypal">
                  <Text fontSize="lg" color="#fff" fontWeight="semibold">
                    Paypal
                  </Text>
                </Radio>
                <Radio value="acb">
                  <Text fontSize="lg" color="#fff" fontWeight="semibold">
                    ACB
                  </Text>
                </Radio>
                <Radio value="wallet">
                  <Text fontSize="lg" color="#fff" fontWeight="semibold">
                    Ví
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
          <CardHeader mb="12px">
            <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px" cap>
              {t("common.payment.selectProduct")}
            </Text>
          </CardHeader>
          <Grid templateColumns="repeat(5, 1fr)" gap={10}>
            <GridItem colSpan={3} w="100%">
              <Flex
                border={"1px"}
                borderColor={"#4656E7"}
                borderRadius={"8px"}
                p={4}
                justifyContent={"space-between"}
                alignContent={"center"}
                gap={2}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src={vps}
                    alt="Dan Abramov"
                  />
                  <Text fontSize="sm" color="#fff" fontWeight="bold" mb="6px">
                    {product?.name}
                  </Text>
                </Box>
                <Link to="/cloud-services/create">
                  <Button colorScheme="blue" variant="outline">
                    CHANGE
                  </Button>
                </Link>
              </Flex>

              <Box textColor={"white"} py={4}>
                {t("common.payment.unitAndBillingCycle")}:{" "}
                {invoice?.cycle === "m"
                  ? t("common.payment.monthly")
                  : invoice?.cycle === "q"
                  ? t("common.payment.quarterly")
                  : invoice?.cycle === "s"
                  ? t("common.payment.semiAnnually")
                  : t("common.payment.annually")}
              </Box>

              <Grid templateColumns="repeat(4, 1fr)" gap={8}>
                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>CPU</Box>
                    <Box>{product?.CPU}</Box>
                  </Box>
                </GridItem>

                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>RAM</Box>
                    <Box>{product?.Ram}</Box>
                  </Box>
                </GridItem>

                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>SSD</Box>
                    <Box>{product?.Storage}</Box>
                  </Box>
                </GridItem>

                <GridItem w="100%" colSpan={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    textColor={"white"}
                    fontStyle={"bold"}
                    bg={"#4656E7"}
                    p={4}
                    rounded={"lg"}
                  >
                    <Box>IPV4</Box>
                    <Box>Private</Box>
                  </Box>
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem w="100%" colSpan={2}>
              {selectPayment === "paypal" ? (
                <Box boxShadow={"md"} rounded={"lg"} bg={"white"} p={4}>
                  <Heading as="h3" fontSize="lg" fontWeight="bold" pb={4}>
                    {t("common.payment.orderStatistic")}
                  </Heading>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">{product?.name}</Text>
                    <Text>{product?.price} $</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">quantity:</Text>
                    <Text>{invoice?.items?.length || 0} </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">INSTALLATION FEES:</Text>
                    <Text>0.00 $</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">Monthly:</Text>
                    <Text>{convertToUsd(invoice?.total || 0)}</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">TAX:</Text>
                    <Text>0.00 $</Text>
                  </Flex>
                  <Flex justifyContent="space-between" py={2}>
                    <Text textTransform="uppercase">Total</Text>
                    <Text>{convertToUsd(invoice?.total || 0)}</Text>
                  </Flex>
                  <PayPalButtons
                    createOrder={(data, actions) => {
                      return createOrder(
                        { ...data, product: tinRef.current },
                        actions
                      );
                    }}
                    onApprove={(data, actions) => {
                      return onApprove(
                        { ...data, product: tinRef.current, userInfo: user },
                        actions
                      );
                    }}
                  />
                </Box>
              ) : selectPayment == "acb" ? (
                <Card p={"7"}>
                  <Flex justifyContent={"space-between"}>
                    <Box>
                      <img
                        src={`https://img.vietqr.io/image/ACB-6863868686-qr_only.png?amount=${
                          invoice?.amount
                        }&addInfo=${
                          invoice?.noidung || ""
                        }&accountName=CTY%20TNHH%20CONG%20NGHE%20VNG%20CLOUD`}
                        style={{
                          height: "200px",
                          maxWidth: "200px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </Box>
                    <Flex
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"column"}
                    >
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Bank:{" "}
                        <Text fontWeight={"600"} as="span">
                          ACB
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Account number:
                        <Text fontWeight={"600"} as="span">
                          {" "}
                          {"6863868686"}
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Account owner:
                        <Text fontWeight={"600"} as="span">
                          {" "}
                          CTY TNHH CONG NGHE VNG CLOUD
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Content billing:{" "}
                        <Text fontWeight={"600"} as="span">
                          {invoice?.noidung}
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Amount:{" "}
                        <Text fontWeight={"600"} as="span">
                          {convertMoney(invoice?.amount)} đ
                        </Text>
                      </Text>
                      <Text
                        color={"white"}
                        fontWeight={"400"}
                        fontSize={"16px"}
                      >
                        Số lượng:{" "}
                        <Text fontWeight={"600"} as="span">
                          {invoice?.items?.length || 0}
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                  <Text
                    color={"white"}
                    fontSize={"16px"}
                    fontWeight={"500"}
                    mt="8"
                    textAlign={"center"}
                  >
                    Please fill in the correct invoice number and amount or scan
                    the QR code Payment is confirmed automatically.
                  </Text>
                </Card>
              ) : (
                <Box boxShadow={"md"} rounded={"lg"} bg={"white"} p={4}>
                  <Heading as="h3" fontSize="lg" fontWeight="bold" pb={4}>
                    {t("common.payment.orderStatistic")}
                  </Heading>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">{product?.name}</Text>
                    <Text>{product?.price} $</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">quantity:</Text>
                    <Text>{invoice?.items?.length || 0} </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">INSTALLATION FEES:</Text>
                    <Text>0.00 $</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">Monthly:</Text>
                    <Text>{convertToUsd(invoice?.total || 0)}</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    borderBottom="1px dashed"
                    py={2}
                  >
                    <Text textTransform="uppercase">TAX:</Text>
                    <Text>0.00 $</Text>
                  </Flex>
                  <Flex justifyContent="space-between" py={2}>
                    <Text textTransform="uppercase">Total</Text>
                    <Text>{convertToUsd(invoice?.total || 0)}</Text>
                  </Flex>
                  <Button
                    width={"full"}
                    bg={"linkedin.600"}
                    color={"#ffffff"}
                    _hover={{ bg: "linkedin.500" }}
                    isLoading={loading}
                    onClick={walletPayment}
                  >
                    Thanh toán
                  </Button>
                </Box>
              )}
            </GridItem>
          </Grid>
        </Card>
      </Box>
    </PayPalScriptProvider>
  );
}

export default Paypal;
