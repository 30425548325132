import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import qr from "assets/img/vietqr.png";
import { convertMoney } from "utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Deposit() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [money, setMoney] = useState();
  const [isShow, setIsShow] = useState(false);
  const [error, setError] = useState("");

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      <Card>
        <CardHeader mb="12px">
          <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
            {t("common.payment.recharge")}
          </Text>
        </CardHeader>

        <Grid
          templateColumns={{
            sm: "1fr",
            lg: "1fr 1fr",
          }}
        >
          <GridItem>
            <Text fontSize="sm" color="#fff" fontWeight="bold" mb="6px">
              {t("common.payment.selectPayment")}
            </Text>
            <Flex gap={[3]}>
              <Flex
                pl="12px"
                py="16px"
                bg="rgb(31, 35, 89)"
                borderColor={"brand.100"}
                borderWidth={"4px"}
                borderRadius="20px"
                align="center"
                cursor={"pointer"}
                w="200px"
                _hover={{
                  borderColor: "brand.100",
                }}
              >
                <Image w={"48px"} borderRadius={"12px"} src={qr} />
                <Text color="#fff" fontSize="lg" ml="1rem">
                  VIETQR
                </Text>
              </Flex>
            </Flex>

            <Text
              fontSize="sm"
              color="#fff"
              fontWeight="bold"
              mb="6px"
              mt="2rem"
            >
              {t("common.payment.amount")}
            </Text>

            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="number"
              placeholder={t("common.payment.enterAmount")}
              minW="275px"
              maxW="275px"
              fontSize="sm"
              value={money}
              onChange={(e) => setMoney(e.target.value)}
            />

            <Flex
              w={"275px"}
              bgColor={"brand.200"}
              h={"40px"}
              borderRadius={"15px"}
              mt="1rem"
              color="white"
              fontWeight={"500"}
              align={"center"}
              px="1rem"
            >
              {convertMoney(money || 0) || 0} VNĐ
            </Flex>
            {error && (
              <Text mt="1rem" color={"red.400"} fontSize={"sm"}>
                {error}
              </Text>
            )}

            <Button
              type="submit"
              variant="brand"
              w="125px"
              h="40px"
              fontSize="xs"
              mt="2rem"
              fontWeight="bold"
              onClick={() => {
                if (money && money >= 5000) {
                  setIsShow(true);
                  setError("");
                } else {
                  setIsShow(false);
                  setError("Số tiền nạp tối thiểu là 50.000 VNĐ");
                }
              }}
            >
              {t("common.payment.recharge")}
            </Button>
          </GridItem>
          {isShow && (
            <GridItem>
              <Flex
                mt={{
                  sm: "1rem",
                  lg: "0px",
                }}
                alignItems={"center"}
                direction={{
                  sm: "column",
                  lg: "row",
                }}
              >
                <Box>
                  <Image
                    w={{
                      sm: "350px",
                      lg: "350px",
                    }}
                    h={{
                      sm: "350px",
                      lg: "350px",
                    }}
                    borderRadius={"20px"}
                    src={`https://img.vietqr.io/image/ACB-6863868686-compact2.png?amount=${money}&addInfo=NAP ${user?.codeId}&accountName=CTY%20TNHH%20CONG%20NGHE%20VNG%%20CLOUD`}
                  />
                  <Text
                    textAlign={"center"}
                    mt="1rem"
                    color={"green.400"}
                    fontSize={"sm"}
                  >
                    <i>(Content billing: {`NAP ${user?.codeId}`})</i>
                  </Text>
                </Box>

                <Box
                  mt={{
                    sm: "1rem",
                    lg: "0px",
                  }}
                  textAlign={"center"}
                  w={"100%"}
                >
                  <Text fontSize="sm" color="#fff" fontWeight="bold" mb="6px">
                    Account Number: 6863868686
                  </Text>
                  <Text fontSize="sm" color="#fff" fontWeight="bold" mb="6px">
                    Account Owner: CTY TNHH CONG NGHE VNG CLOUD
                  </Text>
                  <Text fontSize="sm" color="#fff" fontWeight="bold" mb="6px">
                    Content billing: {`NAP ${user?.codeId}`}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          )}
        </Grid>
      </Card>
    </Box>
  );
}

export default Deposit;
