import { default as React, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Switch,
  Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Assets
import illustration from "assets/img/illustration-auth.png";

// Custom components
import GradientBorder from "components/GradientBorder/GradientBorder";
import AuthFooter from "components/Footer/AuthFooter";
import { login } from "services/api";

const schema = yup.object({
  email: yup
    .string()
    .email("Email không hợp lệ")
    .required("Bạn chưa nhập email"),
  password: yup.string().required("Bạn chưa nhập mật khẩu"),
});

function SignInIllustration() {
  // Chakra color mode
  const titleColor = "white";
  const textColor = "gray.400";
  const illustrationBackground =
    "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const result = await login(data?.email, data?.password);
      console.log("dsaasdasd", result.data);
    } catch (error) {
      console.log(error);
      setError("Email hoặc mật khẩu không hợp lệ");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex position="relative">
      <Flex
        minH="100vh"
        h={{ base: "120vh", lg: "fit-content" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: "100px", md: "0px" }}
        flexDirection="column"
        me={{ base: "auto", lg: "50px", xl: "auto" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          mx={{ base: "auto", lg: "unset" }}
          me={{ base: "auto", lg: "auto" }}
          w={{ base: "100%", md: "50%", lg: "450px" }}
          px="50px"
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{
              base: "50px",
              md: "150px",
              lg: "160px",
              xl: "185px",
              "2xl": "245px",
            }}
            mb={{ base: "60px", lg: "95px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Nice to see you!
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and password to sign in
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color="white"
                >
                  Email
                </FormLabel>
                <GradientBorder
                  mb="8px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color="white"
                    bg={{ base: "rgb(19,21,54)", lg: "rgb(19,21,54)" }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="46px"
                    placeholder="Your email adress"
                    {...register("email")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px">
                  {errors.email?.message}
                </Text>
              </FormControl>
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color="white"
                >
                  Password
                </FormLabel>
                <GradientBorder
                  mb="8px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color="white"
                    bg={{ base: "rgb(19,21,54)", lg: "rgb(19,21,54)" }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    type="password"
                    placeholder="Your password"
                    {...register("password")}
                  />
                </GradientBorder>

                <Text color="red.500" mb="12px">
                  {errors.password?.message}
                </Text>
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <DarkMode>
                  <Switch id="remember-login" colorScheme="brand" me="10px" />
                </DarkMode>
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  ms="1"
                  fontWeight="500"
                  color="white"
                >
                  Remember me
                </FormLabel>
              </FormControl>

              {error && (
                <Text color="red.500" mt="12px">
                  {error}
                </Text>
              )}

              <Button
                variant="brand"
                fontSize="10px"
                type="submit"
                w="100%"
                h="45"
                mb="20px"
                mt="20px"
                isLoading={isLoading}
              >
                SIGN IN
              </Button>
            </form>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <NavLink to="/auth/sign-up">
                  <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                    Sign Up
                  </Link>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w={{ lg: "50vw", "2xl": "50vw" }}
          position="absolute"
          right="0px"
        >
          <Flex
            bg={illustrationBackground}
            justify="center"
            align="center"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
          >
            <Image
              w={{ lg: "450px", xl: "550px", "2xl": "690px" }}
              src={illustration}
              alt="illustration"
            />
          </Flex>
        </Box>
        {/* <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          me={{ base: "auto", lg: "auto" }}
          mb="80px"
        >
          <AuthFooter />
        </Box> */}
      </Flex>
    </Flex>
  );
}

export default SignInIllustration;
