import axios from "axios";
import config from "../config";
import jwtDecode from "jwt-decode";

const baseUrl = config.API_URL;

let axiosInstanceWithoutToken = axios.create({
  baseURL: baseUrl,
});

let axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use((config) => {
  try {
    const jwtToken = JSON.parse(localStorage.getItem("accessToken") || "");

    const decodedToken = jwtDecode(jwtToken);
    if (!decodedToken.exp || decodedToken.exp * 1000 < new Date().getTime()) {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      location.reload();
    }

    config.headers.authorization = `Bearer ${jwtToken}`;
  } catch (error) {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
  }

  return config;
});

export const apiUpdateUserById = (data) =>
  axiosInstance.put("/users/update", data);

export const login = async (username, password) =>
  axiosInstanceWithoutToken.post(`/auth/login`, {
    username: username,
    password: password,
  });

export const resetPassword = (email) =>
  axiosInstanceWithoutToken.post("/users/password-reset", { email });

export const signup = (data) => axiosInstance.post("/users/register", data);

export const getUserSurplus = () => axiosInstance.get(`/users/balance`);

export const checkRole = () => axiosInstance.get(`/users/check-role`);

export const getServicesOvhByUser = (
  pageIndex = 1,
  pageSize = 10,
  search = ""
) =>
  axiosInstance.get(
    `/ovh/get-services-by-user?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
  );

export const getCatalogCart = () => axiosInstance.get(`/ovh/catalog-cart`);

export const getDataCenterCart = (os, planCode) =>
  axiosInstance.get(`/ovh/data-center-cart?os=${os}&planCode=${planCode}`);

export const getSummaryOrderCart = (data) =>
  axiosInstance.post(`/ovh/summary-order-cart`, data);

export const deleteOrderCart = (cartId) =>
  axiosInstance.delete(`/ovh/delete-order-cart/${cartId}`);

export const checkoutOrderCart = (cartId) =>
  axiosInstance.get(`/ovh/checkout-order-cart/${cartId}`);

export const createVpsOvh = (cartId, summary) =>
  axiosInstance.post(`/ovh/create-vps-ovh/${cartId}`, summary);

export const getServicesInfoOvhByUser = (data) =>
  axiosInstance.post(`/ovh/service-info-by-user`, data);

export const getSnapshotByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-snapshot/${serviceName}`);

export const getBackupByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-backup/${serviceName}`);

export const getAdditionalDiskByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-additional-disk/${serviceName}`);

export const getMonitoringByVps = (serviceName, period) =>
  axiosInstance.get(`/ovh/get-monitoring/${serviceName}?period=${period}`);

export const getCartServiceOptionByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-cart-service-option/${serviceName}`);

export const getSecondaryDNSByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-secondary-dns/${serviceName}`);

export const getSummaryAdditionalDisk = (serviceName, planCode) =>
  axiosInstance.post(`/ovh/summary-additional-cart/${serviceName}`, {
    planCode,
  });

export const addAdditionalDisk = (cartId) =>
  axiosInstance.post(`/ovh/add-additional-cart/${cartId}`);

export const addSecondaryDNSDomain = (serviceName, domain) =>
  axiosInstance.post(`/ovh/add-secondary-dns-domain/${serviceName}`, {
    domain,
  });

export const getCatalogCloudDB = () =>
  axiosInstance.get(`/ovh/get-catalog-cloud-db`);

export const createCloudDBForVPS = (serviceName, data) =>
  axiosInstance.post(`/ovh/create-cloud-db-vps/${serviceName}`, data);

export const updateDisplayNameVps = (serviceName, displayName) =>
  axiosInstance.put(`/ovh/update-display-name-vps/${serviceName}`, {
    displayName,
  });

export const rebootVps = (serviceName) =>
  axiosInstance.post(`/ovh/reboot-vps/${serviceName}`);

export const getConsoleUrlByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-console-url-vps/${serviceName}`);

export const getServiceInfoRenewalByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-service-info-renewal-vps/${serviceName}`);

export const updateServiceInfoRenewalByVps = (serviceName, data) =>
  axiosInstance.put(
    `/ovh/update-service-info-renewal-vps/${serviceName}`,
    data
  );

export const getAllDepartments = () => axiosInstance.get("/department/getAll");

export const createNewTicket = (data) =>
  axiosInstance.post("/support/create/", data);

export const getSupportByUserId = (
  pageIndex,
  pageSize,
  supportName,
  SupportTT,
  SupportUT
) =>
  axiosInstance.get(
    `/support/getpagingSupportByUserId?pageIndex=${pageIndex}&supportTT=${SupportTT}&supportUT=${SupportUT}&search=${supportName}&pageSize=${pageSize}`
  );

export const getDashboardOvh = () => axiosInstance.get("/ovh/get-dashboard");

export const getImagesAvailableByVps = (serviceName) =>
  axiosInstance.get(`/ovh/get-images-available-by-vps/${serviceName}`);

export const updateImagesByVps = (serviceName, data) =>
  axiosInstance.put(`/ovh/update-image-by-vps/${serviceName}`, data);

export const getProductById = (id) =>
  axiosInstance.get(`/products/product-detail/${id}`);
export const getProductDetailForConfig = (id) =>
  axiosInstance.get(`/products/product-detail-for-config/${id}`);

export const getProductsBySubOrderPage = (id) =>
  axiosInstance.get(`/products/products-by-sub-order-page/${id}`);

export const getProductDiscount = (data) =>
  axiosInstance.get(`/products/product-discount/${data?.id}${data?.query}`);

export const createNewCloudService = (data) =>
  axiosInstance.post(`/services/create-new-service`, data);

export const getPagingCloudServices = (search, pageIndex, pageSize) =>
  axiosInstance.get(
    `/services/get-paging?search=${search}&pageIndex=${pageIndex}&pageSize=${pageSize}&category_id=&status=all`
  );

export const getServiceDetailForPayment = (id) =>
  axiosInstance.get(`/services/service-detail-for-payment/${id}`);

export const getInvoiceForDetail = (id) =>
  axiosInstance.get(`/invoices/invoice-for-detail/${id}`);

export const getAllInvoicesCloudServiceByClientId = () =>
  axiosInstance.get(`/invoices/invoices-by-client-id`);

export const updatePasswordService = (data) =>
  axiosInstance.post(`/services/update-password-service`, data);

export const updateHostnameService = (data) =>
  axiosInstance.post(`/services/update-hostname-service`, data);

export const shutDownCloud = (id, service_id) =>
  axiosInstance.post(`/listVMS/shutdown/${service_id}/${id}`);

export const resetCloud = (id, service_id) =>
  axiosInstance.post(`/listVMS/reset/${service_id}/${id}`);

export const startCloud = (id, service_id) =>
  axiosInstance.post(`/listVMS/start/${service_id}/${id}`);

export const stopCloud = (id, service_id) =>
  axiosInstance.post(`/listVMS/stop/${service_id}/${id}`);

export const rebootCloud = (id, service_id) =>
  axiosInstance.post(`/listVMS/reboot/${service_id}/${id}`);

export const rebuildCloud = (id, service_id, templateId) =>
  axiosInstance.post(`/listVMS/rebuild/${service_id}/${id}/${templateId}`);

export const getVMDetail = (id, service_id) =>
  axiosInstance.get(`/listVMS/${service_id}/${id}`);

export const getListOsByService = (id) =>
  axiosInstance.get(`/services/get-os-by-service/${id}`);

export const createOrderApi = (data) => {
  return axiosInstance.post(`/orders`, data);
};
export const approveOrderApi = (data) => {
  return axiosInstance.post(`/orders/${data?.orderID}/capture`, data);
};
export const createOrderRenewalApi = (data) => {
  return axiosInstance.post(`/orders/renewal`, data);
};
export const approveOrderRenewalApi = (data) => {
  return axiosInstance.post(`/orders/renewal/${data?.orderID}/capture`, data);
};
export const paymentPaypalApi = (data) => {
  return axiosInstance.post(`/payment/receive-request-paypal`, data);
};

export const vnpayRequest = (data) => {
  return axiosInstance.post(`/payment/receive-request-vnpay`, data);
};

export const vnpayPaymentResult = (data) => {
  return axiosInstance.get(
    `/payment/callback-transaction/${data?.invoiceId}/${data?.paymentId}${data?.queryStr}`
  );
};

export const getListActionHistory = () =>
  axiosInstance.get(`/users/get-histories`);

export const getAllUsers = () => axiosInstance.get(`/users/get-all`);

export const getListPaymentHistory = () =>
  axiosInstance.get(`/users/get-creditlogs`);

export const getPagingSupport = (pageIndex, pageSize, search, level) =>
  axiosInstance.get(
    `/support/getpaging?pageIndex=${pageIndex}&search=${search}&pageSize=${pageSize}&level=${level}`
  );

export const getTicketById = (id) =>
  axiosInstance.post(`/support/getById`, { supportId: id });

export const updateTicket = (id, data) =>
  axiosInstance.put(`/support/update/${id}`, data);

export const getUserById = (id) =>
  axiosInstance.post(`/users/getById`, { id: id });

export const getUserQrCode = (id) =>
  axiosInstance.get(`/users/getQrCode/${id}`);

export const verify2FaToken = (token) =>
  axiosInstance.post(`/users/verify2FaToken`, {
    token,
  });

export const getUserNotification = () =>
  axiosInstance.get(`/notification/get-by-user`);

export const login2Fa = (data) => axiosInstance.post(`/users/login2Fa`, data);

export const renewalCloudService = (serviceId) =>
  axiosInstance.post(`/services/renewal-cloud-service/${serviceId}`);

export const checkServicesRenewal = (serviceIds) =>
  axiosInstance.post(`/services/check-services-renewal`, {
    serviceIds,
  });
export const renewalCloudManyServicesPaypal = (serviceIds) =>
  axiosInstance.post(`/services/renewal-cloud-many-paypal`, {
    serviceIds,
  });
export const renewalCloudManyServices = (serviceIds) =>
  axiosInstance.post(`/services/renewal-cloud-many-services`, {
    serviceIds,
  });

export const createNewPaymentHistory = (data) =>
  axiosInstance.post(`/payment-history`, data);

export const getPagingPaymentHistory = (pageIndex, pageSize, search) =>
  axiosInstance.get(
    `/payment-history?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
  );

export const deletePaymentHistory = (id) =>
  axiosInstance.delete(`/payment-history/${id}`);

export const getPaymentHistoryById = (id) =>
  axiosInstance.get(`/payment-history/get-by-id/${id}`);

export const updatePaymentHistory = (id, data) =>
  axiosInstance.put(`/payment-history/${id}`, data);

export const getPagingPaymentHistoryByUser = (pageIndex, pageSize) =>
  axiosInstance.get(
    `/payment-history/get-paging-by-user?pageIndex=${pageIndex}&pageSize=${pageSize}`
  );

export const changePassword = (data) =>
  axiosInstance.post(`/users/change-password`, data);

export const activePayment = (id) => {
  return axiosInstance.put(`/services/pay-and-active/${id}`);
};

export const getPagingVNGServer = (pageSize, pageIndex) => {
  return axiosInstance.get(
    `/vngserver/get-paging?pageSize=${pageSize}&pageIndex=${pageIndex}`
  );
};

export const createVNGServer = (data) => {
  return axiosInstance.post("/vngserver", data);
};

export const getVngServer = (id) => {
  return axiosInstance.get(`/vngserver/${id}`);
};
