import React, { useMemo } from "react";
import bgProfile from "assets/img/bgProfile.png";
import Card from "components/Card/Card";
import { Box, Flex, Grid, Tag, Text } from "@chakra-ui/react";
import { genGbps } from "utils";
import { genPriceOvh } from "utils";
import { AiFillCheckCircle } from "react-icons/ai";
import { getLanguage } from "utils";
import { convertToVnd } from "utils/convertToUsd";

function ConfigVPSInstanceItem({ item, plan, chosen, index, handleChooseVps }) {
  const genInformation = () => {
    if (!item) return [];
    return [
      {
        title: "Processor",
        value: `${item?.blobs?.technical?.cpu?.cores} ${item?.blobs?.technical?.cpu?.model}`,
      },
      {
        title: "Memory",
        value: `${item?.blobs?.technical?.memory?.size} GB`,
      },
      {
        title: "Storage",
        value: `${item?.blobs?.technical?.storage?.disks?.[0]?.capacity} GB ${item?.blobs?.technical?.storage?.disks?.[0]?.technology} ${item?.blobs?.technical?.storage?.disks?.[0]?.interface}`,
      },
      {
        title: "Public bandwidth",
        value: `${genGbps(item?.blobs?.technical?.bandwidth?.level)}`,
      },
    ];
  };

  const price = useMemo(() => {
    let total = 0;
    plan?.pricings?.forEach((item) => {
      if (item?.commitment === 0 && item?.phase === 1 && item?.interval === 1) {
        total = item?.price;
      }
    });

    return total;
  }, [plan]);

  return (
    <Card
      // bgImage={bgProfile}
      bgColor="#5f68c9"
      bgSize="cover"
      // gridArea={{ xl: "1 / 1 / 2 / 2", "2xl": "auto" }}
      cursor={"pointer"}
      onClick={() => handleChooseVps(index)}
    >
      <Flex direction="column" h="100%">
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Text color="red.400" fontSize="24px" fontWeight="bold" mb="3px">
            {item?.description?.split(" ")?.[1]}
          </Text>

          {chosen === index && (
            <Box bg={"white"} borderRadius={"100%"}>
              <AiFillCheckCircle color="#68D391" size={"32px"} />
            </Box>
          )}
        </Flex>

        <Grid templateColumns={"1fr 1.5fr"} gap={[2, 3]} mt="12px">
          {genInformation()?.map((item, index) => (
            <React.Fragment key={item?.title}>
              <Text fontSize="sm" color="white">
                {item?.title}
              </Text>
              <Text fontSize={"md"} color={"white"} fontWeight={"500"}>
                {item?.value}
              </Text>
            </React.Fragment>
          ))}
        </Grid>

        <Flex mt="32px" justifyContent={"center"} alignItems={"center"}>
          <Text fontSize={"md"} fontWeight={"500"} color={"white"}>
            {getLanguage() == "en" ? `US$` : ""}{" "}
          </Text>
          <Tag
            mx={"4px"}
            bg={"white"}
            fontSize="16px"
            size="lg"
            color="brand.200"
            borderRadius="15px"
            alignSelf="flex-start"
          >
            {convertToVnd(genPriceOvh(price, true), getLanguage())}
          </Tag>
          <Text fontSize={"md"} color={"gray.100"}>
            / month
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export default ConfigVPSInstanceItem;
