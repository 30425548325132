import React, { useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";

// Assets
import signUpImage from "assets/img/bg-signup1.png";

import { NavLink, useHistory } from "react-router-dom";
import GradientBorder from "components/GradientBorder/GradientBorder";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { signup } from "services/api";
import { is_phonenumber } from "utils";
import logo from "../../../assets/img/logo.png";
const schema = yup.object({
  email: yup.string().email("Email is invalid").required("Email is required"),
  firstname: yup.string().required("firstName is required"),
  lastname: yup.string().required("lastName is required"),
  phonenumber: yup.string().required("phoneNumber is required"),
  password: yup.string().required("Password is required"),
  password2: yup.string().required("confirmPassword is required"),
});

function SignUpCover() {
  // Chakra color mode
  const titleColor = "white";
  const textColor = "gray.400";

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });
  const history = useHistory();

  const onRegister = async (data) => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    try {
      console.log(data);
      const checkFirstName = format.test(data?.firstname);
      const checkLastName = format.test(data?.lastname);
      let flag = true;

      if (checkLastName) {
        setError(
          "lastname",
          {
            message: "Họ không được chưa kí tự đặc biệt",
          },
          {
            shouldFocus: true,
          }
        );

        flag = false;
      }

      if (checkFirstName) {
        setError(
          "firstname",
          {
            message: "Tên không được chưa kí tự đặc biệt",
          },
          {
            shouldFocus: true,
          }
        );
        flag = false;
      }

      if (!is_phonenumber(data?.phonenumber)) {
        setError(
          "phonenumber",
          {
            message: "Số điện thoại không hợp lệ",
          },
          {
            shouldFocus: true,
          }
        );
        flag = false;
      }

      if (data?.password !== data?.password2) {
        setError(
          "password2",
          {
            message: "Nhập lại mật khẩu không giống",
          },
          {
            shouldFocus: true,
          }
        );
        flag = false;
      }

      if (!flag) {
        return;
      }

      setIsLoading(true);

      const {
        firstname,
        lastname,
        password,
        password2,
        email,
        phonenumber,
      } = data;

      const result = await signup({
        firstname,
        lastname,
        country: "vn",
        password,
        password2,
        email,
        phonenumber,
      });

      if (result.data?.status == 1) {
        toast({
          status: "success",
          title: "Đăng kí thành công",
        });

        history.push("/auth/sign-in");
      } else {
        toast({
          status: "error",
          title: result.data?.message,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: error.response.data,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex position="relative" overflow={{ lg: "hidden" }}>
      <Flex
        flexDirection="column"
        h={{ sm: "initial", md: "unset" }}
        w={{ base: "90%" }}
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        me={{ base: "auto", lg: "50px", xl: "auto" }}
        minH="100vh"
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          flexDirection="column"
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb="20px"
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            mt="40px"
          ></Flex>
          <GradientBorder p="2px" me={{ base: "none", lg: "30px", xl: "none" }}>
            <Flex
              background="transparent"
              borderRadius="30px"
              direction="column"
              p="40px"
              minW={{ base: "unset", md: "430px", xl: "450px" }}
              w="100%"
              mx={{ base: "0px" }}
              bg={{
                base: "rgb(19,21,56)",
              }}
            >
              <Text
                fontSize="xl"
                color={textColor}
                fontWeight="bold"
                textAlign="center"
                mb="22px"
              >
                Sign Up
              </Text>
              <form onSubmit={handleSubmit(onRegister)}>
                <FormLabel
                  color={titleColor}
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Last name
                </FormLabel>

                <GradientBorder
                  h="44px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="40px"
                    type="text"
                    placeholder="Enter your last name"
                    {...register("lastname")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px" mt="4px" fontSize={"sm"}>
                  {errors.lastname?.message}
                </Text>

                <FormLabel
                  color={titleColor}
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Name
                </FormLabel>

                <GradientBorder
                  h="44px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="40px"
                    type="text"
                    placeholder="Enter your name"
                    {...register("firstname")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px" mt="4px" fontSize={"sm"}>
                  {errors.firstname?.message}
                </Text>

                <FormLabel
                  color={titleColor}
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Email address
                </FormLabel>
                <GradientBorder
                  h="44px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="40px"
                    type="email"
                    placeholder="Enter your email address"
                    {...register("email")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px" mt="4px" fontSize={"sm"}>
                  {errors.email?.message}
                </Text>

                <FormLabel
                  color={titleColor}
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Phone number
                </FormLabel>
                <GradientBorder
                  h="44px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="40px"
                    type="number"
                    placeholder="Enter your phone number"
                    {...register("phonenumber")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px" mt="4px" fontSize={"sm"}>
                  {errors.phonenumber?.message}
                </Text>

                <FormLabel
                  color={titleColor}
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Password
                </FormLabel>
                <GradientBorder
                  h="44px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="40px"
                    type="password"
                    placeholder="Enter your password"
                    {...register("password")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px" mt="4px" fontSize={"sm"}>
                  {errors.password?.message}
                </Text>

                <FormLabel
                  color={titleColor}
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Confirm password
                </FormLabel>
                <GradientBorder
                  h="44px"
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius="20px"
                >
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: "100%", md: "346px" }}
                    maxW="100%"
                    h="40px"
                    type="password"
                    placeholder="Enter your confirm password"
                    {...register("password2")}
                  />
                </GradientBorder>
                <Text color="red.500" mb="12px" mt="4px" fontSize={"sm"}>
                  {errors.password2?.message}
                </Text>

                <Button
                  variant="brand"
                  fontSize="10px"
                  type="submit"
                  w="100%"
                  h="45"
                  mb="20px"
                  isLoading={isLoading}
                >
                  Sign Up
                </Button>
              </form>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  Do you already have an account?
                  <NavLink to="/auth/sign-in">
                    <Link
                      color={titleColor}
                      as="span"
                      ms="5px"
                      fontWeight="bold"
                    >
                      Sign In
                    </Link>
                  </NavLink>
                </Text>
              </Flex>
            </Flex>
          </GradientBorder>
        </Flex>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: "50vw", lg: "48vw" }}
          w="960px"
          position="absolute"
          left="0px"
        >
          <Box
            bgImage={signUpImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500"
            ></Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)"
            ></Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignUpCover;
