import { Box, Flex } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableVNGServer from "components/Tables/TableVNGServer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPagingVNGServer } from "services/api";
import { convertToUsd } from "utils/convertToUsd";

const VNGServer = () => {
  const { t } = useTranslation();
  const [servers, setServers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalDoc, setTotalDoc] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const getServers = async () => {
    try {
      setIsLoading(true);
      const result = await getPagingVNGServer(pageSize, pageIndex);
      setServers(result.data?.servers || []);
      setTotalDoc(result.data?.count || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePageSize = () => {};

  const handleChangePageIndex = () => {};

  const onChangePage = (page) => {
    setPageIndex(page);
  };

  const columns = [
    {
      Header: "checkbox",
    },
    {
      Header: "SERVICE_ID",
      label: "SERVICE_ID",
    },
    {
      Header: "SERVICE",
      label: t("config.billingCycle"),
    },
    {
      Header: "IPV4",
      label: "ADDITIONAL IPV4",
    },
    {
      Header: "IPV6",
      label: "ADDITIONAL IPV6",
    },
    {
      Header: "DATACENTER",
      label: "DATACENTER",
    },
    {
      Header: "ip",
      label: "IP",
    },
    {
      Header: "username",
      label: "USERNAME",
    },
    {
      Header: "password",
      label: "PASSROOT",
    },
    {
      Header: "BACKUP",
      label: "BACKUP",
    },
    {
      Header: "PREMIUM-SUPPORT",
      label: "PREMIUM SUPPORT",
    },
    {
      Header: "price",
      label: "PRICE",
    },
    {
      Header: "Action",
      label: "ACTION",
    },
  ];

  useEffect(() => {
    getServers();
  }, [pageSize, pageIndex]);
  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      <Flex direction="column">
        <Card px="0px">
          <CardBody>
            <TableVNGServer
              isLoading={isLoading}
              tableData={servers}
              totalDoc={totalDoc}
              pageIndex={pageIndex}
              pageSize={pageSize}
              search={search}
              setSearch={setSearch}
              onChangeSize={handleChangePageSize}
              onChangePage={onChangePage}
              columns={columns}
            />
          </CardBody>
        </Card>
      </Flex>
    </Box>
  );
};

export default VNGServer;
