import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import Globe from "components/Globe/Globe";
import InputText from "components/Input/Input";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";
import { changePassword } from "services/api";

function ChangePassword() {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const onSubmitForm = async () => {
    try {
      setIsLoading(true);

      if (!oldPassword || !newPassword || !newPassword2) {
        return setError(t("common.header.errorInputPass"));
      }

      setError("");

      const data = {
        password: oldPassword,
        newPassword: newPassword,
        reNewPassword: newPassword2,
      };

      const result = await changePassword(data);

      toast({
        status: "success",
        title: result?.data?.message,
      });
      setOldPassword("");
      setNewPassword("");
      setNewPassword2("");
    } catch (error) {
      console.log(error);
      setError(error?.response?.data?.message || "Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text color="#fff" fontWeight="bold" fontSize="3xl" mb="30px" ps="20px">
        {t("common.header.changePassword")}
      </Text>
      <Grid
        templateColumns={{ sm: "4fr 1fr", xl: "1.2fr 1fr" }}
        gap="32px"
        maxW="100%"
        w="100%"
      >
        <Box
          minW="700px"
          h="700px"
          position="absolute"
          right="30px"
          top="14%"
          display={{ sm: "none", md: "block" }}
        >
          <Globe />
        </Box>

        <Stack
          direction="column"
          spacing="24px"
          w="100%"
          mb="24px"
          maxW={{ sm: "315px", md: "100%" }}
          zIndex="0"
        >
          <Card px="0px">
            <CardHeader px="22px" mb="20px">
              <Flex alignItems={"center"} gap={2}>
                <BiUserCircle size={24} color="white" />
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Profile
                </Text>
              </Flex>
            </CardHeader>

            <Box style={{ padding: "0px 22px" }}>
              <Flex direction={"column"} gap={4} maxW="100%" w="100%">
                <Text color={"white"} fontWeight={"500"} size={20}>
                  {t("common.header.oldPassword")}
                </Text>
                <Input
                  type="password"
                  color={"white"}
                  value={oldPassword}
                  placeholder={t("common.header.oldPassword")}
                  onChange={(e) => setOldPassword(e?.target?.value)}
                />
                <Text color={"white"} fontWeight={"500"} size={20}>
                  {t("common.header.newPassword")}
                </Text>
                <Input
                  type="password"
                  color={"white"}
                  value={newPassword}
                  placeholder={t("common.header.newPassword")}
                  onChange={(e) => setNewPassword(e?.target?.value)}
                />
                <Text color={"white"} fontWeight={"500"} size={20}>
                  {t("common.header.newPassword2")}
                </Text>
                <Input
                  type="password"
                  color={"white"}
                  value={newPassword2}
                  placeholder={t("common.header.newPassword2")}
                  onChange={(e) => setNewPassword2(e?.target?.value)}
                />
              </Flex>
              {error && (
                <Text
                  mt={8}
                  textAlign={"center"}
                  color={"red.400"}
                  fontWeight={"500"}
                  fontSize={"14px"}
                >
                  {error}
                </Text>
              )}
              <Button
                width={"100%"}
                type="submit"
                mt={"20px"}
                onClick={onSubmitForm}
                isLoading={isLoading}
                variant="brand"
              >
                {t("common.header.changePassword")}
              </Button>
            </Box>
          </Card>
        </Stack>
      </Grid>
    </Flex>
  );
}

export default ChangePassword;
