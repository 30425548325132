import { Button, Flex } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableTickets from "components/Tables/TableTickets";
import useDebounce from "hooks/useDebounce";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getPagingSupport } from "services/api";

function ManagerTicket() {
  const { t } = useTranslation();
  const columns = [
    {
      Header: "TITLE",
      label: t("common.table.title"),
    },
    {
      Header: "PRIORITY",
      label: t("common.table.priority"),
    },
    {
      Header: "DEPARTMENT",
      label: t("common.table.department"),
    },
    {
      Header: "ATTACHMENT",
      label: t("common.table.attachment"),
    },
    {
      Header: "STATUS",
      label: t("common.table.status"),
    },
    {
      Header: "CREATED AT",
      label: t("common.table.createdAt"),
    },
    {
      Header: "ACTION",
      label: t("common.table.action"),
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDoc, setTotalDoc] = useState(0);
  const [priority, setPriority] = useState("");
  const [search, setSearch] = useState("");

  const searchValue = useDebounce(search, 500);

  const getAllTickets = async () => {
    try {
      setIsLoading(true);
      const result = await getPagingSupport(
        pageIndex,
        pageSize,
        searchValue,
        priority
      );
      setTotalDoc(result?.data?.totalItem || 0);
      setTickets(result?.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllTickets();
  }, [pageIndex, pageSize, priority, searchValue]);

  return (
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
      <Card px="0px">
        <CardBody>
          <TableTickets
            columns={columns}
            isLoading={isLoading}
            tableData={tickets}
            totalDoc={totalDoc}
            pageIndex={pageIndex}
            pageSize={pageSize}
            search={search}
            setSearch={setSearch}
            onChangeSize={(e) => setPageSize(e)}
            onChangePage={(e) => setPageIndex(e)}
            isManager
            setPriority={setPriority}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ManagerTicket;
