import {
  Avatar,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  LightMode,
  DarkMode,
  Image,
  Select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Box,
  Spinner,
  Tag,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import Pagination from "rc-pagination";
import React, { useEffect, useMemo, useState } from "react";
import { AiFillEye, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {
  FaCheckCircle,
  FaFileInvoice,
  FaFileInvoiceDollar,
  FaTimesCircle,
  FaUndoAlt,
} from "react-icons/fa";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { convertMBtoGB } from "utils";
import { useHistory } from "react-router-dom";
import { getMonthDateGap } from "utils";
import { FiMoreHorizontal } from "react-icons/fi";
import { BsEye } from "react-icons/bs";
import { genIdNameVps } from "utils";
import { SERVICE_OVH_STATUS } from "utils";
import vietnam from "assets/img/vietnam.png";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import almalinux from "assets/img/ovh/almalinux.svg";
import { convertMoney } from "utils";
import { HSeparator } from "components/Separator/Separator";
import { useSelector } from "react-redux";
import { convertToUsd } from "utils/convertToUsd";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { getLanguage } from "utils";
import { minusNowAndExpireServiceActive } from "utils";
import { EditIcon, UpDownIcon } from "@chakra-ui/icons";
import { renewalCloudService } from "services/api";
import Swal from "sweetalert2";
import { RESPONSE_STATUS } from "utils";
import { getAllCloudServices } from "redux/slices/serviceSlice";
import { getAllInvoicesCloudServiceByClient } from "redux/slices/serviceSlice";
import { getUserCredit } from "redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { renewalCloudManyServices } from "services/api";
import ModalRenewalServices from "containers/CloudServiceList/ModalRenewalServices";
import { updateHostnameService } from "services/api";
import { updateAllService } from "redux/slices/serviceSlice";
import LoadingOVH from "components/Loading/Loading";

const imageTemplate = [
  {
    id: 1,
    value: "windows",
    value2: "template:302",
    image: window,
  },
  {
    id: 2,
    value: "ubuntu",
    value2: "template:256",
    image: ubuntu,
  },
  {
    id: 3,
    value: "centos",
    value2: "template:308",
    image: centos,
  },
  {
    id: 4,
    value: "debian",
    value2: "template:306",
    image: debian,
  },
  {
    id: 5,
    value: "almalinux",
    value2: "template:309",
    image: almalinux,
  },
  {
    id: 6,
    value: "windows",
    value2: "template:301",
    image: window,
  },
  {
    id: 7,
    value: "",
    value2: "template:301",
    image: window,
  },
];

// Danh sách service connect bằng tay
const dataServices = [
  {
    id: 510,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.166",
  },
  {
    id: 490,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.155",
  },
  {
    id: 472,
    os: "windows",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.133",
  },
  {
    id: 456,
    os: "windows",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.147",
  },
  {
    id: 455,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.138",
  },
  {
    id: 454,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.137",
  },
  {
    id: 453,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.136",
  },
  {
    id: 451,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.135",
  },
  {
    id: 450,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.134",
  },
  {
    id: 449,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.132",
  },
  {
    id: 448,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.131",
  },
  {
    id: 447,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.129",
  },
  {
    id: 269,
    os: "centos",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.130",
  },
  {
    id: 268,
    os: "windows",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.143",
  },
  {
    id: 267,
    os: "windows",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.142",
  },
  {
    id: 265,
    os: "windows",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.140",
  },
  {
    id: 264,
    os: "windows",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.139",
  },
  {
    id: 262,
    os: "windows",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.156",
  },
  {
    id: 261,
    os: "windows",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.150",
  },
  {
    id: 2804,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300G",
    ip: "103.127.198.149",
  },
  {
    id: 260,
    os: "windows",
    cores: "16",
    memory: "32GB",
    disk_limit: "120G",
    ip: "116.103.108.149",
  },
  {
    id: 259,
    os: "windows",
    cores: "4",
    memory: "8GB",
    disk_limit: "120G",
    ip: "116.103.108.148",
  },
  {
    id: 197,
    os: "centos",
    cores: "16",
    memory: "32GB",
    disk_limit: "300GB",
    ip: "116.103.108.235",
  },
];

function TableCloudServices(props) {
  const {
    tableData,
    totalDoc,
    pageIndex,
    pageSize,
    onChangePage,
    isLoading,
    handleAddCloud,
    allServices,
    columns,
    handleSortExpire,
    type,
    totalInvoice,
  } = props;

  // all data 4 table
  const [inputValue, setInputValue] = useState("");

  const [multiSelect, setMultiSelect] = useState([]);
  const [itemSelected, setItemSelected] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEditItem = (id) => {
    const item = tableData?.find((item) => item?.id === id);

    setMultiSelect((prev) => [...prev, ...item?.domain.split(",")]);
    setItemSelected(item?.service_id);
    setIsModalOpen(true);
  };
  const handleAddSelect = () => {
    setMultiSelect((prev) => [...prev, inputValue]);
    setInputValue("");
  };
  const handleRemoveSelect = (index) => {
    setMultiSelect((prev) => prev.filter((item, i) => i !== index));
  };

  const handleOk = async () => {
    try {
      const data = {
        id: itemSelected,
        hostname: multiSelect.join(","),
      };

      const result = await updateHostnameService(data);

      const { status } = result?.data;

      if (status === RESPONSE_STATUS.SUCCESS) {
        toast({
          title: "Cập nhật tên nhãn thành công",
          status: "success",
        });

        dispatch(
          updateAllService({
            id: itemSelected,
            data: {
              domain: multiSelect.join(","),
            },
          })
        );

        setIsModalOpen(false);
        setMultiSelect([]);

        return;
      }

      // toast({
      //   title: "Cập nhật tên nhãn thất bại",
      //   status: "error",
      // });
    } catch (error) {
      toast({
        title: "Cập nhật tên nhãn thất bại",
        status: "error",
      });
    }
  };
  const { allInvoicesCloudService: allInvoice } = useSelector(
    (state) => state.service
  );

  const dispatch = useDispatch();

  const history = useHistory();
  const [modal, setModal] = useState({
    visible: false,
    data: [],
  });

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    usePagination
  );
  const [chosenIds, setChosenIds] = useState([]);

  const [ids, setIds] = useState([]);
  const [isRenewing, setIsRenewing] = useState(false);

  const [modalRenewalServices, setModalRenewalServices] = useState({
    show: false,
    services: [],
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setGlobalFilter,
  } = tableInstance;
  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const genServiceHard = (id) => {
    return dataServices.find((item) => item.id === Number(id));
  };

  const render = {
    ubuntu: ubuntu,
    debian: debian,
    centos: centos,
    almalinux: almalinux,
    windows: window,
  };

  const genCycle = (value, lang) => {
    if (lang === "vi") {
      return value === "Annually"
        ? "Năm"
        : value === "Quarterly"
        ? "3 tháng"
        : value === "Semi-Annually"
        ? "6 tháng"
        : "Tháng";
    }
    return value === "Annually"
      ? "Year"
      : value === "Quarterly"
      ? "3 Month"
      : value === "Semi-Annually"
      ? "6 Month"
      : "Month";
  };

  const genInvoiceId = (serviceId) => {
    const temp = allInvoice.find((invoice) => {
      return invoice?.items?.find((e) => e?.item_id === serviceId);
    });

    return temp?.id || "";
  };

  useEffect(() => {
    const newIds = tableData?.map((item) => {
      if (chosenIds?.includes(item?.id)) return item.service_id;
    });
    setIds(newIds);
  }, [chosenIds?.length]);

  const handleChooseId = (value) => {
    setChosenIds((prevState) => {
      const temp = prevState.find((id) => id === value);

      if (temp) {
        return prevState?.filter((id) => id !== value);
      }

      return [...prevState, value];
    });
  };

  const handleSelectAllServicesId = (e) => {
    const idsInCurrentPage = tableData?.map((item) => item?.id);

    if (e) {
      setChosenIds(Array.from(new Set([...chosenIds, ...idsInCurrentPage])));
    } else {
      setChosenIds((prevState) => {
        const temp = [];
        prevState?.forEach((id) => {
          if (!idsInCurrentPage?.includes(id)) temp.push(id);
        });
        return temp;
      });
    }
  };

  const isCheckedAll = () => {
    const idsInCurrentPage = tableData?.map((item) => item?.id);
    if (idsInCurrentPage?.length === 0) return false;
    let result = true;

    idsInCurrentPage?.forEach((id) => {
      if (!chosenIds?.includes(id)) {
        result = false;
      }
    });

    return result;
  };

  const handlePayment = (data) => {
    let result = [];

    result = allServices.filter((service) => {
      return chosenIds.includes(service?.id);
    });
    setModal({
      data: data || result,
      visible: true,
    });
  };

  const genDataModal = (data) => {
    let amount = 0;
    let invoices = "Services: ";

    // data?.forEach((item: any, index: number) => {
    //     amount += Number(item?.linetotal) || 0
    //     invoices += `GOF${item?.invoice_id}${
    //         index < data?.length - 1 ? ',' : ''
    //     } `
    // })

    data?.forEach((item, index) => {
      amount += Number(item?.total) || 0;
      invoices += `${item?.id}${index < data?.length - 1 ? "," : ""} `;
    });

    return {
      amount,
      invoices,
    };
  };

  const handleRenewalCloudService = (row) => {
    Swal.fire({
      title: row?.domain,
      text: t("common.service.confirmRenewal"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsRenewing(true);
          toast({
            title: t("common.service.reviewing"),
            status: "info",
          });

          const result = await renewalCloudService(row?.service_id);
          if (result?.data?.status === RESPONSE_STATUS.FAILED) {
            toast({
              title: result?.data?.message,
              status: "error",
            });
            return;
          }

          if (result?.data?.status === RESPONSE_STATUS.REDIRECT) {
            handlePayment([row]);
            return;
          }

          toast({
            title: t("common.service.successfullyRenewal"),
            status: "success",
          });
          dispatch(getUserCredit({}));
          dispatch(getAllCloudServices());
          dispatch(getAllInvoicesCloudServiceByClient());
        } catch (error) {
          console.log(error);
        } finally {
          setIsRenewing(false);
        }
      }
    });
  };

  const handleRenewalCloudManyServices = async () => {
    try {
      const terminatedServices = allServices
        .filter((service) => {
          return chosenIds.includes(service?.id);
        })
        ?.filter((item) => item?.status !== "Active");

      if (terminatedServices?.length > 0) {
        handlePayment(null);
      } else {
        Swal.fire({
          title:
            "- " +
            allServices
              .filter((service) => {
                return chosenIds.includes(service?.id);
              })
              ?.map((item) => item?.domain)
              ?.toString()
              ?.replace(",", "<br />- "),
          text: t("common.service.confirmRenewal"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const result = await renewalCloudManyServices(chosenIds);
            const { status } = result.data;
            if (status === RESPONSE_STATUS.FAILED) {
              toast({
                title: "Renewal services are failed",
                status: "error",
              });
              return;
            }

            if (status === RESPONSE_STATUS.REDIRECT) {
              handlePayment(null);
              return;
            }

            toast({
              title: t("common.service.successfullyRenewal"),
              status: "success",
            });
            dispatch(getUserCredit({}));
            dispatch(getAllCloudServices());
            dispatch(getAllInvoicesCloudServiceByClient());
          }
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Renewal services are failed",
        status: "error",
      });
    }
  };

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{
          sm: "scroll",
          lg: "scroll",
          xl: "scroll",
          "2xl": "scroll",
        }}
      >
        {chosenIds?.length > 0 && (
          <Flex gap={4}>
            <Button
              variant="brand"
              h="40px"
              mt={"12px"}
              mb={"18px"}
              fontSize="12px"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              onClick={() =>
                setModalRenewalServices({
                  show: true,
                  services: allServices.filter((service) => {
                    return chosenIds.includes(service?.id);
                  }),
                })
              }
            >
              {t("common.service.paymentService")}
            </Button>
            {ids?.length > 0 && (
              <Button
                variant="brand"
                h="40px"
                mt={"12px"}
                mb={"18px"}
                fontSize="12px"
                fontWeight="bold"
                alignSelf={{ sm: "flex-start", lg: null }}
                onClick={() =>
                  history.push(
                    `/cloud-services/invoice-detail/${Array.from(
                      new Set(ids)
                    ).join("-")}`
                  )
                }
              >
                {t("common.service.createInvoice")}
              </Button>
            )}
          </Flex>
        )}

        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    borderColor="#56577A"
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="0px"
                    key={index}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header") === "checkbox" ? (
                        <Checkbox
                          isChecked={Boolean(isCheckedAll())}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleSelectAllServicesId(e.target.checked);
                          }}
                          colorScheme="brand"
                        />
                      ) : column.render("Header") === "EXPIRATION" ? (
                        <>
                          {column.render("label")}
                          <UpDownIcon
                            onClick={handleSortExpire}
                            cursor={"pointer"}
                            _hover={{
                              color: "brand.400",
                            }}
                          />
                        </>
                      ) : (
                        column.render("label")
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td colSpan={9}>
                  <Flex py="2rem" justifyContent="center" w="100%">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#582cff"
                      size="xl"
                    />
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          ) : tableData?.length === 0 ? (
            <Tbody>
              <Tr>
                <Td colSpan={9}>
                  <Flex py="2rem" justifyContent="center" w="100%">
                    <Text color={"white"}>
                      {t("common.service.emptyService")}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {tableData?.map((row, index) => {
                return (
                  <Tr key={index}>
                    <Td minW="50px">
                      <Checkbox
                        value={row?.id}
                        isChecked={Boolean(
                          chosenIds?.find((id) => id === row?.id)
                        )}
                        onChange={(e) => handleChooseId(e?.target?.value)}
                        colorScheme="brand"
                      />
                    </Td>
                    <Td minW="250px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {row?.name}
                      </Text>
                      {genServiceHard(row?.service_id) ? (
                        <Text mt="8px" fontSize="xs">
                          {genServiceHard(row?.service_id)?.memory}
                          RAM - {genServiceHard(row?.service_id)?.cores ||
                            0}{" "}
                          CPUs -{" "}
                          {genServiceHard(row?.service_id)?.disk_limit || 0}{" "}
                          DISK
                        </Text>
                      ) : (
                        <Text mt="8px" fontSize="xs">
                          {convertMBtoGB(Math.ceil(row?.guaranteed_ram || 0))}
                          GB RAM - {row?.config?.cores || 0} CPUs -{" "}
                          {row.disk_limit || 0}GB DISK
                        </Text>
                      )}
                    </Td>
                    <Td w="200px">
                      <Flex alignItems={"center"} gap={3}>
                        <Tooltip
                          hasArrow
                          label={`${row?.domain.split(",").join(", ")}`}
                          bg="gray.300"
                          color="black"
                        >
                          <Text
                            isTruncated
                            color="blue.100"
                            fontWeight="500"
                            fontSize="sm"
                            maxW={"200px"}
                          >
                            {row?.domain.split(",").join(", ")}
                          </Text>
                        </Tooltip>
                        <Icon
                          cursor={"pointer"}
                          color={"white"}
                          as={EditIcon}
                          fontSize={"20px"}
                          onClick={() => handleEditItem(row?.id)}
                        />
                      </Flex>
                      <Modal
                        closeOnOverlayClick={false}
                        isOpen={isModalOpen}
                        onClose={() => {
                          setIsModalOpen(false);
                          setMultiSelect([]);
                        }}
                        size="3xl"
                      >
                        <ModalOverlay />
                        <ModalContent bgColor={"#5f68c9"}>
                          <ModalHeader color={"white"}>Tên nhãn</ModalHeader>
                          <ModalCloseButton color={"white"} />

                          <ModalBody>
                            <Box w="full" position={"relative"}>
                              <Flex
                                color="gray.400"
                                bg="#0F1535"
                                border="0.5px solid"
                                borderColor="#E2E8F04D"
                                borderRadius="15px"
                                w={"100%"}
                                mt="1rem"
                                _focus={{
                                  borderColor: "white",
                                }}
                                _active={{
                                  borderColor: "white",
                                }}
                                rounded="lg"
                                p={2}
                              >
                                <Flex
                                  align="center"
                                  justify="space-between"
                                  gap={2}
                                >
                                  {multiSelect?.map((item, index) => (
                                    <Flex
                                      key={index}
                                      rounded="md"
                                      bg="gray.300"
                                      align="center"
                                      justify="center"
                                      borderWidth={1}
                                      borderColor="gray.300"
                                    >
                                      <Text
                                        color={"gray.700"}
                                        p={2}
                                        py={1}
                                        fontSize="sm"
                                      >
                                        {item}
                                      </Text>
                                      <Badge
                                        as="span"
                                        p={1.5}
                                        px={2}
                                        fontWeight="semibold"
                                        fontSize="xs"
                                        rounded={"md"}
                                        size={"sm"}
                                        cursor="pointer"
                                        onClick={() =>
                                          handleRemoveSelect(index)
                                        }
                                        bg="gray.300"
                                        _hover={{
                                          bg: "purple.500",
                                          color: "white",
                                        }}
                                      >
                                        x
                                      </Badge>
                                    </Flex>
                                  ))}
                                </Flex>
                                <Input
                                  type="text"
                                  flex="1"
                                  size="sm"
                                  outline={"none"}
                                  textColor={"white"}
                                  border={"none"}
                                  borderColor={"white"}
                                  focusBorderColor="none"
                                  value={inputValue || ""}
                                  onChange={(e) =>
                                    setInputValue(e.target.value)
                                  }
                                />
                                <Button
                                  size="sm"
                                  px={4}
                                  colorScheme="purple"
                                  rounded="md"
                                  fontSize="xs"
                                  onClick={handleAddSelect}
                                >
                                  Add
                                </Button>
                              </Flex>
                            </Box>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              onClick={handleOk}
                              colorScheme="purple"
                              mr={3}
                              size="sm"
                            >
                              Confirm
                            </Button>
                            <Button
                              onClick={() => {
                                setIsModalOpen(false);
                                setMultiSelect([]);
                              }}
                              size="sm"
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Td>
                    <Td minW="150px">
                      {row?.ip ? (
                        <Flex alignItems={"center"} gap={3} mb="2">
                          <Image src={vietnam} w={"24px"} h={"24px"} />
                          <Text
                            as="span"
                            color={"white"}
                            fontWeight={"500"}
                            fontSize={"14px"}
                          >
                            {row?.ip}
                          </Text>
                        </Flex>
                      ) : (
                        <>
                          <LoadingOVH mt="0rem" size="1rem" />
                        </>
                      )}
                      {row?.ip ? (
                        <Text color="white" fontSize="sm" fontWeight="400">
                          {t("common.translation.vi")}
                        </Text>
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td minW="50px">
                      {genServiceHard(row?.service_id) ? (
                        <Image
                          src={render[genServiceHard(row?.service_id)?.os]}
                          w={"32px"}
                          h={"32px"}
                        />
                      ) : (
                        <Image
                          src={
                            imageTemplate.find((item) =>
                              row?.os
                                ?.toLowerCase()
                                ?.includes(item?.value || "")
                            )?.image
                          }
                          w={"32px"}
                          h={"32px"}
                        />
                      )}
                    </Td>
                    <Td minW={"200px"} textAlign={"right"}>
                      <Text color={"blue.200"} fontWeight={"600"}>
                        {convertToUsd(Number(row?.total), getLanguage())}
                      </Text>
                      <HSeparator my="2" />

                      <Text
                        fontSize={"14px"}
                        fontWeight={"500"}
                        color={"white"}
                      >
                        {genCycle(row?.billingcycle, getLanguage())}
                      </Text>
                    </Td>
                    <Td minW="150px">
                      {!row?.expires || row?.expires === "0000-00-00" ? (
                        <div></div>
                      ) : (
                        <>
                          <Text
                            mb="2"
                            color="white"
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {row?.expires}
                          </Text>
                          <Text color="red.500" fontSize="sm" fontWeight="600">
                            {getMonthDateGap(row?.expires).months === 0
                              ? null
                              : getMonthDateGap(row?.expires).months +
                                `${t("common.service.month")}`}{" "}
                            {getMonthDateGap(row?.expires).days +
                              ` ${t("common.service.nextDay")} `}{" "}
                          </Text>
                        </>
                      )}
                    </Td>
                    <Td minW="150px">
                      {row?.ip ? (
                        <>
                          {row?.status === "Active" ? (
                            <Tag bgColor={"green.400"} color={"white"}>
                              {t("common.service.active")}
                            </Tag>
                          ) : row?.status === "Terminated" ||
                            row?.status === "Cancelled" ? (
                            <Tag color={"white"} bgColor={"red.400"}>
                              {t("common.service.terminated")}
                            </Tag>
                          ) : (
                            <Tag color={"white"} bgColor={"yellow.500"}>
                              {t("common.service.expires")}
                            </Tag>
                          )}
                        </>
                      ) : (
                        <LoadingOVH size="1rem" mt="0rem" />
                      )}
                    </Td>
                    <Td minW="100px">
                      <Menu placement="bottom-end">
                        <MenuButton
                          color="white"
                          _hover={{
                            color: "#0F1535",
                            background: "white",
                          }}
                          bg="#0F1535"
                          w="40px"
                          h="40px"
                          border="0.5px solid"
                          borderColor="#E2E8F04D"
                          borderRadius="100%"
                          size="sm"
                        >
                          <Flex justifyContent={"center"}>
                            <FiMoreHorizontal size={22} />
                          </Flex>
                        </MenuButton>
                        <MenuList p="0" borderRadius="0" minW="150px">
                          {type !== "modal" && (
                            <MenuItem p="0">
                              <Button
                                color="#fff"
                                _hover={{
                                  color: "#0F1535",
                                  background: "#fff",
                                }}
                                bg="#0F1535"
                                borderRadius="0"
                                size="sm"
                                w="100%"
                                textAlign="left"
                                display="flex"
                                justifyContent="flex-start"
                                onClick={() => handleAddCloud(row)}
                              >
                                <Icon as={AiFillEye} fontSize={"16px"} />
                                <Text ml="12px">
                                  {t("common.header.seeDetail")}
                                </Text>
                              </Button>
                            </MenuItem>
                          )}
                          {row?.status !== "Terminated" &&
                            row?.status !== "Cancelled" && (
                              <MenuItem p="0">
                                <Button
                                  color="#fff"
                                  _hover={{
                                    color: "#0F1535",
                                    background: "#fff",
                                  }}
                                  bg="#0F1535"
                                  borderRadius="0"
                                  size="sm"
                                  w="100%"
                                  textAlign="left"
                                  display="flex"
                                  justifyContent="flex-start"
                                  disabled={isRenewing}
                                  onClick={() => handleRenewalCloudService(row)}
                                >
                                  <Icon
                                    as={FaFileInvoiceDollar}
                                    fontSize={"16px"}
                                  />
                                  <Text ml="12px">
                                    {t("common.table.renewal")}
                                  </Text>
                                </Button>
                              </MenuItem>
                            )}

                          {type !== "modal" && (
                            <MenuItem p="0">
                              <Button
                                color="#fff"
                                _hover={{
                                  color: "#0F1535",
                                  background: "#fff",
                                }}
                                bg="#0F1535"
                                borderRadius="0"
                                size="sm"
                                w="100%"
                                textAlign="left"
                                display="flex"
                                justifyContent="flex-start"
                                onClick={() => {
                                  history.push(
                                    `/cloud-services/invoice-detail/${row?.service_id}`
                                  );
                                }}
                              >
                                <Icon
                                  as={FaFileInvoiceDollar}
                                  fontSize={"16px"}
                                />
                                <Text ml="12px">
                                  {t("common.table.invoice")}
                                </Text>
                              </Button>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="500"
            mb={{ sm: "12px", md: "0px" }}
          >
            Showing {totalDoc > 0 ? pageSize * (pageIndex - 1) + 1 : 0} to{" "}
            {pageSize * pageIndex <= totalDoc ? pageSize * pageIndex : totalDoc}{" "}
            of {totalDoc} entries
          </Text>

          <div className=" col-span-12 md:col-span-6  w-fit text-[18px] uppercase">
            <p className="text-white font-bold rounded-md p-2 ">
              {t("common.table.invoice") + ": "}
              <span className="text-[#90cdf4]">
                {getLanguage() === "en"
                  ? `${Math.round((totalInvoice / 25000) * 100) / 100}`
                  : `${totalInvoice.toLocaleString(3)}`}
                <span>{getLanguage() === "en" ? " $" : " VND"}</span>
              </span>
              <div className="h-[1px] total my-2 w-full"></div>
            </p>
          </div>

          <Box
            mb={{
              sm: "12px",
              lg: "0px",
            }}
          >
            <Pagination
              current={pageIndex}
              total={totalDoc}
              showTitle={false}
              jumpNextIcon={<span>...</span>}
              jumpPrevIcon={<span>...</span>}
              pageSize={pageSize}
              prevIcon={<AiOutlineLeft />}
              nextIcon={<AiOutlineRight />}
              onChange={(current) => {
                onChangePage(current);
              }}
            />
          </Box>
        </Flex>

        <ModalRenewalServices
          show={modalRenewalServices?.show}
          services={modalRenewalServices?.services}
          onClose={() => {
            setModalRenewalServices({
              show: false,
              services: [],
            });
          }}
          columns={[
            {
              Header: "IPV4",
              label: "IPV4",
            },
            {
              Header: "INVOICE",
              label: t("common.table.invoice"),
            },
          ]}
          onPayByBank={() => handlePayment(null)}
        />

        <Modal
          isOpen={modal?.visible}
          onClose={() =>
            setModal({
              data: [],
              visible: false,
            })
          }
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent bgColor={"#5f68c9"}>
            <ModalHeader color={"white"}>Service payment</ModalHeader>
            <ModalCloseButton color={"white"} />

            <ModalBody>
              <Flex
                justifyContent={"space-between"}
                gap={8}
                alignItems={"center"}
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                <Box>
                  <img
                    src={`https://manager.idcviettel.com/vietqr.php?account=6863868686&bankcode=970416&amount=${
                      genDataModal(modal?.data)?.amount
                    }&noidung=${genDataModal(modal?.data)?.invoices}`}
                    style={{
                      height: "200px",
                      maxWidth: "200px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Box>
                <Box color={"white"}>
                  <Text>Account number: 6863868686</Text>
                  <Text>Account owner: CTY TNHH CONG NGHE VNG CLOUD</Text>
                  <Text>Account bank: ACB</Text>
                  <Text>
                    Content billing: {genDataModal(modal?.data).invoices}
                  </Text>
                  <Text>
                    Amount:{" "}
                    {convertMoney(
                      Number(genDataModal(modal?.data).amount) || 0
                    )}
                    đ
                  </Text>
                </Box>
              </Flex>

              <Text textAlign={"center"} mt="8" color={"white"}>
                Please fill in the correct invoice number and amount or scan the
                code QR for payment.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() =>
                  setModal({
                    data: [],
                    visible: false,
                  })
                }
                size="sm"
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
}

export default TableCloudServices;
