import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

function LoadingOVH({ size = "5rem", mt = "4rem" }) {
  return (
    <Box mt={mt} w="100%" textAlign="center">
      <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.200"
        color="blue.500"
        w={size}
        h={size}
      />
    </Box>
  );
}

export default LoadingOVH;
