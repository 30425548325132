import { combineReducers } from "redux";
import userReducer from "./slices/userSlice";
import { configureStore } from "@reduxjs/toolkit";
import serviceReducer from "./slices/serviceSlice";

const rootReducer = combineReducers({
  user: userReducer,
  service: serviceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
