import { useEffect } from "react";
import { useState } from "react";
import logo from "assets/img/logo-gradient.svg";

const PageLoader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {isLoading && (
        <div id="fuse-splash-screen">
          <div>
            <img width="128" src={logo} alt="logo" />
          </div>
        </div>
      )}
    </>
  );
};

export default PageLoader;
