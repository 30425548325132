import React from "react";
import { useTranslation } from "react-i18next";
import ExcelJS from "exceljs";
function ExportExcel({ allService }) {
  const { t, i18n } = useTranslation();

  const selectedFields = [
    "product_name",
    "domain",
    "ip",
    "os",
    "guaranteed_ram",
    "disk_limit",
    "total",
    "expires",
    "status",
  ];

  const renamedHeader = [
    "servie",
    "SERVICE LABEL",
    "IPV4",
    "os",
    "RAM",
    "SSD",
    "INVOICE (VND)",
    "EXPIRATION",
    "STATUS",
  ].map((header) => header.toUpperCase());

  const handleExportExcel = async () => {
    // i18n.changeLanguage("vi");

    const workbook = new ExcelJS.Workbook();
    workbook.eachSheet((sheet) => {
      if (sheet.status === "Data") {
        sheet.pageSetup = {
          fitToPage: true,
          fitToWidth: 1,
          fitToHeight: 1,
          horizontalCentered: true, // Căn giữa theo chiều ngang
          verticalCentered: true, // Căn giữa theo chiều dọc
        };
      }
    });
    // Lặp qua các status khác nhau
    const uniqueIds = [...new Set(allService.map((item) => item.status))];
    uniqueIds.forEach((status) => {
      // Tạo một sheet mới cho mỗi status
      const worksheet = workbook.addWorksheet(`${status}`);

      const dataForId = allService
        .map((item) => {
          return {
            ...item,
            total: item?.total / 25000,
            guaranteed_ram: item?.guaranteed_ram / 1024,
          };
        })
        .filter((item) => item.status === status);

      // Thêm header với các trường đã chọn
      const headerRow = worksheet.addRow(renamedHeader);

      headerRow.eachCell((cell, index) => {
        // Lấy tên của trường gốc
        const originalFieldName = selectedFields[index];
        // Nếu tên không khớp với header mới, thêm tên của trường gốc vào comment
        if (cell.value !== renamedHeader[index]) {
          cell.note = originalFieldName;
        }

        // Định dạng font của header
        cell.font = {
          bold: true,
          bgColor: "rgba(255,255,255)",
          color: { argb: "FFFFFF" },
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "528B8B" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      headerRow.height = 50;
      const coloredColumns = [1, 5];
      dataForId.forEach((item, index) => {
        const row = selectedFields.map((field) => item[field]);
        const dataRow = worksheet.addRow(row);

        const priceCell = dataRow.getCell(5);
        priceCell.numFmt = "#,###"; // Định dạng số float

        dataRow.eachCell((cell, colNumber) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
          dataRow.height = 25;
        });
        coloredColumns.forEach((colIndex) => {
          worksheet
            .getColumn(colIndex)
            .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
              if (rowNumber > 1) {
                // Kiểm tra nếu không phải là hàng header
                // Thiết lập màu cho ô trong cột
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "87CEFA" }, // Màu sắc bạn muốn áp dụng cho ô
                };
              }
            });
        });
      });

      // Tự động điều chỉnh chiều rộng của các cột dựa trên nội dung
      worksheet.columns.forEach((column, columnIndex) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = (maxLength < 10 ? 10 : maxLength + 5) + 2; // Đặt chiều rộng tối thiểu là 10
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `invoice.xlsx`);
  };

  return (
    <div className="text-black grid gap-5">
      <button
        className="md:text-[16px] font-bold uppercase tracking-wide bg-[#edf2f7] px-4 py-2 rounded-xl"
        onClick={handleExportExcel}
      >
        {t("common.service.exportExcel")}
      </button>
    </div>
  );
}

export default ExportExcel;
