export const formatPriceVND = (val) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "VND",
  }).format(val);

export const formatPriceUSD = (val) =>
  new Intl.NumberFormat("en-HOSSDDG", {
    style: "currency",
    currency: "USD",
  }).format(val);
