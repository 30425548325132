import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
// Assets
import signInImage from "assets/img/bg-signin1.png";

import { useLocation, useHistory } from "react-router-dom";
import { useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { login2Fa } from "services/api";
import { useEffect } from "react";
import { updateUser } from "redux/slices/userSlice";

function Authentication2FA() {
  const titleColor = "white";
  const textColor = "gray.400";
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    duration: 5000,
    position: "top-end",
    isClosable: true,
  });

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location?.state?.userId) {
      history.push("/auth/sign-in");
    }
  }, []);

  const handleVerify = async () => {
    try {
      if (!token) {
        setError("The Confirmation code is empty");
        return;
      }
      setIsLoading(true);

      const formData = new FormData();
      formData.append("id", location?.state?.userId);
      formData.append("token", token);

      const result = await login2Fa(formData);

      const { user, accessToken } = result?.data?.data;
      dispatch(updateUser(user));
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      history.push("/admin/services/list");

      setError("");
    } catch (error) {
      setError("The confirmation is incorrect");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex position="relative">
      <Flex
        minH="100vh"
        h={{ base: "120vh", lg: "fit-content" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: "100px", md: "0px" }}
        flexDirection="column"
        me={{ base: "auto", lg: "50px", xl: "auto" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          w={{ base: "100%", md: "50%", lg: "450px" }}
          px="50px"
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{ base: "50px", md: "150px", lg: "160px", xl: "245px" }}
            mb={{ base: "60px", lg: "95px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Two-Factor Authentication
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your two-factor authentication to sign in
            </Text>

            <OTPInput
              value={token}
              onChange={(e) => {
                setToken(e);
              }}
              numInputs={6}
              renderSeparator={
                <Text color={"white"} minW="10px">
                  {" "}
                </Text>
              }
              renderInput={(props) => {
                return (
                  <Input minW={"50px"} h="50px" color={"white"} {...props} />
                );
              }}
            />

            {error && (
              <Text color={"red.400"} mt="2">
                {error}
              </Text>
            )}

            <Button
              isLoading={isLoading}
              variant="brand"
              w="85px"
              h="35px"
              fontSize="xs"
              fontWeight="bold"
              alignSelf={{ sm: "flex-start", lg: null }}
              mt="2rem"
              onClick={handleVerify}
            >
              CONFIRM
            </Button>
          </Flex>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb="80px"
        ></Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: "50vw", lg: "50vw" }}
          minH="100vh"
          w="960px"
          position="absolute"
          left="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="absolute"
          >
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500"
            ></Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)"
            ></Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Authentication2FA;
