// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import { BiWorld } from "react-icons/bi";
// Chakra Imports
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { updateUser } from "redux/slices/userSlice";
import { formatMoney } from "utils";
import { convertToUsd } from "utils/convertToUsd";
import { useTranslation } from "react-i18next";
import { setLanguage } from "utils";
import { getLanguage } from "utils";
import { AiFillCaretDown, AiOutlineReload } from "react-icons/ai";
import { getUserNotification } from "../../services/api";
import moment from "moment";
import TableCloudServices from "components/Tables/TableCloudServices";
import useDebounce from "hooks/useDebounce";
import { updateLang } from "redux/slices/userSlice";

export default function HeaderLinks(props) {
  const {
    routes,
    variant,
    children,
    fixed,
    secondary,
    // onOpen,
    ...rest
  } = props;

  const { user, surplus } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en");

  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onClose, onOpen: onOpenProfile } = useDisclosure();

  const { isOpen: isOpenVps, onOpen, onClose: onCloseVps } = useDisclosure();
  const [notification, setNotification] = useState([]);

  const getNotification = async () => {
    try {
      const notifications = await getUserNotification();
      setNotification(notifications.data?.notifications);
    } catch (error) {
      console.log(error);
    }
  };

  // Chakra Color Mode
  let mainBrand = "white";
  let inputBg = "#0F1535";
  let mainText = "white";
  let navbarIcon = "white";
  let searchIcon = "white";

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();

  const handleLogout = () => {
    dispatch(updateUser(null));
    localStorage.removeItem("accessToken");
    history.push("/sign-in");
  };

  const onChangeLanguage = (language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language);
    setLanguage(language);
    dispatch(updateLang(language));
  };

  useEffect(() => {
    getNotification();
  }, []);

  //////////////////

  const columns = [
    {
      Header: "checkbox",
    },
    {
      Header: "SERVICE",
      label: t("common.table.service"),
    },
    {
      Header: "SERVICE LABEL",
      label: t("common.table.serviceLabel"),
    },
    {
      Header: "IPV4",
      label: "IPV4",
    },
    {
      Header: "OS",
      label: t("common.table.os"),
    },
    {
      Header: "INVOICE",
      label: t("common.table.invoice"),
    },
    {
      Header: "EXPIRATION",
      label: t("common.table.expiration"),
    },
    {
      Header: "STATUS",
      label: t("common.table.status"),
    },
    {
      Header: "ACTION",
      label: t("common.table.action"),
    },
  ];

  const ListMenuCloud = [
    {
      optionId: 1,
      name: t("common.service.active"),
      isCloud: false,
      cloudId: "Active",
    },
    {
      optionId: 2,
      name: t("common.service.terminated"),
      isCloud: false,
      cloudId: "Terminated",
    },
    {
      optionId: 3,
      name: t("common.service.expires"),
      isCloud: false,
      cloudId: "Expires",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [listMenuCloud, setListMenuCloud] = useState(ListMenuCloud);
  const [notificationsData, setNotificationsData] = useState();
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDoc, setTotalDoc] = useState(0);
  const searchValue = useDebounce(search, 500);

  // service support for pagination
  const [allServicesExtra, setAllServicesExtra] = useState([]);

  // service to show
  const [services, setServices] = useState([]);

  // all services of user
  const { allService } = useSelector((state) => state.service);

  const handleReloadData = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    const groupedData = {};
    let temp = allService?.filter((item) => {
      var targetDate = moment(item?.expires);

      // Get the current date and time
      var currenttDate = moment();
      // Calculate the difference in days and minutes
      var daysLeft = targetDate.diff(currenttDate, "days");
      if (item?.status === "Active" && daysLeft <= 7 && daysLeft >= 0) {
        if (!groupedData[daysLeft]) {
          groupedData[daysLeft] = [];
        }
        groupedData[daysLeft].push(item);
        return item;
      }
    });
    setAllServicesExtra(temp || []);
    setNotificationsData(groupedData || {});
    setServices(temp);
    setPageIndex(1);
    setSearch(searchValue);
  }, [allService, isLoading, searchValue]);

  // useEffect(() => {
  // 	// get services by tab
  // 	let temp = [];
  // 	if (tab === "Active") {
  // 		temp = allService?.filter((item) => item?.status === "Active");
  // 	} else if (tab === "Terminated") {
  // 		temp = allService?.filter((item) => item?.status === "Terminated");
  // 	} else {
  // 		temp = allService.filter((item) => {
  // 			const temp =
  // 				new Date(item?.next_due).getTime() - Date.now() <
  // 				7 * 24 * 60 * 60 * 1000;

  // 			return temp;
  // 		});
  // 	}
  // 	if (searchValue) {
  // 		const result = temp.filter((item) => {
  // 			return (
  // 				item?.domain?.includes(searchValue) ||
  // 				item?.config?.ip?.includes(searchValue)
  // 			);
  // 		});

  // 		setAllServicesExtra(result);
  // 		setServices(result?.slice(0, pageSize));
  // 	} else {
  // 		setAllServicesExtra(temp || []);
  // 		setServices(temp?.slice(0, pageSize) || []);
  // 	}

  // 	setPageIndex(1);
  // }, [searchValue]);

  const handleAddCloud = (item) => {
    let menuCloudItem = listMenuCloud.find((x) => x.cloudId === item.id);
    if (!menuCloudItem) {
      let menuCloudItem = {
        optionId: listMenuCloud.length + 1,
        name: "Service detail #" + item.id,
        isCloud: true,
        cloudId: item.id || "",
      };
      setListMenuCloud([...listMenuCloud, menuCloudItem]);
      setTab(item?.id);
    } else {
      setTab(item?.id);
    }
  };

  const handleCloseCloudTab = (cloudId) => {
    if (tab === cloudId) {
      setTab(listMenuCloud[0].cloudId);
    }

    setListMenuCloud((prevState) =>
      prevState.filter((item) => item?.cloudId !== cloudId)
    );
  };
  const handleRefreshData = () => {
    setIsLoading(true);
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "0px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      justifyContent={{
        sm: "end",
      }}
    >
      {/* <InputGroup
        color="gray.400"
        bg="#0F1535"
        border="0.5px solid"
        borderColor="#E2E8F04D"
        borderRadius="15px"
        cursor="pointer"
        w={{
          sm: "128px",
          md: "200px",
        }}
        me={{ sm: "auto", md: "20px" }}
        ms={{ sm: "20px", md: "0px"}}
        _focus={{
          borderColor: { mainBrand },
        }}
        _active={{
          borderColor: { mainBrand },
        }}
      >
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
            ></IconButton>
          }
        />
        <Input
          fontSize="xs"
          py="11px"
          color={mainText}
          placeholder="Type here..."
          borderRadius="inherit"
        />
      </InputGroup> */}

      <Text color="white" fontSize="sm">
        {t("common.header.balance")}:
      </Text>
      <Text color="green.400" fontWeight="600" mr="12px" ml="4px">
        {convertToUsd(surplus, getLanguage())}
      </Text>

      <Menu placement="bottom-start">
        <MenuButton>
          <Button
            onClick={onOpenProfile}
            ms="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="transparent-with-icon"
            leftIcon={
              <ProfileIcon color={navbarIcon} w="28px" h="28px" me="0px" />
            }
          >
            <Text
              display={{ sm: "none", md: "flex" }}
              fontSize="sm"
              color="white"
            >
              {user?.firstname} {user?.lastname}
            </Text>
          </Button>
        </MenuButton>
        <MenuList p="0" borderRadius="0" minW="150px">
          <MenuItem p="0">
            <Button
              color="#fff"
              _hover={{
                color: "#0F1535",
                background: "#fff",
              }}
              bg="#0F1535"
              borderRadius="0"
              size="sm"
              w="100%"
              textAlign="left"
              display="flex"
              justifyContent="flex-start"
              onClick={() => history.push("/profile")}
            >
              <Text ml="4px">{t("common.header.seeDetail")}</Text>
            </Button>
          </MenuItem>
          <MenuItem p="0">
            <Button
              color="#fff"
              _hover={{
                color: "#0F1535",
                background: "#fff",
              }}
              bg="#0F1535"
              borderRadius="0"
              size="sm"
              w="100%"
              textAlign="left"
              display="flex"
              justifyContent="flex-start"
              onClick={() => history.push("/change-password")}
            >
              <Text ml="4px">{t("common.header.changePassword")}</Text>
            </Button>
          </MenuItem>
          <MenuItem p="0">
            <Button
              color="red.500"
              _hover={{
                color: "red.500",
                background: "#fff",
              }}
              bg="#0F1535"
              borderRadius="0"
              size="sm"
              w="100%"
              textAlign="left"
              display="flex"
              justifyContent="flex-start"
              onClick={handleLogout}
            >
              <Text ml="4px">{t("common.header.logout")}</Text>
            </Button>
          </MenuItem>
        </MenuList>
      </Menu>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />

      <Modal isOpen={isOpenVps} onClose={onCloseVps} size={"5xl"}>
        <ModalOverlay />
        <ModalContent bg={"#0f143e"}>
          <ModalBody>
            <ModalCloseButton color={"white"} />
            <TableCloudServices
              columns={columns}
              totalDoc={allServicesExtra?.length}
              pageIndex={pageIndex}
              pageSize={pageSize}
              type={"modal"}
              tableData={services}
              onChangePage={(value) => {
                setServices((prevState) => {
                  const newState = allServicesExtra.slice(
                    (value - 1) * pageSize,
                    value * pageSize
                  );

                  return newState;
                });
                setPageIndex(value);
              }}
              handleAddCloud={handleAddCloud}
              allServices={allService}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList
          border="transparent"
          backdropFilter="blur(63px)"
          bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)"
          borderRadius="20px"
          p="16px 8px"
          maxHeight={"500px"}
          overflowY={"auto"}
        >
          <Flex
            bg=""
            alignItems={"center"}
            justifyContent={"center"}
            gap={5}
            mb={5}
          >
            <Text style={{ textTransform: "capitalize" }} color={"white"}>
              {t("common.header.noti")}
            </Text>

            <Button className="bg-">{services?.length} new</Button>
          </Flex>
          {notificationsData &&
            Object.keys(notificationsData)?.map((item, key) => (
              <Flex flexDirection="column">
                <Text
                  fontWeight={"semibold"}
                  p={2}
                  color={"white"}
                  onClick={onOpen}
                  cursor={"pointer"}
                  style={{ textTransform: "capitalize" }}
                >
                  {t("common.header.vps")} {item} {t("common.header.day")} (
                  {notificationsData[item]?.length})
                </Text>
                {/* {notificationsData[item].map((item) => {
                  return (
                    <MenuItem
                      _hover={{
                        bg: "transparent",
                      }}
                      _active={{
                        bg: "transparent",
                      }}
                      _focus={{
                        bg: "transparent",
                      }}
                      borderRadius="8px"
                      mb="5px"
                      border={"1px solid #E2E8F04D"}
                      cursor={"text"}
                    >
                      <Text color={"white"}> {item.name}</Text>
                    </MenuItem>
                  );
                })} */}
              </Flex>
            ))}
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton display={"flex"} ml={"10px"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ color: "white", textTransform: "uppercase" }}>
              {getLanguage()}
            </p>
            <AiFillCaretDown style={{ color: "white" }} />
          </div>
        </MenuButton>
        <MenuList
          border="transparent"
          backdropFilter="blur(63px)"
          bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)"
          borderRadius="20px"
          minWidth={"initial"}
        >
          <Flex flexDirection="column">
            <MenuItem
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              borderRadius="8px"
              onClick={() => onChangeLanguage("vi")}
            >
              <Text color={"white"} display={"flex"} gap={"10px"}>
                <img width={20} height={20} src="/locales/icon/vietnam.png" />
                {t("common.translation.vi")}
              </Text>
            </MenuItem>
            <MenuItem
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              borderRadius="8px"
              onClick={() => onChangeLanguage("en")}
            >
              <Text color={"white"} display={"flex"} gap={"10px"}>
                <img
                  width={20}
                  height={20}
                  src="/locales/icon/united-kingdom.png"
                />
                {t("common.translation.en")}
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
