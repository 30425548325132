import { Box, Button, Select, Tag, Text, useToast } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getUserById } from "services/api";
import { getTicketById } from "services/api";
import config from "../../config";
import { saveAs } from "file-saver";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { updateTicket } from "services/api";

function ManagerTicketDetail() {
  const params = useParams();
  const [support, setSupport] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [lastname, setLastname] = useState("");
  const [firtname, setFirtname] = useState("");
  const [status, setStatus] = useState("0");
  const feedBackRef = useRef(null);
  const [feedBack, setFeedBack] = useState("");
  const [error, setError] = useState("");
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const toast = useToast({
    isClosable: true,
    duration: 5000,
    position: "top-right",
  });

  const getSupport = async (id) => {
    try {
      setIsLoading(true);
      const result = await getTicketById(id || "");
      setSupport(result.data?._doc || {});
      setStatus(result?.data?._doc?.status || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getSupport(params?.id);
    }
  }, [params?.id]);

  const getUserByIds = async (userId) => {
    const user = await getUserById(userId);
    setLastname(user?.data?.data?.lastname);
    setFirtname(user?.data?.data.firstname);
  };
  useEffect(() => {
    if (support?.userId) {
      getUserByIds(support?.userId);
    }
  }, [support]);

  const handleDownload = () => {
    saveAs(`${config.API_URL_UPLOAD_FILES}/${support?.file}`, support?.file);
  };

  useEffect(() => {
    let myIframe = document.getElementsByClassName("tox-edit-area__iframe");

    const timer = setTimeout(() => {
      if (myIframe) {
        let doc = myIframe?.[0]?.contentDocument;
        doc.body.innerHTML =
          doc?.body?.innerHTML + "<style>p { color: #fff }</style>";
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleUpdate = async () => {
    try {
      if (feedBack === "") {
        return setError("Feedback is empty");
      }

      const data = {
        modifiedUser: user?.id,
        feedBack: feedBack,
        status: status,
      };

      const result = await updateTicket(support?._id, data);
      if (result?.data?.status === 1) {
        toast({
          status: "success",
          title: "Updating ticket is success",
        });
        history.push("/manager-ticket/list");
      }
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Updating ticket is fail",
      });
    }
  };

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      <Card>
        <CardHeader mb="12px">
          <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
            Manager Ticket
          </Text>
        </CardHeader>
        <CardBody>
          <Box w={"100%"}>
            <Text fontSize={"3xl"} fontWeight={"600"} color={"white"}>
              Title: {support?.subject}
            </Text>

            <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"white"}>
              Sender's name: {firtname} {lastname}
            </Text>
            <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"white"}>
              Email's name: {support?.email}
            </Text>

            <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"white"}>
              Date: {moment(support?.createdAt).format("YYYY-MM-DD HH:mm")}
            </Text>
            <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"white"}>
              Attachment:{" "}
              <Text
                as="span"
                color={"blue.200"}
                cursor={"pointer"}
                onClick={handleDownload}
              >
                {support?.file}
              </Text>
            </Text>
            <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"white"}>
              Priority:{" "}
              <Text as="span">
                {support?.level === 3 ? (
                  <Tag bgColor={"red.400"} color={"white"}>
                    Emergency
                  </Tag>
                ) : support?.level === 2 ? (
                  <Tag bgColor={"yellow.500"} color={"white"}>
                    Priority
                  </Tag>
                ) : (
                  <Tag bgColor={"green.500"} color={"white"}>
                    Normal
                  </Tag>
                )}
              </Text>
            </Text>

            <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"white"}>
              Content:
            </Text>
            <Text
              fontSize={"md"}
              mt="1"
              fontWeight={"400"}
              color={"white"}
              dangerouslySetInnerHTML={{ __html: support?.body }}
            />

            <Text
              fontSize="md"
              color="#fff"
              fontWeight="bold"
              mb="6px"
              mt="1rem"
            >
              Status: {status}
            </Text>

            <Select
              mt="8px"
              border=".5px solid"
              borderColor="rgba(226, 232, 240, 0.3)"
              _hover="none"
              bg="#0F1535"
              borderRadius="15px"
              color="gray.400"
              fontSize="sm"
              w={"300px"}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {[
                { value: "0", name: "Unconfirmed" },
                { value: "2", name: "Confirmed" },
                { value: "1", name: "Solved" },
              ]?.map((item) => (
                <option value={item?.value} key={item?.value}>
                  {item?.name}
                </option>
              ))}
            </Select>

            <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"white"}>
              Feedback:
            </Text>

            <Box className="content-support-editor" w={"100%"}>
              <Editor
                apiKey="8bdvb1zv2m8b9x4zqybrj7ob3b1x3p856kjodqdblirisbmh"
                value={feedBack}
                ref={feedBackRef}
                onEditorChange={(e) => setFeedBack(e)}
              />
            </Box>
            {error && (
              <Text fontSize={"md"} mt="4" fontWeight={"400"} color={"red.400"}>
                {error}
              </Text>
            )}
            <Button
              type="submit"
              variant="brand"
              w="125px"
              h="40px"
              fontSize="xs"
              mt="2rem"
              fontWeight="bold"
              // isLoading={isLoadingCreate}
              onClick={handleUpdate}
            >
              UPDATE TICKET
            </Button>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
}

export default ManagerTicketDetail;
