import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Form, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllDepartments } from "services/api";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import { createNewTicket } from "services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  level: yup.string().required().default("1"),
  room: yup.string().required("You have not selected a processing room"),
  subject: yup.string().required("You have not entered a title"),
});
function slugify(text) {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphens
}


function SupportCreate() {
  const contentRef = useRef(null);
  const [content, setContent] = useState("");
  const [processingRooms, setProcessingRooms] = useState([]);
  const [avatar, setAvatar] = useState();
  const chooseAvatarRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const history = useHistory();
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema, { stripUnknown: true, abortEarly: false }),
  });

  const level = watch("level", "1");
  const processingRoom = watch("room");
  const { lang } = useSelector((state) => state.user);

  const getAllProcessingRoom = async () => {
    try {
      const result = await getAllDepartments();
      setProcessingRooms(result.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  // useEffect(() => {
  //   getAllProcessingRoom();

  //   let myIframe = document.getElementsByClassName("tox-edit-area__iframe");

  //   const timer = setTimeout(() => {
  //     if (myIframe) {
  //       let doc = myIframe?.[0]?.contentDocument;
  //       doc.body.innerHTML =
  //         doc?.body?.innerHTML + "<style>p { color: #fff }</style>";
  //     }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const onChooseAvatar = (e) => {
    setAvatar(e?.target?.files?.[0]);
  };

  const onSubmit = async (data) => {
    setIsLoadingCreate(true);
    const formData = new FormData();
    formData.append("level", data?.level);
    formData.append("subject", data.subject || "");
    formData.append("body", content || "");
    formData.append("user", user?.id);
    formData.append("file", avatar);
    formData.append("dept_id", data?.room);
    formData.append("status", "0");
    try {
      const result = await createNewTicket(formData);

      toast({
        status: "success",
        title: "Create ticket successfully",
      });

      history.push("/supports/list");
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Create ticket failed",
      });
    } finally {
      setIsLoadingCreate(false);
    }
  };

  const { t } = useTranslation();

  const changeNameRoomLang = (name, lang) => {
    if (lang === "vi") return name;

    if (name?.includes("kỹ thuật")) return "Technical Department";
    if (name?.includes("kinh doanh")) return "Business Department";

    return name;
  };

  const genRoomsByLang = useMemo(() => {
    if (lang === "en") {
      return processingRooms?.map((item) => ({
        ...item,
        name: changeNameRoomLang(item?.name, lang),
      }));
    }

    return processingRooms;
  }, [lang, processingRooms]);

  const levels = [
    {
      title:  `${t("common.ticket.normal")}`,
      value: "1",
    },
  
    {
      title:  `${t("common.ticket.priority")}`,
      value: "2",
    },
    {
      title:  `${t("common.ticket.emergency")}`,
      value: "3",
    },
  ];

  return (
    <Box pt={{ sm: "125px", md: "75px" }}>
      <Card>
        <CardHeader mb="12px">
          <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
          {t("common.ticket.createTicket")}
          </Text>
        </CardHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize="md" color="#fff" fontWeight="bold" mb="6px">
          {t("common.ticket.priorityLevel")}:
          </Text>
          <Flex gap={[3]}>
            {levels?.map((item) => (
              <Flex
                pl="12px"
                py="16px"
                bg="rgb(31, 35, 89)"
                borderColor={
                  level === item?.value ? "brand.100" : "transparent"
                }
                borderWidth={"4px"}
                borderRadius="20px"
                align="center"
                cursor={"pointer"}
                w="200px"
                key={item.title}
                _hover={{
                  borderColor: "brand.100",
                }}
                onClick={() => setValue("level", item.value)}
              >
                <Text color="#fff" fontSize="sm">
                  {item?.title}
                </Text>
              </Flex>
            ))}
          </Flex>

          <Text fontSize="md" color="#fff" fontWeight="bold" mb="6px" mt="1rem">
          {t("common.ticket.processingRoom")}:
          </Text>

          <Select
            mt="8px"
            border=".5px solid"
            borderColor="rgba(226, 232, 240, 0.3)"
            _hover="none"
            bg="#0F1535"
            borderRadius="15px"
            color="gray.400"
            fontSize="sm"
            placeholder={t("common.ticket.selectProcessingRoom")}
            w={"300px"}
            value={processingRoom}
            onChange={(e) => setValue("room", e.target.value)}
          >
            {genRoomsByLang?.map((item) => (
              <option value={item?._id} key={item?._id}>
                {item?.name}
              </option>
            ))}
          </Select>
          <Text color="red.500" mb="12px">
            {errors.room?.message}
          </Text>

          <Text fontSize="md" color="#fff" fontWeight="bold" mb="6px" mt="1rem">
            {t("common.ticket.title")}:
          </Text>

          <Textarea
            placeholder={t("common.ticket.requestedContent")}
            minH="120px"
            fontSize="sm"
            border=".5px solid"
            borderColor="rgba(226, 232, 240, 0.3)"
            color="#fff"
            bg="#0F1535"
            borderRadius="15px"
            {...register("subject")}
          />
          <Text color="red.500" mb="12px">
            {errors.subject?.message}
          </Text>

          <Text fontSize="md" color="#fff" fontWeight="bold" mb="6px" mt="1rem">
          {t("common.ticket.requestedContent")}:
          </Text>

          <Box className="content-support-editor1">
            <Editor
              apiKey="8bdvb1zv2m8b9x4zqybrj7ob3b1x3p856kjodqdblirisbmh"
              value={content}
              ref={contentRef}
              onEditorChange={(e) => setContent(e)}
            />
          </Box>

          <Text fontSize="md" color="#fff" fontWeight="bold" mb="6px" mt="1rem">
            {t("common.ticket.selectAttachments")}:
          </Text>
          <Flex alignItems={"center"}>
            <Button
              onClick={() => chooseAvatarRef?.current?.click()}
              variant="brand"
              h="40px"
              fontSize="xs"
              fontWeight="bold"
            >
              {t("common.ticket.chooseFile")}
            </Button>
            <Text fontSize="sm" color="gray.200" fontWeight="bold" ms="2rem">
              {avatar?.name}
            </Text>
          </Flex>

          <Text
            fontSize="sm"
            color="gray.200"
            fontWeight="bold"
            mb="6px"
            mt="1rem"
          >
            <i>{t("common.ticket.fileTypes")} .gif, .jpeg, .jpg, .png, .pdf</i>
          </Text>

          <Button
            type="submit"
            variant="brand"
            w="125px"
            h="40px"
            fontSize="xs"
            mt="2rem"
            fontWeight="bold"
            isLoading={isLoadingCreate}
          >
            {t("common.ticket.createTicket")}
          </Button>
        </form>
      </Card>

      <input
        hidden
        ref={chooseAvatarRef}
        type="file"
        onClick={(e) => {
          e.target.value = null;
        }}
        accept=".gif, .jpeg, .jpg, .png, .pdf"
        onChange={onChooseAvatar}
      />
    </Box>
  );
}

export default SupportCreate;
