import {
  Avatar,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  LightMode,
  DarkMode,
  Image,
  Select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Box,
  Spinner,
  Tag,
} from "@chakra-ui/react";
import Pagination from "rc-pagination";
import React, { useMemo } from "react";
import { AiFillEye, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle, FaUndoAlt } from "react-icons/fa";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { convertMBtoGB } from "utils";
import { useHistory } from "react-router-dom";
import window from "assets/img/ovh/windows_server.svg";
import ubuntu from "assets/img/ovh/ubuntu.svg";
import centos from "assets/img/ovh/centos.svg";
import debian from "assets/img/ovh/debian.svg";
import fedora from "assets/img/ovh/fedora.svg";

import almalinux from "assets/img/ovh/almalinux.svg";
import cpanel from "assets/img/ovh/cpanel.svg";
import docker from "assets/img/ovh/docker.svg";
import rocky_linux from "assets/img/ovh/rocky_linux.svg";
import plesk_debian from "assets/img/ovh/plesk_debian.svg";
import { getMonthDateGap } from "utils";
import { FiMoreHorizontal } from "react-icons/fi";
import { BsEye } from "react-icons/bs";
import { genIdNameVps } from "utils";
import { SERVICE_OVH_STATUS } from "utils";
import { useTranslation } from "react-i18next";

export const columns = [
  {
    Header: "SERVICE",
    accessor: "service",
  },
  {
    Header: "IPV4",
    accessor: "ipv4",
  },
  {
    Header: "OS",
    accessor: "os",
  },
  {
    Header: "CREATED AT",
    accessor: "creation",
  },
  {
    Header: "EXPIRATION",
    accessor: "expiration",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "ACTION",
    accessor: "",
  },
];

const imageTemplate = [
  {
    id: 1,
    value: "windows",
    icon: window,
  },
  {
    id: 2,
    value: "ubuntu",
    icon: ubuntu,
  },
  {
    id: 3,
    value: "centos",
    icon: centos,
  },
  {
    id: 4,
    value: "debian",
    icon: debian,
  },
  {
    id: 5,
    value: "almalinux",
    icon: almalinux,
  },
  {
    id: 6,
    value: "fedora",
    icon: fedora,
  },
  {
    id: 7,
    value: "rocky",
    icon: rocky_linux,
  },
  {
    id: 8,
    value: "docker",
    icon: docker,
  },
  {
    id: 9,
    value: "cpanel",
    icon: cpanel,
  },
  {
    id: 10,
    value: "plesk",
    icon: plesk_debian,
  },
];

function TableServices(props) {
  const {
    tableData,
    totalDoc,
    pageIndex,
    pageSize,
    onChangeSize,
    onChangePage,
    search,
    setSearch,
    isLoading,
  } = props;

  const history = useHistory();

  const { t } = useTranslation();

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setGlobalFilter,
  } = tableInstance;

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          direction={{ lg: "row", sm: "column" }}
          align={{ sm: "flex-start", lg: "center" }}
          justify={{ sm: "flex-start", lg: "space-between" }}
          w="100%"
          px="22px"
          mb="12px"
        >
          <Text fontWeight="600" color="white" mb={{ lg: "0px", sm: "8px" }}>
            LIST OF SERVICES
          </Text>
          <Input
            color="gray.400"
            bg="#0F1535"
            border="0.5px solid"
            borderColor="#E2E8F04D"
            borderRadius="15px"
            type="text"
            placeholder="Search..."
            minW="75px"
            maxW="175px"
            fontSize="sm"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Flex>

        <Stack
          direction={{ sm: "row", md: "row" }}
          spacing={{ sm: "4px", md: "12px" }}
          align="center"
          me="12px"
          my="12px"
          minW={{ sm: "100px", md: "200px" }}
          px="22px"
        >
          <Select
            value={pageSize}
            onChange={(e) => onChangeSize(Number(e.target.value))}
            color="gray.400"
            bg="#0F1535"
            border="0.5px solid"
            borderColor="#E2E8F04D"
            borderRadius="15px"
            size="sm"
            maxW="75px"
            cursor="pointer"
          >
            <option>5</option>
            <option>10</option>
            <option>50</option>
            <option>100</option>
          </Select>
          <Text fontSize="xs" color="gray.400" fontWeight="500">
            entries per page
          </Text>
        </Stack>

        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    borderColor="#56577A"
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="0px"
                    key={index}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                      {/* <Icon
                        w={{ sm: "10px", md: "14px" }}
                        h={{ sm: "10px", md: "14px" }}
                        color={columns.isSorted ? "gray.500" : "gray.400"}
                        float="right"
                        as={
                          column.isSorted
                            ? column.isSortedDesc
                              ? TiArrowSortedDown
                              : TiArrowSortedUp
                            : TiArrowUnsorted
                        }
                      /> */}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td colSpan={7}>
                  <Flex py="2rem" justifyContent="center" w="100%">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#582cff"
                      size="xl"
                    />
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {tableData?.map((row, index) => {
                return (
                  <Tr key={index}>
                    <Td minW="300px">
                      <Text color="white" fontWeight="600" fontSize="lg">
                        {row?.nameVps}
                      </Text>
                      <Text mt="8px" fontSize="xs">
                        {row?.vps?.model?.vcore || 0} CPUs -{" "}
                        {convertMBtoGB(Math.ceil(row?.vps?.model?.memory || 0))}{" "}
                        GB RAM - {row?.vps?.model?.disk || 0} GB DISK
                      </Text>
                    </Td>
                    <Td minW="250px">
                      <Text color="white" fontWeight="400" fontSize="sm">
                        {row?.ips?.[0]}
                      </Text>
                      <Text color="white" fontWeight="400" fontSize="sm">
                        {row?.vps?.zone}
                      </Text>
                    </Td>
                    <Td minW="200px">
                      <Flex alignItems="center">
                        <Image
                          src={
                            imageTemplate?.find((item) =>
                              row?.images?.name
                                ?.toLowerCase()
                                .includes(item?.value)
                            )?.icon
                          }
                        />
                        <Text
                          ml="8px"
                          color="white"
                          fontSize="sm"
                          fontWeight="400"
                        >
                          {row?.images?.name}
                        </Text>
                      </Flex>
                    </Td>
                    <Td minW="150px">
                      <Text color="white" fontSize="sm" fontWeight="400">
                        {row?.info?.creation}
                      </Text>
                    </Td>
                    <Td minW="150px">
                      {row?.info?.expiration && (
                        <>
                          <Text color="white" fontSize="sm" fontWeight="400">
                            {row?.info?.expiration}
                          </Text>
                          <Text color="red.500" fontSize="sm" fontWeight="600">
                            {getMonthDateGap(row?.info?.expiration).months === 0
                              ? null
                              : getMonthDateGap(row?.info?.expiration).months +
                                "month"}{" "}
                            {getMonthDateGap(row?.info?.expiration).days +
                              " next day "}{" "}
                          </Text>
                        </>
                      )}
                    </Td>
                    <Td minW="150px">
                      {row?.info?.expiration && (
                        <>
                          {row?.status === SERVICE_OVH_STATUS.PENDING ? (
                            <Tag bgColor={"yellow.500"} color={"white"}>
                              Pending
                            </Tag>
                          ) : row?.vps?.state === "running" ? (
                            <Tag color={"white"} bgColor={"green.400"}>
                              Active
                            </Tag>
                          ) : (
                            <Tag color={"white"} bgColor={"red.400"}>
                              Stop
                            </Tag>
                          )}
                        </>
                      )}
                    </Td>
                    <Td minW="100px">
                      <Menu placement="bottom-end">
                        <MenuButton
                          color="white"
                          _hover={{
                            color: "#0F1535",
                            background: "white",
                          }}
                          bg="#0F1535"
                          w="40px"
                          h="40px"
                          border="0.5px solid"
                          borderColor="#E2E8F04D"
                          borderRadius="100%"
                          size="sm"
                        >
                          <Flex justifyContent={"center"}>
                            <FiMoreHorizontal size={22} />
                          </Flex>
                        </MenuButton>
                        <MenuList p="0" borderRadius="0" minW="150px">
                          <MenuItem p="0">
                            <Button
                              color="#fff"
                              _hover={{
                                color: "#0F1535",
                                background: "#fff",
                              }}
                              bg="#0F1535"
                              borderRadius="0"
                              size="sm"
                              w="100%"
                              textAlign="left"
                              display="flex"
                              justifyContent="flex-start"
                              onClick={() => {
                                history.push(
                                  `/services/detail/${genIdNameVps(
                                    row?.nameVps
                                  )}`
                                );
                              }}
                            >
                              <AiFillEye size={20} />
                              <Text ml="4px">
                                {t("common.header.seeDetail")}
                              </Text>
                            </Button>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="500"
            mb={{ sm: "12px", md: "0px" }}
          >
            Showing {pageSize * (pageIndex - 1) + 1} to{" "}
            {pageSize * pageIndex <= totalDoc ? pageSize * pageIndex : totalDoc}{" "}
            of {totalDoc} entries
          </Text>
          <Box
            mb={{
              sm: "12px",
              lg: "0px",
            }}
          >
            <Pagination
              current={pageIndex}
              total={totalDoc}
              showTitle={false}
              jumpNextIcon={<span>...</span>}
              jumpPrevIcon={<span>...</span>}
              pageSize={pageSize}
              prevIcon={<AiOutlineLeft />}
              nextIcon={<AiOutlineRight />}
              onChange={(current) => {
                onChangePage(current);
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default TableServices;
