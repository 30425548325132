import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import LoadingOVH from "components/Loading/Loading";
import React, { useState, useEffect, useMemo } from "react";
import { getCatalogCart } from "services/api";
import { BILLING_CYCLE_OVH } from "utils";
import Stepper from "react-stepper-horizontal";
import Step1 from "containers/ServiceCreate/Step1";
import { genPriceOvh } from "utils";
import { genGbps } from "utils";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import teamsImage from "assets/img/teams-image.png";
import { getDataCenterCart } from "services/api";
import { HSeparator } from "components/Separator/Separator";
import generalBg from "assets/img/generalBg.png";
import CardBody from "components/Card/CardBody";
import GradientBorder from "components/GradientBorder/GradientBorder";
import Step2 from "containers/ServiceCreate/Step2";
import Step3 from "containers/ServiceCreate/Step3";
import { getSummaryOrderCart } from "services/api";
import { useTranslation } from "react-i18next";
import { getLanguage } from "utils";
import { convertToVnd } from "utils/convertToUsd";

const images = [
  {
    title: "Distribution only",
    value: "order-funnel:distribution-only",
  },
  {
    title: "Distribution with application",
    value: "order-funnel:distribution-with-application",
  },
];

const initVps = [
  {
    id: 1,
    value: "vps-value-1-2-40",
  },
  {
    id: 2,
    value: "vps-essential-2-4-80",
  },
  {
    id: 3,
    value: "vps-essential-2-8-160",
  },
  {
    id: 4,
    value: "vps-comfort-4-4-80",
  },
  {
    id: 5,
    value: "vps-comfort-4-8-160",
  },
  {
    id: 6,
    value: "vps-comfort-4-16-80",
  },
  {
    id: 7,
    value: "vps-comfort-4-8-320",
  },
  {
    id: 8,
    value: "vps-comfort-4-16-160",
  },
  {
    id: 9,
    value: "vps-elite-8-16-160",
  },
  {
    id: 10,
    value: "vps-elite-8-16-320",
  },
  {
    id: 11,
    value: "vps-elite-8-32-160",
  },
  {
    id: 12,
    value: "vps-elite-8-32-320",
  },
];

function ServiceCreate() {
  const [isLoading, setIsLoading] = useState(false);
  const [catalog, setCatalog] = useState();
  const [step, setStep] = useState(1);
  const [cartId, setCartId] = useState("");
  const [priceSummary, setPriceSummary] = useState();

  const [distribution, setDistribution] = useState("");
  const [listVps, setListVps] = useState([]);
  const [listPlans, setListPlan] = useState([]);
  const [listDataCenters, setListDataCenters] = useState([]);
  const [chosen, setChosen] = useState(0);
  const [os, setOs] = useState("");
  const [license, setLicense] = useState("");

  //os
  const [listOs, setListOs] = useState([]);
  const [chosenFamily, setChosenFamily] = useState();

  //contract duration
  const [duration, setDuration] = useState(0);
  const [billCycle, setBillCycle] = useState(BILLING_CYCLE_OVH.MONTH);

  // option step 2
  const [optionStep2, setOptionStep2] = useState({
    backup: null,
    additionalDisk: null,
    snapshot: null,
  });

  const { t } = useTranslation();

  const getCatalog = async () => {
    try {
      setIsLoading(true);
      const result = await getCatalogCart();
      const catalog = result?.data?.data;
      catalog.addons = catalog?.addons?.map((item) => {
        item.pricings = item.pricings?.map((x) => {
          console.log(x);
          x.price = x.price + 300000000;
          return x;
        });
        return item;
      });
      setCatalog(catalog);

      const list = [];
      const plans = [];
      catalog?.products?.forEach((item) => {
        const id = initVps?.find((i) => i.value === item?.name)?.id;
        if (id && !list?.find((e) => e?.name === item?.name)) {
          list.push({
            id,
            item: item,
          });
        }
      });
      catalog?.plans?.forEach((item) => {
        const id = initVps?.find((i) => i.value === item?.planCode)?.id;
        if (id && !list?.find((e) => e?.name === item?.planCode)) {
          plans.push({
            id,
            item: item,
          });
        }
      });

      list?.sort((a, b) => a?.id - b?.id);
      plans?.sort((a, b) => a?.id - b?.id);

      setListVps(list?.map((item) => item?.item));
      setListPlan(
        plans?.map((item) => {
          const newPricings = item.item?.pricings?.map((price) => {
            if (
              price?.commitment === 0 &&
              price?.phase === 1 &&
              price?.interval === 1
            ) {
              price.price = price?.price + 300000000;
            }

            return price;
          });
          return { ...item?.item, pricings: newPricings };
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCatalog();
  }, []);

  const genDataCenter = useMemo(() => {
    const data = [];
    let count = 0;

    listDataCenters?.forEach((item) => {
      if (item?.quantity > 0) {
        data.push(item);
        count += item?.quantity;
      }
    });

    return {
      data,
      count,
    };
  }, [listDataCenters]);

  // get list os with family and distribute
  useEffect(() => {
    const listOs = listVps?.[chosen]?.blobs?.meta?.configurations?.[0]?.values;

    let result = [];
    const families = [];

    listOs?.forEach((item) => {
      if (
        !families?.find((e) => item?.blobs?.technical?.os?.family === e?.family)
      ) {
        families.push({
          id: item?.blobs?.tags?.[0],
          family: item?.blobs?.technical?.os?.family,
          distribution: item?.blobs?.technical?.os?.distribution,
        });
      }
    });

    images?.forEach((image) => {
      const values = [];
      families?.forEach((family) => {
        if (family.id === image?.value)
          values.push({
            family: family?.family,
            distribution: family.distribution,
            items:
              listOs?.filter(
                (item) => item?.blobs?.technical?.os?.family === family?.family
              ) || [],
          });
      });

      result.push({
        id: image?.value,
        title: image?.title,
        value: values,
      });
    });

    setListOs(result || []);
    setDistribution("");
  }, [listVps, chosen]);

  useEffect(() => {
    const getDataCenter = async () => {
      try {
        const result = await getDataCenterCart(
          os || "",
          listVps?.[chosen]?.name || ""
        );
        const response = result?.data?.data?.datacenters;
        const data =
          listVps?.[chosen]?.blobs?.meta?.configurations?.[1]?.values;

        const list = [];

        for (const res of response) {
          const item = data.find((e) => e.value === res.datacenter);
          if (item && res?.status === "available") {
            list.push({
              ...item,
              quantity: 0,
            });
          }
        }

        setListDataCenters(
          list?.map((e, index) => {
            if (index === 0)
              return {
                ...e,
                quantity: 1,
              };
            return e;
          }) || []
        );
      } catch (error) {
        console.log(error);
      }
    };

    if (listVps?.length > 0) getDataCenter();
  }, [listVps, os, chosen]);

  const vpsOs = useMemo(() => {
    setChosenFamily(null);
    setOs("");
    setLicense("");
    return listOs?.find((i) => i?.id === distribution)?.value || [];
  }, [distribution]);

  const genPriceDurationChosen = useMemo(() => {
    let priceDefault =
      listPlans?.[chosen]?.pricings?.find(
        (item) =>
          item?.commitment === 0 && item?.phase === 1 && item?.interval === 1
      )?.price || 0;

    const optionDuration = [];
    listPlans?.[chosen]?.pricings?.forEach((item) => {
      if (item?.phase === 1 && item?.interval === 1) {
        optionDuration.push({
          value: {
            commitment: item?.commitment,
            price: item?.price,
          },
          label: {
            title:
              item?.commitment === 0
                ? `Without commitment`
                : `${item?.commitment} months`,
            extra: `${Math.floor(
              ((priceDefault - item?.price) / priceDefault) * 100
            )}%`,
          },
        });
      }
    });

    return {
      price: priceDefault,
      duration: optionDuration || [],
    };
  }, [listPlans, chosen]);

  useEffect(() => {
    setDuration(0);
    setBillCycle(BILLING_CYCLE_OVH.MONTH);
    setOptionStep2({
      backup: null,
      additionalDisk: null,
      snapshot: null,
    });
  }, [chosen]);

  const getDiscountBillingCycle = useMemo(() => {
    if (duration === 0) return 0;
    let priceDefault =
      listPlans?.[chosen]?.pricings?.find(
        (item) =>
          item?.commitment === duration &&
          item?.phase === 1 &&
          item?.interval === 1
      )?.price || 0;
    const price =
      listPlans?.[chosen]?.pricings?.find(
        (item) =>
          item?.phase === 1 &&
          item?.commitment === duration &&
          item?.interval === duration
      )?.price || 0;
    return priceDefault * duration - price;
  }, [duration]);

  // gen price with data center and package
  const pricePackage = useMemo(() => {
    let priceDefault =
      listPlans?.[chosen]?.pricings?.find(
        (item) =>
          item?.commitment === 0 && item?.phase === 1 && item?.interval === 1
      )?.price || 0;

    let pricePerMonth = priceDefault;
    let total = priceDefault;

    //  get price with package
    if (duration !== 0) {
      if (billCycle === "month") {
        const temp =
          listPlans?.[chosen]?.pricings?.find(
            (item) =>
              item?.commitment === duration &&
              item?.phase === 1 &&
              item?.interval === 1
          )?.price || 0;

        pricePerMonth = temp;
        total = temp;
      } else {
        const temp =
          listPlans?.[chosen]?.pricings?.find(
            (item) =>
              item?.phase === 1 &&
              item?.commitment === duration &&
              item?.interval === duration
          )?.price || 0;
        pricePerMonth = temp / duration;
        total = temp;
      }
    }

    // calc price of datacenter
    total = total * genDataCenter?.count;

    return {
      priceDefault,
      pricePerMonth,
      total,
    };
  }, [duration, billCycle, genDataCenter, os]);

  // get price total with os
  const genPriceOs = useMemo(() => {
    let total = 0;

    if (os) {
      // get price by os
      const temp =
        catalog?.addons
          ?.find((item) => {
            return (
              item?.planCode?.includes(
                listVps?.[chosen]?.name?.substring(
                  listVps?.[chosen]?.name?.indexOf("-") + 1
                )
              ) && item?.planCode?.includes(os?.split(" ")?.[0]?.toLowerCase())
            );
          })
          ?.pricings?.find(
            (item) =>
              item?.commitment === 0 &&
              item?.interval === 1 &&
              item?.phase === 1
          )?.price || 0;

      total += temp;
    }

    return total;
  }, [os]);

  // get price total with os
  const genPriceLicense = useMemo(() => {
    let total = 0;
    let temp;
    if (license) {
      // get price by os
      temp = catalog?.addons?.find((item) => item.planCode === license);

      total +=
        temp?.pricings?.find(
          (item) =>
            item?.commitment === 0 && item?.interval === 1 && item?.phase === 1
        )?.price || 0;
    }

    return {
      price: total,
      name: temp?.blobs?.commercial?.name,
    };
  }, [license]);

  const genDueDate = () => {
    if (duration === 0 || billCycle === "month")
      return {
        including: "month",
        withThisConfig: `Next month`,
      };

    if (duration === 12 && billCycle === "all")
      return {
        including: "one year",
        withThisConfig: `Next year`,
      };

    return {
      including: "two years",
      withThisConfig: `the two following years`,
    };
  };

  const genPriceByAddon = (addon, listDataCenter = []) => {
    if (!addon) return 0;
    let quantity = 0;
    listDataCenter.map((item) => (quantity += item.quantity));
    return (
      (addon?.pricings?.find(
        (item) =>
          item?.commitment === 0 && item?.interval === 1 && item?.phase === 1
      )?.price || 0) * quantity
    );
  };

  // gen basic data of vps
  const genInformation = useMemo(() => {
    return [
      {
        title: "Processor",
        value: `${listVps?.[chosen]?.blobs?.technical?.cpu?.cores} ${listVps?.[chosen]?.blobs?.technical?.cpu?.model}`,
      },
      {
        title: "Memory",
        value: `${listVps?.[chosen]?.blobs?.technical?.memory?.size} GB`,
      },
      {
        title: "Storage",
        value: `${listVps?.[chosen]?.blobs?.technical?.storage?.disks?.[0]?.capacity} GB ${listVps?.[chosen]?.blobs?.technical?.storage?.disks?.[0]?.technology} ${listVps?.[chosen]?.blobs?.technical?.storage?.disks?.[0]?.interface}`,
      },
      {
        title: "Public bandwidth",
        value: `${genGbps(
          listVps?.[chosen]?.blobs?.technical?.bandwidth?.level
        )}`,
      },
    ];
  }, [listVps, chosen]);

  const summaryOrderCart = async () => {
    try {
      setIsLoading(true);

      const data = {
        duration,
        billCycle,
        vps: listPlans?.[chosen]?.planCode,
        datacenters: genDataCenter?.data?.map((item) => {
          return {
            value: item?.value,
            quantity: item?.quantity,
          };
        }),
        snapshot: optionStep2?.snapshot?.planCode,
        additionalDisk: optionStep2?.additionalDisk?.planCode,
        backup: optionStep2?.backup?.planCode,
        os,
        optionOs:
          catalog?.addons?.find((item) => {
            return (
              item?.planCode?.includes(
                listVps?.[chosen]?.name?.substring(
                  listVps?.[chosen]?.name?.indexOf("-") + 1
                )
              ) && item?.planCode?.includes(os?.split(" ")?.[0]?.toLowerCase())
            );
          })?.planCode ||
          license ||
          "",
      };

      const result = await getSummaryOrderCart(data);
      const { data: res } = result.data;
      setCartId(res?.cartId);
      let additional = 0;
      if (optionStep2?.additionalDisk?.blobs?.commercial?.name) {
        additional += 3;
      }

      if (optionStep2?.snapshot?.blobs?.commercial?.name) {
        additional += 3;
      }

      if (optionStep2?.backup?.blobs?.commercial?.name) {
        additional += 3;
      }
      if(os.includes("Windows Server")){
        additional += 3
      }
      let quantity = 0;
      listDataCenters.forEach((item) => (quantity += item.quantity));
      setPriceSummary({
        ...res.prices,
        withoutTax: {
          ...res.prices.withoutTax,
          value:
            res.prices.withoutTax.value + additional * quantity + quantity * 3 + res.prices?.reduction?.value || 0,
          text: `$${
            Math.round(
              (res.prices.withoutTax.value +
                additional * quantity +
                quantity * 3 + res.prices?.reduction?.value || 0) *
                100
            ) / 100
          } USD`,
        },
        tax: {
          ...res.prices.tax,
          value:
            (res.prices.withoutTax.value +
              additional * quantity +
              quantity * 3+ res.prices?.reduction?.value || 0) /
            10,
          text: `$${
            Math.round(
              ((res.prices.withoutTax.value +
                additional * quantity +
                quantity * 3 + res.prices?.reduction?.value || 0) /
                10) *
                100
            ) / 100
          } USD`,
        },
        withTax: {
          ...res.prices.withTax,
          value:
            (res.prices.withoutTax.value +
              additional * quantity +
              quantity * 3 + res.prices?.reduction?.value || 0) /
              10 +
            (res.prices.withoutTax.value + additional * quantity + res.prices?.reduction?.value || 0) * quantity,
          text: `$${
            Math.round(
              ((res.prices.withoutTax.value +
                additional * quantity +
                quantity * 3 + res.prices?.reduction?.value || 0) /
                10 +
                (res.prices.withoutTax.value +
                  additional * quantity +
                  quantity * 3 + res.prices?.reduction?.value || 0)) *
                100
            ) / 100
          } USD`,
        },
      });
      setStep(step + 1);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const render = {
    1: (
      <Step1
        addons={catalog?.addons}
        genDataCenter={genDataCenter}
        vpsOs={vpsOs}
        listPlans={listPlans}
        listVps={listVps}
        chosenFamily={chosenFamily}
        setChosenFamily={setChosenFamily}
        distribution={distribution}
        setDistribution={setDistribution}
        os={os}
        setOs={setOs}
        chosen={chosen}
        setChosen={setChosen}
        listDataCenters={listDataCenters}
        setListDataCenters={setListDataCenters}
        step={step}
        setStep={setStep}
        license={license}
        setLicense={setLicense}
      />
    ),
    2: (
      <Step2
        chosen={chosen}
        listVps={listVps}
        addons={catalog?.addons}
        step={step}
        setStep={setStep}
        optionStep2={optionStep2}
        setOptionStep2={setOptionStep2}
        onSummaryOrderCart={summaryOrderCart}
      />
    ),
    3: (
      <Step3
        cartId={cartId}
        optionDuration={genPriceDurationChosen?.duration}
        billCycle={billCycle}
        duration={duration}
        setBillCycle={setBillCycle}
        setDuration={setDuration}
        getDiscountBillingCycle={getDiscountBillingCycle}
        genDataCenter={genDataCenter}
        chosen={chosen}
        listVps={listVps}
        pricePackage={pricePackage}
        os={os}
        genPriceOs={genPriceOs}
        setStep={setStep}
        step={step}
        optionStep2={optionStep2}
        genPriceLicense={genPriceLicense}
        family={chosenFamily?.family}
        priceSummary={priceSummary}
        genInformation={genInformation}
      />
    ),
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
      {isLoading ? (
        <LoadingOVH />
      ) : (
        <Box>
          <div className="step-wrapper">
            <Stepper
              steps={[
                { title: "Build" },
                { title: "Enhance" },
                { title: "Summary" },
              ]}
              defaultTitleColor="#fff"
              defaultColor="#aaa"
              completeColor="#82c418"
              activeTitleColor="#fff"
              completeTitleColor="#fff"
              defaultBorderColor="#82c418"
              activeBorderColor="#82c418"
              activeStep={step}
              titleTop={4}
            />
          </div>
          <Grid templateColumns="repeat(4, 1fr)" gap={4} mt={{ lg: "16px" }}>
            {render[step]}
            {step !== 3 && (
              <GridItem colSpan={1}>
                <Card
                  bgImage={teamsImage}
                  bgSize="cover"
                  position={"sticky"}
                  top={"1rem"}
                >
                  <CardHeader
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text color="white" fontWeight={"600"} fontSize={"xl"}>
                      {t("common.service.orderSummary")}
                    </Text>
                    <Text fontSize={"xs"} color="gray.200">
                      Price ex. GST (USD)
                    </Text>
                  </CardHeader>
                  <Stack mt={"0.5rem"} mb={"1rem"}>
                    <Text color="white" fontWeight={"500"} fontSize={"md"}>
                      {t("common.service.dataCenter")}
                    </Text>

                    <HSeparator mb="12px" />
                    {genDataCenter?.data?.map((item) => (
                      <Flex
                        mt={"4px"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        key={item?.value}
                      >
                        <Text
                          color={"white"}
                          fontSize={"sm"}
                          fontWeight={"500"}
                        >
                          {item?.blobs?.technical?.datacenter?.city},{" "}
                          {item?.blobs?.technical?.datacenter?.country}
                        </Text>
                        <Tag
                          bg={"white"}
                          fontSize="12px"
                          size="sm"
                          color="brand.600"
                          borderRadius="15px"
                          alignSelf="flex-start"
                        >
                          {item?.quantity}X
                        </Tag>
                      </Flex>
                    ))}
                  </Stack>

                  <Card bgImage={generalBg} bgSize="cover">
                    <CardHeader justifyContent="space-between">
                      <Text color="white" fontWeight={"600"} fontSize={"lg"}>
                        {listVps?.[chosen]?.description?.split(" ")?.[1]}
                      </Text>

                      <Box className="summary-cart-info-type-price">
                        {pricePackage?.priceDefault !==
                          pricePackage?.pricePerMonth && (
                          <Text
                            color={"red.400"}
                            textDecoration={"line-through"}
                          >
                            US$ {genPriceOvh(pricePackage?.priceDefault, true)}
                          </Text>
                        )}

                        <Text color="white" fontWeight={"500"} fontSize={"sm"}>
                          US$ {genPriceOvh(pricePackage?.pricePerMonth, true)}
                        </Text>
                        <Text color="white" fontWeight={"500"} fontSize={"sm"}>
                          /month
                        </Text>
                      </Box>
                    </CardHeader>
                    {genInformation?.map((item) => (
                      <React.Fragment key={item.title}>
                        <HSeparator my={"8px"} />
                        <Flex h="100%">
                          <Flex
                            bgColor="green.200"
                            borderRadius="12px"
                            minWidth="3px"
                            minH="100%"
                          />
                          <Box ml="1rem">
                            <Text
                              color={"white"}
                              fontWeight={"600"}
                              fontSize={"sm"}
                            >
                              {item.title}
                            </Text>
                            <Text
                              color={"white"}
                              fontWeight={"500"}
                              fontSize={"xs"}
                            >
                              {item.value}
                            </Text>
                          </Box>
                        </Flex>
                      </React.Fragment>
                    ))}

                    {os && (
                      <>
                        <HSeparator my={"8px"} />
                        <Flex h="100%">
                          <Flex
                            bgColor="green.200"
                            borderRadius="12px"
                            minWidth="3px"
                            minH="100%"
                          />
                          <Box ml="1rem">
                            <Text
                              color={"white"}
                              fontWeight={"600"}
                              fontSize={"sm"}
                            >
                              Image
                            </Text>
                            <Text
                              color={"white"}
                              fontWeight={"500"}
                              fontSize={"xs"}
                            >
                              {os}
                            </Text>
                          </Box>
                        </Flex>

                        {(genPriceOs > 0 || genPriceLicense?.price > 0) && (
                          <>
                            <HSeparator my={"8px"} />
                            <Flex h="100%">
                              <Flex
                                bgColor="green.200"
                                borderRadius="12px"
                                minWidth="3px"
                                minH="100%"
                              />
                              <Flex
                                justifyContent={"space-between"}
                                ml="1rem"
                                w={"100%"}
                              >
                                <Box>
                                  <Text
                                    color={"white"}
                                    fontWeight={"600"}
                                    fontSize={"sm"}
                                  >
                                    License
                                  </Text>

                                  <Text
                                    color={"white"}
                                    fontWeight={"500"}
                                    fontSize={"xs"}
                                  >
                                    {genPriceLicense?.price > 0
                                      ? genPriceLicense?.name
                                      : chosenFamily?.family}
                                  </Text>
                                </Box>

                                <Box>
                                  <Text
                                    color={"white"}
                                    fontWeight={"500"}
                                    fontSize={"xs"}
                                  >
                                    US${" "}
                                    {genPriceOvh(
                                      genPriceOs || genPriceLicense?.price,
                                      true
                                    )}
                                  </Text>

                                  <Text
                                    color={"white"}
                                    fontWeight={"400"}
                                    fontSize={"xs"}
                                  >
                                    /month
                                  </Text>
                                </Box>
                              </Flex>
                            </Flex>
                          </>
                        )}
                      </>
                    )}

                    {optionStep2?.snapshot && (
                      <>
                        <HSeparator my={"8px"} />
                        <Flex h="100%">
                          <Flex
                            bgColor="green.200"
                            borderRadius="12px"
                            minWidth="3px"
                            minH="100%"
                          />
                          <Flex
                            justifyContent={"space-between"}
                            ml="1rem"
                            w={"100%"}
                          >
                            <Box>
                              <Text
                                color={"white"}
                                fontWeight={"600"}
                                fontSize={"sm"}
                              >
                                Snapshot
                              </Text>
                              <Text
                                color={"white"}
                                fontWeight={"500"}
                                fontSize={"xs"}
                              >
                                {optionStep2?.snapshot?.blobs?.commercial?.name}
                              </Text>
                            </Box>

                            <Box>
                              <Text
                                color={"white"}
                                fontWeight={"500"}
                                fontSize={"xs"}
                              >
                                US${" "}
                                {genPriceOvh(
                                  genPriceByAddon(
                                    optionStep2?.snapshot,
                                    listDataCenters
                                  ),
                                  true
                                )}
                              </Text>

                              <Text
                                color={"white"}
                                fontWeight={"400"}
                                fontSize={"xs"}
                              >
                                /month
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </>
                    )}

                    {optionStep2.additionalDisk && (
                      <>
                        <HSeparator my={"8px"} />
                        <Flex h="100%">
                          <Flex
                            bgColor="green.200"
                            borderRadius="12px"
                            minWidth="3px"
                            minH="100%"
                          />
                          <Flex
                            justifyContent={"space-between"}
                            ml="1rem"
                            w={"100%"}
                          >
                            <Box>
                              <Text
                                color={"white"}
                                fontWeight={"600"}
                                fontSize={"sm"}
                              >
                                Addition Storage
                              </Text>
                              <Text
                                color={"white"}
                                fontWeight={"500"}
                                fontSize={"xs"}
                              >
                                {
                                  optionStep2?.additionalDisk?.blobs?.commercial
                                    ?.name
                                }
                              </Text>
                            </Box>
                            <Box>
                              <Text
                                color={"white"}
                                fontWeight={"500"}
                                fontSize={"xs"}
                              >
                                US${" "}
                                {genPriceOvh(
                                  genPriceByAddon(
                                    optionStep2?.additionalDisk,
                                    listDataCenters
                                  ),
                                  true
                                )}
                              </Text>

                              <Text
                                color={"white"}
                                fontWeight={"400"}
                                fontSize={"xs"}
                              >
                                /month
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </>
                    )}

                    {optionStep2.backup && (
                      <>
                        <HSeparator my={"8px"} />
                        <Flex h="100%">
                          <Flex
                            bgColor="green.200"
                            borderRadius="12px"
                            minWidth="3px"
                            minH="100%"
                          />
                          <Flex
                            justifyContent={"space-between"}
                            ml="1rem"
                            w={"100%"}
                          >
                            <Box>
                              <Text
                                color={"white"}
                                fontWeight={"600"}
                                fontSize={"sm"}
                              >
                                Automated backup
                              </Text>

                              <Text
                                color={"white"}
                                fontWeight={"500"}
                                fontSize={"xs"}
                              >
                                {optionStep2?.backup?.blobs?.commercial?.name}
                              </Text>
                            </Box>
                            <Box>
                              <Text
                                color={"white"}
                                fontWeight={"500"}
                                fontSize={"xs"}
                              >
                                US${" "}
                                {genPriceOvh(
                                  genPriceByAddon(
                                    optionStep2?.backup,
                                    listDataCenters
                                  ),
                                  true
                                )}
                              </Text>

                              <Text
                                color={"white"}
                                fontWeight={"400"}
                                fontSize={"xs"}
                              >
                                /month
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </>
                    )}
                  </Card>

                  <Card bgImage={generalBg} bgSize="cover" mt="1rem">
                    <CardHeader justifyContent="space-between">
                      <Text color="white" fontWeight={"600"} fontSize={"sm"}>
                        Contract duration
                      </Text>
                    </CardHeader>
                    <Box>
                      <RadioGroup value={duration}>
                        {genPriceDurationChosen?.duration?.map((item) => (
                          <GradientBorder w="100%" borderRadius="20px">
                            <Flex
                              pl="12px"
                              py="8px"
                              bg="rgb(31, 35, 89)"
                              border="transparent"
                              borderRadius="20px"
                              align="center"
                              w="100%"
                            >
                              <Radio
                                value={item?.value?.commitment}
                                onChange={() => {
                                  setDuration(item?.value?.commitment);
                                  if (!item?.value?.commitment)
                                    setBillCycle(BILLING_CYCLE_OVH.MONTH);
                                }}
                                colorScheme="white"
                                mr="8px"
                              />
                              <Text color="#fff" fontSize="xs">
                                {item?.label?.title}
                                {!item?.value?.commitment ? null : (
                                  <Text
                                    as="span"
                                    color={"green.200"}
                                    fontWeight={"500"}
                                  >
                                    {" "}
                                    Save {item?.label?.extra} per month
                                  </Text>
                                )}
                              </Text>
                            </Flex>
                          </GradientBorder>
                        ))}
                      </RadioGroup>
                    </Box>

                    {duration !== 0 && (
                      <>
                        <HSeparator my="1rem" />
                        <CardHeader justifyContent="space-between">
                          <Text
                            color="white"
                            fontWeight={"600"}
                            fontSize={"sm"}
                          >
                            Billing cycle
                          </Text>
                        </CardHeader>

                        <Box>
                          <RadioGroup value={billCycle}>
                            <GradientBorder w="100%" borderRadius="20px">
                              <Flex
                                pl="12px"
                                py="8px"
                                bg="rgb(31, 35, 89)"
                                border="transparent"
                                borderRadius="20px"
                                align="center"
                                w="100%"
                              >
                                <Radio
                                  value={BILLING_CYCLE_OVH.MONTH}
                                  onChange={() =>
                                    setBillCycle(BILLING_CYCLE_OVH.MONTH)
                                  }
                                  colorScheme="white"
                                  mr="8px"
                                />
                                <Text color="#fff" fontSize="xs">
                                  Monthly
                                </Text>
                              </Flex>
                            </GradientBorder>

                            <GradientBorder w="100%" borderRadius="20px">
                              <Flex
                                px="12px"
                                py="8px"
                                bg="rgb(31, 35, 89)"
                                border="transparent"
                                borderRadius="20px"
                                align="center"
                                w="100%"
                              >
                                <Radio
                                  value={BILLING_CYCLE_OVH.ALL}
                                  onChange={() =>
                                    setBillCycle(BILLING_CYCLE_OVH.ALL)
                                  }
                                  colorScheme="white"
                                  mr="8px"
                                />
                                <Text color="#fff" fontSize="xs">
                                  Up-front:{" "}
                                  <Text
                                    as="span"
                                    color={"green.200"}
                                    fontWeight={"500"}
                                  >
                                    Get an additional US$
                                    {genPriceOvh(getDiscountBillingCycle)} off
                                    on your total bill
                                  </Text>
                                </Text>
                              </Flex>
                            </GradientBorder>
                          </RadioGroup>
                        </Box>
                      </>
                    )}
                  </Card>

                  <Flex mt="1rem" justifyContent={"space-between"}>
                    <Box>
                      <Text color={"white"} fontSize={"md"} fontWeight={"400"}>
                        Total ex. GST
                      </Text>
                      <Text
                        color={"green.200"}
                        fontSize={"xs"}
                        fontWeight={"400"}
                      >
                        Including the first {genDueDate().including} of use
                      </Text>
                    </Box>
                    <Text color={"white"} fontSize={"xl"} fontWeight={"600"}>
                      {getLanguage() == "en" ? `US$` : ""}{" "}
                      {convertToVnd(
                        genPriceOvh(
                          pricePackage?.total +
                            genPriceOs +
                            genPriceByAddon(
                              optionStep2?.snapshot,
                              listDataCenters
                            ) +
                            genPriceByAddon(
                              optionStep2?.additionalDisk,
                              listDataCenters
                            ) +
                            genPriceByAddon(
                              optionStep2?.backup,
                              listDataCenters
                            ) +
                            genPriceLicense?.price,
                          true
                        ),
                        getLanguage()
                      )}
                    </Text>
                  </Flex>

                  <HSeparator my="12px" />

                  <Flex mt="1rem" justifyContent={"space-between"}>
                    <Box>
                      <Text color={"white"} fontSize={"md"} fontWeight={"400"}>
                        {genDueDate().withThisConfig}
                      </Text>
                      <Text
                        color={"green.200"}
                        fontSize={"xs"}
                        fontWeight={"400"}
                      >
                        with this configuration
                      </Text>
                    </Box>
                    <Text color={"white"} fontSize={"xl"} fontWeight={"600"}>
                      {getLanguage() == "en" ? `US$` : ""}{" "}
                      {convertToVnd(
                        genPriceOvh(
                          pricePackage?.total +
                            genPriceOs +
                            genPriceByAddon(
                              optionStep2?.snapshot,
                              listDataCenters
                            ) +
                            genPriceByAddon(
                              optionStep2?.additionalDisk,
                              listDataCenters
                            ) +
                            genPriceByAddon(
                              optionStep2?.backup,
                              listDataCenters
                            ) +
                            genPriceLicense?.price,
                          true
                        ),
                        getLanguage()
                      )}
                    </Text>
                  </Flex>
                </Card>
              </GridItem>
            )}
          </Grid>
        </Box>
      )}
    </Flex>
  );
}

export default ServiceCreate;
