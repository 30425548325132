import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Tag,
  Text,
  useToast,
  Select as SelectChakra,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import logo from "../../assets/img/logo.png";
import bgInvoice from "assets/img/background.png";
import { useState } from "react";
import { PiPlus } from "react-icons/pi";
import { CloseIcon } from "@chakra-ui/icons";
import { useMemo } from "react";
import { formatMoney } from "utils";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import { createNewPaymentHistory } from "services/api";
import { getAllUsers } from "services/api";
import { useEffect } from "react";
import { getPaymentHistoryById } from "services/api";
import { updatePaymentHistory } from "services/api";

const clouds = [
  {
    id: 1,
    name: "IP (CPU: 2 Core - RAM: 4GB - SSD: 60GB)",
    price: 290000,
  },
  {
    id: 2,
    name: "IP (CPU: 4 Core - RAM: 8GB - SSD: 90GB)",
    price: 590000,
  },
  {
    id: 3,
    name: "IP (CPU: 4 Core - RAM: 16GB - SSD: 150GB)",
    price: 1299000,
  },
  {
    id: 4,
    name: "IP (CPU: 8 Core - RAM: 8GB - SSD: 240GB)",
    price: 1599000,
  },
  {
    id: 5,
    name: "IP (CPU: 8 Core - RAM: 16GB - SSD: 240GB)",
    price: 1999000,
  },
  {
    id: 6,
    name: "IP (CPU: 16 Core - RAM: 16GB - SSD: 240GB)",
    price: 2299000,
  },
  {
    id: 7,
    name: "IP (CPU: 16 Core - RAM: 32GB - SSD: 300GB)",
    price: 3800000,
  },
  {
    id: 8,
    name: "IP (CPU: 32 Core - RAM: 64GB - SSD: 300GB)",
    price: 5300000,
  },
];

function ManagerPaymentHistoryCreate() {
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const [payments, setPayments] = useState([
    {
      id: new Date().getTime(),
      name: clouds[0]?.name,
      isCustom: false,
      price: clouds[0]?.price,
      ip: "",
    },
  ]);
  const [errors, setErrors] = useState([]);
  const [dateOfInvoice, setDateOfInvoice] = useState(new Date());
  const [paymentNeededDate, setPaymentNeededDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const { id } = useParams();

  const getPaymentHistory = async (id) => {
    try {
      const result = await getPaymentHistoryById(id);

      const { data } = result.data;

      setDateOfInvoice(new Date(data?.dateOfInvoice));
      setPaymentNeededDate(new Date(data?.paymentNeededDate));
      setUser({
        value: data?.user?.id,
        label: data?.user?.email,
      });
      setPayments(
        data?.services?.map((item, index) => ({
          ...item,
          id: index,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const result = await getAllUsers();
      setUsers(
        (result?.data?.data || [])?.map((item) => ({
          value: item?.id,
          label: item?.email,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (id) {
      getPaymentHistory(id);
    }
  }, [id]);

  const handleAddPayment = () => {
    setPayments((prev) => [
      ...prev,
      {
        id: new Date().getTime(),
        name: clouds[0]?.name,
        isCustom: false,
        price: clouds[0]?.price,
        ip: "",
      },
    ]);
  };

  const handleUpdate = (id, name, price, isCustom, ip) => {
    setPayments((prev) => {
      const newPrev = [...prev];
      const index = prev?.findIndex((item) => item?.id === id);
      newPrev?.splice(index, 1, {
        id,
        name,
        price,
        isCustom,
        ip,
      });
      return newPrev;
    });
  };

  const handleDeletePayment = (id) => {
    setPayments((prev) => prev.filter((item) => item?.id !== id));
  };

  const genTotal = useMemo(() => {
    let total = 0;
    payments?.forEach((item) => {
      total += Number(item?.price) || 0;
    });
    return total;
  }, [payments]);

  const handleCreatePaymentHistory = async () => {
    try {
      console.log(user)
      if (!user?.value) {
        return toast({
          status: "error",
          title: "User is empty",
        });
      }

      const errors = [];
      payments?.forEach((item) => {
        if (!item?.name || !item?.ip || !item?.price) {
          errors.push({
            id: item?.id,
            message: "Information is empty",
          });
        }
      });
      setErrors(errors);
      if (errors?.length) {
        return;
      }

      const data = {
        dateOfInvoice,
        paymentNeededDate,
        services: payments || [],
        total: genTotal || 0,
        userId: user?.value,
      };

      if (id) {
        await updatePaymentHistory(id, data);

        toast({
          status: "success",
          title: "Update payment history is successfully",
        });

        return;
      }

      const result = await createNewPaymentHistory(data);

      toast({
        status: "success",
        title: "Create payment history is successfully",
      });

      history.push("/manager-payment-history/list");
    } catch (error) {
      toast({
        status: "error",
        title: id
          ? "Update payment history is failed"
          : "Create payment history is failed",
      });

      console.log(error);
    }
  };

  const renderItem = (id, isCustom, price, name, ip, index) => {
    if (isCustom)
      return (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="rgba(255, 255, 255, 0.4)"
          py={3}
          px={6}
        >
          <Box>
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="text"
              placeholder="Enter description..."
              minW="200px"
              h={"40px"}
              maxW="350px"
              fontSize="sm"
              value={name}
              onChange={(e) =>
                handleUpdate(id, e?.target?.value, price, isCustom, ip)
              }
            />

            <Flex mt={2} alignContent={"center"} gap={4}>
              <Input
                color="gray.400"
                bg="#0F1535"
                border="0.5px solid"
                borderColor="#E2E8F04D"
                borderRadius="15px"
                type="number"
                placeholder="Enter price..."
                minW="200px"
                h={"32px"}
                maxW="200px"
                fontSize="sm"
                value={price}
                onChange={(e) =>
                  handleUpdate(id, name, e?.target?.value, isCustom, ip)
                }
              />
              <Flex mt={2} alignContent={"center"} gap={4}>
                <Text fontSize={"14px"}>Custom</Text>
                <Checkbox
                  onChange={() =>
                    handleUpdate(
                      id,
                      clouds?.[0]?.name,
                      clouds?.[0]?.price,
                      false,
                      ip
                    )
                  }
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            <Flex alignItems={"center"} gap={4}>
              <Input
                color="gray.400"
                bg="#0F1535"
                border="0.5px solid"
                borderColor="#E2E8F04D"
                borderRadius="15px"
                type="text"
                placeholder="Enter IP..."
                minW="200px"
                maxW="200px"
                fontSize="sm"
                value={ip}
                onChange={(e) =>
                  handleUpdate(
                    id,
                    name,
                    price,
                    isCustom,
                    e?.target?.value || ""
                  )
                }
              />
              {index !== 0 ? (
                <CloseIcon
                  onClick={() => handleDeletePayment(id)}
                  color={"red"}
                  cursor={"pointer"}
                />
              ) : (
                <Box minW={"16px"} minH={"16px"}></Box>
              )}
            </Flex>
            {errors?.find((item) => item?.id === id) && (
              <Text mt={1} fontSize={12} color={"red"}>
                {errors?.find((item) => item?.id === id)?.message}
              </Text>
            )}
          </Box>
        </Flex>
      );

    return (
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        bg="rgba(255, 255, 255, 0.4)"
        py={3}
        px={6}
      >
        <Box>
          <SelectChakra
            color="gray.400"
            bg="#0F1535"
            border="0.5px solid"
            borderColor="#E2E8F04D"
            borderRadius="15px"
            size="sm"
            minW="350px"
            cursor="pointer"
            value={price}
            onChange={(e) => {
              handleUpdate(
                id,
                clouds?.find((item) => item?.price === Number(e?.target?.value))
                  ?.name,
                e?.target?.value,
                isCustom,
                ip
              );
            }}
          >
            {clouds?.map((item) => (
              <option value={item?.price} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </SelectChakra>

          <Flex mt={2} alignContent={"center"} gap={4}>
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="text"
              placeholder="Enter price..."
              minW="200px"
              h={"32px"}
              maxW="200px"
              fontSize="sm"
              isDisabled
              value={price}
            />
            <Flex mt={2} alignContent={"center"} gap={4}>
              <Text fontSize={"14px"}>Custom</Text>
              <Checkbox onChange={() => handleUpdate(id, "", "", true, ip)} />
            </Flex>
          </Flex>
        </Box>
        <Box>
          <Flex alignItems={"center"} gap={4}>
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="text"
              placeholder="Enter IP..."
              minW="200px"
              maxW="200px"
              fontSize="sm"
              value={ip}
              onChange={(e) =>
                handleUpdate(id, name, price, isCustom, e?.target?.value || "")
              }
            />

            {index !== 0 ? (
              <CloseIcon
                onClick={() => handleDeletePayment(id)}
                color={"red"}
                cursor={"pointer"}
              />
            ) : (
              <Box minW={"16px"} minH={"16px"}></Box>
            )}
          </Flex>
          {errors?.find((item) => item?.id === id) && (
            <Text mt={1} fontSize={12} color={"red"}>
              {errors?.find((item) => item?.id === id)?.message}
            </Text>
          )}
        </Box>
      </Flex>
    );
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
      <Card>
        <CardHeader mb="12px">
          <Box>
            <Text
              fontSize="lg"
              color="#fff"
              fontWeight="bold"
              mb="6px"
              textTransform={"uppercase"}
            >
              {id
                ? t("common.managerPaymentHistory.update")
                : t("common.managerPaymentHistory.create")}
            </Text>

            <Box mt={4} w={"300px"}>
              <Text color={"white"} fontSize={"16px"} fontWeight={"500"} mb={2}>
               {t("common.managerPaymentHistory.selectUser")}:
              </Text>
              <Select
                options={users}
                value={user}
                onChange={(e) => {
                  setUser(e);
                }}
                placeholder={t("common.managerPaymentHistory.selectUser")}
              />
            </Box>
          </Box>
        </CardHeader>

        <Box
          border={"1px solid"}
          borderColor={"gray.200"}
          width={"1000px"}
          minWidth={"1000px"}
          minHeight={"900px"}
          px="60px"
          py="70px"
          bgImage={bgInvoice}
          bgSize={"100% 100%"}
          bgRepeat={"no-repeat"}
          bgBlendMode={""}
        >
          <Image
            boxSize="30px 30px"
            objectFit="cover"
            src={logo}
            alt="Dan Abramov"
          />

          <Flex justifyContent={"space-between"}>
            <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
              <Box>
                <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                  BILL
                </Text>

                <Text
                  color={"white"}
                  mt="1"
                  fontSize={"13px"}
                  fontWeight={"500"}
                >
                  DATE OF INVOICE
                </Text>

                <Text
                  color={"white"}
                  mt="1"
                  fontSize={"13px"}
                  fontWeight={"500"}
                >
                  PAYMENT NEEDED DATE
                </Text>
              </Box>

              <Box textAlign={"right"}>
                <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                  -
                </Text>
                <Datetime
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  value={dateOfInvoice}
                  onChange={(e) => {
                    setDateOfInvoice(new Date(e));
                  }}
                  renderInput={(props) => (
                    <Input
                      {...props}
                      color="gray.400"
                      bg="#0F1535"
                      border="0.5px solid"
                      borderColor="#E2E8F04D"
                      borderRadius="15px"
                      type="text"
                      h={"28px"}
                      placeholder="Enter IP..."
                      minW="110px"
                      maxW="110px"
                      fontSize="sm"
                    />
                  )}
                />
                <Datetime
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  value={paymentNeededDate}
                  onChange={(e) => {
                    setPaymentNeededDate(new Date(e));
                  }}
                  renderInput={(props) => (
                    <Input
                      {...props}
                      color="gray.400"
                      bg="#0F1535"
                      border="0.5px solid"
                      borderColor="#E2E8F04D"
                      borderRadius="15px"
                      type="text"
                      h={"28px"}
                      placeholder="Enter IP..."
                      minW="110px"
                      maxW="110px"
                      fontSize="sm"
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex mt="6" w={"300px"} justifyContent={"space-between"}>
              <Box>
                <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                  PAYMENT METHOD
                </Text>

                <Tag
                  color={"white"}
                  bg={"green.500"}
                  mt="1"
                  size="md"
                  pb={2}
                  pt={2.5}
                  lineHeight={"1"}
                >
                  PAID
                </Tag>
              </Box>

              <Box textAlign={"right"}>
                <Text color={"white"} fontSize={"13px"} fontWeight={"500"}>
                  TRANSFER
                </Text>

                <Text
                  color={"white"}
                  mt="1"
                  fontSize={"13px"}
                  fontWeight={"500"}
                ></Text>
              </Box>
            </Flex>
          </Flex>

          {/* table item */}
          <Flex mt="16" py={4} px={6} bg={"white"}>
            <Text fontWeight={"600"} fontSize={"16px"}>
              INVOICE CONTENT
            </Text>
          </Flex>

          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            bg="rgba(135, 245, 219, 0.4)"
            py={4}
            px={6}
          >
            <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
              INVOICE DETAIL
            </Text>
            <Text color={"black"} fontSize={"13px"} fontWeight={"600"}>
              <Button
                p={0}
                bg={"brand.200"}
                color={"white"}
                _hover={{
                  bg: "brand.400",
                }}
                onClick={handleAddPayment}
              >
                <PiPlus size={20} />
              </Button>
            </Text>
          </Flex>
          {payments?.map((item, index) =>
            renderItem(
              item?.id,
              item?.isCustom,
              item?.price,
              item?.name,
              item?.ip,
              index
            )
          )}

          <Flex
            justifyContent={"flex-end"}
            alignItems={"center"}
            bg="linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6)"
            py={4}
            px={6}
            textColor={"black"}
          >
            <Text fontSize={"15px"} fontWeight={"600"}>
              TOTAL
            </Text>

            <Text
              width={"150px"}
              textAlign={"right"}
              fontSize={"15px"}
              fontWeight={"600"}
            >
              {/* {convertToUsd(
                itemServiceData?.reduce((total, item) => {
                  return total + Number(item?.service?.total);
                }, 0),
                getLanguage()
              )} */}
              {formatMoney(genTotal)}
            </Text>
          </Flex>

          <Box mt="8" textAlign={"right"}>
            <Text fontWeight={"600"} fontSize={"18px"}>
              VNG CLOUD TECHNOLOGY COMPANY LIMITED
            </Text>
            <Text fontWeight={"500"} fontSize={"13px"}>
              698/1/12 Truong Chinh Street, Ward 15, Tan Binh District, Ho Chi
              Minh City, Vietnam.
            </Text>
          </Box>
        </Box>

        <Flex
          mt="1rem"
          width={"1000px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Button
            w="125px"
            h="35px"
            bg={"red.400"}
            color={"white"}
            fontSize="xs"
            fontWeight="bold"
            alignSelf={{ sm: "flex-start", lg: null }}
            mb={{ sm: "12px", md: "0px" }}
            onClick={() => {
              history.push("/manager-payment-history/list");
            }}
          >
            {t("common.button.back")}
          </Button>

          <Button
            variant="brand"
            w="200px"
            h="35px"
            fontSize="xs"
            fontWeight="bold"
            alignSelf={{ sm: "flex-start", lg: null }}
            mb={{ sm: "12px", md: "0px" }}
            onClick={handleCreatePaymentHistory}
          >
            {id
              ? t("common.managerPaymentHistory.update")
              : t("common.managerPaymentHistory.create")}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}

export default ManagerPaymentHistoryCreate;
