import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

function ModalChangeDisplayNameVps({ onClose, onOk, show, defaultName }) {
  const [newName, setNewName] = useState("");

  useEffect(() => {
    if (show) {
      setNewName(defaultName || "");
    }
  }, [show]);

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor={"#5f68c9"}>
        <ModalHeader color={"white"}>Change the name</ModalHeader>
        <ModalCloseButton color={"white"} />

        <ModalBody>
          <Box>
            <Text color={"white"} fontWeight={"600"} fontSize={"sm"}>
              Default name: {defaultName}
            </Text>
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              w={"100%"}
              mt="1rem"
              _focus={{
                borderColor: "white",
              }}
              _active={{
                borderColor: "white",
              }}
              value={newName}
              onChange={(e) => setNewName(e?.target?.value)}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => onOk(newName)}
            colorScheme="purple"
            mr={3}
            size="sm"
          >
            Confirm
          </Button>
          <Button onClick={onClose} size="sm">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalChangeDisplayNameVps;
